// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { ECLContext } from "./ECLContext";
import { CredentialsContext } from "../credentials/CredentialsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { APIReturnObject, Client, CPTCode, ECLAnswer, ECLAnswerObject, ECLQuestion, Status, Therapist, TherapistSmallObject, Schedule } from "../../utils/Definitions";
import { Navigate, useNavigate } from "react-router-dom";
import { ECLQuestionNode } from "../../components/ecl/ECLQuestionNode";
import Select from "react-select";


import pic1562 from '../../images/1562.png';
import pic222 from '../../images/222.png';
import pic255 from '../../images/255.png';
import pic889 from '../../images/889.png';
import pic94 from '../../images/94.png'; 
import { addDays, format, isAfter, isBefore, isSameDay, isYesterday, parse, parseISO, set, subDays } from "date-fns";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import { ClassNames } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import { time } from "console";
import { timingSafeEqual } from "crypto";
import { ModalNotification } from "../../components/ModalNotification";
import { ECLViewLogCard } from "../../components/ecl/ECLViewLogCard";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePicture } from "../../components/ProfilePicture";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalConfirm } from "../../components/ModalConfirm";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

//only renders if authenticated
const ECLViewLogContent = () => {

    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);

    const eclContext = useContext(ECLContext);

    const credentialsContext = useContext(CredentialsContext);

    const [loading, setLoading] = useState<boolean>(true);

    const [pdfLoading, setPDFLoading] = useState<boolean>(false);


    const [addLogButtonDisabled, setAddLogButtonDisabled] = useState<boolean>(true);

    const [answerObject, setAnswerObject] = useState<ECLAnswerObject | null>(null);

    const [questions, setQuestions] = useState<ECLQuestion[]>();

    const [filteredQuestions, setFilteredQuestions] = useState<ECLQuestion[]>();


    const [selectedQuestion, setSelectedQuestion] = useState<ECLQuestion | null>(null);

    const [CPTCodes, setCPTCodes] = useState<CPTCode[] | null>();
    const [FilteredCPTCodes, setFilteredCPTCodes] = useState<CPTCode[] | null>();
    const [selectedCPTCode, setSelectedCPTCode] = useState<CPTCode | null>();

    const [servicePlaces, setServicePlaces] = useState<[{ value: string, label: string }] | null>();
    const [selectedServicePlace, setSelectedServicePlace] = useState<{ value: string, label: string } | null>();

    const [modeOfServices, setModeOfServices] = useState<[{ value: string, label: string }] | null>();
    const [selectedModeOfService, setSelectedModeOfService] = useState<{ value: string, label: string } | null>();

    const [RBTOptions, setRBTOptions] = useState<[{ value: string, label: string }]>();

    const [allTherapists, setAllTherapists] = useState<TherapistSmallObject[] | null>(null);

    const [billingDate, setBillingDate] = useState<Date | null>(new Date());
    const [timeIn, setTimeIn] = useState<Date | null>(null);
    const [timeInText, setTimeInText] = useState<string>("");

    const [timeOut, setTimeOut] = useState<Date | null>(null);
    const [timeOutText, setTimeOutText] = useState<string>("");

    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);
    const [addLogLoading, setAddLogLoading] = useState<boolean>(false);

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [futureModalActive, setFutureModalActive] = useState<boolean>(false);
    const [futureMessage, setFutureMessage] = useState<string>("");

    const [questionStack, setQuestionStack] = useState<ECLQuestion[]>([]);

    const [editTimeVerification, setEditTimeVerification] = useState<boolean>(false);

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    function ProfilePictureOnCLose() {
        setSelectedClientForModal(null);
    }

    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);

    const [overlapModalActive, setOverlapModalActive] = useState<boolean>(false);
    const [overlapMessage, setOverlapMessage] = useState<string>("");

    const [timeModalActive, setTimeModalActive] = useState<boolean>(false);
    const [timeMessage, setTimeMessage] = useState<string>("");

    var tempFlatQuestions: ECLQuestion[] = [];
    const [flatQuestions, setFlatQuestions] = useState<ECLQuestion[]>([]);

    const [billingDaySchedule, setBillingDaySchedule] = useState<Schedule | null>();

    function validatePDFBlob(blob: Blob): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const bytes = new Uint8Array(reader.result as ArrayBuffer);
                const isValidPDF = bytes[0] === 0x25 && bytes[1] === 0x50 && bytes[2] === 0x44 && bytes[3] === 0x46;
                resolve(isValidPDF);
            };
            reader.readAsArrayBuffer(blob.slice(0, 4)); // Read the first 4 bytes for PDF header check
        });
    }

    useEffect(() => {
        if (eclContext.selectedClient && billingDate) {
            GetSchdedule();
        }
    }, [billingDate]);

    function GetSchdedule() {
        if (billingDate) {
            var dayID = billingDate.getDay() + 1;

            var schedule = credentialsContext.schedules.find(x => x.therapyScheduleId == eclContext.selectedClient?.therapyScheduleId && x.dayOfWeekId == dayID);

            if (schedule) {
                setBillingDaySchedule(schedule);
            }
        }
        
    }

    function CreatePDF() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/ECL/PrintECLLog?id=" + eclContext.selectedEditAnswer?.logId;

            setPDFLoading(true);

            fetch(url, options)
                .then((response) => {
                    if (response.ok) {
                        return response.blob();
                    } else {
                        throw new Error("Failed to fetch the PDF.");
                    }
                })
                .then((blob) => {
                    // Validate that the blob is a PDF
                    validatePDFBlob(blob)
                        .then((isValidPDF) => {
                            if (isValidPDF) {
                                // Create a blob URL for the PDF content
                                const pdfBlobUrl = window.URL.createObjectURL(blob);

                                // Open the PDF in a new tab
                                window.open(pdfBlobUrl, "_blank");

                                // Clean up the blob URL when it's no longer needed
                                window.URL.revokeObjectURL(pdfBlobUrl);

                                setPDFLoading(false);

                            } else {
                                setPDFLoading(false);

                                throw new Error("The fetched content is not a valid PDF.");
                            }
                        })
                        .catch((error) => {
                            setPDFLoading(false);

                            globalContext.setErrorModalProps({ errorMessage: error.message, errorLocation: "/ECL/PrintECLLog" });
                            globalContext.setErrorModalActive(true);
                        });
                })
                .catch((error) => {
                    setPDFLoading(false);

                    globalContext.setErrorModalProps({ errorMessage: error.message, errorLocation: "/ECL/PrintECLLog" });
                    globalContext.setErrorModalActive(true);
                });
        });
    }
                    

    function UpdateModesOfService() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/ECL/GetModesOfService";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setModeOfServices(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/GetModesOfService" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateServicePlaces() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Admin/GetPlacesOfService?app=ECL";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setServicePlaces(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetPlacesOfService" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateCPTCodes() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Admin/GetCPTCodes?app=ecl";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setCPTCodes(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetCPTCodes" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function GenerateFlatQuestions(questions: ECLQuestion[]) {
        tempFlatQuestions = tempFlatQuestions.concat(questions);

        questions.forEach((q) => {
            if (Array.isArray(q.children)) {
                GenerateFlatQuestions(q.children);
            }
        })

    }

    useEffect(() => {
        if (CPTCodes) {

            // filter CPTcodes by state
            var tempcodes: CPTCode[] = CPTCodes.filter(x => x.value != "H0032" && x.value != "G9012" && x.value != "97152");

            //add michigan codes
            if ((eclContext.selectedCenter && eclContext.selectedCenter.state == "MI") || (eclContext.selectedClient && eclContext.selectedClient.isMimedicaid)) {
                CPTCodes.filter(x => x.value == "H0032" || x.value == "97152").forEach(x => tempcodes.push(x));
            }

            //add iowa codes
            if ((eclContext.selectedCenter && eclContext.selectedCenter.state == "IA") || (eclContext.selectedClient && eclContext.selectedClient.isIamedicaid)) {
                CPTCodes.filter(x => x.value == "G9012" || x.value == "97152").forEach(x => tempcodes.push(x));
            }

            setFilteredCPTCodes(tempcodes);
        }

    }, [CPTCodes]);

    useEffect(() => {
        if (questions) {
            //reset tempflatquestions, then regenerate them.
            tempFlatQuestions = [];

            GenerateFlatQuestions(questions);

            setFlatQuestions(tempFlatQuestions);
        }

    }, [questions]);

    function UpdateQuestions() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Admin/GetQuestions?app=ecl";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setQuestions(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetQuestions" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateAllTherapists() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Centers/GetAllTherapists";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setAllTherapists(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetAllTherapists" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function SuccessModalClose() {
        NavigateBack();
    }

    useEffect(() => {

        if (selectedCPTCode && questions) {
            if (questions) {
                setFilteredQuestions(questions.filter(question => String(question.cptcode) == String(selectedCPTCode.value)));
            }
        }
    }, [selectedCPTCode, questions]);

    useEffect(() => {

        if (selectedCPTCode && answerObject) {
            setAnswerObject({ ...answerObject, cptcode: selectedCPTCode.value });
        }
    }, [selectedCPTCode]);

    useEffect(() => {
            UpdateQuestions();
            UpdateAllTherapists();
            UpdateServicePlaces();
            UpdateCPTCodes();
            UpdateModesOfService();
    }, [eclContext.selectedEditAnswer]);

    useEffect(() => {

        if (!selectedModeOfService && modeOfServices && eclContext.selectedEditAnswer) {
            //autoselect edit
            var modeofservicefound = modeOfServices.find(x => x.value == String(eclContext.selectedEditAnswer?.modeOfServiceId));

            if (modeofservicefound) {
                setSelectedModeOfService(modeofservicefound);
            }
        }
        else if (!selectedModeOfService && modeOfServices) {
            //autoselect fresh
            setSelectedModeOfService(modeOfServices[0]);
        }
    }, [modeOfServices])

    useEffect(() => {
        if (!eclContext.selectedClient) {
            navigate("/ecl/logs");
        }
    })

    useEffect(() => {

        if (editTimeVerification) {
            TimeInValidateTime(false, null);
            TimeOutValidateTime(false, null);
        }
    }, [editTimeVerification])
    

    useEffect(() => {

        if (!answerObject) {
            ResetAnswerObject();
        }
    }, [])

    function GetQuestionByID(id: number, array: ECLQuestion[]): ECLQuestion | undefined {
        for (const node of array) {
            if (node.questionId === id) return node;
            if (node.children) {
                const child = GetQuestionByID(id, node.children);
                if (child) return child;
            }
        }
    }

    useEffect(() => {
        if (answerObject) {
            console.log("answerObject:", answerObject);
        }
    }, [answerObject, answerObject?.answers])

    useEffect(() => {

        if (eclContext.selectedEditAnswer && questions && CPTCodes) {
            //autoselect cptcode
            var cptcoderesult = CPTCodes.find(x => x.value == eclContext.selectedEditAnswer?.cptcode)
            if (cptcoderesult) {
                setSelectedCPTCode(cptcoderesult);
            }

            //autoselect billing date
            if (eclContext.selectedEditAnswer.billingDate) {
                setBillingDate(new Date(eclContext.selectedEditAnswer.billingDate));
            }

            //autoselect time in
            if (eclContext.selectedEditAnswer.startTime) {
                setTimeInText(new Date(eclContext.selectedEditAnswer.startTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
                setEditTimeVerification(true);
            }

            //autoselect time out
            if (eclContext.selectedEditAnswer.endTime) {
                setTimeOutText(new Date(eclContext.selectedEditAnswer.endTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit"}));
                setEditTimeVerification(true);
            }

            //set question stack from answer
            if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.answers && eclContext.selectedEditAnswer.answers[0].questionId && questions) {

                var Continue = true;
                var QuestionID = eclContext.selectedEditAnswer.answers[0].questionId;
                while (Continue) {

                    var questionresult = GetQuestionByID(QuestionID, questions);
                    if (questionresult) {

                        //push only tree into stack, ignore actual question forms
                        if (questionresult.type == "none") {
                            var tempQuestionStack = questionStack;
                            tempQuestionStack.push(questionresult);

                            setQuestionStack(tempQuestionStack);
                        }

                        //if question has a parent, set parent as next question id
                        if (questionresult.parentQuestionId) {
                            QuestionID = questionresult.parentQuestionId;
                        }
                        else {
                            Continue = false;
                        }
                    }
                    else {
                        Continue = false;
                    }
                }
            }
            

        }
    }, [eclContext.selectedEditAnswer, questions, CPTCodes]);

    function ResetAnswerObject() {

        var TherapistID: number | null = null;
        if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.therapistID) {
            TherapistID = globalContext.CoreAccount.currentUser.therapistID;
        }


        if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.therapistId) {
            TherapistID = eclContext.selectedEditAnswer.therapistId;
        }

        setAnswerObject({
            answers: [],
            logId: null,
            startTime: null,
            endTime: null,
            createdDate: null,
            approvedDate: null,
            clientId: eclContext.selectedClient ? eclContext.selectedClient.clientId : null,
            cptcode: null,
            narrative: null,
            billingDate: null,
            therapistId: TherapistID,
            dpnstatusId: null,
            active: true,
            harvested: false,
            client: null,
            dpnstatus: null,
            therapist: null,
            ecldpnanswers: null,
            placeOfServiceId: null,
            centerId: null,
            updatedOn: null,
            updatedBy: null,
            approvedById: null,
            podId: null,
            clientName: null,
            comments: null,
            modeOfServiceId: null,
            isIamedicaid: null,
            isIlmedicaid: null,
            isInmedicaid: null,
            isMimedicaid: null,
            logSetId: null,
            timezone: null,
            showApproveButton: null,
            therapyScheduleId: null,
            therapySchedule: null,
            companyInsuranceId: null,
        })

    }

    useEffect(() => {
        if (answerObject) {
            UpdateDiableAddLogButton();
        }
    }, [answerObject])

    
    useEffect(() => {
        UpdateDiableAddLogButton();
    }, [timeIn, timeOut, billingDate])

    useEffect(() => {

        if (!selectedServicePlace && servicePlaces && eclContext.selectedEditAnswer) {
            //autoselect edit

            var placeofservicefound = servicePlaces.find(x => x.value == String(eclContext.selectedEditAnswer?.placeOfServiceId));

            if (placeofservicefound) {
                setSelectedServicePlace(placeofservicefound);
            }
        }
        else if (!selectedServicePlace && servicePlaces) {
            //autoselect fresh
            setSelectedServicePlace(servicePlaces[0]);
        }
    }, [servicePlaces])

    function NavigateBack() {
        navigate(-1);
    }

    function GetTherapistNameByID(id: number | null): string {
        if (allTherapists) {
            var therapist = allTherapists.find(x => x.therapistId == id);
            if (therapist) {
                return therapist.employeeName;
            }
            else if (id == -1) {
                return "";
            }
            else {
                if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.name) {
                    return globalContext.CoreAccount.currentUser.name;
                }
                else {
                    return "Unknown Clinician";
                }
            }
        }
        else {
            return "Unknown Clinician";
        }
    }

    async function LogOverlap(token: string, eclanswerobject: ECLAnswerObject | null) {

        if (eclanswerobject) {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(eclanswerobject)
            };

            const response = await fetch(apiConfig.apiEndpoint + "/ECL/InsertLogCheck", options);
            var overlappinglogs: ECLAnswerObject[] = await response.json();

            if (overlappinglogs.length > 0) {
                //overlap!

                var overlapmessagetemp = "<div style='height:1rem'></div>";
                var tempcount = 1;
                overlappinglogs.forEach(x => {

                    overlapmessagetemp += "<div style='border: 1px solid black; padding: .5rem'>";
                    overlapmessagetemp += "<div><strong>Clinician: </strong><span>" + GetTherapistNameByID(x.therapistId) + "</span></div>";
                    overlapmessagetemp += "<div><strong>Learner: </strong><span>" + x.clientName + "</span></div>";
                    overlapmessagetemp += "<div><strong>Service Date: </strong><span>" + (x.billingDate ? new Date(x.billingDate).toLocaleDateString() : "") + "</span></div>";
                    overlapmessagetemp += "<div><strong>Start Time: </strong><span>" + (x.startTime ? new Date(x.startTime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }) : "") + "</span></div>";
                    overlapmessagetemp += "<div><strong>End Time: </strong><span>" + (x.endTime ? new Date(x.endTime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }) : "") + "</span></div>";
                    overlapmessagetemp += "</div>";

                    if (overlappinglogs.length > tempcount) {
                        overlapmessagetemp += "<div style='height:1rem'></div>";
                    }
                    tempcount += 1;
                })
                overlapmessagetemp += "</div>";


                setOverlapMessage(overlapmessagetemp);
                setOverlapModalActive(true);

                return true;
            }
            else {
                //no overlap, continue
                return false;
            }
        }
        else {
            return true;
        }


    }

    function EditLogCheck() {
        if (eclContext.selectedCenter && eclContext.selectedCenter.timezone && billingDate && timeIn && timeOut) {
            //I LOVE TIMEZONES I LOVE TIMEZONES I LOVE TIMEZONES I LOVE TIMEZONES I LOVE TIMEZONES I LOVE TIMEZONES I LOVE TIMEZONES I LOVE TIMEZONES I LOVE TIMEZONES I LOVE TIMEZONES I LOVE TIMEZONES

            //billing date
            const billingdateLOCAL = new Date(billingDate.toLocaleString());
            const billingdateCENTER = set(billingdateLOCAL, { hours: 12, minutes: 0, seconds: 0 });

            //starttime
            const timeinCENTER = zonedTimeToUtc(set(billingdateCENTER, { hours: timeIn.getHours(), minutes: timeIn.getMinutes(), seconds: timeIn.getSeconds() }), eclContext.selectedCenter.timezone);

            //endtime
            const timeoutCENTER = zonedTimeToUtc(set(billingdateCENTER, { hours: timeOut.getHours(), minutes: timeOut.getMinutes(), seconds: timeOut.getSeconds() }), eclContext.selectedCenter.timezone);

            //center current time
            const centercurrenttimeCENTER = new Date();

            //calculate log length
            const time1 = timeinCENTER.getTime();
            const time2 = timeoutCENTER.getTime();
            const diffInMs = Math.abs(time1 - time2);
            const diffInHours = Number((diffInMs / (1000 * 60 * 60)).toFixed(2));

            //FUTURE CHECK
            if (centercurrenttimeCENTER && isSameDay(billingdateCENTER, centercurrenttimeCENTER) && ((centercurrenttimeCENTER.getTime() < timeinCENTER.getTime()) || (centercurrenttimeCENTER.getTime() < timeoutCENTER.getTime()))) {
                setFutureMessage("<div>You cannot edit logs to have a start or end time in the future.</div>");
                setFutureModalActive(true);

                return;
            }

            //CHECK LOG LENGTH
            if (diffInHours > 3) {
                //warn user
                setTimeMessage(diffInHours.toString());
                setTimeModalActive(true);

                return;
            }
        }

        //if log passes all checks, add log
        EditLog();
    }

    function EditLog() {

        setAddLogLoading(true); // Show loading spinner

        var finalAnswerObject = answerObject;

        if (answerObject && finalAnswerObject) {
            // Set cptcode if selectedCPTCode is available
            if (selectedCPTCode && selectedCPTCode.value) {
                finalAnswerObject.cptcode = selectedCPTCode.value;
            }

            // Set logId if selectedEditAnswer is available
            if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.logId) {
                finalAnswerObject.logId = eclContext.selectedEditAnswer.logId;
            }

            // Set dpnstatusId if selectedEditAnswer is available
            if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.dpnstatusId) {
                finalAnswerObject.dpnstatusId = eclContext.selectedEditAnswer.dpnstatusId;
            }

            // Set createdDate if selectedEditAnswer is available
            if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.createdDate) {
                finalAnswerObject.createdDate = new Date(new Date(eclContext.selectedEditAnswer.createdDate).toLocaleString() + " +0000");
            }

            // Set billingDate, startTime, and endTime if billingDate, timeIn, and timeOut are available
            if (billingDate && timeIn && timeOut) {
                finalAnswerObject.billingDate = new Date(billingDate.toLocaleString() + " +0000")
                finalAnswerObject.startTime = new Date(timeIn.toLocaleString() + " +0000")
                finalAnswerObject.endTime = new Date(timeOut.toLocaleString() + " +0000")
            }

            // Set placeOfServiceId if selectedServicePlace is available
            if (selectedServicePlace) {
                finalAnswerObject.placeOfServiceId = Number(selectedServicePlace.value);
            }

            //set mode of service
            if (selectedModeOfService) {
                finalAnswerObject.modeOfServiceId = Number(selectedModeOfService.value);
            }

            // Set centerId if selectedEditAnswer is available
            if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.centerId) {
                finalAnswerObject.centerId = eclContext.selectedEditAnswer.centerId;
            }

            // Set podId if selectedEditAnswer is available
            if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.podId) {
                finalAnswerObject.podId = eclContext.selectedEditAnswer.podId;
            }

            // Set approvedDate
            if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.approvedDate) {
                finalAnswerObject.approvedDate = new Date(new Date(eclContext.selectedEditAnswer.approvedDate).toLocaleString() + " +0000");
            }

            // Set approvedByID
            if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.approvedById) {
                finalAnswerObject.approvedById = eclContext.selectedEditAnswer.approvedById;
            }
        }

        globalContext.GetToken().then((token: any) => {

            //test for log overlap
            LogOverlap(token, finalAnswerObject).then((overlap) => {

                if (overlap) {
                    setAddLogLoading(false);
                }
                else {
                    var headers = new Headers();
                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    headers.append("Content-type", "application/json;charset=UTF-8");

                    var options = {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify(finalAnswerObject)
                    };

                    console.log("final answer object edit:", finalAnswerObject);

                    // Log the size of finalAnswerObject in kb for debugging purposes
                    const size = new TextEncoder().encode(JSON.stringify(finalAnswerObject)).length;
                    const kiloBytes = size / 1024;
                    console.log("size:" + kiloBytes + " kb ", finalAnswerObject);

                    var url = apiConfig.apiEndpoint + "/ECL/EditLog";

                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result: APIReturnObject) => {
                                if (result.status === Status.SUCCESS) {
                                    setAddLogLoading(false);
                                    setSuccessModalActive(true);
                                }
                                else if (result.status === Status.FAIL) {
                                    globalContext.setNotificationModalMessage("Cannot edit log. " + result.message);
                                    globalContext.setNotificationModalActive(true);
                                    setAddLogLoading(false);
                                }
                                else if (result.status === Status.ERROR) {
                                    globalContext.setErrorModalProps({ errorMessage: "Failed to edit log. Unknown error.", errorLocation: "/ECL/EditLog" });
                                    globalContext.setErrorModalActive(true);
                                    setAddLogLoading(false);
                                }
                            },
                            (error) => {
                                setAddLogLoading(false);
                                globalContext.setErrorModalProps({ errorMessage: "Failed to edit log. Unknown error.", errorLocation: "/ECL/EditLog" });
                                globalContext.setErrorModalActive(true);
                            }
                        )
                }
                
            })
            
        })
    }


    function UpdateDiableAddLogButton() {

        //loop through all answers, if one answer is blank, set allanswerscomplete to false
        let AllAnswersComplete = true;
        if (answerObject && answerObject.answers) {

            //only check required questions
            var RequiredQuestions = flatQuestions.filter(x => x.type != "multirbt");

            //rbt present
            var RBTPresentQuestions = flatQuestions.filter(x => x.type == "rbt");

            answerObject.answers.filter(a =>
                RequiredQuestions.find(y => y.questionId == a.questionId)
                && (!a.choiceValue || a.choiceValue == -1) 
                && (!a.numberValue)
                && (!a.textValue || a.textValue.length == 0 || RBTPresentQuestions.find(z => z.questionId == a.questionId && a.textValue == "-1"))
                && (a.boolValue == null)
            ).forEach(z => AllAnswersComplete = false);
        }

        //logic for add log button
        setAddLogButtonDisabled(
            !answerObject ||
            !answerObject.answers ||
            answerObject.answers.length == 0 ||
            !AllAnswersComplete ||
            billingDate == null ||
            timeIn == null ||
            timeOut == null
        );
    }

    const AddLogButton = () => {

        if (addLogLoading) {
            return (
                <button disabled={true} className="m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacblue-600">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else {
            return (<button onClick={() => EditLogCheck()} disabled={addLogButtonDisabled} className={"bg-lacblue m-auto w-full w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 " + (addLogButtonDisabled ? 'bg-lacblue-800 hover:bg-lacblue-800' : '')}><span className="drop-shadow">Edit Log</span></button>)
        }
    }

    function TimeInValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempTimeInText = timeInText.replace(/\s+/g, '').trim()

        var d1 = parse(tempTimeInText, "h:mm a", new Date());
        var d2 = parse(tempTimeInText, "h:mma", new Date());
        var d3 = parse(tempTimeInText, "hmma", new Date());
        var d4 = parse(tempTimeInText, "h a", new Date());
        var d5 = parse(tempTimeInText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempTimeInText != dformatted) {
                    setTimeInText(dformatted);
                }

                //check for negative time
                if ((timeOut && isBefore(d, timeOut)) || (SiblingDate && isBefore(d, SiblingDate))) {
                    //update time in
                    setTimeIn(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeOutValidateTime(false, d);
                    }
                }
                else if (!timeOut) {
                    setTimeIn(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeOutValidateTime(false, d);
                    }
                }
                else {
                    setTimeIn(null);
                }

            }
        }
        else {
            setTimeIn(null);
        }
    }

    function TimeOutValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempTimeOutText = timeOutText.replace(/\s+/g, '').trim()

        var d1 = parse(tempTimeOutText, "h:mm a", new Date());
        var d2 = parse(tempTimeOutText, "h:mma", new Date());
        var d3 = parse(tempTimeOutText, "hmma", new Date());
        var d4 = parse(tempTimeOutText, "h a", new Date());
        var d5 = parse(tempTimeOutText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempTimeOutText != dformatted) {
                    setTimeOutText(dformatted);
                }

                //check for negative time
                if ((timeIn && isAfter(d, timeIn)) || (SiblingDate && isAfter(d, SiblingDate))) {
                    //update time out
                    setTimeOut(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeInValidateTime(false, d);
                    }
                }
                else if (!timeIn) {
                    setTimeOut(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeInValidateTime(false, d);
                    }
                }
                else {

                    setTimeOut(null);
                }
            }
        }
        else {
            setTimeOut(null);
        }
    }



    const LogEditView = () => {
        if (eclContext.selectedEditAnswer) {
            return <ECLViewLogCard Log={eclContext.selectedEditAnswer} Therapists={allTherapists} DeleteButtonVisible={false} EditButtonVisible={false} ApproveButtonVisible={false} ApproveLog={function noop() { }} DeleteLog={function noop() { }} SetCredentialsModalActive={setCredentialsModalActive} />
        }
        else {
            return <></>
        }

    }

    const GetMinBillingDate = () => {
        //get today at mightnight
        var minDate = new Date();
        minDate.setHours(0, 0, 0, 0);

        if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.billingDate) {

            if (isBefore(new Date(eclContext.selectedEditAnswer.billingDate), minDate)) {
                return eclContext.selectedEditAnswer.billingDate;
            }
            else {
                return subDays(new Date(), 1);
            }
        }


        return minDate;
    }

    const CreatePDFButton = () => {

        if (pdfLoading) {
            return (
                <button disabled={true} className="ml-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-purple-600">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Creating</span>
                </button>
            );
        }
        else {
            return (<button onClick={CreatePDF} disabled={pdfLoading} className={"bg-purple ml-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"}><span className="drop-shadow">Create PDF</span></button>
)
        }
    }


    if (eclContext.selectedClient) {
        return (
            <div className="ml-auto mr-auto p-2">
                <div className="grid grid-cols-2 h-fit mt-0 mb-auto">

                    <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-40 cursor-pointer noprint col-span-1">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to Logs</span>
                        <div className="flex-1"></div>
                    </div>

                    <div className="my-2 mb-8 flex items-right w-full cursor-pointer col-span-1 noprint">
                        <CreatePDFButton/>
                    </div>

                    


                    <ProfilePicture isEditable={false} Client={eclContext.selectedClient} setProfilePictureModalActive={setProfilePictureModalActive} setSelectedClientForModal={setSelectedClientForModal} />

                    <table>
                        <tbody>
                            <tr>
                                <td><span className="font-bold">Learner Name:</span></td>
                                <td><span className="text-bold ml-2">{eclContext.selectedClient.fullName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Birthday:</span></td>
                                <td><span className="text-bold ml-2">{new Date(eclContext.selectedClient.dob).toLocaleDateString()}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Insurance Provider:</span></td>
                                <td><span className="text-bold ml-2">{eclContext.selectedClient.insuranceName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">RBT Required:</span></td>
                                <td><span className="text-bold ml-2">{eclContext.selectedClient.rbtrequired == null ? '' : eclContext.selectedClient.rbtrequired ? 'Yes' : 'No'}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Telehealth Allowed:</span></td>
                                <td><span className="text-bold ml-2">{eclContext.selectedClient.telehealthAllowed == null ? '' : eclContext.selectedClient.telehealthAllowed ? 'Yes' : 'No'}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Billing Day Schedule:</span></td>
                                <td><span className="text-bold ml-2">{billingDaySchedule ? new Date(billingDaySchedule.startTime).toLocaleTimeString() + "-" + new Date(billingDaySchedule.endTime).toLocaleTimeString() : "N/A"}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="col-span-2 mt-8 xl:inline hidden noprint">
                    <span className="text-lg font-bold">Original Log:</span>
                    <LogEditView />
                </div>

                <div className="grid gap-8 grid-cols-12 xl:w-[40rem] w-full m-auto mt-4 mb-8">

                    <div className="w-full xl:col-span-12 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Service Type:
                        </label>
                        <Select isDisabled={true} options={FilteredCPTCodes ? FilteredCPTCodes : undefined} onChange={setSelectedCPTCode} value={selectedCPTCode} />
                    </div>

                    <div className="w-full xl:col-span-6 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Place of Service:
                        </label>
                        <Select isDisabled={true} options={servicePlaces ? servicePlaces : undefined} onChange={setSelectedServicePlace} value={selectedServicePlace} />
                    </div>

                    <div className="w-full xl:col-span-6 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Mode of Service:
                        </label>
                        <Select isDisabled={true} options={modeOfServices ? modeOfServices : undefined} onChange={setSelectedModeOfService} value={selectedModeOfService} />
                    </div>

                    <div className="w-full m-auto xl:col-span-4 col-span-12">
                        <label className="block font-bold">
                            Service Date:
                        </label>
                        <DatePicker
                            value={billingDate}
                            disabled={true}
                            disableFuture={true}
                            minDate={GetMinBillingDate()}
                            onChange={(newValue) => {
                                setBillingDate(newValue);
                            }}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </div>

                    <div className="w-full m-auto xl:col-span-4 col-span-12">
                        <label className="block font-bold" htmlFor="search">
                            Time In:
                        </label>
                        <input disabled={true} onBlur={() => TimeInValidateTime(true, null)} onChange={event => setTimeInText(event.target.value)} value={timeInText} autoComplete="off" type="text" className={"outline-none h-[38px]  text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (timeInText.length > 0 && !timeIn ? 'border-2 border-rose' : 'border border-gray-300')} placeholder="hh:mm aa" />
                    </div>

                    <div className="w-full m-auto xl:col-span-4 col-span-12">
                        <label className="block font-bold" htmlFor="search">
                            Time Out:
                        </label>
                        <input disabled={true} onBlur={() => TimeOutValidateTime(true, null)} onChange={event => setTimeOutText(event.target.value)} value={timeOutText} autoComplete="off" type="text" className={"outline-none h-[38px]  text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (timeOutText.length > 0 && !timeOut ? 'border-2 border-rose' : 'border border-gray-300')} placeholder="hh:mm aa" />
                    </div>

                    <div className="w-full h-fit col-span-12">
                        {filteredQuestions && <ECLQuestionNode ECLQuestions={filteredQuestions} ECLParentQuestion={null} setAnswerObject={setAnswerObject} answerObject={answerObject} Therapists={allTherapists} ResetAnswerObject={ResetAnswerObject} AutoSelectQuestionStack={questionStack} FlatQuestions={flatQuestions} />}
                    </div>


                    <div className="w-full col-span-12 flex justify-between noprint">
                        <button onClick={NavigateBack} className="bg-rose w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 flex-grow-0 mr-2">
                            <span className="drop-shadow">Back</span>
                        </button>
                        <div className="flex-grow"></div>
                    </div>

                </div>

                <ModalNotification Title={"Success!"} Body={"Log for " + eclContext.selectedClient.fullName + " successfully edited."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={SuccessModalClose} />
                <ModalNotification Title={"Error!"} Body={"Contact IT if this error persists. Error Message: " + errorMessage} Active={errorModalActive} Icon={"Warning"} setActive={setErrorModalActive} OnModalClose={function noop() {} } />
                <ProfilePictureModal Client={selectedClientForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose} />
                <ModalNotification Title={"Warning!"} Body={"Failed to edit log. Log times overlaps with the following log(s): " + overlapMessage} Active={overlapModalActive} Icon={"Warning"} setActive={setOverlapModalActive} OnModalClose={function noop() { }} />
                <ModalConfirm Message={"Your log is " + timeMessage + " hours long. Are you sure you want to edit this log?"} Active={timeModalActive} setActive={setTimeModalActive} OnConfirm={() => EditLog()} />
                <ModalNotification Title={"Warning: Log cannot be edited."} Body={futureMessage} Active={futureModalActive} setActive={setFutureModalActive} Icon={"Warning"} OnModalClose={function noop() { }} />

            </div>

        );
    }
    else {
        return (<div>loading</div>);
    }
    
};




export function ECLViewLog() {

    const authRequest = {
        ...loginRequest
    };


    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['ECL.View', 'ECL.Write', 'ECL.Approve']}>
                    <ECLViewLogContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
      )
};