import { useContext, useEffect, useState } from "react";
import { ECLContext } from "../../pages/ecl/ECLContext";
import { GlobalContext } from "../../utils/GlobalContext";

import { ECLAnswer, ECLAnswerObject, ECLQuestion, Therapist, TherapistOption, TherapistSmallObject } from "../../utils/Definitions";
import { apiConfig } from "../../authConfig";
import Select from "react-select";

interface Props {
    ECLQuestion: ECLQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<ECLAnswerObject | null>>;
    answerObject: ECLAnswerObject | null;
    Therapists: TherapistSmallObject[] | null,
}

export const ECLQuestionTypeRBT: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);
    const eclContext = useContext(ECLContext);

    const [selectedRBT, setSelectedRBT] = useState <TherapistOption | null>();

    const [RBTOptions, setRBTOptions] = useState<TherapistOption[]>();

    useEffect(() => {
        if (props.Therapists) {
            const tempTherapists: TherapistOption[] = props.Therapists.map((therapist, index) => {
                return { value: String(therapist.therapistId), label: therapist.employeeName };
            });

            setRBTOptions(tempTherapists);

        }
    }, [props.Therapists]);

    useEffect(() => {
        //if answer already exists, update
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);
            if (answer) {

                var tempAnswers = props.answerObject.answers;
                if (tempAnswers) {
                    if (selectedRBT) {
                        tempAnswers.filter(x => x.questionId == props.ECLQuestion.questionId).forEach(x => x.textValue = selectedRBT.value);
                    }
                    else {
                        tempAnswers.filter(x => x.questionId == props.ECLQuestion.questionId).forEach(x => x.textValue = '-1');
                    }
                }

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
        
    }, [selectedRBT]);

    useEffect(() => {
        //autopopulate answer object
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);
            if (!answer) {

                //if answer doesn't already exist, create new
                var tempAnswer: ECLAnswer = {
                    answerId: null,
                    questionId: props.ECLQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: selectedRBT ? selectedRBT.value : '-1',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });

            }
        }

    }, [eclContext.selectedEditAnswer, props.answerObject]);


    //auto populate rbt
    useEffect(() => {
        if (eclContext.selectedEditAnswer && selectedRBT == null && RBTOptions) {
            if (eclContext.selectedEditAnswer && props.ECLQuestion) {
                var tempanswer = eclContext.selectedEditAnswer.answers?.find(x => x.questionId == props.ECLQuestion.questionId);
                if (tempanswer && tempanswer.textValue) {
                    var temprbt = RBTOptions.find(x => x.value == tempanswer?.textValue);
                    if (temprbt) {
                        setSelectedRBT(temprbt);
                    }
                }
            }
        }

    }, [eclContext.selectedEditAnswer, props.ECLQuestion, RBTOptions]);
    
    return (
        <div className="w-full m-auto mt-4">
            <label className="block font-bold">
                {props.ECLQuestion.questionText}:
            </label>
            <Select isDisabled={eclContext.isViewOnly} options={RBTOptions} onChange={setSelectedRBT} value={selectedRBT} />
        </div>
    )

};

