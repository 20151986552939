import { useContext, useEffect, useState } from "react";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { GlobalContext } from "../../utils/GlobalContext";

import { EDPNAnswer, EDPNAnswerObject, EDPNQuestion, Therapist, TherapistOption, TherapistSmallObject } from "../../utils/Definitions";
import { apiConfig } from "../../authConfig";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Props {
    EDPNQuestion: EDPNQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>;
    answerObject: EDPNAnswerObject | null;
    Therapists: TherapistSmallObject[] | null,

}

export const EDPNQuestionTypeRBT: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);
    const edpnContext = useContext(EDPNContext);

    const [selectedRBT, setSelectedRBT] = useState <TherapistOption | null>();

    const [RBTOptions, setRBTOptions] = useState<TherapistOption[]>();

    useEffect(() => {
        if (props.Therapists) {
            const tempTherapists: TherapistOption[] = props.Therapists.map((therapist, index) => {
                return { value: String(therapist.therapistId), label: therapist.employeeName };
            });

            setRBTOptions(tempTherapists);

        }
    }, [props.Therapists]);

    useEffect(() => {
        //if answer already exists, update
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.EDPNQuestion.questionId);
            if (answer) {

                var tempAnswers = props.answerObject.answers;
                if (tempAnswers) {
                    if (selectedRBT) {
                        tempAnswers.filter(x => x.questionId == props.EDPNQuestion.questionId).forEach(x => x.textValue = selectedRBT.value);
                    }
                    else {
                        tempAnswers.filter(x => x.questionId == props.EDPNQuestion.questionId).forEach(x => x.textValue = '-1');
                    }
                }

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
        
    }, [selectedRBT]);

    useEffect(() => {
        //autopopulate answer object
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.EDPNQuestion.questionId);
            if (!answer) {

                //if answer doesn't already exist, create new
                var tempAnswer: EDPNAnswer = {
                    answerId: null,
                    questionId: props.EDPNQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: selectedRBT ? selectedRBT.value : '-1',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });

            }
        }

    }, [edpnContext.selectedEditAnswer, props.answerObject]);


    //auto populate rbt
    useEffect(() => {
        if (edpnContext.selectedEditAnswer && selectedRBT == null && RBTOptions) {
            if (edpnContext.selectedEditAnswer && props.EDPNQuestion) {
                var tempanswer = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == props.EDPNQuestion.questionId);
                if (tempanswer && tempanswer.textValue) {
                    var temprbt = RBTOptions.find(x => x.value == tempanswer?.textValue);
                    if (temprbt) {
                        setSelectedRBT(temprbt);
                    }
                }
            }
        }

    }, [edpnContext.selectedEditAnswer, props.EDPNQuestion, RBTOptions]);
    
    function OpenExample() {
        if (props.EDPNQuestion.exampleText) {
            edpnContext.setExampleMessage(props.EDPNQuestion.exampleText);
            edpnContext.setExampleModalActive(true);
        }
    }

    if (props.EDPNQuestion.exampleText) {
        return (
            <div className="w-full m-auto mt-4 col-span-12 grid grid-cols-12">
                <label className="font-bold col-span-11">
                    {props.EDPNQuestion.questionText}:
                </label>
                <div onClick={OpenExample} className="cursor-pointer ml-auto mb-1 col-span-1">
                    <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle text-lacblue hover:text-lacblue-500" icon={solid('circle-info')} />
                </div>
                <Select className="col-span-12" isDisabled={edpnContext.isViewOnly} options={RBTOptions} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={setSelectedRBT} value={selectedRBT} />
            </div>
        )
    }
    else {
        return (
            <div className="w-full m-auto mt-4 col-span-12">
                <label className="block font-bold">
                    {props.EDPNQuestion.questionText}:
                </label>
                <Select isDisabled={edpnContext.isViewOnly} options={RBTOptions} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={setSelectedRBT} value={selectedRBT} />
            </div>
        )
    }

};

