import { createContext, useContext, useEffect, useState } from 'react'
import { useMsal, useAccount } from "@azure/msal-react";
import { apiConfig, loginRequest } from '../authConfig';
import { act } from 'react-dom/test-utils';
import { getRoles } from '@testing-library/react';
import { GrammarItem, GrammarSet } from './Definitions';
import { AccountInfo } from '@azure/msal-browser';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from './GlobalContext';

import { Popover } from 'flowbite';
import type { PopoverOptions, PopoverInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';


// This is how you implement in the frontend
// *****************************************

/*<div className="w-full xl:col-span-12 col-span-12">
    <div id="unique_id" className="grammar w-200" contentEditable style={grammarContext.TextAreaStyle}></div>
    <div id="unique_id_2" className="grammar w-200" contentEditable style={grammarContext.InputStyle}></div>

    <CheckGrammarButton></CheckGrammarButton>

    <PopperGrammarBoxes GrammarSet={grammarContext.grammarSets} makeCorrection={grammarContext.MakeCorrection}></PopperGrammarBoxes>
</div>*/

// There is a function that is called when the 'CheckGrammarButton' component is clicked. You can use the function on any call.
// Div needs to have a unique Id so the popover can tie itself to it.
// Div also needs to have a class of 'grammar' so the context function knows all of the fields it needs to check.




//props for global context
type GrammarContextProviderProps = {
    children: React.ReactNode
}

//definition of export type
type GrammarContextType = {
    grammarSets: GrammarSet[];
    MakeCorrection: any;
    CheckNote: any;
    TextAreaStyle: any;
    InputStyle: any;
    //GrammarCheck: any;
    CheckAllGrammar: any;
}


export const GrammarContext = createContext<GrammarContextType>({
    //grammarCorrections: [],
    grammarSets: [],
    MakeCorrection: function noop() { },
    CheckNote: function noop() { },
    TextAreaStyle: {},
    InputStyle: {},
    //GrammarCheck: function noop() { },
    CheckAllGrammar: function noop() { },
});


export const GrammarContextProvider = ({ children, }: GrammarContextProviderProps) => {

    const globalContext = useContext(GlobalContext);
    //const [grammarCorrections, setGrammarCorrections] = useState<GrammarItem[]>([]);
    const [grammarSets, setGrammarSets] = useState<GrammarSet[]>([]);
    const [makingCorrection, setMakingCorrection] = useState<boolean>(false);

    const textAreaStyle = {
        border: '1px solid #ccc',
        borderRadius: '3px',
        padding: '8px',
        minHeight: '100px',
        lineHeight: '1.5',
        outline: 'none',
        rwhiteSpace: 'pre-wrap', // Allow the div to wrap text
        fontFamily: 'inherit', // Inherit font family from parent
        fontSize: 'inherit',   // Inherit font size from parent
    }

    const inputStyle = {
        border: '1px solid #ccc',
        borderRadius: '3px',
        padding: '8px',
        lineHeight: '1.5',
        outline: 'none',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        width: '100%',
    };

    useEffect(() => {
        var update = SomethingNeedsUpdating();

        if (update) {
            DisplayCorrections();
        }
    }, [grammarSets])

    useEffect(() => {

    }, [makingCorrection])

    function SomethingNeedsUpdating() {
        var needsUpdating = false;

        if (grammarSets.length > 0) {

            grammarSets?.reverse().forEach((grammarSet) => {

                if (grammarSet.needsUpdating === true) {
                    needsUpdating = true;
                }

            });

        }
        return needsUpdating;
    }

    async function CheckAllGrammar(): Promise<boolean> {
        var areGrammarMistakes = false;
        const grammarDivs = document.getElementsByClassName('grammar');

        const tempGrammarCorrections: GrammarSet[] = [];

        // Map each element to a Promise returned by CheckNote
        const promises = Array.from(grammarDivs).map(async (element: Element) => {
            const tempGrammarItems = await CheckNote(element.id);
            tempGrammarCorrections.push(...tempGrammarItems);
        });

        // Wait for all promises to resolve
        await Promise.all(promises);

        setGrammarSets(tempGrammarCorrections);

        areGrammarMistakes = tempGrammarCorrections.some(x => x.grammarItems.length > 0);

        return areGrammarMistakes;
    }

    async function CheckSingleGrammar(elementId: string) {

        const tempGrammarItems = await CheckNote(elementId);


        var thisGrammarSetIndex = grammarSets.findIndex(x => x.grammarDivId == elementId);
        if (thisGrammarSetIndex !== -1) {
            const updatedGrammarSets = [...grammarSets];
            updatedGrammarSets[thisGrammarSetIndex] = {
                ...updatedGrammarSets[thisGrammarSetIndex], // Copy existing properties
                needsUpdating: true,
                grammarText: tempGrammarItems[0].grammarText,
                grammarItems: tempGrammarItems[0].grammarItems
            };
            setGrammarSets(updatedGrammarSets);
        }
        else {
            // If the grammarDivId doesn't exist, add a new GrammarSet to the array
            const newGrammarSet = {
                grammarDivId: elementId,
                needsUpdating: true,
                grammarText: tempGrammarItems[0].grammarText,
                grammarItems: tempGrammarItems[0].grammarItems
            };

            // Create a copy of the grammarSets array and add the new GrammarSet
            const updatedGrammarSets = [...grammarSets, newGrammarSet];

            // Set the state with the updated grammar sets
            setGrammarSets(updatedGrammarSets);
        }
    }


    // I attempted to use this function for the OnBlur effect, but this was unsuccessful due to repeated calls when performing a correction
    // I will leave this in just in case we want to look further into this in the future, but otherwise, this is to be ignored.
    /*async function GrammarCheck(event: any) {
        event.preventDefault();

        var clickedElement = event.target;

        const tempGrammarItems = await CheckNote(clickedElement.id);


        var thisGrammarSetIndex = grammarSets.findIndex(x => x.grammarDivId == clickedElement.id);
        if (thisGrammarSetIndex !== -1) {
            const updatedGrammarSets = [...grammarSets];
            updatedGrammarSets[thisGrammarSetIndex] = {
                ...updatedGrammarSets[thisGrammarSetIndex], // Copy existing properties
                needsUpdating: true,
                grammarText: tempGrammarItems[0].grammarText,
                grammarItems: tempGrammarItems[0].grammarItems
            };
            setGrammarSets(updatedGrammarSets);
        }
        else {
            // If the grammarDivId doesn't exist, add a new GrammarSet to the array
            const newGrammarSet = {
                grammarDivId: clickedElement.id,
                needsUpdating: true,
                grammarText: tempGrammarItems[0].grammarText,
                grammarItems: tempGrammarItems[0].grammarItems
            };

            // Create a copy of the grammarSets array and add the new GrammarSet
            const updatedGrammarSets = [...grammarSets, newGrammarSet];

            // Set the state with the updated grammar sets
            setGrammarSets(updatedGrammarSets);
        }
    }*/

    async function CheckNote(divId: string): Promise<GrammarSet[]> {
        return new Promise((resolve, reject) => {
            globalContext.GetToken().then((token: any) => {
                var element = document.getElementById(divId);

                if (element) {
                    var noteText = element.innerText;
                    resolve(GrammarValidation2(token, noteText, divId)); // Resolve with the result of GrammarValidation2
                } else {
                    resolve([]); // Resolve with an empty array if element is not found
                }
            }).catch(reject); // Reject if GetToken() fails
        });
    }

    async function GrammarValidation(token: string, note: string, elementId: string) {
        // only want to run this if there are itmes in the text fields
        if (note && !makingCorrection) {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "GET",
                headers: headers,
                body: note,
            };

            const response = await fetch(apiConfig.apiEndpoint + "/Grammar/CheckLanguage", options);
            var grammarItems = await response.json();

            if (grammarItems.length >= 0) {
                var thisGrammarSetIndex = grammarSets.findIndex(x => x.grammarDivId == elementId);
                if (thisGrammarSetIndex !== -1) {
                    const updatedGrammarSets = [...grammarSets];
                    updatedGrammarSets[thisGrammarSetIndex] = {
                        ...updatedGrammarSets[thisGrammarSetIndex],
                        needsUpdating: true,
                        grammarText: note,
                        grammarItems: grammarItems
                    };
                    setGrammarSets(updatedGrammarSets);
                }
                else {
                    // If grammarSet with elementId not found, add a new grammarSet
                    const newGrammarSet = {
                        grammarDivId: elementId,
                        needsUpdating: true,
                        grammarText: note,
                        grammarItems: grammarItems
                    };
                    // Create a new array with the new grammarSet and existing grammarSets
                    const updatedGrammarSets = [...grammarSets, newGrammarSet];
                    // Update the grammarSets hook with the modified list
                    setGrammarSets(updatedGrammarSets);
                }
            }
        }
        setMakingCorrection(false);
    }

    async function GrammarValidation2(token: string, note: string, elementId: string): Promise<GrammarSet[]> {
        // only want to run this if there are items in the text fields
        if (note) {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(note),
            };

            const response = await fetch(apiConfig.apiEndpoint + "/Grammar/CheckLanguage", options);
            var grammarItems = await response.json();

            if (grammarItems) {
                const newGrammarSet = {
                    grammarDivId: elementId,
                    needsUpdating: true,
                    grammarText: note,
                    grammarItems: grammarItems
                };

                return [newGrammarSet]; // Return an array containing the GrammarSet
            }
        }

        setMakingCorrection(false);
        // Return an empty array if no grammar items were found
        return [];
    }


    function CreateNewPopover(offset: number, grammarElementId: string) {
        // set the popover content element
        const $targetEl = document.getElementById('popoverContent_' + grammarElementId + "_" + offset);

        // set the element that trigger the popover using hover or click
        const $triggerEl = document.getElementById('popoverButton_' + grammarElementId + "_" + offset);

        // options with default values
        const options: PopoverOptions = {
            placement: 'top',
            triggerType: 'click',
            offset: 10,
            onHide: () => {
                console.log('popover is hidden');
            },
            onShow: () => {
                console.log('popover is shown');
            },
            onToggle: () => {
                console.log('popover is toggled');
            },
        };

        // instance options object
        const instanceOptions: InstanceOptions = {
            id: 'popoverContent_' + grammarElementId + "_" + offset,
            override: true
        };

        if ($targetEl) {
            /*
             * targetEl: required
             * triggerEl: required
             * options: optional
             * instanceOptions: optional
             */
            const popover: PopoverInterface = new Popover(
                $targetEl,
                $triggerEl,
                options,
                instanceOptions
            );
        }
    }

    function DisplayCorrections() {
        if (grammarSets?.length > 0) {
            // foreach through the grammarset
            grammarSets?.reverse().forEach((grammarSet) => {
                if (grammarSet.grammarItems?.length > 0 && grammarSet.needsUpdating) {
                    var innerhtml = "";
                    var errorIDs: number[] = [];

                    grammarSet.grammarItems?.reverse().forEach((correction) => {
                        if (innerhtml === "") {
                            //innerhtml += correction.originalText;
                            // new
                            innerhtml = grammarSet.grammarText;
                        }
                        innerhtml = ShowError(innerhtml, correction.replacementOffset, correction.replacementLength, grammarSet.grammarDivId);

                        errorIDs.push(correction.replacementOffset);
                    });
                    var test = document.getElementById(grammarSet.grammarDivId);
                    if (test) {
                        test.innerHTML = innerhtml;
                    }

                    errorIDs?.forEach((offsetID) => {
                        CreateNewPopover(offsetID, grammarSet.grammarDivId);
                    })
                }
                else if(grammarSet.needsUpdating === true) {
                    var test = document.getElementById(grammarSet.grammarDivId);
                    if (test) {
                        test.innerHTML = grammarSet.grammarText;
                    }
                }
            });
            
        }
    }

    //try to make it work using the const function method
    const MakeCorrection = (event: any, correction: string, offset: number, length: number, elementDivId: string) => {
        //event.stopPropogation();
        setMakingCorrection(true);

        var masterElement = document.getElementById(elementDivId);
        if (masterElement) {
            // I want this function to replace the word with the new one, then re-run the grammar check.
            ReplaceString(correction, offset, length, masterElement);

            // Update the grammarSet list


            // Re-run Note grammar check
            CheckSingleGrammar(elementDivId);

            // Put the div back in focus so it will fire the Onblur event to update the generated summary
            masterElement.focus();
        }
    }


    // This needs to be changed to account for dynamic divs
    // Send the div object in the function
    function ReplaceString(correction: string, offset: number, length: number, grammarElement: HTMLElement) {
        // Get the part of the sentence before the word to be replaced
        const prefix = grammarElement.innerText.substring(0, offset);

        // Get the part of the sentence after the word to be replaced
        const suffix = grammarElement.innerText.substring(offset + length);

        const updatedText = prefix + correction + suffix;

        if (grammarElement) {
            grammarElement.innerText = updatedText;
            
        }
    }

    function ShowError(sentence: string, offset: number, length: number, divID: string) {
        // Get the part of the sentence before the word to be replaced
        const prefix = sentence.substring(0, offset);

        // Get the word
        const erroredWord = sentence.substring(offset, offset + length);

        // Get the part of the sentence after the word to be replaced
        const suffix = sentence.substring(offset + length);

        // Concatenate the prefix, new word, and suffix to form the updated sentence
        const updatedSentence = prefix + "<span id=\"popoverButton_" + divID + "_" + offset + "\" onmouseover=\"this.style.fontWeight='bold'\" onmouseout=\"this.style.fontWeight='normal'\" style='color:red; cursor:pointer;'>" + erroredWord + "</span>" + suffix;

        return updatedSentence;
    }




    const GrammarContextExport: GrammarContextType = {
        grammarSets: grammarSets,
        MakeCorrection: MakeCorrection,
        CheckNote: CheckNote,
        TextAreaStyle: textAreaStyle,
        InputStyle: inputStyle,
        //GrammarCheck: GrammarCheck,
        CheckAllGrammar: CheckAllGrammar,
    }
    return <GrammarContext.Provider value={GrammarContextExport}>{children}</GrammarContext.Provider>
}