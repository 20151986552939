import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { Client, EDPNAnswerObject, Therapist, TherapistSmallObject } from "../../utils/Definitions";
import pic1562 from '../../images/1562.png'; 
import pic222 from '../../images/222.png'; 
import pic255 from '../../images/255.png'; 
import pic889 from '../../images/889.png'; 
import pic94 from '../../images/94.png'; 
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ModalConfirm } from "../ModalConfirm";
import { format, isAfter, sub } from "date-fns";
import { GlobalContext } from "../../utils/GlobalContext";


interface Props {
    Log: EDPNAnswerObject,
    Therapists: TherapistSmallObject[] | null,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const EDPNViewLogCard: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const edpnContext = useContext(EDPNContext);
    const navigate = useNavigate();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    function GetTherapistName(id: number) {
        if (props.Therapists) {

            var therapist = props.Therapists.find(x => x.therapistId == id);

            if (therapist) {
                return therapist.employeeName;
            }
            else {
                return String(id);
            }
        }
        else {
            return String(id);
        }
    }

    function ToggleExpand() {
        setIsExpanded(!isExpanded);
    }

    const ExpandButton = () => {

        if (isExpanded) {
            return (
                <FontAwesomeIcon onClick={ToggleExpand} className="fill-current w-8 h-8 cursor-pointer my-auto" icon={solid('angle-up')} />
            );
        }
        else {
            return <FontAwesomeIcon onClick={ToggleExpand} className="fill-current w-8 h-8 cursor-pointer my-auto" icon={solid('angle-down')} />
        }

    }

    const Narritive = () => {
        if (isExpanded) {
            return (
                <div className="mt-4">
                    <span className="font-bold">Session Summary:</span>
                    <div dangerouslySetInnerHTML={{ __html: props.Log.narrative != null ? props.Log.narrative : "N/A" }} />
                </div>
            );
        }
        else {
            return <></>
        }
    }

    const Comments = () => {
        if (isExpanded && props.Log.comments && props.Log.comments.length > 0) {
            return (
                <div className="mt-4">
                    <span className="font-bold">Comments:</span>
                    <table className="border-separate border-spacing-3">
                        <tbody>
                            {props.Log.comments ? props.Log.comments.map(comment => <tr key={comment.dpncommentId}><td>[{comment.createdOn ? format(new Date(comment.createdOn), 'M/d/yy H:mm a') : ""}]</td><td>{comment.createdBy}: </td><td>{comment.comment}</td></tr>) : <></>}
                        </tbody>
                    </table>
                </div>
            );
        }
        else {
            return <></>
        }
    }

    const BillingDate = () => {
        if (isExpanded) {
            return (
                <div className="grid grid-flow-row grow h-auto w-[10rem]">
                    <span className="font-bold">Service Date:</span>
                    {props.Log.billingDate && <span>{new Date(props.Log.billingDate).toLocaleDateString()}</span>}

                    <span className="font-bold mt-2">Time In:</span>
                    {props.Log.startTime && <span>{new Date(props.Log.startTime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</span>}
                </div>
            );
        }
        else {
            return (
                <div className="grid grid-flow-row grow h-auto w-[10rem]">
                    <span className="font-bold">Service Date:</span>
                    {props.Log.billingDate && <span>{new Date(props.Log.billingDate).toLocaleDateString()}</span>}
                </div>
            )
        }
    }

    const CPTCode = () => {
        if (isExpanded) {
            return (
                <div className="grid grid-flow-row grow h-auto w-[10rem]">
                    <span className="font-bold">CPT Code:</span>
                    {props.Log.cptcode && <span>{props.Log.cptcode}</span>}

                    <span className="font-bold mt-2">Time Out:</span>
                    {props.Log.endTime && <span>{new Date(props.Log.endTime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</span>}
                </div>
                );
        }
        else {
            return (
                <div className="grid grid-flow-row grow h-auto w-[10rem]">
                    <span className="font-bold">CPT Code:</span>
                    {props.Log.cptcode && <span>{props.Log.cptcode}</span>}
                </div>
                )
        }
    }

    const Status = () => {
        switch (props.Log.dpnstatusId) {
            case 1:
                //approved
                return (
                    <span className="text-lacgreen">Approved</span> 
                )
            case 2:
                //pending correction
                return (
                    <span className="text-yellow">Pending Correction</span>
                )
            case 3:
                //imported
                return (
                    <span className="text-lacblue">Imported</span>
                )
            case 4:
                //created
                return (
                    <span className="text-rose">Created</span>
                )
            case 6:
                //incomplete
                return (
                    <span className="text-teal">Incomplete</span>
                )
            case 7:
                //pending approval
                return (
                    <span className="text-orange">Pending Approval</span>
                )
            default:
                return <></>;
        }
    }
    

    return (

        <div className={"h-fit bg-gray-50 border drop-shadow rounded p-2 mb-8 w-[45rem] xl:w-[56rem]"}>

            <div className="flex">

                <div className="grid grid-flow-row grow h-fit w-[3rem] mt-2">
                    <ExpandButton/>
                </div>

                <BillingDate/>

                <CPTCode/>

                <div className="grid grid-flow-row grow h-fit w-[12rem]">
                    <span className="font-bold">Clinician:</span>
                    {props.Log.therapistId && props.Therapists && <span>{GetTherapistName(props.Log.therapistId)}</span>}
                </div>

                <div className="grid grid-flow-row grow h-fit w-[10rem]">
                    <span className="font-bold">Status:</span>
                    <Status/>
                </div>

            </div>

            <Narritive />

            <Comments/>

        </div>
    )
    
    
};

