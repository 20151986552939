import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../utils/GlobalContext";

interface Props {
}

export const Toast: React.FC<Props> = (props) => {
    const globalContext = useContext(GlobalContext);
    const [visible, setVisible] = useState(false);

    useEffect(() => {

        if (globalContext.toastActive == true) {
            setVisible(true);

            const timer = setTimeout(() => {
                setVisible(false);
                globalContext.setToastActive(false);
            }, 1000); // Toast visible for 1 second

            return () => clearTimeout(timer); // Cleanup timeout on unmount or when toastActive changes
        }
    }, [globalContext.toastActive]);

    return (
        <div
            className={
                "z-[100] relative pointer-events-none " +
                (visible
                    ? "opacity-100 transition-opacity duration-1000 ease-out"
                    : "opacity-0 transition-opacity duration-1000 ease-in") +
                " bg-black"
            }
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full justify-end text-center p-12">
                    <div className="bg-white mb-auto p-4 rounded shadow-xl">
                        <div className="mx-auto flex h-fit w-fit flex-shrink-0 items-center justify-center rounded-full bg-lacgreen-100">
                            <FontAwesomeIcon
                                className={"h-6 w-6 text-lacgreen-600 " + (globalContext.toastIcon == "Success" ? "" : "hidden")}
                                icon={regular("check-circle")}
                            />
                            <FontAwesomeIcon
                                className={"h-6 w-6 text-rose-600 " + (globalContext.toastIcon == "Warning" ? "" : "hidden")}
                                icon={regular("xmark-circle")}
                            />
                        </div>
                        <span
                            className="text-lg ml-2 mt-auto mb-auto font-medium leading-6"
                            id="modal-title"
                        >
                            {globalContext.toastMessage}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Toast;
