import isAfter from 'date-fns/esm/isAfter/index.js';
import React, { useContext, useEffect, useState } from 'react';
import { EDPNAnswerObject, EDPNSet, Therapist } from '../../utils/Definitions';
import { GlobalContext } from '../../utils/GlobalContext';
import ProtectedComponent from '../../utils/ProtectedComponent';
import { EDPNApprovalCard } from './EDPNApprovalCard';
import { EDPNSetCard } from './EDPNSetCard';



interface Props {
    Logs: EDPNAnswerObject[],
    Therapists: Therapist[] | null,
    ApproveLog: any,
    UnapproveLog: any,
    DeleteLog: any,
    HoldLog: any,
    ShowImportedLogs: boolean,
    ApproveSet: any,
    ApproveAll: any,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>,
}

export const EDPNLogsList: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const [pendingCorrectionLogs, setPendingCorrectionLogs] = useState<EDPNAnswerObject[]>([]);
    const [pendingApprovalLogs, setPendingApprovalLogs] = useState<EDPNAnswerObject[]>([]);
    const [incompleteLogs, setIncompleteLogs] = useState<EDPNAnswerObject[]>([]);
    const [createdLogs, setCreatedLogs] = useState<EDPNAnswerObject[]>([]);
    const [approvedLogs, setApprovedLogs] = useState<EDPNAnswerObject[]>([]);
    const [importedLogs, setImportedLogs] = useState<EDPNAnswerObject[]>([]);
    const [listOfSets, setListofSets] = useState<EDPNSet[]>([]);

    useEffect(() => {
        setPendingCorrectionLogs(props.Logs.filter(x => x.dpnstatusId == 2));
        setPendingApprovalLogs(props.Logs.filter(x => x.dpnstatusId == 7));
        setIncompleteLogs(props.Logs.filter(x => x.dpnstatusId == 6));
        setCreatedLogs(props.Logs.filter(x => x.dpnstatusId == 4));
        UpdateListOfSets();
        setApprovedLogs(props.Logs.filter(x => x.dpnstatusId == 1));
        setImportedLogs(props.Logs.filter(x => x.dpnstatusId == 3));
    }, [props.Logs]);

    function UpdateListOfSets() {
        let newEDPNSetArray: EDPNSet[] = [];

        let logs: EDPNAnswerObject[] = props.Logs.filter(x => x.dpnstatusId == 5 && x.logSetId != null)

        logs.sort((a, b) => {
            if (a.logSetId === null && b.logSetId === null) {
                return 0;
            } else if (a.logSetId === null) {
                return 1;
            } else if (b.logSetId === null) {
                return -1;
            } else {
                return a.logSetId - b.logSetId;
            }
        });

        logs.forEach(x => {

            //create sets
            if (!newEDPNSetArray.find(z => z.LogSetID == x.logSetId)) {

                //create set with id
                let newEDPNSet: EDPNSet = {
                    LogSetID: x.logSetId ? x.logSetId : 0,
                    LogRecentLogDate: x.billingDate ? x.billingDate : new Date(),
                    Logs: []
                }

                //add set to set array
                newEDPNSetArray.push(newEDPNSet);
            }

            //add log to set
            newEDPNSetArray.find(z => z.LogSetID == x.logSetId)?.Logs.push(x);

            //sort logs by date
            const distantFuture = new Date(8640000000000000);
            newEDPNSetArray.find(z => z.LogSetID == x.logSetId)?.Logs.sort((a, b) => {
                let dateA: Date = a.billingDate ? new Date(a.billingDate) : distantFuture
                let dateB: Date = b.billingDate ? new Date(b.billingDate) : distantFuture
                return dateA.getTime() - dateB.getTime();
            })
        })

        //assign most recent log dates to logs
        newEDPNSetArray.forEach(x => {
            if (x.Logs && x.Logs[0] && x.Logs[0].billingDate) {
                x.LogRecentLogDate = x.Logs[0].billingDate;
            }
        })

        //sort sets by most recent log date
        newEDPNSetArray.sort((a, b) => new Date(b.LogRecentLogDate).getTime() - new Date(a.LogRecentLogDate).getTime());

        setListofSets(newEDPNSetArray);
    }

    function WarnUserMissingCredentials() {
        props.SetCredentialsModalActive(true);
    }

    const ImportedLogs = () => {

        if (props.ShowImportedLogs) {
            return (
                <div className={"grid grid-cols-1 gap-3 place-items-center xl:grid-cols-1 w-full xl:w-fit m-auto h-fit bg-gray-50 border-2 border-lacblue drop-shadow rounded p-4 mb-8 " + (importedLogs.length > 0 ? "" : "hidden")}>
                    <h2 className="text-lacblue text-2xl text-left w-full">Imported</h2>
                    {importedLogs.map(log => <EDPNApprovalCard key={log.logId} Therapists={props.Therapists} Log={log} ApproveLog={() => props.ApproveLog(log)} UnapproveLog={() => props.UnapproveLog(log)} DeleteLog={() => props.DeleteLog(log)} HoldLog={() => props.HoldLog(log)} SetCredentialsModalActive={props.SetCredentialsModalActive} />)}
                </div>
                )
        }
        else {
            return <></>
        }
        
    }

    const ApproveAllButton = () => {
        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData.signatureImage) {
            return (
                <button onClick={() => props.ApproveAll()} className="bg-lacgreen ml-auto my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve All</span></button>
            )
        }
        else {
            return (
                <button onClick={WarnUserMissingCredentials} className="bg-lacgreen ml-auto my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve All</span></button>
            )
        }
    }

    return (

        <div className="mt-4 p-2 w-full">

            <div>
                {props.Logs.length == 0 ? <span className="font-bold text-xl text-rose w-full">No logs found.</span> : <></>}
            </div>

            <div className={"grid grid-cols-1 gap-3 place-items-center w-full xl:w-fit m-auto mt-8 h-fit bg-gray-50 border-2 border-yellow drop-shadow rounded p-4 mb-8 " + (pendingCorrectionLogs.length > 0 ? "" : "hidden")}>
                <h2 className="text-yellow text-2xl text-left w-full">Pending Correction</h2>
                {pendingCorrectionLogs.map(log => <EDPNApprovalCard key={log.logId} Therapists={props.Therapists} Log={log} ApproveLog={() => props.ApproveLog(log)} UnapproveLog={() => props.UnapproveLog(log)} DeleteLog={() => props.DeleteLog(log)} HoldLog={() => props.HoldLog(log)} SetCredentialsModalActive={props.SetCredentialsModalActive} />)}
            </div>

            <div className={"grid grid-cols-1 gap-3 place-items-center w-full xl:w-fit m-auto mt-8 h-fit bg-gray-50 border-2 border-orange drop-shadow rounded p-4 mb-8 " + (pendingApprovalLogs.length > 0 ? "" : "hidden")}>
                <h2 className="text-orange text-2xl text-left w-full">Pending Approval</h2>
                {pendingApprovalLogs.map(log => <EDPNApprovalCard key={log.logId} Therapists={props.Therapists} Log={log} ApproveLog={() => props.ApproveLog(log)} UnapproveLog={() => props.UnapproveLog(log)} DeleteLog={() => props.DeleteLog(log)} HoldLog={() => props.HoldLog(log)} SetCredentialsModalActive={props.SetCredentialsModalActive} />)}
            </div>

            <div className={"grid grid-cols-1 gap-3 place-items-center w-full xl:w-fit m-auto mt-8 h-fit bg-gray-50 border-2 border-teal drop-shadow rounded p-4 mb-8 " + (incompleteLogs.length > 0 ? "" : "hidden")}>
                <h2 className="text-teal text-2xl text-left w-full">Incomplete</h2>
                {incompleteLogs.map(log => <EDPNApprovalCard key={log.logId} Therapists={props.Therapists} Log={log} ApproveLog={() => props.ApproveLog(log)} UnapproveLog={() => props.UnapproveLog(log)} DeleteLog={() => props.DeleteLog(log)} HoldLog={() => props.HoldLog(log)} SetCredentialsModalActive={props.SetCredentialsModalActive} />)}
            </div>

            <div className={"grid grid-cols-1 gap-3 place-items-center w-full xl:w-fit m-auto h-fit bg-gray-50 border-2 border-rose drop-shadow rounded p-4 mb-8 " + (createdLogs.length > 0 ? "" : "hidden")}>
                <div className="grid grid-cols-2 w-full">
                    <h2 className="text-rose text-2xl text-left w-full">Created</h2>
                    <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                        <ApproveAllButton/>
                    </ProtectedComponent>
                </div>
                {createdLogs.map(log => <EDPNApprovalCard key={log.logId} Therapists={props.Therapists} Log={log} ApproveLog={() => props.ApproveLog(log)} UnapproveLog={() => props.UnapproveLog(log)} DeleteLog={() => props.DeleteLog(log)} HoldLog={() => props.HoldLog(log)} SetCredentialsModalActive={props.SetCredentialsModalActive} />)}
            </div>

            <div className={"grid grid-cols-1 gap-3 place-items-center w-full xl:w-fit m-auto h-fit bg-gray-50 border-2 border-purple drop-shadow rounded p-2 mb-8 " + (listOfSets.length > 0 ? "" : "hidden")}>
                <h2 className="text-purple text-2xl text-left w-full">On Hold</h2>
                {listOfSets.map(log => <EDPNSetCard key={log.LogSetID} Therapists={props.Therapists} Logs={log.Logs} ApproveLog={() => props.ApproveLog(log)} UnapproveLog={() => props.UnapproveLog(log)} DeleteLog={() => props.DeleteLog(log)} HoldLog={() => props.HoldLog(log)} ApproveSet={props.ApproveSet} SetCredentialsModalActive={props.SetCredentialsModalActive} />)}
            </div>

            <div className={"grid grid-cols-1 gap-3 place-items-center w-full xl:w-fit m-auto h-fit bg-gray-50 border-2 border-lacgreen drop-shadow rounded p-4 mb-8 " + (approvedLogs.length > 0 ? "" : "hidden")}>
                <h2 className="text-lacgreen text-2xl text-left w-full">Approved</h2>
                {approvedLogs.map(log => <EDPNApprovalCard key={log.logId} Therapists={props.Therapists} Log={log} ApproveLog={() => props.ApproveLog(log)} UnapproveLog={() => props.UnapproveLog(log)} DeleteLog={() => props.DeleteLog(log)} HoldLog={() => props.HoldLog(log)} SetCredentialsModalActive={props.SetCredentialsModalActive} />)}
            </div>

            <ImportedLogs/>

            
        </div>
    )
};