import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { GrammarItem } from '../utils/Definitions';
import { GrammarSet } from '../utils/Definitions';

interface Props {
    GrammarSet: GrammarSet[],
    makeCorrection: any,
   /* grammarItem: GrammarItem,
    makeCorrection: any,
    masterDivId: string,*/
}

export const PopperGrammarBoxes: React.FC<Props> = (props) => {

    return (
        <div>
            {props.GrammarSet.map(set =>
                set.grammarItems.map(correction =>
                    <div
                        data-popover
                        id={"popoverContent_" + set.grammarDivId + "_" + correction.replacementOffset}
                        key={set.grammarDivId + "_" + correction.replacementOffset}
                        role="tooltip"
                        className="invisible popover absolute z-10 inline-block w-64 rounded-lg border border-gray-200 bg-white text-sm text-gray-500 opacity-0 shadow-sm transition-opacity duration-300 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400">
                        <div className="rounded-t-lg border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                            <h3 className="font-semibold text-gray-900 dark:text-white">
                                {correction.message}
                            </h3>
                        </div>
                        <div className="px-3 py-2">
                            <div className="grid grid-cols-3">
                                {correction.replacements.map(replacement =>
                                    <span className="popoverWord hover:font-bold hover:cursor-pointer hover:text-lacgreen m-3" onClick={(event) => props.makeCorrection(event, replacement, correction.replacementOffset, correction.replacementLength, set.grammarDivId) }>{replacement}</span>
                                )}

                            </div>
                        </div>
                        <div data-popper-arrow></div>
                    </div>
                )
            )}
        </div>
        


        /*<div
            data-popover
            id={"popoverContent_" + props.masterDivId + "_" + props.grammarItem.replacementOffset}
            key={props.masterDivId + "_" + props.grammarItem.replacementOffset }
            role="tooltip"
            className="invisible absolute z-10 inline-block w-64 rounded-lg border border-gray-200 bg-white text-sm text-gray-500 opacity-0 shadow-sm transition-opacity duration-300 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400">
            <div className="rounded-t-lg border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                <h3 className="font-semibold text-gray-900 dark:text-white">
                    {props.grammarItem.message}
                </h3>
            </div>
            <div className="px-3 py-2">
                <div className="grid grid-cols-3">
                    {props.grammarItem.replacements.map(replacement => 
                        <span className="hover:font-bold hover:cursor-pointer hover:text-lacgreen m-3" onClick={() => props.makeCorrection(replacement, props.grammarItem.replacementOffset, props.grammarItem.replacementLength, props.masterDivId)}>{replacement}</span>
                    )}
                    
                </div>
            </div>
            <div data-popper-arrow></div>
        </div>*/
    );
}

