// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { ECLClientCard } from "../../components/ecl/ECLClientCard";
import { useNavigate } from "react-router-dom";
import { Center, Client, Pod, EDPNAnswerObject, AbsentReason, ChoiceOption, TherapistOption, APIReturnObject, Status } from "../../utils/Definitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Select, { MultiValue, OnChangeValue } from "react-select";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalNotification } from "../../components/ModalNotification";
import { addDays, format, isAfter, isBefore, isMonday, isSameDay, isYesterday, parse, parseISO, set, subDays } from "date-fns";
import { EDPNContext } from "./EDPNContext";
import { EDPNClientCard } from "../../components/edpn/EDPNClientCard";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../../components/ProfilePicture";
import { EDPNQuestionNode } from "../../components/edpn/EDPNQuestionNode";
import { ValueContainer } from "../../components/ValueContainer";
import { ModalConfirm } from "../../components/ModalConfirm";

interface AutosaveObject {
    clientID: number;
    LogID: string;
    GroupId: string;
}


//only renders if authenticated
const EDPNGroupCreateContent = () => {


    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);
    const edpnContext = useContext(EDPNContext);

    const [timeIn, setTimeIn] = useState<Date | null>(null);
    const [timeInText, setTimeInText] = useState<string>("");

    const [timeOut, setTimeOut] = useState<Date | null>(null);
    const [timeOutText, setTimeOutText] = useState<string>("");

    const [groupSummary, setGroupSummary] = useState<string>("");

    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [addNoteLoading, setAddNoteLoading] = useState<boolean>(false);

    const [noteIncomplete, setNoteIncomplete] = useState<boolean>(true);

    const [selectedReason, setSelectedReason] = useState<AbsentReason | null>();

    const [submitNoteConfirmationModalActive, setSubmitNoteConfirmationModalActive] = useState<boolean>(false);
    const [submitNoteConfirmationMessage, setSubmitNoteConfirmationMessage] = useState<string>("");

    const [saveNoteConfirmationModalActive, setSaveNoteConfirmationModalActive] = useState<boolean>(false);
    const [saveNoteConfirmationMessage, setSaveNoteConfirmationMessage] = useState<string>("");

    const [selectedClients, setSelectedClients] = useState<ChoiceOption[] | null>(null);


    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [validationModalActive, setValidationModalActive] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<string>("");

    //ABA
    const [abaClinician, setAbaClinician] = useState<boolean>(false);
    const [abaClinicianOptions, setAbaClinicianOptions] = useState<ChoiceOption[]>();
    const [selectedAbaClinicianOption, setSelectedAbaClinicianOption] = useState<ChoiceOption | null>();
    const [selectedABAClinicians, setSelectedABAClinicians] = useState<TherapistOption[]>([]);

    //other people
    const [otherPeople, setOtherPeople] = useState<boolean>(false);
    const [otherPeopleOptions, setOtherPeopleOptions] = useState<ChoiceOption[]>();
    const [selectedOtherPeopleOption, setSelectedOtherPeopleOption] = useState<ChoiceOption | null>();
    const [selectedOtherPeople, setSelectedOtherPeople] = useState<TherapistOption[]>([]);

    const [groupSummaryExample, setGroupSummaryExample] = useState<string>("");
    const [isAutosaving, setIsAutoSaving] = useState<boolean>(false);
    const [autosaveArray, setAutosaveArray] = useState<AutosaveObject[]>([]);

    useEffect(() => {
        UpdateAbaClinicianOptions();
        UpdateOtherPeopleOptions();

        if (edpnContext.selectedServicePlace == null) {
            edpnContext.setSelectedServicePlace(edpnContext.servicePlaces?.find(x => x.value === '1') ?? null);
        }

    }, [])

    useEffect(() => {
        if (edpnContext.CPTCodes) {
            edpnContext.setSelectedCPTCode(edpnContext.CPTCodes?.find(x => x.value == '97154') ?? null);
        }

    }, [edpnContext.CPTCodes])

    useEffect(() => {
        if (edpnContext.servicePlaces) {
            edpnContext.setSelectedServicePlace(edpnContext.servicePlaces?.find(x => x.value == '1') ?? null);
        }

    }, [edpnContext.servicePlaces])

    useEffect(() => {
        if (!edpnContext.selectedCenter) {
            navigate("/edpn");
        }
    })

    useEffect(() => {
        UpdateNoteComplete();
    }, [timeIn, timeOut, selectedClients, groupSummary])

    useEffect(() => {
        UpdateExampleTextByQuestionID();
    }, [])

    // AUTO REFRESH EVERY 1/2 sec for autosave
    useEffect(() => {
        const intervalId = setInterval(() => {

            //if autosave flag is set, and we are not currently autosaving, autosave
            if (!isAutosaving && edpnContext.autosaveFlag) {
                console.log("Autosaving");
                edpnContext.setAutosaveFlag(false);
                CreateGroupNotes(false);
            }

        }, (0.5 * 1000)); // 0.5 seconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    });

    function NavigateBack() {
        navigate("/edpn");
    }

    function UpdateNoteComplete() {

        setNoteIncomplete(
            groupSummary.length == 0 ||
            selectedClients == null ||
            selectedClients?.length < 2 ||
            timeIn == null ||
            timeOut == null
        );
    }

    function TimeInValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempTimeInText = timeInText.replace(/\s+/g, '').trim()

        var d1 = parse(tempTimeInText, "h:mm a", new Date());
        var d2 = parse(tempTimeInText, "h:mma", new Date());
        var d3 = parse(tempTimeInText, "hmma", new Date());
        var d4 = parse(tempTimeInText, "h a", new Date());
        var d5 = parse(tempTimeInText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempTimeInText != dformatted) {
                    setTimeInText(dformatted);
                }

                //check for negative time
                if ((timeOut && isBefore(d, timeOut)) || (SiblingDate && isBefore(d, SiblingDate))) {
                    //update time in
                    setTimeIn(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeOutValidateTime(false, d);
                    }
                }
                else if (!timeOut) {
                    setTimeIn(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeOutValidateTime(false, d);
                    }
                }
                else {
                    setTimeIn(null);
                }

            }
        }
        else {
            setTimeIn(null);
        }
    }

    function TimeOutValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempTimeOutText = timeOutText.replace(/\s+/g, '').trim()

        var d1 = parse(tempTimeOutText, "h:mm a", new Date());
        var d2 = parse(tempTimeOutText, "h:mma", new Date());
        var d3 = parse(tempTimeOutText, "hmma", new Date());
        var d4 = parse(tempTimeOutText, "h a", new Date());
        var d5 = parse(tempTimeOutText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempTimeOutText != dformatted) {
                    setTimeOutText(dformatted);
                }

                //check for negative time
                if ((timeIn && isAfter(d, timeIn)) || (SiblingDate && isAfter(d, SiblingDate))) {
                    //update time out
                    setTimeOut(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeInValidateTime(false, d);
                    }
                }
                else if (!timeIn) {
                    setTimeOut(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeInValidateTime(false, d);
                    }
                }
                else {

                    setTimeOut(null);
                }
            }
        }
        else {
            setTimeOut(null);
        }
    }

    function UpdateExampleTextByQuestionID() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };
            var url = apiConfig.apiEndpoint + "/EDPN/GetExampleTextByQuestionID?questionID=250";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setGroupSummaryExample(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetExampleTextByQuestionID" });
                        globalContext.setErrorModalActive(true);
                    }
                );
        });
    }

    function UpdateAbaClinicianOptions() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };
            var url = apiConfig.apiEndpoint + "/EDPN/GetChoices?questionid=228";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setAbaClinicianOptions(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GroupCreate" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateOtherPeopleOptions() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };
            var url = apiConfig.apiEndpoint + "/EDPN/GetChoices?questionid=230";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setOtherPeopleOptions(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GroupCreate" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }
    function OnABAChange(input: MultiValue<TherapistOption>) {
        var rbts: TherapistOption[] = [];

        if (globalContext.therapists) {
            input.forEach(x => {
                var foundrbt = globalContext.therapists.find(z => String(z.therapistId) === String(x.value));
                if (foundrbt)
                {
                    var temp: TherapistOption = ({ value: String(foundrbt.therapistId), label: foundrbt.employeeName })
                    rbts.push(temp);
                }
            })
        }

        setSelectedABAClinicians(rbts);
    }

    function OnOtherChange(input: MultiValue<TherapistOption>) {
        var rbts: TherapistOption[] = [];

        if (globalContext.therapists) {
            input.forEach(x => {
                var foundrbt = globalContext.therapists.find(z => String(z.therapistId) === String(x.value));
                if (foundrbt) {
                    var temp: TherapistOption = ({ value: String(foundrbt.therapistId), label: foundrbt.employeeName })
                    rbts.push(temp);
                }
            })
        }

        setSelectedOtherPeople(rbts);
    }

    function OnClientsChange(input: MultiValue<ChoiceOption>) {

        var clients: ChoiceOption[] = [];

        if (edpnContext.clients) {
            input.forEach(x => {
                var foundClient = edpnContext.clients?.find(z => String(z.clientId) === String(x.value));
                if (foundClient) {
                    var temp: ChoiceOption = ({ value: String(foundClient.clientId), label: foundClient.fullName })
                    clients.push(temp);
                }
            })
        }

        setSelectedClients(clients);
    }

    const CreateNotesButton = () => {

        if (addNoteLoading) {
            return (
                <button disabled={true} className="m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacblue-800">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else if (selectedClients && selectedClients?.length > 1) {
            return (<button disabled={noteIncomplete} onClick={() => CreateGroupNotes(true)} className={"bg-lacblue m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 " + (noteIncomplete ? 'bg-lacblue-600' : '')}><span className="drop-shadow">Create Notes</span></button>)
        }
        else {
            return (<button disabled={true} onClick={() => CreateGroupNotes(true)} className={"bg-lacblue m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 bg-lacblue-600"}><span className="drop-shadow">Create Notes</span></button>)
        }
    }

    

    function SuccessModalClose() {
        NavigateBack();
    }


    function CreateAnswerObject(clientid: number): EDPNAnswerObject 
    {
        var answerObject: EDPNAnswerObject = {
            answers: [
                { //ABA Clinician
                    answerId: null,
                    questionId: 238,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: abaClinician,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { //ABA Clinician Present
                    answerId: null,
                    questionId: 228,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: selectedAbaClinicianOption ? Number(selectedAbaClinicianOption.value) : -1,
                    log: null,
                    question: null
                },
                { // Additional ABA Clinician Name
                    answerId: null,
                    questionId: 229,
                    multiIndex: null,
                    logId: null,
                    textValue: selectedABAClinicians && selectedABAClinicians.length > 0 ? selectedABAClinicians.map(person => person.value).join(',') : '-1',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present bool
                    answerId: null,
                    questionId: 240,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: otherPeople,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present
                    answerId: null,
                    questionId: 230,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: selectedOtherPeopleOption ? Number(selectedOtherPeopleOption.value) : -1,
                    log: null,
                    question: null
                },
                { // Other People Present Name
                    answerId: null,
                    questionId: 231,
                    multiIndex: null,
                    logId: null,
                    textValue: selectedOtherPeople && selectedOtherPeople.length > 0 ? selectedOtherPeople.map(person => person.value).join(',') : '-1',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // group summary
                    answerId: null,
                    questionId: 250,
                    multiIndex: null,
                    logId: null,
                    textValue: groupSummary,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                }
            ],
            logId: null,
            startTime: timeIn ? new Date(timeIn.toLocaleString() + " +0000") : null,
            endTime: timeOut ? new Date(timeOut.toLocaleString() + " +0000") : null,
            createdDate: new Date(new Date().toLocaleString() + " +0000"),
            approvedDate: null,
            clientId: clientid,
            cptcode: edpnContext.selectedCPTCode?.value ?? null,
            narrative: null,
            billingDate: new Date(),
            therapistId: globalContext.CoreAccount.therapistData?.therapistId ?? null,
            dpnstatusId: 6,
            active: true,
            harvested: false,
            client: null,
            dpnstatus: null,
            therapist: null,
            ecldpnanswers: null,
            placeOfServiceId: edpnContext.selectedServicePlace ? Number(edpnContext.selectedServicePlace.value) : null,
            centerId: edpnContext.selectedCenter ? edpnContext.selectedCenter.centerId : null,
            updatedOn: null,
            updatedBy: null,
            approvedById: null,
            podId: edpnContext.clients ? (edpnContext.clients.find(x => x.clientId === clientid)?.podId ?? null) : null,
            clientName: null,
            comments: null,
            modeOfServiceId: null,
            isIamedicaid: null,
            isIlmedicaid: null,
            isInmedicaid: null,
            isMimedicaid: null,
            logSetId: null,
            timezone: null,
            showApproveButton: null,
            therapyScheduleId: null,
            therapySchedule: null,
            companyInsuranceId: null,
            absent: null,
            absentReasonId: null,
            tardyReasonId: null,
            tardyReason: null,
            antecendant: null,
            bcbaname: null,
            injury: null,
            scheduleDuration: null,
            dpnactualDuration: null,
            groupId: null,
        };

        return answerObject;
    }

    function CreateGroupNotes(isComplete: boolean) {

        setIsAutoSaving(true);

        
        if (isComplete) {
            setAddNoteLoading(true);
        }

        //delete autosaved logs that are no longer in select
        if (selectedClients) {
            const filteredAutosaveArray = autosaveArray.filter((autosaveObject) => !selectedClients.some(client => client.value === String(autosaveObject.clientID)));
            filteredAutosaveArray.forEach(autosaveObject => {
                //delete log
                HardDeleteLog(autosaveObject.LogID);

                //remove log from autosave array
                var tempAutosaveArray = autosaveArray;
                tempAutosaveArray = tempAutosaveArray.filter(x => x.clientID != autosaveObject.clientID);
                console.log("new autosave array:", tempAutosaveArray);
                setAutosaveArray(tempAutosaveArray);
            })
        }

        var currentGroupID = "";

        globalContext.GetRandomGuid().then((newGuid: any) => {

            //update all logs
            selectedClients?.forEach(client => {

                console.log("saving for:", client.label);

                //set created date
                setAddNoteLoading(true);
                var finalAnswerObject = CreateAnswerObject(Number(client.value));

                if (finalAnswerObject) {

                    if (edpnContext.selectedCPTCode && edpnContext.selectedCPTCode.value) {
                        finalAnswerObject.cptcode = edpnContext.selectedCPTCode.value;
                    }

                    finalAnswerObject.createdDate = new Date(new Date().toLocaleString() + " +0000");
                    finalAnswerObject.billingDate = new Date();

                    //set dates
                    if (timeIn) {
                        //forced timezone to UTC so it saves in database correctly.
                        finalAnswerObject.startTime = new Date(timeIn.toLocaleString() + " +0000");
                    }

                    if (timeOut) {
                        //forced timezone to UTC so it saves in database correctly.
                        finalAnswerObject.endTime = new Date(timeOut.toLocaleString() + " +0000");
                    }

                    //set place of service
                    if (edpnContext.selectedServicePlace) {
                        finalAnswerObject.placeOfServiceId = Number(edpnContext.selectedServicePlace.value);
                    }

                    //set centerid
                    if (edpnContext.selectedClient && edpnContext.selectedClient.centerId) {
                        finalAnswerObject.centerId = edpnContext.selectedClient.centerId;
                    }

                    //set podid
                    if (edpnContext.selectedClient && edpnContext.selectedClient.podId) {
                        finalAnswerObject.podId = edpnContext.selectedClient.podId;
                    }

                    if (selectedReason) {
                        finalAnswerObject.tardyReasonId = selectedReason.id;
                    }

                    finalAnswerObject.dpnstatusId = 6;

                    //use existing group id, or generate new one
                    if (!currentGroupID) {

                        //grab group id from previous autosave or get a new one
                        if (autosaveArray.length > 0) {
                            console.log("get group id from autosave");
                            finalAnswerObject.groupId = autosaveArray[0].GroupId;
                        }
                        else {
                            console.log("got group id from backend");
                            finalAnswerObject.groupId = newGuid;
                        }
                    }
                    else {
                        console.log("get group id from previous client in grouop");
                        finalAnswerObject.groupId = currentGroupID;
                    }


                    if (finalAnswerObject.groupId) {
                        currentGroupID = finalAnswerObject.groupId;
                    }
                
                    

                    //get logid, groupid from autosave array if it exists
                    console.log("autosave array check:", autosaveArray);
                    console.log("clientid:", finalAnswerObject.clientId);
                    var foundAutosaveObject = autosaveArray.find(x => x.clientID == finalAnswerObject.clientId);
                    console.log("foundAutosaveObject:", foundAutosaveObject);
                    if (foundAutosaveObject) {
                        finalAnswerObject.logId = foundAutosaveObject.LogID;
                        finalAnswerObject.groupId = foundAutosaveObject.GroupId;
                    }
                }


                console.log("finalanswerobject:", finalAnswerObject);

                //add log
                globalContext.GetToken().then((token: any) => {

                    //add log to database
                    var headers = new Headers();
                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    headers.append("Content-type", "application/json;charset=UTF-8");

                    var options = {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify(finalAnswerObject)
                    };

                    //debug size in kb
                    const size = new TextEncoder().encode(JSON.stringify(finalAnswerObject)).length
                    const kiloBytes = size / 1024;
                    console.log("size:" + kiloBytes + " kb ", finalAnswerObject);

                    var url = apiConfig.apiEndpoint + "/EDPN/UpdateEDPN";

                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result: APIReturnObject) => {

                                console.log("api result:", result);
                                if (result.status === Status.SUCCESS) {
                                    if (isComplete) {
                                        setAddNoteLoading(false);
                                        setSuccessModalActive(true);
                                        console.log("regular save");
                                    }
                                    else {
                                        if (result.message) {

                                            const jsonResult = JSON.parse(result.message);
                                            console.log("raw json result:", jsonResult);

                                            var newLogObject: AutosaveObject = {
                                                clientID: jsonResult.ClientId,
                                                LogID: jsonResult.LogId,
                                                GroupId: jsonResult.GroupId,
                                            };
                                            console.log("Autosaved:", newLogObject);

                                            globalContext.setToastMessage("Autosave Successful");
                                            globalContext.setToastActive(true);

                                            //update logid
                                            var tempAnswerObjects = autosaveArray;
                                            var foundObject = tempAnswerObjects.find(x => x.LogID == newLogObject.LogID);
                                            if (foundObject) {
                                                //already exists, overwrite
                                                foundObject.LogID = newLogObject.LogID;
                                            }
                                            else {
                                                //add
                                                tempAnswerObjects.push(newLogObject);
                                                console.log("adding log to autosave array:", newLogObject);
                                            }
                                        }
                                    }

                                }
                                else if (result.status === Status.FAIL) {
                                    globalContext.setNotificationModalMessage("Cannot create note. " + result.message);
                                    globalContext.setNotificationModalActive(true);
                                    setAddNoteLoading(false);
                                }
                                else if (result.status === Status.ERROR) {
                                    globalContext.setErrorModalProps({ errorMessage: "Failed to create note. " + result.message, errorLocation: "/EDPN/UpdateEDPN" });
                                    globalContext.setErrorModalActive(true);
                                    setAddNoteLoading(false);
                                }
                                setIsAutoSaving(false);
                                setAddNoteLoading(false);
                            },
                            (error) => {
                                setAddNoteLoading(false);
                                setErrorMessage(error);
                                setErrorModalActive(true);
                                setIsAutoSaving(false);
                            }
                        )

                })

            })
        })
    }

    function HardDeleteLog(LogID: string) {
        //delete log
        globalContext.GetToken().then((token: any) => {

            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
            };
           
            var url = apiConfig.apiEndpoint + "/EDPN/HardDeleteLog?id=" + LogID;

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result: APIReturnObject) => {
                        if (result.status === Status.SUCCESS) {
                            console.log("hard delete success:", LogID);
                        }
                        else if (result.status === Status.FAIL) {
                            globalContext.setNotificationModalMessage("Cannot delete note. " + result.message);
                            globalContext.setNotificationModalActive(true);
                        }
                        else if (result.status === Status.ERROR) {
                            globalContext.setErrorModalProps({ errorMessage: "Failed to delete note. " + result.message, errorLocation: "/EDPN/HardDeleteLog" });
                            globalContext.setErrorModalActive(true);
                        }
                    },
                    (error) => {
                        setErrorMessage(error);
                        setErrorModalActive(true);
                    }
                )

        })


        
    }

    function OpenExample() {
        if (groupSummaryExample) {
            edpnContext.setExampleMessage(groupSummaryExample);
            edpnContext.setExampleModalActive(true);
        }
    }

    if (!pageLoading) {
        return (
            <div className="ml-auto mr-auto p-2">
                <div className="grid grid-cols-2 h-fit mt-0 mb-auto">

                    <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-40 cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to Logs</span>
                        <div className="flex-1"></div>
                    </div>

                </div>

                <div className="grid gap-2 grid-cols-12 xl:w-[40rem] w-full m-auto mt-4 mb-8">

                    <span className="w-full col-span-12 text-xl font-bold text-center">
                        Group Note
                    </span>

                    <span className="w-full col-span-12 text-center">
                        {new Date().toLocaleDateString() }
                    </span>

                    <div className="w-full xl:col-span-12 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Learners Present:
                        </label>
                        <Select isMulti={true} isSearchable={true} closeMenuOnSelect={false} options={edpnContext.clients && edpnContext.clients.length > 0 ? edpnContext.clients.map((client) => ({ value: String(client.clientId), label: client.fullName })) : undefined} onChange={OnClientsChange} onBlur={() => edpnContext.setAutosaveFlag(true)} value={selectedClients} />
                    </div>

                    <div className="w-full xl:col-span-12 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Service Type:
                        </label>
                        <Select isSearchable={false} isDisabled={true} value={edpnContext.selectedCPTCode} options={edpnContext.CPTCodes ? edpnContext.CPTCodes : undefined} onChange={edpnContext.setSelectedCPTCode} />
                    </div>

                    <div className="w-full xl:col-span-12 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Place of Service:
                        </label>
                        <Select isSearchable={false} options={edpnContext.servicePlaces ? edpnContext.servicePlaces : undefined} onChange={edpnContext.setSelectedServicePlace} onBlur={() => edpnContext.setAutosaveFlag(true)} value={edpnContext.selectedServicePlace} />
                    </div>


                    <div className="w-full m-auto sm:col-span-6 col-span-12">
                        <label className="block font-bold" htmlFor="search">
                            Time In:
                        </label>
                        <input onBlur={() => { TimeInValidateTime(true, null); edpnContext.setAutosaveFlag(true);}} onChange={event => setTimeInText(event.target.value)} value={timeInText} autoComplete="off" type="text" className={"outline-none h-[38px]  text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (timeInText.length > 0 && !timeIn ? 'border-2 border-rose' : 'border border-gray-300')} placeholder="hh:mm aa" />
                    </div>

                    <div className="w-full m-auto sm:col-span-6 col-span-12">
                        <label className="block font-bold" htmlFor="search">
                            Time Out:
                        </label>
                        <input onBlur={() => { TimeOutValidateTime(true, null); edpnContext.setAutosaveFlag(true); } } onChange={event => setTimeOutText(event.target.value)} value={timeOutText} autoComplete="off" type="text" className={"outline-none h-[38px]  text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (timeOutText.length > 0 && !timeOut ? 'border-2 border-rose' : 'border border-gray-300')} placeholder="hh:mm aa" />
                    </div>

                    <div className="w-full grid grid-cols-12 col-span-12">
                        <label className="font-bold col-span-11" htmlFor="centers">
                            Group Summary:
                        </label>
                        <div onClick={OpenExample} className={"cursor-pointer ml-auto mb-1 col-span-1 " + (!groupSummaryExample ?? "hidden")} >
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle text-lacblue hover:text-lacblue-500" icon={solid('circle-info')} />
                        </div>
                        <textarea disabled={edpnContext.isViewOnly} autoComplete="off" className="outline-none col-span-12 min-h-[95px] h-[95px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" value={groupSummary} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={(event) => setGroupSummary(event.target.value)} />
                    </div>

                    <div className="w-full m-auto mt-4 col-span-12">
                        <label className="block font-bold">
                            ABA Clinician:
                        </label>
                        <div className="flex items-center space-x-4 h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5">
                            <label className="inline-flex items-center w-[50%]">
                                <input type="checkbox" className="form-radio text-blue-500" onChange={(e) => setAbaClinician(!abaClinician)} onBlur={() => edpnContext.setAutosaveFlag(true)} checked={abaClinician}  />
                                <span className="ml-2">Yes</span>
                            </label>
                        </div>
                    </div>

                    <div className={"w-full m-auto mt-4 col-span-12 " + (!abaClinician ? 'hidden' : '')}>
                        <label className="block font-bold">
                            ABA Clinician Present:
                        </label>
                        <Select isSearchable={false} isDisabled={!abaClinician} options={abaClinicianOptions} onChange={setSelectedAbaClinicianOption} onBlur={() => edpnContext.setAutosaveFlag(true)} value={selectedAbaClinicianOption} />
                    </div>


                    <div className={"w-full m-auto mt-4 col-span-12 " + (!abaClinician ? "hidden" : "")}>
                        <label className="block font-bold">
                            Additional ABA Clinician Name:
                        </label>
                        <Select
                            isDisabled={!abaClinician}
                            components={{ ValueContainer }}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            onBlur={() => edpnContext.setAutosaveFlag(true)}
                            options={globalContext.therapists.map((therapist) => ({ value: String(therapist.therapistId), label: therapist.employeeName }))}
                            onChange={(selectedOptions: OnChangeValue<TherapistOption, true>) => OnABAChange(selectedOptions)}
                            value={selectedABAClinicians} />
                    </div>


                    <div className="w-full m-auto mt-4 col-span-12">
                        <label className="block font-bold">
                            Other People:
                        </label>
                        <div className="flex items-center space-x-4 h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5">
                            <label className="inline-flex items-center w-[50%]">
                                <input type="checkbox" className="form-radio text-blue-500" onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={(e) => setOtherPeople(!otherPeople)} checked={otherPeople} />
                                <span className="ml-2">Yes</span>
                            </label>
                        </div>
                    </div>

                    <div className={"w-full m-auto mt-4 col-span-12 " + (!otherPeople ? 'hidden' : '')}>
                        <label className="block font-bold">
                            Other People Present:
                        </label>
                        <Select isSearchable={false} isDisabled={!otherPeople} options={otherPeopleOptions} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={setSelectedOtherPeopleOption} value={selectedOtherPeopleOption} />
                    </div>

                    <div className={"w-full m-auto mt-4 col-span-12 " + (!otherPeople ? "hidden" : "")}>
                        <label className="block font-bold">
                            Other People Present Name:
                        </label>
                        <Select
                            isDisabled={!otherPeople}
                            components={{ ValueContainer }}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            onBlur={() => edpnContext.setAutosaveFlag(true)}
                            options={globalContext.therapists.map((therapist) => ({ value: String(therapist.therapistId), label: therapist.employeeName }))}
                            onChange={(selectedOptions: OnChangeValue<TherapistOption, true>) => OnOtherChange(selectedOptions)}
                            value={selectedOtherPeople} />
                    </div>
                    

                    <div className="w-full col-span-12 flex justify-between">
                        <button onClick={NavigateBack} className="bg-rose w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 flex-grow-0 mr-2">
                            <span className="drop-shadow">Cancel</span>
                        </button>
                        <div className="flex-grow"></div>
                        <CreateNotesButton />
                    </div>


                </div>

                <ModalNotification Title={"Summary"} Body={edpnContext.exampleMessage} Active={edpnContext.exampleModalActive} Icon={"Info"} setActive={edpnContext.setExampleModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Success!"} Body={"Notes for " + selectedClients?.map(x => x.label).join(",") + " successfully added."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={SuccessModalClose} />
                <ModalNotification Title={"Error!"} Body={"Contact IT if this error persists. Error Message: " + errorMessage} Active={errorModalActive} Icon={"Warning"} setActive={setErrorModalActive} OnModalClose={function noop() { }} />

            </div>



        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }



};


export function EDPNGroupCreate() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                    <EDPNGroupCreateContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
};