import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";


interface Props {
    BigSize: boolean,
}

export const SignInSignOutButton: React.FC<Props> = (props) => {
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { instance } = useMsal();

    function handleLogin(loginType: string) {

        if (loginType === "popup") {
            instance.loginPopup(loginRequest);
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest);
        }
    }

    function handleLogout(logoutType: string) {
        if (logoutType === "popup") {
            instance.logoutPopup({
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect();
        }
    }

    const SignInButton = () => {
        if (props.BigSize) {
            return (
                <button onClick={() => handleLogin('redirect')} className="bg-lacgreen m-3 w-[12rem] h-[3rem] rounded font-bold text-white transition duration-250 ease-in-out hover:drop-shadow hover:bg-lacgreen-600"><span className="drop-shadow">Sign in</span></button>
            )
        }
        else {
            return (
                <button onClick={() => handleLogin('redirect')} className="bg-lacgreen m-3 w-[8rem] h-[2rem] rounded font-bold text-white transition duration-250 ease-in-out hover:drop-shadow hover:bg-lacgreen-600"><span className="drop-shadow">Sign in</span></button>
            )
        }
    }

    const SignOutButton = () => {
        if (props.BigSize) {
            return (
                <button onClick={() => handleLogout('redirect')} className="bg-rose m-3 w-[12rem] h-[3rem] rounded font-bold text-white transition duration-250 ease-in-out hover:drop-shadow hover:bg-rose-600"><span className="drop-shadow">Sign out</span></button>
            )
        }
        else {
            return (
                <button onClick={() => handleLogout('redirect')} className="bg-rose m-3 w-[8rem] h-[2rem] rounded font-bold text-white transition duration-250 ease-in-out hover:drop-shadow hover:bg-rose-600"><span className="drop-shadow">Sign out</span></button>
            )
        }
    }

    if (isAuthenticated) {
        return (
            <SignOutButton />
        )
    } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
        // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
        return (
            <SignInButton />
        )
    } else {
        return null;
    }
}