import { createContext, useContext, useEffect, useState } from 'react'
import { apiConfig } from '../../authConfig';
import { Center, ChoiceOption, Client, OutcomeEvaluation, Pod, VwOutcomesEvaluation } from '../../utils/Definitions';
import { GlobalContext } from '../../utils/GlobalContext';

//props for global context
type OutcomesContextProviderProps = {
    children: React.ReactNode
}

//definition of export type
type OutcomesContextType = {
    selectedClient: Client | undefined;
    setSelectedClient: any;
    selectedCenter: Center | null;
    setSelectedCenter: React.Dispatch<React.SetStateAction<Center | null>>;
    selectedPod: Pod | null;
    setSelectedPod: React.Dispatch<React.SetStateAction<Pod | null>>;
    getAllClients: boolean;
    setGetAllClients: React.Dispatch<React.SetStateAction<boolean>>;
    allChoiceOptions: ChoiceOption[] | null;
    selectedEvaluationType: number | null;
    setSelectedEvaluationType: React.Dispatch<React.SetStateAction<number | null>>;
    outcomeEvaluationObject: OutcomeEvaluation | null;
    setOutcomeEvaluationObject: React.Dispatch<React.SetStateAction<OutcomeEvaluation | null>>;
    selectedEditAddOrView: string | null;
    setSelectedEditAddOrView: React.Dispatch<React.SetStateAction<string | null>>;
    selectedEvaluationForEdit: VwOutcomesEvaluation | null;
    setSelectedEvaluationForEdit: React.Dispatch<React.SetStateAction<VwOutcomesEvaluation | null>>;
}

//initilize context
export const OutcomesContext = createContext<OutcomesContextType>({
    selectedClient: undefined,
    setSelectedClient: function noop() { },
    selectedCenter: null,
    setSelectedCenter: function noop() { },
    selectedPod: null,
    setSelectedPod: function noop() { },
    getAllClients: false,
    setGetAllClients: function noop() { },
    allChoiceOptions: null,
    selectedEvaluationType: null,
    setSelectedEvaluationType: function noop() { },
    outcomeEvaluationObject: null,
    setOutcomeEvaluationObject: function noop() { },
    selectedEditAddOrView: null,
    setSelectedEditAddOrView: function noop() { },
    selectedEvaluationForEdit: null,
    setSelectedEvaluationForEdit: function noop() { },
});


export const OutcomesContextProvider = ({ children, }: OutcomesContextProviderProps) => {

    const globalContext = useContext(GlobalContext);


    const [selectedClient, setSelectedClient] = useState<Client>();
    const [selectedEditAddOrView, setSelectedEditAddOrView] = useState<string | null>(null);


    const [selectedCenter, setSelectedCenter] = useState<Center | null>(null);
    const [selectedPod, setSelectedPod] = useState<Pod | null>(null);


    const [getAllClients, setGetAllClients] = useState<boolean>(false);

    const [allChoiceOptions, setAllChoiceOptions] = useState<ChoiceOption[] | null>(null);

    const [selectedEvaluationType, setSelectedEvaluationType] = useState<number | null>(null);

    const [outcomeEvaluationObject, setOutcomeEvaluationObject] = useState<OutcomeEvaluation | null>(null);

    const [selectedEvaluationForEdit, setSelectedEvaluationForEdit] = useState<VwOutcomesEvaluation | null>(null);


    useEffect(() => {
        UpdateAllChoiceOptions();
    }, []);

    function UpdateAllChoiceOptions() {
        globalContext.GetToken().then((token: any) => {

            if (token) {

                if (globalContext.CoreAccount.roles.length > 0) {


                    var headers = new Headers();

                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    var options = {
                        method: "GET",
                        headers: headers
                    };

                    var url = apiConfig.apiEndpoint + "/ECL/GetAllChoices";

                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                setAllChoiceOptions(result);
                            },
                            (error) => {
                                globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/GetAllChoices" });
                                globalContext.setErrorModalActive(true);
                            }
                        )

                }
            }
            
        })
    }

    //initilize object that is accessible globally
    const ECLContextExport: OutcomesContextType = {
        selectedClient: selectedClient,
        setSelectedClient: setSelectedClient,
        selectedCenter: selectedCenter,
        setSelectedCenter: setSelectedCenter,
        selectedPod: selectedPod,
        setSelectedPod: setSelectedPod,
        getAllClients: getAllClients,
        setGetAllClients: setGetAllClients,
        allChoiceOptions: allChoiceOptions,
        selectedEvaluationType: selectedEvaluationType,
        setSelectedEvaluationType: setSelectedEvaluationType,
        outcomeEvaluationObject: outcomeEvaluationObject,
        setOutcomeEvaluationObject: setOutcomeEvaluationObject,
        selectedEditAddOrView: selectedEditAddOrView,
        setSelectedEditAddOrView: setSelectedEditAddOrView,
        selectedEvaluationForEdit: selectedEvaluationForEdit,
        setSelectedEvaluationForEdit: setSelectedEvaluationForEdit,
    };

    return <OutcomesContext.Provider value={ECLContextExport}>{children}</OutcomesContext.Provider>;
}