import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiConfig } from "../../authConfig";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { ChoiceOption, Client, EDPNAnswer, EDPNAnswerObject, EDPNQuestion, Therapist, TherapistSmallObject } from "../../utils/Definitions";
import { GlobalContext } from "../../utils/GlobalContext";


interface Props {
    Narritive: string;
    EDPNAnswerObject: EDPNAnswerObject;
    Therapists: TherapistSmallObject[];
    EDPNQuestions: EDPNQuestion[];
    setAnswerObject: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>;
    answerObject: EDPNAnswerObject | null;
}

export const EDPNNarritiveSentance: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const edpnContext = useContext(EDPNContext);
    const navigate = useNavigate();

    //generate narritive when answers change
    useEffect(() => {
        if (props.answerObject) {
            var newNarritive = GenerateNarritive(props.Narritive);
            if (props.answerObject.narrative != newNarritive) {
                props.setAnswerObject({ ...props.answerObject, narrative: newNarritive });
            }
        }
    }, [props.answerObject, props.answerObject?.answers]);


    function GetQuestionByID(id: number, array: EDPNQuestion[]): EDPNQuestion | undefined {
        for (const node of array) {
            if (node.questionId === id) return node;
            if (node.children) {
                const child = GetQuestionByID(id, node.children);
                if (child) return child;
            }
        }
    }


    function GetValueFromAnswer(answers: EDPNAnswer[]): string {
        switch (answers && answers[0] && answers[0].questionId && GetQuestionByID(answers[0].questionId, props.EDPNQuestions)?.type) {
            case "text":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempTextValue = answers[0].textValue ? answers[0].textValue : "";
                    result = tempTextValue;
                }
                return result;
            case "multitext":
                var result: string = "";
                for (let i = 0; i < answers.length; i++) {
                    var tempMultiTextValue = answers[i].textValue ? answers[i].textValue : "";
                    result = String(result + "<div>" + tempMultiTextValue + "</div>");
                }
                return result;
            case "longtext":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempTextValue = answers[0].textValue ? answers[0].textValue : "";
                    result = tempTextValue;
                }
                return result;
            case "number":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempNumberValue = answers[0].numberValue ? String(answers[0].numberValue) : "";
                    result = tempNumberValue;
                }
                return result;
            case "rbt":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var therapistname = GetTherapistNameByID(Number(answers[0].textValue));
                    if (therapistname.length > 0) {
                        result = therapistname;
                    }
                }
                return result;
            case "multirbt":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var therapistnames = GetListOfTherapistNamesByCommaSeperatedID(answers[0].textValue);
                    if (therapistnames.length > 0) {
                        result = "Also present was: " + therapistnames;
                    }
                }
                return result;
            case "choice":
                var result: string = "";
                if (answers && answers[0] && answers[0].choiceValue) {
                    var choice = edpnContext.allChoiceOptions?.find(x => x.value == String(answers[0].choiceValue));
                    if (choice) {
                        return choice.label;
                    }
                }
                return result;
            case "multichoicecreatable":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempTextValue = answers[0].textValue ? answers[0].textValue : "";
                    result = tempTextValue;
                }
                return result;
            case "multichoice":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempTextValue = answers[0].textValue ? answers[0].textValue : "";
                    result = tempTextValue;
                }
                return result;
            case "bool_default_false":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    result = answers[0].boolValue ? "Yes" : "No";
                }
                return result;
            case "bcbapresent":
                var result: string = "";
                if (answers && answers[0] && answers[0].choiceValue) {
                    var choice = edpnContext.allChoiceOptions?.find(x => x.value == String(answers[0].choiceValue));
                    if (choice) {
                        return choice.label;
                    }
                }
                return result;
            case "bcbaname":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var therapistname = GetTherapistNameByID(Number(answers[0].textValue));
                    if (therapistname.length > 0) {
                        result = therapistname;
                    }
                }
                return result;
            case "abapresent":
                var result: string = "";
                if (answers && answers[0] && answers[0].choiceValue) {
                    var choice = edpnContext.allChoiceOptions?.find(x => x.value == String(answers[0].choiceValue));
                    if (choice) {
                        return choice.label;
                    }
                }
                return result;
            case "abaname":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var therapistname = GetTherapistNameByID(Number(answers[0].textValue));
                    if (therapistname.length > 0) {
                        result = therapistname;
                    }
                }
                return result;
            case "peoplepresent":
                var result: string = "";
                if (answers && answers[0] && answers[0].choiceValue) {
                    var choice = edpnContext.allChoiceOptions?.find(x => x.value == String(answers[0].choiceValue));
                    if (choice) {
                        return choice.label;
                    }
                }
                return result;
            case "peoplename":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var therapistname = GetTherapistNameByID(Number(answers[0].textValue));
                    if (therapistname.length > 0) {
                        result = therapistname;
                    }
                }
                return result;
            case "text_abnormal_behaviors":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempTextValue = answers[0].textValue ? answers[0].textValue : "";
                    result = tempTextValue;
                }
                return result;
            case "text_injury":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempTextValue = answers[0].textValue ? answers[0].textValue : "";
                    result = tempTextValue;
                }
                return result;
            case "text_participate":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempTextValue = answers[0].textValue ? answers[0].textValue : "";
                    result = tempTextValue;
                }
                return result;
            case "text_split":
                var result: string = "[BLANK]";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempTextValue = answers[0].textValue ? answers[0].textValue : "[BLANK]";
                    result = tempTextValue;
                }
                return result;
            default:
                return "______";
        }
    }

    function GetTherapistNameByID(id: number): string {
        var therapist = props.Therapists.find(x => x.therapistId == id);
        if (therapist) {
            return therapist.employeeName;
        }
        else if (id == -1)
        {
            return "";
        }
        else {
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.name) {
                return globalContext.CoreAccount.currentUser.name;
            }
            else {
                return "Unknown User";
            }
        }
    }

    function GetListOfTherapistNamesByCommaSeperatedID(ids: string): string {

        var names: string[] = [];

        ids.split(',').forEach(x => {
            var temp = GetTherapistNameByID(Number(x));
            names.push(temp);
        })

        if (names.length > 0) {
            return names.join(", ");
        }
        else {
            return "";
        }
         
    }


    function ReplaceNarritive(input: string): string {

        if (input == "{BCBA}") {
            if (props.EDPNAnswerObject.therapistId) {
                return GetTherapistNameByID(props.EDPNAnswerObject.therapistId);
            }
            else {
                return "ERROR!";
            }
        }
        else if (input == "{CLIENT}") {
            if (edpnContext.selectedClient && edpnContext.selectedClient.fullName) {
                return edpnContext.selectedClient.fullName;
            }
            else {
                return "ERROR!";
            }
        }
        else if (input == "{LB}") {
            return "</div><div>";
        }

        //answer replace
        var nummatch = input.match(/\d+/);

        if (nummatch && nummatch[0] && props.EDPNAnswerObject && props.EDPNAnswerObject.answers) {

            var answersFound = props.EDPNAnswerObject.answers.filter(x => x.questionId != null && nummatch && nummatch[0] && String(x.questionId) == nummatch[0]);
            return GetValueFromAnswer(answersFound);
        }

        return input;

    }

    function GenerateNarritive(Narritive: string): string {
        var reg = /\{(.*?)\}/g;

        // Just get all numbers
        const result = Narritive.replace(reg, function (wholeMatch, num) {
            // act here or after the loop...

            return ReplaceNarritive(wholeMatch);
        });

        return result;
    }


    if (props.Narritive && props.Narritive.length > 0 && props.EDPNAnswerObject && props.EDPNAnswerObject.answers && props.answerObject) {
        return (

            <div className="w-full max-w-full h-fit mt-4 bg-gray-50 border drop-shadow rounded p-2 grid grid-cols-12 col-span-12">
                <span className="font-bold">Generated Summary:</span>
                <div className="col-span-12 break-words max-w-fit overflow-x-auto break-words" dangerouslySetInnerHTML={{ __html: props.answerObject.narrative != null ? props.answerObject.narrative : "" }} />

            </div>
        )
    }
    else {
        return <></>
    }
    


};

