import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { CredentialsContext } from "../../pages/credentials/CredentialsContext";
import { Therapist, Center, CredentialsTherapistPayor, VwPayorsPerCenter, TherapistCredentialStatus } from "../../utils/Definitions";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../ProfilePicture";
import { GlobalContext } from "../../utils/GlobalContext";
import { ModalNotification } from "../ModalNotification";
import Select, { MultiValue } from "react-select";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from '@mui/material';



interface Props {
    /*SetSelectedTherapistForModal: React.Dispatch<React.SetStateAction<Therapist | null>>,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>*/
    updateFunction: any;
    newCenterCredentials: boolean;
    centerId: number | null;
}


export const CenterPayorCredentialsCard: React.FC<Props> = (props) => {

    const credentialsContext = useContext(CredentialsContext);
    const globalContext = useContext(GlobalContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [centers, setCenters] = useState<Center[]>();

    const [therapistPayorCredentials, setTherapistPayorCredentials] = useState<CredentialsTherapistPayor[]>();
    const [therapistCredentialStatus, setTherapistCredentialStatus] = useState<TherapistCredentialStatus>();


    function UpdateCenters() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Centers/GetCenters", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setCenters(result);
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetCenters" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateCenterCards() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = "";
            if (props.newCenterCredentials) {
                url = "/Credentials/GetTherapistPayorCredentials?therapistId=" + credentialsContext.selectedTherapist?.therapistId + "&centerId=" + credentialsContext.selectedCenter?.centerId;
            }
            else {
                url = "/Credentials/GetTherapistPayorCredentials?therapistId=" + credentialsContext.selectedTherapist?.therapistId + "&centerId=" + props.centerId
            }

            fetch(apiConfig.apiEndpoint + url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setTherapistPayorCredentials(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetCredentials" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
        
    }

    function UpdateTherapistCredentialsForCenterPayor(payor: CredentialsTherapistPayor, status: boolean, date: Date | null) {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "POST",
                headers: headers
            };
            var url = "";
            if (date == null) {
                url = "/Credentials/SaveCredentials?centerId=" + payor.centerId + "&therapistId=" + payor.therapistId + "&payorId=" + payor.payorId + "&status=" + status + "&date=";
            }
            else {
                url = "/Credentials/SaveCredentials?centerId=" + payor.centerId + "&therapistId=" + payor.therapistId + "&payorId=" + payor.payorId + "&status=" + status + "&date=" + new Date(date).toDateString();
            }

            fetch(apiConfig.apiEndpoint + url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("Update Result:", result);
                        props.updateFunction();
                        credentialsContext.setNewCenterCredentialsCardActive(false);
                        UpdateCenterCards();
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/SaveCredentials" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })

    }

    function UpdateTherapistCredentialStatus() {
        if (props.centerId != null) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };
                fetch(apiConfig.apiEndpoint + "/Credentials/GetTherapistCredentialStatus?therapistId=" + credentialsContext.selectedTherapist?.therapistId + "&centerId=" + props.centerId, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setTherapistCredentialStatus(result);
                        },
                        (error) => {
                            setLoading(false);
                            console.log("In Error: ", props.centerId);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetTherapistCredentialStatus" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function SaveTherapistCredentialStatus(credentialed: boolean) {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "POST",
                headers: headers
            };
            fetch(apiConfig.apiEndpoint + "/Credentials/SaveTherapistCredentialStatus?therapistId=" + credentialsContext.selectedTherapist?.therapistId + "&centerId=" + props.centerId + "&credentialed=" + credentialed, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("Fully Credentialed update status: ", result);
                        UpdateTherapistCredentialStatus();
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/SaveTherapistCredentialStatus" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })

    }

    function GetCenterName() {
        if (centers && props.centerId != null) {
            var center = centers.find(obj => obj.centerId === props.centerId)?.centerName;
            return center;
        }
        else {
            return "";
        }
        
    }


    //update centers
    useEffect(() => {
        UpdateCenterCards();
    }, []);

    //update centers
    useEffect(() => {
        UpdateCenters();
    }, []);

    //update centers
    useEffect(() => {
        UpdateCenterCards();
    }, [credentialsContext.selectedCenter]);

    //update Full Credentials
    useEffect(() => {
        UpdateTherapistCredentialStatus();
    }, []);




    return (
        <div className=" grid w-full md:w-[38rem] w-[28rem] p-5 mb-5 bg-gray-50 border drop-shadow rounded flex">


            {/*<div className="flex-none p-4 h-fit m-auto hidden sm:inline">
                <ProfilePicture Client={null} setSelectedClientForModal={props.SetSelectedTherapistForModal} firstName={props.Therapist.employeeName.split(' ')[0].toString()} lastName={props.Therapist.employeeName.split(' ')[2].toString()} imageSource={null} setProfilePictureModalActive={props.SetProfilePictureModalActive} />
            </div>*/}

            <div className="grid grid-cols-2 w-full m-auto">
                <div className="grid grid-cols-2">
                    <label className="block font-bold" htmlFor="centers">
                        Center:
                    </label>

                    <Select className={(props.newCenterCredentials ? "normal" : "hidden")} options={centers} isDisabled={!props.newCenterCredentials} onChange={credentialsContext.setSelectedCenter} value={credentialsContext.selectedCenter} />
                    <label className={"block " + (!props.newCenterCredentials ? "normal" : "hidden")}>
                        {GetCenterName()}
                    </label>
                </div>

                <div className="grid grid-cols-2 float-right">
                    <span className="ml-3 text-sm font-medium">Fully Credentialed</span>
                    <label className="relative content-right cursor-pointer">
                        <input type="checkbox" className="sr-only peer" onChange={event => SaveTherapistCredentialStatus(!therapistCredentialStatus?.credentialed)} checked={therapistCredentialStatus?.credentialed === true} />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    </label>
                </div>

            </div>

            <table className="table-auto">
                <thead className="border-b dark:border-lacblue-500">
                    <tr className="m-auto w-full">
                        <th>Payor</th>
                        <th>Status</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {therapistPayorCredentials ? therapistPayorCredentials.map(payor => (
                        <tr key={payor.payorId} className="border-b dark:border-lacblue-500 ">
                            <td className="px-10 border-r-2 dark:border-lacblue-500">{payor.payorName}</td>
                            <td className="px-10">
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" className="sr-only peer" onChange={event => UpdateTherapistCredentialsForCenterPayor(payor, !payor.credentialed, payor.credentialedDate)} checked={payor.credentialed === true} />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                </label>
                            </td>

                            <td className="px-10">
                                <DatePicker
                                    value={payor.credentialedDate}
                                    disabled={false}
                                    disableFuture={false}
                                    onChange={(newValue) => {
                                        UpdateTherapistCredentialsForCenterPayor(payor, payor.credentialed, newValue)
                                    }}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                    />
                            </td>
                        </tr>
                    )) : <></>}

                </tbody>

            </table>


        </div>
    )
};