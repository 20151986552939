import { useContext, useEffect, useState } from "react";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { GlobalContext } from "../../utils/GlobalContext";

import { EDPNAnswer, EDPNAnswerObject, EDPNQuestion, Therapist, TherapistOption, TherapistSmallObject } from "../../utils/Definitions";
import { apiConfig } from "../../authConfig";
import Select, { MultiValue, OnChangeValue } from "react-select";
import { ValueContainer } from "../ValueContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Props {
    EDPNQuestion: EDPNQuestion;
    setAnswerObject: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>;
    answerObject: EDPNAnswerObject | null;
    Therapists: TherapistSmallObject[] | null;

}


export const EDPNQuestionTypeMultiRBT: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);
    const edpnContext = useContext(EDPNContext);

    const [selectedRBTs, setSelectedRBTs] = useState<TherapistOption[]>([]);

    const [RBTOptions, setRBTOptions] = useState<TherapistOption[]>();

    useEffect(() => {
        if (props.Therapists) {
            const tempTherapists: TherapistOption[] = props.Therapists.map((therapist, index) => {
                return { value: String(therapist.therapistId), label: therapist.employeeName };
            });

            setRBTOptions(tempTherapists);

        }
    }, [props.Therapists]);

    useEffect(() => {
        //if answer already exists, update
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.EDPNQuestion.questionId);
            if (answer) {

                var tempAnswers = props.answerObject.answers;
                if (tempAnswers) {
                    if (selectedRBTs.length > 0) {
                        tempAnswers.filter(x => x.questionId == props.EDPNQuestion.questionId).forEach(x => x.textValue = selectedRBTs.map(z => z.value).join(','));
                    }
                    else {
                        tempAnswers.filter(x => x.questionId == props.EDPNQuestion.questionId).forEach(x => x.textValue = '-1');
                    }
                }

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
        
    }, [selectedRBTs]);

    useEffect(() => {
        //autopopulate answer object
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.EDPNQuestion.questionId);
            if (!answer) {

                //if answer doesn't already exist, create new
                var tempAnswer: EDPNAnswer = {
                    answerId: null,
                    questionId: props.EDPNQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: selectedRBTs ? selectedRBTs.join(',') : '-1',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });

            }
        }

    }, [edpnContext.selectedEditAnswer, props.answerObject]);


    //auto populate rbt
    useEffect(() => {

        if (edpnContext.selectedEditAnswer && selectedRBTs.length == 0 && RBTOptions) {
            if (edpnContext.selectedEditAnswer && props.EDPNQuestion) {
                var tempanswers = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == props.EDPNQuestion.questionId);

                if (tempanswers && tempanswers.textValue) {

                    var rbts: TherapistOption[] = [];

                    tempanswers.textValue.split(',').forEach((answer) => {
                        var temprbt = RBTOptions.find(x => x.value == answer);
                        if (temprbt) {
                            rbts.push(temprbt);
                        }
                    })

                    setSelectedRBTs(rbts);
                    
                }
            }
        }

    }, [edpnContext.selectedEditAnswer, props.EDPNQuestion, RBTOptions]);
    

    function OnChange(input: MultiValue<TherapistOption>) {
        var rbts: TherapistOption[] = [];

        if (RBTOptions) {
            input.forEach(x => {
                var foundrbt = RBTOptions.find(z => z.value == x.value);
                if (foundrbt) {
                    rbts.push(foundrbt);
                }
            })
        }

        setSelectedRBTs(rbts);
    }

    function OpenExample() {
        if (props.EDPNQuestion.exampleText) {
            edpnContext.setExampleMessage(props.EDPNQuestion.exampleText);
            edpnContext.setExampleModalActive(true);
        }
    }

    if (props.EDPNQuestion.exampleText) {
        return (
            <div className="w-full m-auto mt-4 col-span-12 grid grid-cols-12">
                <label className="font-bold col-span-11">
                    {props.EDPNQuestion.questionText}:
                </label>
                <div onClick={OpenExample} className="cursor-pointer ml-auto mb-1 col-span-1">
                    <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle text-lacblue hover:text-lacblue-500" icon={solid('circle-info')} />
                </div>
                <Select
                    className="col-span-12"
                    isDisabled={edpnContext.isViewOnly}
                    components={{ ValueContainer }}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={RBTOptions}
                    onBlur={() => edpnContext.setAutosaveFlag(true)}
                    onChange={(selectedOptions: OnChangeValue<TherapistOption, true>) => OnChange(selectedOptions)}
                    value={selectedRBTs} />
            </div>
        )
    }
    else {
        return (
            <div className="w-full m-auto mt-4 col-span-12">
                <label className="block font-bold">
                    {props.EDPNQuestion.questionText}:
                </label>
                <Select
                    isDisabled={edpnContext.isViewOnly}
                    components={{ ValueContainer }}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={RBTOptions}
                    onBlur={() => edpnContext.setAutosaveFlag(true)}
                    onChange={(selectedOptions: OnChangeValue<TherapistOption, true>) => OnChange(selectedOptions)}
                    value={selectedRBTs} />
            </div>
        )
    }

};

