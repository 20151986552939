import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignInSignOutButton } from './SignInSignOutButton';

interface Props {
    MenuOpen: boolean,
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export const AccountMenu: React.FC<Props> = (props) => {

    const dropdownRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();


    function handleClickOutside(event: { target: any; }) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            props.setMenuOpen(false);
        }
    }

    function NavigateToProfilePage() {
        navigate("/profile");
        props.setMenuOpen(false);
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return (
        <div ref={dropdownRef} className={"absolute right-0 z-10 mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none " + (props.MenuOpen ? "" : "hidden")} role="menu" aria-orientation="vertical" id="dropdown" aria-labelledby="dropdownDefaultButton" >
            <div className="py-1">
                <button onClick={NavigateToProfilePage} className="text-lacblue block px-4 py-2 font-bold text-xl hover:text-lacgreen transition duration-250 ease-in-out">Edit Profile</button>
            </div>
            <div className="py-1 w-fit m-auto">
                <SignInSignOutButton BigSize={false} />
            </div>
        </div>
    );
}

