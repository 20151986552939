import { useContext, useEffect, useState } from "react";
import { ECLContext } from "../../pages/ecl/ECLContext";
import { ECLAnswer, ECLAnswerObject, ECLQuestion } from "../../utils/Definitions";

interface Props {
    ECLQuestion: ECLQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<ECLAnswerObject | null>>;
    answerObject: ECLAnswerObject | null;
}

export const ECLQuestionTypeNumber: React.FC<Props> = (props) => {

    const eclContext = useContext(ECLContext);

    const [textValue, setTextValue] = useState<string>("");

    useEffect(() => {
        //autopopulate answer object
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);
            if (!answer) {

                var AutoPopulatedNumber = eclContext.selectedEditAnswer?.answers?.find(x => x.questionId == props.ECLQuestion.questionId)?.numberValue;

                //if answer doesn't already exist, create new
                var tempAnswer: ECLAnswer = {
                    answerId: null,
                    questionId: props.ECLQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: AutoPopulatedNumber? AutoPopulatedNumber: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }

    }, [eclContext.selectedEditAnswer, props.answerObject]);
    
    function SaveToObject(value: string) {
        if (props.answerObject && props.answerObject.answers) {

            //if answer already exists, update
            var answer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);
            if (answer) {

                var tempAnswers = props.answerObject.answers;
                if (tempAnswers) {
                    tempAnswers.filter(x => x.questionId == props.ECLQuestion.questionId).forEach(x => x.numberValue = Number(value));
                }

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
            else {
                //if answer doesn't already exist, create new
                var tempAnswer: ECLAnswer = {
                    answerId: null,
                    questionId: props.ECLQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: Number(value),
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
    }

    //auto populate text value
    useEffect(() => {
        if (eclContext.selectedEditAnswer && textValue.length == 0) {
            if (eclContext.selectedEditAnswer && props.ECLQuestion) {
                var tempanswer = eclContext.selectedEditAnswer.answers?.find(x => x.questionId == props.ECLQuestion.questionId);

                if (tempanswer && tempanswer.numberValue) {
                    setTextValue(String(tempanswer.numberValue));
                }
            }
        }

    }, [eclContext.selectedEditAnswer, props.ECLQuestion]);
    

    
    return (
        <div className="w-full m-auto mt-4">
            <label className="block font-bold">
                {props.ECLQuestion.questionText}:
            </label>
            <input disabled={eclContext.isViewOnly} onChange={(event) => SaveToObject(event.target.value)} defaultValue={textValue} autoComplete="off" type="number" className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
        </div>
    )

};

