import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";
/*import toast, { Toaster } from 'react-hot-toast';*/

// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Center, RbtcredentialState, ChoiceOption, Client, AuthorizationStatus, PayorInfoType, VwCenterAuthorization } from "../../utils/Definitions";
import Select, { MultiValue } from "react-select";
import ProtectedPage from "../../utils/ProtectedPage";
import { AuthorizationModal } from "../../components/authorizations/AuthorizationModal";


const AuthorizationsContent = () => {

    const globalContext = useContext(GlobalContext);
    const navigate = useNavigate();

    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const [selectedClientId, setSelectedClientId] = useState<number | null>(null);
    const [selectedAuthId, setSelectedAuthId] = useState<string | null>(null);
    const [isMyCentersChecked, setIsMyCentersChecked] = useState<boolean>(false);
    const [isAuthorizationModalActive, setIsAuthorizationModalActive] = useState<boolean>(false);
    const [selectedCenter, setSelectedCenter] = useState<Center | null>();

    const [payorInfoTypes, setPayorInfoTypes] = useState<PayorInfoType[]>([]);
    const [authorizationStatuses, setAuthorizationStatuses] = useState<AuthorizationStatus[]>([]);
    const [centerAuthorization, setCenterAuthorization] = useState<VwCenterAuthorization[]>([]);
    const [authorizationsDue, setAuthorizationsDue] = useState<VwCenterAuthorization[]>([]);
    const [authorizationsPastDue, setAuthorizationsPastDue] = useState<VwCenterAuthorization[]>([]);

    function NavigateBack() {
        navigate(-1);
    }

    const OnModalClose = () => {
        setSelectedClientId(null);
        setSelectedAuthId(null);
        setSelectedClient(null);
        setIsAuthorizationModalActive(false);
    }

    const HandleAuthorizationClick = (authId: string, clientId: number) => {
        setSelectedAuthId(authId);
        setSelectedClientId(clientId);
    }

    useEffect(() => {
        if (selectedClientId) {
            setIsAuthorizationModalActive(true);
            GetClientById();
        }

    }, [selectedClientId, selectedAuthId]);

    useEffect(() => {
        if (isMyCentersChecked) {
            setAuthorizationsDue(centerAuthorization.filter(x => x.reportDueDate >= new Date() && x.centerId == selectedCenter?.centerId));
            setAuthorizationsPastDue(centerAuthorization.filter(x => x.reportDueDate < new Date() && x.centerId == selectedCenter?.centerId));
        }
        else {
            setAuthorizationsDue(centerAuthorization.filter(x => x.reportDueDate >= new Date()));
            setAuthorizationsPastDue(centerAuthorization.filter(x => x.reportDueDate < new Date()));
        }

    }, [isMyCentersChecked]);

    useEffect(() => {
        GetPayorInfoTypes();
        GetAuthorizationStatuses();
        GetCenterAuthorizations();
    }, []);


    useEffect(() => {
        //select first center if there is no selected center
        if (globalContext.lockdownCenterChoices && globalContext.centers.length > 0 && !selectedCenter) {

            //attempt to default to user's center if they have one
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.centerID && globalContext.CoreAccount.currentUser.centerID != null) {
                var center = globalContext.centers.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.centerId == globalContext.CoreAccount.currentUser.centerID)
                if (center) {
                    setSelectedCenter(center);
                }
                else {
                    //if user doesnt have a center, select first index
                    setSelectedCenter(globalContext.centers[0]);
                }
            }
            else {
                //if user doesnt have a center, select first index
                setSelectedCenter(globalContext.centers[0]);
            }
        }

    }, [centerAuthorization]);

    function GetClientById() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");


            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Clients/GetClientByID?clientId=" + selectedClientId;
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setSelectedClient(result);
                    },
                    (error) => {
                        //setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetClientByID" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function GetCenterAuthorizations() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");


            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Credentials/GetAllCenterAuthorizations";
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setCenterAuthorization(result);
                    },
                    (error) => {
                        //setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetAllCenterAuthorizations" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function GetPayorInfoTypes() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");


            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Credentials/GetPayorInfoTypes";
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setPayorInfoTypes(result);
                    },
                    (error) => {
                        //setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetPayorInfoTypes" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    /* Get payor status  authorizationStatus*/

    function GetAuthorizationStatuses() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Credentials/GetAuthorizationStatuses";
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setAuthorizationStatuses(result);
                    },
                    (error) => {
                        //setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetAuthorizationStatuses" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    

    return (

        <div className="grid grid-rows-auto ml-auto mr-auto">

            <div className="grid grid-cols-1 h-fit mt-0 mb-8">

                <div onClick={NavigateBack} className="my-2 flex items-center justify-center w-fit cursor-pointer col-span-2">
                    <div className="flex-1">
                        <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                    </div>
                    <span className="text-xl w-auto ml-3">Back</span>
                    <div className="flex-1"></div>
                </div>

                <div className="grid grid-flow-row grid-cols-1">

                    <div className="grid grid-cols-1 gap-4 xl:gap-[4.5rem] content-between xl:w-fit xl:m-auto">

                        <div className="grid grid-cols-1 h-fit w-800 p-5 mx-auto">

                            {/*<div className="w-full xl:w-[16rem] m-auto">
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <span className="ml-3 text-sm font-medium">My Authorizations</span>
                                    <input type="checkbox" onChange={event => setIsMyCentersChecked(event.target.checked)} checked={isMyCentersChecked} className="sr-only peer" />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                    <span className="ml-3 text-sm font-medium">Center Authorizations</span>
                                </label>
                            </div>*/}

                            <div className="w-fit m-auto">
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        onChange={(event) => setIsMyCentersChecked(event.target.checked)}
                                        checked={isMyCentersChecked}
                                        className="sr-only peer"
                                    />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                    <span className="ml-3 text-sm font-medium">Center Authorizations</span>
                                </label>

                                {isMyCentersChecked && (
                                    <div className="w-full">
                                        <label className="font-bold" htmlFor="pods">
                                            Center:
                                        </label>
                                        <Select
                                            options={globalContext.lockdownCenters}
                                            onChange={setSelectedCenter}
                                            value={selectedCenter}
                                        />
                                    </div>
                                )}
                            </div>

                            

                            <div className="ml-auto p-2 border-2 border-lacblue rounded-lg">
                                <div className="w-full flex items-center justify-between">

                                    <h2 className="text-2xl text-lacblue w-fit">Authorizations Due in the next 30 Days</h2>

                                </div>

                                <table className="table-auto w-full">
                                    <thead className="w-full border border-lacblue-500">
                                        <tr className="bg-lacblue text-white">
                                            <th>Learner</th>
                                            <th>Payor</th>
                                            <th>Payor Type</th>
                                            <th>Authorized BCBA</th>
                                            <th>Assigned Clinician</th>
                                            <th>Status</th>
                                            <th>Report Due</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Build out dependent on the view */ }

                                        {authorizationsDue.length > 0 ? authorizationsDue.map(authorization =>
                                            <tr className="border border-gray-500 text-center">
                                                <td className="border border-gray-500"><span>{authorization.firstName + " " + authorization.lastName}</span></td>
                                                <td className="border border-gray-500"><span>{authorization.payorName}</span></td>
                                                <td className="border border-gray-500"><span>{authorization.payorInfoType}</span></td>
                                                <td className="border border-gray-500"><span>{authorization.authorizedProvider}</span></td>
                                                <td className="border border-gray-500"><span>{authorization.assignedProvider}</span></td>
                                                <td className="border border-gray-500"><span>{authorization.status}</span></td>
                                                <td className="border border-gray-500"><span>{new Date(authorization.reportDueDate).toLocaleDateString()}</span></td>
                                                <td onClick={e => HandleAuthorizationClick(authorization.authId, authorization.clientId)} className="border border-gray-500"><FontAwesomeIcon className="float-right text-lacblue w-7 h-7 m-auto hover:cursor-pointer" icon={solid('circle-info')} ></FontAwesomeIcon></td>
                                            </tr>
                                        ) : <tr><td colSpan={8} className="text-center text-rose">No Authorizations.</td></tr>}
                                    </tbody>
                                </table>
                            </div>

                            <div className=" p-2 border-2 border-lacblue rounded-lg mt-5">
                                <div className="w-full flex items-center justify-between">

                                    <h2 className="text-2xl text-lacblue w-fit">Completed Authorizations</h2>

                                </div>

                                <table className="table-auto w-full">
                                    <thead className="w-full border border-lacblue-500">
                                        <tr className="bg-lacblue text-white">
                                            <th>Learner</th>
                                            <th>Payor</th>
                                            <th>Payor Type</th>
                                            <th>Authorized BCBA</th>
                                            <th>Assigned Clinician</th>
                                            <th>Status</th>
                                            <th>Report Due</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {authorizationsPastDue.filter(x => x.status !== "Imported").length > 0 ? authorizationsPastDue.filter(x => x.status !== "Imported").map(authorization =>
                                            <tr className="border border-gray-500 text-center">
                                                <td className="border border-gray-500"><span>{authorization.firstName + " " + authorization.lastName}</span></td>
                                                <td className="border border-gray-500"><span>{authorization.payorName}</span></td>
                                                <td className="border border-gray-500"><span>{authorization.payorInfoType}</span></td>
                                                <td className="border border-gray-500"><span>{authorization.authorizedProvider}</span></td>
                                                <td className="border border-gray-500"><span>{authorization.assignedProvider}</span></td>
                                                <td className="border border-gray-500"><span>{authorization.status}</span></td>
                                                <td className="border border-gray-500"><span>{new Date(authorization.reportDueDate).toLocaleDateString()}</span></td>
                                                <td onClick={e => HandleAuthorizationClick(authorization.authId, authorization.clientId)} className="border border-gray-500"><FontAwesomeIcon className="float-right text-lacblue w-7 h-7 m-auto hover:cursor-pointer" icon={solid('circle-info')} ></FontAwesomeIcon></td>
                                            </tr>
                                        ) : <tr><td colSpan={8} className="text-center text-rose">No Authorizations.</td></tr>
                                    }
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>

                    <AuthorizationModal Client={selectedClient} AuthId={selectedAuthId} PayorInfoTypes={payorInfoTypes} AuthorizationStatuses={authorizationStatuses} Active={isAuthorizationModalActive} setActive={setIsAuthorizationModalActive} OnModalClose={OnModalClose} ></AuthorizationModal>
                </div>

            </div>
        </div>
    );

}

export function Authorizations() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>

            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['Authorizations.AppAccess']}>
                    <AuthorizationsContent />
                </ProtectedPage>

            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>

        </>
    );
}