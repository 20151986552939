import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ECLContext } from "../../pages/ecl/ECLContext";
import { CredentialsContext } from "../../pages/credentials/CredentialsContext";
import { Client, Schedule } from "../../utils/Definitions";
import pic1562 from '../../images/1562.png'; 
import pic222 from '../../images/222.png'; 
import pic255 from '../../images/255.png'; 
import pic889 from '../../images/889.png'; 
import pic94 from '../../images/94.png'; 
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../ProfilePicture";
import { GlobalContext } from "../../utils/GlobalContext";
import { ModalNotification } from "../ModalNotification";


interface Props {
    Client: Client,
    SetProfilePictureModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    SetSelectedClientForModal: React.Dispatch<React.SetStateAction<Client | null>>,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>

}

export const ECLClientCard: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const credentialsContext = useContext(CredentialsContext);

    const eclContext = useContext(ECLContext);
    const navigate = useNavigate();

    const [billingDaySchedule, setBillingDaySchedule] = useState<Schedule | null>();


    useEffect(() => {
        if (props.Client) {
            GetSchdedule();
        }
    }, []);

    function GetSchdedule() {
        var today = new Date;
        var dayID = today.getDay() + 1;

        var schedule = credentialsContext.schedules.find(x => x.therapyScheduleId == props.Client.therapyScheduleId && x.dayOfWeekId == dayID);

        if (schedule) {
            setBillingDaySchedule(schedule);
        }
    }

    function NavigateToAddLog() {
        navigate("/ecl/logs/addlog");
    }

    function NavigateToViewLogs() {
        navigate("/ecl/logs/viewlogs");
    }

    function WarnUserMissingCredentials() {
        props.SetCredentialsModalActive(true);
    }

    const HandleAddLog = (value?: string) => {
        if (value) {
            eclContext.setSelectedClient(JSON.parse(value));
            NavigateToAddLog();
        }
    }

    const HandleViewLogs = (value?: string) => {
        if (value) {
            eclContext.setSelectedClient(JSON.parse(value));
            NavigateToViewLogs();
        }
    }

    const AddLogButton = () => {
        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData.signatureImage) {
            return (
                <button onClick={(e) => HandleAddLog(JSON.stringify(props.Client))} className="bg-lacblue m-auto w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Add Log</span></button>
            )
        }
        else {
            return (
                <button onClick={WarnUserMissingCredentials} className="bg-lacblue m-auto w-full h-[3rem] xl:h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Add Log</span></button>
            )
        }
        
    }

    function HandleLearnerInfo() {
        eclContext.setSelectedClient(props.Client);
        navigate("/ecl/learnerinfo");
    }


    return (

        <div className="w-full xl:w-[38rem] h-[12rem] bg-gray-50 border drop-shadow rounded flex">


            <div className="flex-none p-4 h-fit m-auto hidden sm:inline">
                <ProfilePicture isEditable={false} Client={props.Client} setProfilePictureModalActive={props.SetProfilePictureModalActive} setSelectedClientForModal={props.SetSelectedClientForModal} />
            </div>
            

            <div className="grid grid-flow-row grow h-fit">
                <span onClick={HandleLearnerInfo} className="font-bold text-2xl pt-1 cursor-pointer hover:text-lacblue">{props.Client.fullName}</span>


                <span className="font-bold">BIRTHDAY:</span>
                <span>{new Date(props.Client.dob).toLocaleDateString()}</span>

                <span className="font-bold mt-1">SCHEDULE:</span>
                <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{billingDaySchedule ? new Date(billingDaySchedule.startTime).toLocaleTimeString() + "-" + new Date(billingDaySchedule.endTime).toLocaleTimeString() : "N/A"}</span>

                <span className="font-bold mt-1">LOGS TODAY:</span>
                <span>{props.Client.logsCount} ({props.Client.logsTime} hours)</span>

            </div>

            <div className="grid grid-rows-3 flex-none p-2">
                <ProtectedComponent RequiredRoles={['ECL.Write', 'ECL.Approve']}>
                    <AddLogButton/>
                </ProtectedComponent>
                <button onClick={(e) => HandleViewLogs(JSON.stringify(props.Client))} className="bg-lacyellow m-auto w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacyellow-600"><span className="drop-shadow">View Logs</span></button>
                <button className="hidden bg-rose m-auto w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">No Activity</span></button>
            </div>


        </div>
    )
    
    
};

