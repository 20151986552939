import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OutcomesContext } from "../../pages/outcomes/OutcomesContext";
import { Client, VwOutcomesEvaluation } from "../../utils/Definitions";
import pic1562 from '../../images/1562.png';
import pic222 from '../../images/222.png';
import pic255 from '../../images/255.png';
import pic889 from '../../images/889.png';
import pic94 from '../../images/94.png';
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../ProfilePicture";
import { GlobalContext } from "../../utils/GlobalContext";
import { ModalNotification } from "../ModalNotification";

interface Props {
    OutcomesEvaluation: VwOutcomesEvaluation,
    NavigateEditAssessment: any,
}


export const OutcomesEvaluationCard: React.FC<Props> = (props) => {

    return (
        <div className="md:h-fit bg-gray-50 border drop-shadow rounded mt-4 grid">

            <div className="flex grid grid-cols-1 md:grid-cols-4 p-2">
                <span className="grid grow my-auto h-fit w-fit mx-4">
                    <span className="font-bold">DATE:</span>
                    <span className="text-xl">{new Date(props.OutcomesEvaluation.dateCreated).toLocaleDateString()}</span>
                </span>

                <span className="grid grow my-auto h-fit w-fit mx-4 sm:mt-4">
                    <span className="font-bold">TYPE:</span>
                    <span className="text-xl">{props.OutcomesEvaluation.evaluationsType}</span>
                </span>

                <span className="grid grow my-auto h-fit w-fit mx-4 sm:mt-4">
                    <span className="font-bold">STATUS:</span>
                    <span className={"text-xl " + (props.OutcomesEvaluation.dateCompleted == null ? 'text-rose' : 'text-lacgreen')}>{props.OutcomesEvaluation.dateCompleted == null ? 'Not Complete' : 'Complete'}</span>
                </span>

                <ProtectedComponent RequiredRoles={["Outcomes.Write"]} >
                    <div className="grid my-auto">
                        <button onClick={() => props.NavigateEditAssessment(props.OutcomesEvaluation)} className="bg-lacblue mt-1 m-auto w-[8rem] h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>
                    </div>
                </ProtectedComponent>

            </div>
            
        </div>
    )
};
