// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { EDPNContext } from "./EDPNContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";

import { addDays, format, isAfter, isBefore, isMonday, isSameDay, isYesterday, parse, parseISO, set, subDays } from "date-fns";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import { ClassNames } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import { time } from "console";
import { timingSafeEqual } from "crypto";
import { ModalNotification } from "../../components/ModalNotification";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePicture } from "../../components/ProfilePicture";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalConfirm } from "../../components/ModalConfirm";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { AbsentReason, APIReturnObject, Client, ClientComment, ECLAnswerObject, ECLComment, EDPNAnswerObject, EDPNQuestion, FutureTimeObject, Status } from "../../utils/Definitions";
import { EDPNQuestionNode } from "../../components/edpn/EDPNQuestionNode";

//only renders if authenticated
const EDPNAddAbsentContent = () => {

    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);

    const edpnContext = useContext(EDPNContext);

    const [addNoteButtonDisabled, setAddNoteButtonDisabled] = useState<boolean>(true);

    const [commentsLoading, setCommentsLoading] = useState<boolean>(true);

    const [addNoteLoading, setAddNoteLoading] = useState<boolean>(false);

    const [answerObject, setAnswerObject] = useState<EDPNAnswerObject | null>(null);


    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [validationModalActive, setValidationModalActive] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<string>("");

    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);

    

    const [selectedReason, setSelectedReason] = useState<AbsentReason | null>();


    var tempFlatQuestions: EDPNQuestion[] = [];
    const [flatQuestions, setFlatQuestions] = useState<EDPNQuestion[]>([]);

    const [filteredQuestions, setFilteredQuestions] = useState<EDPNQuestion[]>();

    const [clientComment, setClientComment] = useState<string>("");
    const [clientComments, setClientComments] = useState<ClientComment[]>([]);


    useEffect(() => {
        if (!edpnContext.selectedClient) {
            navigate("/edpn");
        }
    })

    useEffect(() => {
        UpdateDiableAddLogButton();

    }, [selectedReason])


    useEffect(() => {
        ResetAnswerObject();
        UpdateComments();
    }, [])

    function NavigateBack() {
        navigate("/edpn");
    }

    function UpdateComments() {
        if (edpnContext.selectedClient && edpnContext.selectedClient.clientId) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Clients/GetCommentsByDate?clientID=" + edpnContext.selectedClient?.clientId + "&date=" + new Date(new Date().toLocaleString() + " +0000").toISOString();

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("raw result:", result);
                            setClientComments(result);
                            setCommentsLoading(false);
                        },
                        (error) => {
                            setCommentsLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetCommentsByDate" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
        
    }

    function ResetAnswerObject() {

        var therapistID: number | null = null;
        if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.therapistID) {
            therapistID = globalContext.CoreAccount.currentUser.therapistID;
        }

        setAnswerObject({
            answers: [],
            logId: null,
            startTime: null,
            endTime: null,
            createdDate: null,
            approvedDate: null,
            clientId: edpnContext.selectedClient ? edpnContext.selectedClient.clientId : null,
            cptcode: null,
            narrative: null,
            billingDate: null,
            therapistId: therapistID,
            dpnstatusId: null,
            active: true,
            harvested: false,
            client: null,
            dpnstatus: null,
            therapist: null,
            ecldpnanswers: null,
            placeOfServiceId: null,
            centerId: null,
            updatedOn: null,
            updatedBy: null,
            approvedById: null,
            podId: null,
            clientName: null,
            comments: null,
            modeOfServiceId: null,
            isIamedicaid: null,
            isIlmedicaid: null,
            isInmedicaid: null,
            isMimedicaid: null,
            logSetId: null,
            timezone: null,
            showApproveButton: null,
            absent: false,
            absentReasonId: null,
            tardyReasonId: null,
            tardyReason: null,
            therapyScheduleId: null,
            therapySchedule: null,
            companyInsuranceId: null,
            antecendant: null,
            bcbaname: null,
            injury: null,
            scheduleDuration: null,
            dpnactualDuration: null,
            groupId: null,
        })

    }

    function AddNote() {

        //set created date
        setAddNoteLoading(true);
        var finalAnswerObject = answerObject;

        if (answerObject && finalAnswerObject && selectedReason?.id) {

            //set dates
            //forced timezone to UTC so it saves in database correctly.
            finalAnswerObject.createdDate = new Date(new Date().toLocaleString() + " +0000");
            finalAnswerObject.billingDate = new Date();

            //set centerid
            if (edpnContext.selectedClient && edpnContext.selectedClient.centerId) {
                finalAnswerObject.centerId = edpnContext.selectedClient.centerId;
            }

            //set podid
            if (edpnContext.selectedClient && edpnContext.selectedClient.podId) {
                finalAnswerObject.podId = edpnContext.selectedClient.podId;
            }

            //set absent stuff
            finalAnswerObject.absent = true;
            finalAnswerObject.absentReasonId = selectedReason.id;

            finalAnswerObject.dpnstatusId = 4;
        }



        //add log
        globalContext.GetToken().then((token: any) => {

            //add log to database
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(finalAnswerObject)
            };

            //debug size in kb
            const size = new TextEncoder().encode(JSON.stringify(finalAnswerObject)).length
            const kiloBytes = size / 1024;
            console.log("size:" + kiloBytes + " kb ", finalAnswerObject);

            var url = apiConfig.apiEndpoint + "/EDPN/UpdateEDPN";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result: APIReturnObject) => {

                        if (result.status === Status.SUCCESS) {
                            setAddNoteLoading(false);
                            setSuccessModalActive(true);
                        }
                        else if (result.status === Status.FAIL) {
                            globalContext.setNotificationModalMessage("Cannot create note. " + result.message);
                            globalContext.setNotificationModalActive(true);
                            setAddNoteLoading(false);
                        }
                        else if (result.status === Status.ERROR) {
                            globalContext.setErrorModalProps({ errorMessage: "Failed to create note. " + result.message, errorLocation: "/EDPN/UpdateEDPN" });
                            globalContext.setErrorModalActive(true);
                            setAddNoteLoading(false);
                        }
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/UpdateEDPN" });
                        globalContext.setErrorModalActive(true);
                        setAddNoteLoading(false);
                    }
                )

        })
    }


    function UpdateDiableAddLogButton() {

        //logic for add log button
        setAddNoteButtonDisabled(selectedReason == null || selectedReason == undefined);
    }


    function SuccessModalClose() {
        NavigateBack();
    }

    function ValidationModalClose() {
    }

    function AddComment() {
        globalContext.GetToken().then((token: any) => {
            if (edpnContext && edpnContext.selectedClient && edpnContext.selectedClient.clientId && globalContext.CoreAccount.name) {

                var commentObject: ClientComment = {
                    clientId: edpnContext.selectedClient.clientId,
                    billingDate: new Date(new Date().toLocaleString() + " +0000"),
                    comment: clientComment,
                    createdOn: new Date(new Date().toLocaleString() + " +0000"),
                    createdBy: globalContext.CoreAccount.currentUser?.name,
                    refId: null,
                };

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(commentObject)
                };

                //debug size in kb
                const size = new TextEncoder().encode(JSON.stringify(commentObject)).length
                const kiloBytes = size / 1024;
                console.log("size:" + kiloBytes + " kb ", commentObject);
                console.log("comment:", commentObject);

                var url = apiConfig.apiEndpoint + "/Clients/AddComment";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                console.log("success adding comment");
                                setClientComment("");
                                UpdateComments();
                            }
                            else {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to add comment. Unknown error.", errorLocation: "/Clients/AddComment" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            setErrorMessage(error);
                            setErrorModalActive(true);
                        }
                )

            }
        })
        
    }

    const AddNoteButton = () => {

        if (addNoteLoading) {
            return (
                <button disabled={true} className="m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacblue-800">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else {
            return (<button onClick={AddNote} disabled={addNoteButtonDisabled} className={"bg-lacblue m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 " + (addNoteButtonDisabled ? 'bg-lacblue-800 hover:bg-lacblue-800' : '')}><span className="drop-shadow">Submit</span></button>)
        }
    }

    function GetTherapistNameByID(id: string | undefined): string {
        if (globalContext.therapists) {
            var therapist = globalContext.therapists.find(x => x.therapistId == (id ? parseInt(id) : undefined));
            if (therapist) {
                return therapist.employeeName;
            } else if (id === "-1") {
                return String(id);
            } else {
                return String(id);
            }
        } else {
            return String(id);
        }
    }


    if (edpnContext.selectedClient && !commentsLoading) {

        return (
            <div className="ml-auto mr-auto p-2">
                <div className="grid grid-cols-2 h-fit mt-0 mb-auto">

                    <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-40 cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to Logs</span>
                        <div className="flex-1"></div>
                    </div>

                    <ProfilePicture isEditable={false} setProfilePictureModalActive={setProfilePictureModalActive} Client={edpnContext.selectedClient} setSelectedClientForModal={setSelectedClientForModal} />

                    <table>
                        <tbody>
                            <tr>
                                <td><span className="font-bold">Learner Name:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.fullName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Birthday:</span></td>
                                <td><span className="text-bold ml-2">{new Date(edpnContext.selectedClient.dob).toLocaleDateString()}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Insurance Provider:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.insuranceName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">RBT Required:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.rbtrequired == null ? '' : edpnContext.selectedClient.rbtrequired ? 'Yes' : 'No'}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Telehealth Allowed:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.telehealthAllowed == null ? '' : edpnContext.selectedClient.telehealthAllowed ? 'Yes' : 'No'}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="grid gap-8 grid-cols-12 xl:w-[40rem] w-full m-auto mt-4 mb-8">

                    <div className="w-full xl:col-span-12 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Absent Reason:
                        </label>
                        <Select isSearchable={false} options={edpnContext.absentReasons ? edpnContext.absentReasons?.filter(x => x.absent == true) : undefined} onChange={setSelectedReason} value={selectedReason} />
                    </div>

                    <div className="w-full xl:col-span-12 col-span-12">

                        <div className="w-full border-2">
                            <span className="font-bold">Comments:</span>
                            <table className="border-separate border-spacing-3">
                                <tbody>
                                    {clientComments ? clientComments.map(comment => <tr key={comment.dpncommentId}><td>[{comment.createdOn ? format(new Date(comment.createdOn), 'M/d/yy') : ""}]</td><td>{comment.createdBy}: </td><td>{comment.comment}</td></tr>) : <></>}
                                    {clientComments.length == 0 && <tr><td className="text-rose">No Comments Today.</td></tr>}

                                </tbody>
                            </table>
                        </div>

                        <label className="block font-bold mt-2">
                            Add Comment:
                        </label>
                        <input maxLength={250} autoComplete="off" type="text" value={clientComment} onChange={(event) => setClientComment(event.target.value)} className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                        <button onClick={AddComment} className={"bg-lacblue m-auto mt-2 w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 noprint"}><span className="drop-shadow">Add Comment</span></button>

                    </div>

                    <div className="w-full col-span-12 flex justify-between">
                        <button onClick={NavigateBack} className="bg-rose w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 flex-grow-0 mr-2">
                            <span className="drop-shadow">Cancel</span>
                        </button>
                        <div className="flex-grow"></div>
                        <AddNoteButton />
                    </div>

                </div>

                <ModalNotification Title={"Success!"} Body={"Note for " + edpnContext.selectedClient.fullName + " successfully added."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={SuccessModalClose} />
                <ModalNotification Title={"Error!"} Body={"Contact IT if this error persists. Error Message: " + errorMessage} Active={errorModalActive} Icon={"Warning"} setActive={setErrorModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning!"} Body={validationMessage} Active={validationModalActive} Icon={"Warning"} setActive={setValidationModalActive} OnModalClose={ValidationModalClose} />


            </div>

            

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        )
       
    }
    
};




export function EDPNAddAbsent() {

    const authRequest = {
        ...loginRequest
    };


    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                    <EDPNAddAbsentContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
      )
};

