// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { JSXElementConstructor, ReactElement, SetStateAction, useContext, useEffect, useState } from "react";
import { ECLClientCard } from "../../components/ecl/ECLClientCard";
import { useNavigate } from "react-router-dom";
import { Center, ChoiceOption, Client, ClientAllergyObject, ClientComment, Pod, TherapistOption, AuthorizedBCBAObject, authorizedBcba, TherapistSmallObject, APIReturnObject, Status } from "../../utils/Definitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Select, { MultiValue, OnChangeValue, SingleValue } from "react-select";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalNotification } from "../../components/ModalNotification";
import { format, zonedTimeToUtc } from 'date-fns-tz'
import { EDPNContext } from "./EDPNContext";
import { EDPNClientCard } from "../../components/edpn/EDPNClientCard";
import { ValueContainer } from "../../components/ValueContainer";
import { EDPNClientCardLearnerInfo } from "../../components/edpn/EDPNClientCardLearnerInfo";
import CreatableSelect from "react-select/creatable";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField, TextFieldProps } from "@mui/material";
import { ModalConfirm } from "../../components/ModalConfirm";


//only renders if authenticated
const EDPNLearnerInfoContent = () => {


    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);
    const edpnContext = useContext(EDPNContext);

    const [allergies, setAllergies] = useState<string>("");
    const [allergiesList, setAllergiesList] = useState<string[]>([]);
    const [allergiesFormatted, setAllergiesFormatted] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(true);
    const [saveAllergiesLoading, setSaveAllergiesLoading] = useState<boolean>(false);
    const [saveBCBALoading, setSaveBCBALoading] = useState<boolean>(true);
    const [saveCommentLoading, setSaveCommentLoading] = useState<boolean>(false);

    const [addAuthorizedBCBADate, setAddAuthorizedBCBADate] = useState<Date | null>(new Date());

    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);
    const [clientComment, setClientComment] = useState<string>("");
    const [clientComments, setClientComments] = useState<ClientComment[]>([]);

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    const [commentsLoading, setCommentsLoading] = useState<boolean>(true);

    const [noLearnerInfo, setNoLearnerInfo] = useState<boolean>(true);

    const [authBCBAS, setAuthBCBAS] = useState<authorizedBcba[]>([]);
    const [selectedAuthBCBA, setSelectedAuthBCBA] = useState<ChoiceOption | null>(null);

    const [selectedDeleteAuth, setSelectedDeleteAuth] = useState<authorizedBcba | null>(null);

    const [confirmDeleteModalActive, setConfirmDeleteModalActive] = useState<boolean>(false);
    const [confirmDeleteModalMessage, setConfirmDeleteModalMessage] = useState<string>("");

    type OptionType = { value: string; label: string } | null;

    const [parentConsentTelehealth, setParentConsentTelehealth] = useState<boolean | null>(null);
    const [parentConsentTelehealthSelectedOption, setParentConsentTelehealthSelectedOption] = useState<OptionType>(null);

    function ProfilePictureOnCLose() {
        setSelectedClientForModal(null);
    }
    const [selectedBCBA, setSelectedBCBA] = useState<TherapistOption | null>();
    const [BCBAOptions, setBCBAOptions] = useState<TherapistOption[]>();


    function NavigateBack() {
        navigate(-1);
    }

    useEffect(() => {
        if (!edpnContext.selectedClient) {
            navigate("/edpn");
        }
    })

    useEffect(() => {
        UpdateAllergies();
        UpdateComments();
        setNoLearnerInfo(!globalContext.CheckRoles("LearnerInfoUpdate"));
        GetClientAuthorizedBCBA();
        GetTelehealthGuardianConsent();
    }, []);

    useEffect(() => {
        if (!selectedBCBA) {
            GetAuthorizedBCBA();
        }
    }, [BCBAOptions]);

    useEffect(() => {
        UpdateAllergiesList();
    }, [allergies]);

    useEffect(() => {
        if (globalContext.therapists) {
            const tempTherapists: TherapistOption[] = globalContext.therapists.map((therapist, index) => {
                return { value: String(therapist.therapistId), label: therapist.employeeName };
            });

            setBCBAOptions(tempTherapists);

        }
    }, [globalContext.therapists]);

    useEffect(() => {
        // Trim allergies and create a new array with trimmed values
        const trimmedAllergiesList = allergiesList.map(allergy => {
            if (typeof allergy === "string") {
                return allergy.trim();
            }
            return allergy; // If it's not a string, keep it as is
        });

        // Filter out empty strings and join the trimmed values
        const formattedAllergies = trimmedAllergiesList.filter(x => x.length > 0).join(",");

        setAllergiesFormatted(formattedAllergies);
    }, [allergiesList]);

    /*useEffect(() => {
        if (parentConsentTelehealth) {
            UpdateTelehealthGuardianConsent();
        }
    }, [parentConsentTelehealth])*/

    useEffect(() => {
        if (parentConsentTelehealth) {
            setParentConsentTelehealthSelectedOption({ value: "1", label: "Yes" }); 
        }
        else {
            setParentConsentTelehealthSelectedOption({ value: "0", label: "No" }); 
        }
    }, [parentConsentTelehealth]);
    

    function UpdateAllergiesList() {
        var temp: string[] = allergies.split(",");
        setAllergiesList(temp);
    }

    function UpdateAllergies() {

        if (edpnContext.selectedClient) {
            globalContext.GetToken().then((token: any) => {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/GetLearnerAllergies?clientID=" + edpnContext.selectedClient?.clientId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setAllergies(result);
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetLearnerAllergies" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function AddComment() {
        globalContext.GetToken().then((token: any) => {

            if (edpnContext && edpnContext.selectedClient && edpnContext.selectedClient.clientId && globalContext.CoreAccount.currentUser) {


                var commentObject: ClientComment = {
                    clientId: edpnContext.selectedClient.clientId,
                    billingDate: new Date(new Date().toLocaleString() + " +0000"),
                    comment: clientComment,
                    createdOn: new Date(new Date().toLocaleString() + " +0000"),
                    createdBy: globalContext.CoreAccount.currentUser.name,
                    refId: null,
                };

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(commentObject)
                };

                //debug size in kb
                const size = new TextEncoder().encode(JSON.stringify(commentObject)).length
                const kiloBytes = size / 1024;
                console.log("size:" + kiloBytes + " kb ", commentObject);

                var url = apiConfig.apiEndpoint + "/Clients/AddComment";

                setSaveCommentLoading(true);

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                console.log("success adding comment");
                                setClientComment("");
                                UpdateComments();
                                setSaveCommentLoading(false);
                            }
                            else {
                                setSaveCommentLoading(false);

                                globalContext.setErrorModalProps({ errorMessage: "Failed to add comment. Unknown error.", errorLocation: "/Clients/AddComment" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            setErrorMessage(error);
                            setErrorModalActive(true);
                        }
                    )

            }
        })

    }

    function UpdateComments() {
        if (edpnContext.selectedClient && edpnContext.selectedClient.clientId) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Clients/GetCommentsByDate?clientID=" + edpnContext.selectedClient?.clientId + "&date=" + new Date(new Date().toLocaleString() + " +0000").toISOString();

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("raw result:", result);
                            setClientComments(result);
                            setCommentsLoading(false);
                        },
                        (error) => {
                            setCommentsLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetCommentsByDate" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }

    }

    function GetAuthorizedBCBA() {
        if (edpnContext.selectedClient && edpnContext.selectedClient.clientId) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Clients/GetAuthorizedBCBA?clientID=" + edpnContext.selectedClient?.clientId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("raw authorized bcba:", result);

                            SelectAuthorizedBCBA(result);
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetAuthorizedBCBA" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }

    }

    function SelectAuthorizedBCBA(BCBA: string) {
        var foundBCBA = BCBAOptions?.find(x => x.label.toLowerCase() == BCBA.toLowerCase());

        if (foundBCBA) {
            setSelectedBCBA(foundBCBA);
        }
        else {
            var tempBCBAOptions = BCBAOptions;

            if (tempBCBAOptions) {

                var tempBCBA: TherapistOption = {
                    value: BCBA,
                    label: BCBA
                };

                tempBCBAOptions.push(tempBCBA);

                tempBCBAOptions = tempBCBAOptions.sort((a, b) => a.label < b.label ? -1 : 1);

                setBCBAOptions(tempBCBAOptions);
                setSelectedBCBA(tempBCBA);
            }
            
        }
        
    }

    function UpdateAuthBCBA(bcba: authorizedBcba) {
        if (bcba) {

            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");


                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(bcba)
                };

                var url = apiConfig.apiEndpoint + "/Clients/UpdateAuthorizedBCBA";

                setSaveBCBALoading(true);

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            if (result.status === Status.SUCCESS) {
                                GetClientAuthorizedBCBA();
                                setSaveBCBALoading(false);

                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setNotificationModalMessage("Cannot save authorized bcba. " + result.message);
                                globalContext.setNotificationModalActive(true);
                                setSaveBCBALoading(false);

                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to save authorized bcba. " + result.message, errorLocation: "/EDPN/UpdateAuthorizedBCBA" });
                                globalContext.setErrorModalActive(true);
                                setSaveBCBALoading(false);

                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/UpdateAuthorizedBCBA" });
                            globalContext.setErrorModalActive(true);
                            setSaveBCBALoading(false);

                        }
                    )
            })




        }
    }

    function SaveAllergies() {

        setSaveAllergiesLoading(true);


        if (edpnContext.selectedClient && edpnContext.selectedClient.clientId) {
            globalContext.GetToken().then((token: any) => {


                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");


                var finalAnswerObject: ClientAllergyObject = {
                    clientID: edpnContext.selectedClient?.clientId,
                    allergies: allergiesFormatted
                };

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(finalAnswerObject)
                };

                var url = apiConfig.apiEndpoint + "/EDPN/UpdateLearnerAllergies";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                UpdateAllergies();
                                setSuccessModalActive(true);
                                setSaveAllergiesLoading(false);
                            }
                            else {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to save allergies. Unknown error.", errorLocation: "/EDPN/UpdateLearnerAllergies" });
                                globalContext.setErrorModalActive(true);
                                setSaveAllergiesLoading(false);

                            }
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/UpdateLearnerAllergies" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }


    }

    function UpdateTelehealthGuardianConsent(hasConsent: boolean) {
        if (edpnContext.selectedClient) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "POST",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/SetTeleHealthParentConsent?clientID=" + edpnContext.selectedClient?.clientId + "&hasConsent=" + hasConsent;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetAuthorizedBCBA" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function GetTelehealthGuardianConsent() {
        if (edpnContext.selectedClient) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/GetTeleHealthParentConsent?clientID=" + edpnContext.selectedClient?.clientId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setParentConsentTelehealth(result);
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetAuthorizedBCBA" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }


    function AddLine() {
        var tempanswers: string[] = [];

        for (var i = 0; i < allergiesList.length; i++) {
            tempanswers.push(allergiesList[i]);
        }

        //new line
        tempanswers.push("");

        setAllergiesList(tempanswers);
    }

    function RemoveLine() {
        if (allergiesList.length > 1) {
            var tempanswers: string[] = [];

            for (var i = 0; i < allergiesList.length; i++) {
                tempanswers.push(allergiesList[i]);
            }

            //remove line
            tempanswers.pop();

            setAllergiesList(tempanswers);
        }
    }


    function UpdateAnswers(index: number, value: string) {

        var tempanswers: string[] = [];

        for (var i = 0; i < allergiesList.length; i++) {
            if (i != index) {
                tempanswers.push(allergiesList[i]);
            }
            else {
                tempanswers.push(value);
            }
        }
        setAllergiesList(tempanswers);
    }

    function SuccessModalClose() {

    }

    function GetTherapistNameByID(id: string | undefined): string {
        if (globalContext.therapists) {
            var therapist = globalContext.therapists.find(x => x.therapistId == (id ? parseInt(id) : undefined));
            if (therapist) {
                return therapist.employeeName;
            } else if (id === "-1") {
                return String(id);
            } else {
                return String(id);
            }
        } else {
            return String(id);
        }
    }

    function OnChange(input: SingleValue<TherapistOption>) {
        var finalOption: TherapistOption | null = null;

        if (BCBAOptions && input) {
            var foundOption = BCBAOptions.find(z => z.value == input.value);
            if (foundOption) {
                finalOption = foundOption;
            }
            else {

                var CustomChoice: ChoiceOption = {
                    value: input.value,
                    label: input.label
                };

                finalOption = CustomChoice;
            }

            setSelectedBCBA(finalOption);
        }

    }

    function AddAuthBCBA() {
        if (selectedAuthBCBA && selectedAuthBCBA.label && addAuthorizedBCBADate && edpnContext.selectedClient && edpnContext.selectedClient.clientId) {

            var authorizedBcba: authorizedBcba = {
                therapistId: Number(selectedAuthBCBA.value),
                clientId: edpnContext.selectedClient.clientId,
                effectiveStart: addAuthorizedBCBADate,
                active: true,
                authorizedBcbaid: null
            };


            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");


                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(authorizedBcba)
                };

                var url = apiConfig.apiEndpoint + "/Clients/UpdateAuthorizedBCBA";

                setSaveBCBALoading(true);

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            if (result.status === Status.SUCCESS) {
                                GetClientAuthorizedBCBA();
                                setSelectedAuthBCBA(null);
                                setSaveBCBALoading(false);
                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setNotificationModalMessage("Cannot save authorized bcba. " + result.message);
                                globalContext.setNotificationModalActive(true);
                                setSaveBCBALoading(false);
                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to save authorized bcba. " + result.message, errorLocation: "/EDPN/UpdateAuthorizedBCBA" });
                                globalContext.setErrorModalActive(true);
                                setSaveBCBALoading(false);
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/UpdateAuthorizedBCBA" });
                            globalContext.setErrorModalActive(true);
                            setSaveBCBALoading(false);
                        }
                    )
            })




        }

    }

    function DeleteAuth() {
        if (selectedDeleteAuth) {

            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");


                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(selectedDeleteAuth)
                };

                var url = apiConfig.apiEndpoint + "/Clients/DeleteClientAuthorizedBCBA";

                setSaveBCBALoading(true);

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            if (result.status === Status.SUCCESS) {
                                GetClientAuthorizedBCBA();
                                setSelectedAuthBCBA(null);
                                setSaveBCBALoading(false);
                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setNotificationModalMessage("Cannot delete authorized bcba. " + result.message);
                                globalContext.setNotificationModalActive(true);
                                setSaveBCBALoading(false);
                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to delete authorized bcba. " + result.message, errorLocation: "/EDPN/DeleteClientAuthorizedBCBA" });
                                globalContext.setErrorModalActive(true);
                                setSaveBCBALoading(false);
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/DeleteClientAuthorizedBCBA" });
                            globalContext.setErrorModalActive(true);
                            setSaveBCBALoading(false);
                        }
                    )
            })




        }

    }

    function GetClientAuthorizedBCBA() {

        if (edpnContext.selectedClient && edpnContext.selectedClient.clientId) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");


                var options = {
                    method: "GET",
                    headers: headers,
                };

                var url = apiConfig.apiEndpoint + "/Clients/GetClientAuthorizedBCBA?clientid=" + edpnContext.selectedClient?.clientId;
                setSaveBCBALoading(true);

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setAuthBCBAS(result);
                            setSaveBCBALoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            setSaveBCBALoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetClientAuthorizedBCBA" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function OnClickDeleteAuth(Auth: authorizedBcba) {
        setSelectedDeleteAuth(Auth);
        setConfirmDeleteModalMessage("<div>Are you sure you want to delete the authorized bcba for this learner:</div><div>" + GetTherapistNameByID(String(Auth.therapistId)) + "?</div>");
        setConfirmDeleteModalActive(true);
    }

    const SaveAllergiesButton = () => {

        if (saveAllergiesLoading) {
            return (
                <button disabled={true} className="m-auto w-[8rem] xl:w-[8rem] h-[2rem] xl:h-[2rem] rounded font-bold text-lg text-white bg-lacgreen-600">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else {
            return (<button disabled={noLearnerInfo} onClick={SaveAllergies} className={"bg-lacgreen m-auto w-[8rem] xl:w-[8rem] h-[2rem] xl:h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600 " + (noLearnerInfo ? 'bg-lacgreen-600' : 'cursor-pointer')}><span className="drop-shadow">Save Allergies</span></button>)
        }
    }

    const AddAuthorizedBCBAButton = () => {

        if (saveBCBALoading) {
            return (
                <button disabled={true} className="m-auto w-[8rem] xl:w-[8rem] h-[2rem] xl:h-[2rem] rounded font-bold text-lg text-white bg-lacgreen-600">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else {
            return (<button disabled={noLearnerInfo || addAuthorizedBCBADate == null || selectedAuthBCBA == null} onClick={AddAuthBCBA} className={"bg-lacgreen m-auto w-[8rem] xl:w-[8rem] h-[2rem] xl:h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600 " + (noLearnerInfo || addAuthorizedBCBADate == null || selectedAuthBCBA == null ? 'bg-lacgreen-600' : 'cursor-pointer')}><span className="drop-shadow">Add</span></button>)
        }
    }

    const AddCommentButton = () => {
        if (saveCommentLoading) {
            return (
                <button disabled={true} className="m-auto w-[8rem] xl:w-[8rem] h-[2rem] xl:h-[2rem] rounded font-bold text-lg text-white bg-lacgreen-600">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else {
            return (<button disabled={clientComment.length == 0} onClick={AddComment} className={"bg-lacgreen m-auto w-[10rem] xl:w-[10rem] h-[2rem] xl:h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600 " + (clientComment.length > 0 ? '' : 'bg-lacgreen-600')}><span className="drop-shadow">Add Comment</span></button>)
        }
    }

    const ClientCard = () => {

        if (edpnContext.selectedClient) {
            return (
                <EDPNClientCardLearnerInfo Client={edpnContext.selectedClient} SetProfilePictureModalActive={setProfilePictureModalActive} SetSelectedClientForModal={setSelectedClientForModal} SetCredentialsModalActive={setCredentialsModalActive} />
            )
        }
        else {
            return <></>
        }
        
    }

    return (

        <div className="grid grid-rows-auto ml-auto mr-auto">

            <div className="grid grid-cols-1 h-fit mt-0 mb-8">

                <div className="grid grid-flow-row">

                    <div className="grid grid-cols-1 mt-4 gap-4 content-between xl:w-fit xl:m-auto">

                        <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-40 cursor-pointer">
                            <div className="flex-1">
                                <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                            </div>
                            <span className="text-xl w-auto ml-3">Back</span>
                            <div className="flex-1"></div>
                        </div>

                        
                        <ClientCard />

                        <div className={"w-full m-auto mt-4 " + (saveBCBALoading ? '' : 'hidden')}>
                            <div className="w-full h-auto flex">
                                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
                            </div>
                        </div>

                        <div className={"w-full m-auto mt-4 " + (saveBCBALoading ? 'hidden' : '')}>
                            <div className="w-full m-auto mt-4 col-span-12">
                                <label className="block font-bold">Authorized Provider History:</label>

                                {/* Check if authBCBAS is empty */}
                                {authBCBAS && authBCBAS.length === 0 && (
                                    <div className="text-red-500">No authorized provider history</div>
                                )}

                                {/* Map through authBCBAS if not empty */}
                                {authBCBAS && authBCBAS.map((bcba, index) => (
                                    <div className="grid grid-cols-3 gap-1 mt-2" key={index}>
                                        {/* Input Box 1 */}
                                        <label htmlFor={`inputBox1-${index}`} className="block font-bold">Authorized Provider:</label>
                                        <label htmlFor={`inputBox2-${index}`} className="block font-bold">Start Date:</label>
                                        <div></div>

                                        <Select
                                            isDisabled={noLearnerInfo}
                                            className=""
                                            components={{ ValueContainer }}
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            options={globalContext.bcbas.map((therapist) => ({ value: String(therapist.therapistId), label: therapist.employeeName }))}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    UpdateAuthBCBA({
                                                        ...bcba, therapistId: Number(newValue.value)
                                                    })
                                                }
                                            }}
                                            value={{
                                                value: String(globalContext.bcbas.find(x => x.therapistId === bcba.therapistId)?.therapistId) || "",
                                                label: globalContext.bcbas.find(x => x.therapistId === bcba.therapistId)?.employeeName || ""
                                            }} 
                                        />

                                        {/* Input Box 2 */}
                                        <DatePicker
                                            disabled={noLearnerInfo}
                                            value={new Date(bcba.effectiveStart)}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    UpdateAuthBCBA({
                                                        ...bcba, effectiveStart: newValue
                                                    })
                                                }
                                            }}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                        />

                                        <button disabled={noLearnerInfo} onClick={() => OnClickDeleteAuth(bcba)} className={"bg-rose m-auto w-[8rem] xl:w-[8rem] h-[2rem] xl:h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 " + (noLearnerInfo ? 'bg-rose-600' : '')}><span className="drop-shadow">Delete</span></button>

                                    </div>
                                ))}

                                {/* New Record Input Boxes */}
                                <div className="grid grid-cols-3 gap-4 mt-2 border-2 border-lacgreen p-2">
                                    {/* Input Box 1 for New Record */}
                                    <label className="block font-bold">New Authorized Provider:</label>
                                    <label className="block font-bold">New Start Date:</label>
                                    <div></div>
                                    <Select
                                        isDisabled={noLearnerInfo}
                                        className=""
                                        components={{ ValueContainer }}
                                        isMulti={false}
                                        closeMenuOnSelect={true}
                                        options={globalContext.bcbas.map((therapist) => ({value: String(therapist.therapistId),label: therapist.employeeName}))}
                                        onChange={setSelectedAuthBCBA}
                                        value={selectedAuthBCBA} />

                                    {/* Input Box 2 for New Record */}
                                    <DatePicker
                                        disabled={noLearnerInfo}
                                        value={addAuthorizedBCBADate}
                                        onChange={setAddAuthorizedBCBADate}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />


                                    <AddAuthorizedBCBAButton/>
                                </div>
                            </div>
                        </div>

                        {/* Telehealth Consent */}
                        <div className="w-full m-auto mt-4">

                            <label className="block font-bold">Does guardian consent to telehealth?:</label>

                            <Select
                                isDisabled={noLearnerInfo}
                                className=""
                                components={{ ValueContainer }}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                options={[
                                    { value: "1", label: "Yes" },
                                    { value: "0", label: "No" }
                                ]}
                                onChange={(newValue) => {
                                    if (newValue) {

                                        setParentConsentTelehealthSelectedOption(newValue);
                                        const boolValue = newValue.value === "1";

                                        UpdateTelehealthGuardianConsent(boolValue);

                                    }
                                    else {
                                        setParentConsentTelehealthSelectedOption(null);
                                    }
                                }}
                                value={parentConsentTelehealthSelectedOption}
                            />
                        </div>


                        <div className="w-full m-auto mt-4">

                            <label className="block font-bold">Allergies:</label>

                            {Array.from(Array(allergiesList.length), (e, i) => {
                                return <input disabled={noLearnerInfo} key={i} onChange={e => UpdateAnswers(i, e.target.value)} value={allergiesList[i]} autoComplete="off" type="text" className="mb-2 outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                            })}

                            <button disabled={noLearnerInfo} onClick={AddLine} className={"bg-lacblue m-auto w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 noprint " + (noLearnerInfo ? 'bg-lacblue-600' : 'cursor-pointer')}><span className="drop-shadow">Add Allergy</span></button>
                            <button disabled={allergiesList.length == 1 || noLearnerInfo} onClick={RemoveLine} className={"bg-rose m-auto ml-2 w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 noprint " + (allergiesList.length == 1 || noLearnerInfo ? 'bg-rose-600' : 'cursor-pointer')}><span className="drop-shadow">Remove</span></button>

                        </div>

                        <div>
                            <SaveAllergiesButton />
                        </div>

                        <div className="w-full mt-12">

                            <div className="w-full border-2">
                                <span className="font-bold">Comments:</span>
                                <table className="border-separate border-spacing-3">
                                    <tbody>
                                        {clientComments ? clientComments.map(comment => <tr key={comment.dpncommentId}><td>[{comment.createdOn ? format(new Date(comment.createdOn), 'M/d/yy') : ""}]</td><td>{comment.createdBy}: </td><td>{comment.comment}</td></tr>) : <></>}
                                        {clientComments.length == 0 && <tr><td className="text-rose">No Comments Today.</td></tr>}
                                    </tbody>
                                </table>
                            </div>

                            <label className="block font-bold mt-2">
                                Add Comment:
                            </label>
                            <input maxLength={250} autoComplete="off" type="text" value={clientComment} onChange={(event) => setClientComment(event.target.value)} className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />

                            <div className="mt-2">
                                <AddCommentButton />

                            </div>


                        </div>

                    </div>

                </div>

            </div>

            <ProfilePictureModal Client={selectedClientForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose} />

            <ModalNotification Title={"Success!"} Body={"Data for " + edpnContext.selectedClient?.fullName + " successfully saved."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={SuccessModalClose} />
            <ModalNotification Title={"Error!"} Body={"Contact IT if this error persists. Error Message: " + errorMessage} Active={errorModalActive} Icon={"Warning"} setActive={setErrorModalActive} OnModalClose={function noop() { }} />
            <ModalConfirm Message={confirmDeleteModalMessage} Active={confirmDeleteModalActive} setActive={setConfirmDeleteModalActive} OnConfirm={DeleteAuth} />

        </div>

    );
    
};


export function EDPNLearnerInfo() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Read', 'EDPN.Write', 'EDPN.Approve', 'ECL.Read', 'ECL.Write', 'ECL.Approve', 'Outcomes.Write']}>
                    <EDPNLearnerInfoContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
};