import { text } from "@fortawesome/fontawesome-svg-core";
import { useContext, useEffect, useState } from "react";
import { ECLContext } from "../../pages/ecl/ECLContext";
import {  ECLAnswer, ECLAnswerObject, ECLQuestion } from "../../utils/Definitions";

interface Props {
    ECLQuestion: ECLQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<ECLAnswerObject | null>>;
    answerObject: ECLAnswerObject | null;
}

export const ECLQuestionTypeBoolDefaultFalse: React.FC<Props> = (props) => {

    const [checkedValue, setCheckedValue] = useState<string | null>(null);

    const eclContext = useContext(ECLContext);


    useEffect(() => {
        if (eclContext.selectedEditAnswer && props.answerObject && props.answerObject.answers && props.ECLQuestion) {


            var tempanswer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);



            if (tempanswer && tempanswer.boolValue != undefined) {
                //autopopulate bool value

                console.log("check check:", tempanswer?.boolValue);

                setCheckedValue(tempanswer.boolValue == true ? 'true' : 'false');
            }
        }

    }, [props.answerObject, props.answerObject?.answers, props.ECLQuestion]);

    useEffect(() => {
        //autopopulate answer object
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);
            if (!answer) {

                var AutoPopulatedBool = eclContext.selectedEditAnswer?.answers?.find(x => x.questionId == props.ECLQuestion.questionId)?.boolValue;

                //if answer doesn't already exist, create new
                var tempAnswer: ECLAnswer = {
                    answerId: null,
                    questionId: props.ECLQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: AutoPopulatedBool == true,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                console.log("creating new bool answer:", tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }

    }, [eclContext.selectedEditAnswer, props.answerObject]);

    useEffect(() => {
        if (props.answerObject && props.answerObject.answers) {

            //if answer already exists, update
            var answer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);
            if (answer) {

                var tempAnswers = props.answerObject.answers;
                if (tempAnswers) {
                    tempAnswers.filter(x => x.questionId == props.ECLQuestion.questionId).forEach(x => x.boolValue = checkedValue == 'true');
                }

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
            else {
                //if answer doesn't already exist, create new
                var tempAnswer: ECLAnswer = {
                    answerId: null,
                    questionId: props.ECLQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: checkedValue == 'true',
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
    }, [checkedValue]);
    

    const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedValue(e.currentTarget.value);
    };

    return (
        <div className="w-full m-auto mt-4">
            <label className="block font-bold">
                {props.ECLQuestion.questionText}:
            </label>

            <div className="flex items-center space-x-4 h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5">

                <label className="inline-flex items-center w-[50%]">
                    <input disabled={eclContext.isViewOnly} type="radio" className="form-radio text-blue-500" name="radio-group" value={'true'} checked={'true' == checkedValue} onChange={(e) => onRadioChange(e)} />
                    <span className="ml-2">Yes</span>
                </label>

                <label className="inline-flex items-center  w-[50%]">
                    <input disabled={eclContext.isViewOnly} type="radio" className="form-radio text-blue-500" name="radio-group" value={'false'} checked={'false' == checkedValue} onChange={(e) => onRadioChange(e)} />
                    <span className="ml-2">No</span>
                </label>

            </div>

        </div>
    )
    
};

