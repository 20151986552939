import React, { ReactChildren, ReactElement, ReactNode, useContext } from "react";
import { GlobalContext } from "./GlobalContext";

interface RequireType {
    children: ReactChildren | ReactNode | ReactElement;
    RequiredRoles: string[];
}

/**
 * If the user has ANY of the given RequiredRoles, renders child components. Otherwise, it will show permission denied message.
 */
const ProtectedPage = ({ children, RequiredRoles }: RequireType): ReactElement => {
    const globalContext = useContext(GlobalContext);

    let hasPermission = globalContext.CheckRoles(RequiredRoles);

    if (hasPermission) {
        return <>{children}</>;
    }
    else {

        if (globalContext.CoreAccount.currentUser) {
            return (
                <div className='text-3xl text-rose font-bold w-full m-auto text-center'>You do not have the required permissions.</div>
            )
        }
        else {
            return <></>
        }
        
    }
};

export default ProtectedPage;