import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SingleValue, ActionMeta, InputActionMeta } from "react-select/dist/declarations/src";
import Select from "react-select";
import { ECLContext } from "../../pages/ecl/ECLContext";
import {  ECLAnswer, ECLAnswerObject, ECLQuestion, Therapist, ChoiceOption, TherapistSmallObject } from "../../utils/Definitions";
import { ECLQuestionTypeMultiText } from "./ECLQuestionTypeMultiText";
import { ECLQuestionTypeText } from "./ECLQuestionTypeText";
import { ECLQuestionTypeNumber } from "./ECLQuestionTypeNumber";
import { ECLQuestionTypeRBT } from "./ECLQuestionTypeRBT";
import { ECLNarritiveSentance } from "./ECLNarritiveSentence";
import { ECLQuestionTypeMultiRBT } from "./ECLQuestionTypeMultiRBT";
import { ECLQuestionTypeChoice } from "./ECLQuestionTypeChoice";
import { GlobalContext } from "../../utils/GlobalContext";
import { ECLQuestionTypeBoolDefaultFalse } from "./ECLQuestionTypeBoolDefaultFalse";
import { ECLQuestionTypeLongText } from "./ECLQuestionTypeLongText";
import { ECLQuestionTypeTextSplit } from "./ECLQuestionTypeTextSplit";

interface Props {
    ECLQuestions: ECLQuestion[],
    FlatQuestions: ECLQuestion[],
    ECLParentQuestion: ECLQuestion | null,
    setAnswerObject: React.Dispatch<React.SetStateAction<ECLAnswerObject | null>>;
    answerObject: ECLAnswerObject | null;
    Therapists: TherapistSmallObject[] | null,
    ResetAnswerObject: any,
    AutoSelectQuestionStack: ECLQuestion[] | null,
}



export const ECLQuestionNode: React.FC<Props> = (props) => {

    const eclContext = useContext(ECLContext);
    const navigate = useNavigate();


    const [options, setOptions] = useState<ECLQuestion[] | null>();

    const [selectedOption, setSelectedOption] = useState<ECLQuestion | null>(null);


    useEffect(() => {

        var tempOptions = GetOptions();

        if (tempOptions) {
            setOptions(tempOptions);
        }
    }, [props.ECLParentQuestion, props.ECLQuestions]);

    function InitializeAnswers() {
        if (options && props.ECLQuestions && (props.ECLParentQuestion == null || (props.ECLParentQuestion && props.ECLParentQuestion.type == "none")) && !(props.ECLParentQuestion && props.ECLParentQuestion.children && props.ECLParentQuestion.children.filter(x => x.type != "none").length > 0)) {

        }
        else {
            //initailize answers
            if (props.answerObject && props.answerObject.answers && props.answerObject.answers.length == 0 && props.ECLParentQuestion && props.ECLParentQuestion.children && (props.ECLParentQuestion.children.filter(x => x.type != "none").length > 0)) {

                var tempAnswers: ECLAnswer[] = [];
                props.ECLParentQuestion.children.filter(x => x.type != "none").forEach((child, i) => {
                    tempAnswers.push(CreateAnswer(child));
                })
                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
    }

    useEffect(() => {
        if (props.answerObject && props.answerObject.answers && props.answerObject.answers.length == 0) {
            InitializeAnswers();
        }

    }, [props.answerObject, props.answerObject?.answers]);

    //when options change, null selected option
    useEffect(() => {
        if (options) {
            setSelectedOption(null);
        }
        
    }, [options]);

    //autoselect question
    useEffect(() => {
        if (props.AutoSelectQuestionStack && props.ECLQuestions && options) {
            
            var tempoptionselect = options.find(z => props.AutoSelectQuestionStack?.find(x => x.questionId == z.questionId));
            if (tempoptionselect) {
                setSelectedOption(tempoptionselect);
            }
        }

    }, [props.AutoSelectQuestionStack, props.ECLQuestions, options]);

    //when options or selected option changes, clear answers array
    useEffect(() => {
        props.ResetAnswerObject();

    }, [selectedOption, options]);
    

    function GetOptions(): ECLQuestion[] | null {

        //if parent question has null id, return top level of tree
        if (!props.ECLParentQuestion || (props.ECLParentQuestion && props.ECLParentQuestion.questionId == null)) {
            return props.ECLQuestions;
        }
        else {
            //find questions from flat questions array
            var temp = props.FlatQuestions.filter(x => x.parentQuestionId == props.ECLParentQuestion?.questionId);
            if (temp) {
                return temp;
            }

        }

        return null;
    }


    function CreateAnswer(ECLQuestion: ECLQuestion): ECLAnswer {

        if (ECLQuestion.type == "text") {
            //text
            return {
                questionId: ECLQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (ECLQuestion.type == "text_split") {
            //text
            return {
                questionId: ECLQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (ECLQuestion.type == "longtext") {
            //long text
            return {
                questionId: ECLQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (ECLQuestion.type == "number") {
            //number
            return {
                questionId: ECLQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (ECLQuestion.type == "multitext") {
            //multitext
            return {
                questionId: ECLQuestion.questionId,
                answerId: null,
                multiIndex: 0,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (ECLQuestion.type == "rbt") {
            //rbt
            return {
                questionId: ECLQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: '-1',
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (ECLQuestion.type == "multirbt") {
            //multi rbt
            return {
                questionId: ECLQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: '-1',
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (ECLQuestion.type == "choice") {
            //choice
            return {
                questionId: ECLQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: -1,
                log: null,
                question: null
            };
        }
        else {
            //booldefaultfalse

            //autopopulate edit
            var tempboolvalue: boolean | null = null;
            if (eclContext.selectedEditAnswer) {
                var tempanswer = eclContext.selectedEditAnswer.answers?.find(x => x.questionId == ECLQuestion.questionId);
                if (tempanswer) {
                    tempboolvalue = tempanswer.boolValue;
                }
            }

            return {
                questionId: ECLQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: tempboolvalue,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        
    }

    if (options && props.ECLQuestions && (props.ECLParentQuestion == null || (props.ECLParentQuestion && props.ECLParentQuestion.type == "none")) && !(props.ECLParentQuestion && props.ECLParentQuestion.children && props.ECLParentQuestion.children.filter(x => x.type != "none").length > 0)) {

        return (
            <div className="w-full m-auto mt-4">

                <label className="block font-bold" htmlFor="pods">
                    Choose:
                </label>

                <Select isDisabled={eclContext.isViewOnly} options={options ? options : undefined} onChange={setSelectedOption} value={selectedOption} />
                
                {selectedOption && selectedOption.children.length > 0 && <ECLQuestionNode ECLQuestions={props.ECLQuestions} ECLParentQuestion={selectedOption} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={props.Therapists} ResetAnswerObject={props.ResetAnswerObject} AutoSelectQuestionStack={props.AutoSelectQuestionStack} FlatQuestions={props.FlatQuestions} />}
                
            </div>

        )
    }
    else {

        return (
            <div className="inline-flex flex-wrap w-full">
                {props.ECLParentQuestion && props.ECLParentQuestion.children && props.ECLParentQuestion.children.map((question, i) => {


                    if (question.type == "text") {
                        return <ECLQuestionTypeText key={question.questionId} ECLQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} />
                    }
                    else if (question.type == "text_split") {
                        return <ECLQuestionTypeTextSplit key={question.questionId} ECLQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} />
                    }
                    else if (question.type == "multitext") {
                        return <ECLQuestionTypeMultiText key={question.questionId} ECLQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} FlatQuestions={props.FlatQuestions} />
                    }
                    else if (question.type == "longtext") {
                        return <ECLQuestionTypeLongText key={question.questionId} ECLQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} />
                    }
                    else if (question.type == "number") {
                        return <ECLQuestionTypeNumber key={question.questionId} ECLQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} />
                    }
                    else if (question.type == "rbt") {
                        return <ECLQuestionTypeRBT key={question.questionId} ECLQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={props.Therapists} />
                    }
                    else if (question.type == "multirbt") {
                        return <ECLQuestionTypeMultiRBT key={question.questionId} ECLQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={props.Therapists} />
                    }
                    else if (question.type == "choice") {
                        return <ECLQuestionTypeChoice key={question.questionId} ECLQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} />
                    }
                    else if (question.type == "bool_default_false") {
                        return <ECLQuestionTypeBoolDefaultFalse key={question.questionId} ECLQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} />
                    }
                })
                }


                {props.ECLParentQuestion && props.ECLParentQuestion.narrativeText && props.answerObject && props.Therapists && <ECLNarritiveSentance Narritive={props.ECLParentQuestion.narrativeText} ECLAnswerObject={props.answerObject} Therapists={props.Therapists} ECLQuestions={props.ECLQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} /> }
            </div>
        );
    }
    
    
    
};

