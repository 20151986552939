import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";

// imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalContext } from "../../utils/GlobalContext";
import ProtectedComponent from "../../utils/ProtectedComponent";
import ProtectedPage from "../../utils/ProtectedPage";




const CredentialsHomeContent = () => {

    const navigate = useNavigate();

    function NavigateToBCBAAdmin() {
        navigate("/credentials/bcbaadmin")
    }

    function NavigateToPayorAdministration() {
        navigate("/credentials/payoradministration")
    }

    function NavigateToLearnerEdit() {
        navigate("/credentials/LearnerEdit")
    }

    function NavigateToCenterAdministration() {
        navigate("/credentials/CenterAdministration")
    }

    return (
        <div className="flex-1 p-5">
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-8 place-items-center w-fit h-full m-auto">
                <ProtectedComponent RequiredRoles={['Credentials.Read', 'Credentials.Write'] }>
                    <div onClick={NavigateToBCBAAdmin} className="h-[16rem] w-[16rem] bg-gray-50 border drop-shadow rounded-lg p-4 hover:border-blue hover:border-4  cursor-pointer">
                        <FontAwesomeIcon className="w-full h-[12rem] grow text-blue" icon={solid('star')} />
                        <span className="flex text-center w-fit m-auto font-bold text-xl">BCBA Administration</span>
                    </div>
                </ProtectedComponent>

                <ProtectedComponent RequiredRoles={['Credentials.Read', 'Credentials.Write']}>
                    <div onClick={NavigateToPayorAdministration} className="h-[16rem] w-[16rem] bg-gray-50 border drop-shadow rounded-lg p-4 hover:border-rose hover:border-4  cursor-pointer">
                        <FontAwesomeIcon className="w-full h-[12rem] grow text-rose" icon={solid('money-bill-wave')} />
                        <span className="flex text-center w-fit m-auto font-bold text-xl">Payor Administration</span>
                    </div>
                </ProtectedComponent>
               
                <ProtectedComponent RequiredRoles={['Administration.Learner.Write']}>
                    <div onClick={NavigateToLearnerEdit} className="h-[16rem] w-[16rem] bg-gray-50 border drop-shadow rounded-lg p-4 hover:border-green hover:border-4  cursor-pointer">
                        <FontAwesomeIcon className="w-full h-[12rem] grow text-green" icon={solid('user-gear')} />
                        <span className="flex text-center w-fit m-auto font-bold text-lg">Learner Administration</span>
                    </div>
                </ProtectedComponent>

                <ProtectedComponent RequiredRoles={['CenterLockdown']}>
                    <div onClick={NavigateToCenterAdministration} className="h-[16rem] w-[16rem] bg-gray-50 border drop-shadow rounded-lg p-4 hover:border-purple hover:border-4 cursor-pointer">
                        <FontAwesomeIcon className="w-full h-[12rem] grow text-purple" icon={solid('house-user')} />
                        <span className="flex text-center w-fit m-auto font-bold text-lg">Center Administration</span>
                    </div>
                </ProtectedComponent>
            </div>
        </div>
    );
};

export function CredentialsHome() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['Credentials.Read', 'Credentials.Write', 'Administration.Learner.Write', 'CenterLockdown']}>
                    <CredentialsHomeContent />
                </ProtectedPage>

            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
}

