import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";
import { CredentialsContext } from "./CredentialsContext";

// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Center, TherapistCredentials, TherapistCredentialNote, TherapistCredentialStatus } from "../../utils/Definitions";
import { CenterPayorCredentialsCard } from "../../components/credentials/CenterPayorCredentialsCard";
import { CredentialsTherapistNotesCard } from "../../components/credentials/CredentialsTherapistNotesCard";
import { CredentialNotesModal } from "../../components/credentials/CredentialNotesModal";
import ProtectedPage from "../../utils/ProtectedPage";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from '@mui/material';


const BCBACredentialsContent = () => {

    const navigate = useNavigate();

    const credentialsContext = useContext(CredentialsContext);
    const globalContext = useContext(GlobalContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [centers, setCenters] = useState<Center[]>();
    const [therapistCredentials, setTherapistCredentials] = useState<TherapistCredentials[]>();
    const [newCenterCredentials, setNewCenterCredentials] = useState<boolean>(false);
    const [therapistNotes, setTherapistNotes] = useState<TherapistCredentialNote[]>();
    const [therapistCredentialedCenters, setTherapistCredentialedCenters] = useState<TherapistCredentialStatus[]>();


    useEffect(() => {
        if (!credentialsContext.selectedTherapist) {
            navigate("/credentials/bcbaadmin");
        }
    })

    function Navigateback() {
        navigate(-1);
    }

    function UpdateNotes() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Credentials/GetTherapistNotes?therapistId=" + credentialsContext.selectedTherapist?.therapistId, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("rawNotes:", result);
                        setTherapistNotes(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetTherapistNotes" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })

    }

    function GetCredentialedCenters() {
        if (credentialsContext && credentialsContext.selectedTherapist) {

            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Credentials/GetTherapistFullyCredentialedCenters?therapistId=" + credentialsContext.selectedTherapist?.therapistId, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setTherapistCredentialedCenters(result);
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetTherapistFullyCredentialedCenters" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }


    function UpdateCenterCards() {
        if (credentialsContext && credentialsContext.selectedTherapist && credentialsContext.selectedTherapist?.therapistId) {

            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Credentials/GetDistinctTherapistCredentials?therapistId=" + credentialsContext.selectedTherapist?.therapistId, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setTherapistCredentials(result);
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetCredentials" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }



    function UpdateCenters() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Centers/GetCenters", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("rawcenters:", result);
                        setCenters(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetCenters" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateRecertDate(date: Date | null) {
        if (credentialsContext.selectedTherapist) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "POST",
                    headers: headers
                };
                var url = "";
                if (date == null) {
                    url = "/Credentials/UpdateTherapistRecertDate?therapistId=" + credentialsContext.selectedTherapist?.therapistId + "&certDate=";
                }
                else {
                    url = "/Credentials/UpdateTherapistRecertDate?therapistId=" + credentialsContext.selectedTherapist?.therapistId + "&certDate=" + new Date(date).toDateString();
                }

                fetch(apiConfig.apiEndpoint + url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result && credentialsContext.selectedTherapist) {
                                credentialsContext.setSelectedTherapist({...credentialsContext.selectedTherapist, bcbarecertDate: date })
                            }
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/UpdateTherapistRecertDate" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }


    function AddAdditionalCenter() {
        credentialsContext.setNewCenterCredentialsCardActive(true);
    }

    function CancelAdditionalCenter() {
        credentialsContext.setNewCenterCredentialsCardActive(false);
    }

    //update centers
    useEffect(() => {
        GetCredentialedCenters();
    }, []);

    //update centers
    useEffect(() => {
        UpdateCenterCards();
    }, []);

    //update centers
    useEffect(() => {
        if (credentialsContext.selectedTherapist) {
            credentialsContext.setNewCenterCredentialsCardActive(false);
        }
    }, []);

    //update centers
    useEffect(() => {
        UpdateCenters();
    }, []);

    function FullyCredentialedCenters() {
        var displayCredentials = "";
        if (centers != undefined && therapistCredentialedCenters != undefined) {

            therapistCredentialedCenters.forEach(function (credentialedCenter) {

                var center = centers?.find(obj => obj.centerId === credentialedCenter.centerId && credentialedCenter.credentialed === true)?.centerName;

                if (center) {
                    displayCredentials += center + ", ";
                }
            })
        }
        return displayCredentials.slice(0, -2);
    }


    if (credentialsContext.selectedTherapist && !loading) {
        return (

            <div className="grid grid-rows-auto ml-auto mr-auto">
                <div className="grid grid-cols-1 h-fit mt-0 mb-8">
                    <div onClick={Navigateback} className="my-2 flex items-center justify-center w-fit cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to Therapist Selection</span>
                        <div className="flex-1"></div>
                    </div>

                    <div className="grid grid-flow-row">

                        <div className="grid grid-cols-1 gap-4 xl:gap-[4.5rem] content-between xl:grid-flow-col xl:w-fit xl:m-auto">

                            <div className="grid grid-cols-1 xl:grid-cols-3 p-5 h-fit mt-0 border rounded bg-gray-50 mx-auto">

                                <table className="mt-4 xl:my-8">
                                    <tbody>
                                        <tr>
                                            <td><span className="text-2xl font-bold">{credentialsContext.selectedTherapist.employeeName}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">Re-Cert Date:</span></td>
                                            <td><span className="text-bold ml-2">{credentialsContext.selectedTherapist.bcbarecertDate ? new Date(credentialsContext.selectedTherapist.bcbarecertDate).toLocaleDateString() : ""}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">Fully Credentialed:</span></td>
                                            <td><span className="text-bold ml-2">{FullyCredentialedCenters()}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        
                        <div className="w-fit mx-auto m-10">

                            <div className="w-fit mx-auto mb-5">

                                <label className="font-bold p-5">Recert Date:
                                </label>
                                <DatePicker
                                    value={credentialsContext.selectedTherapist.bcbarecertDate}
                                    disabled={false}
                                    disableFuture={false}
                                    onChange={(newValue) => {
                                        UpdateRecertDate(newValue)
                                    }}
                                    renderInput={(params) => <TextField id="recertDatePicker" size="small" {...params} />}
                                />
                            </div>

                            <div className="w-fit mx-auto">
                                <CredentialsTherapistNotesCard key={credentialsContext.selectedTherapist.therapistId} therapistNotes={therapistNotes} UpdateNotes={UpdateNotes} />
                            </div>


                            <div className="w-fit m-auto">
                                {therapistCredentials ? therapistCredentials.map(credentials => <CenterPayorCredentialsCard key={credentials.centerId} updateFunction={UpdateCenterCards} centerId={credentials.centerId} newCenterCredentials={false} />) : <></>}
                            </div>


                            <div className={(credentialsContext.newCenterCredentialsCardActive ? "normal" : "hidden")}>
                                <CenterPayorCredentialsCard updateFunction={UpdateCenterCards} centerId={null} newCenterCredentials={true} />
                            </div>


                        </div>

                        <div>
                            <button className={"bg-lacblue w-[15rem] h-[4rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 flex-grow-0 mr-2 " + (!credentialsContext.newCenterCredentialsCardActive ? "normal" : "hidden")} onClick={AddAdditionalCenter}>
                                <span className="drop-shadow">Add Additional Center</span>
                            </button>

                            <button className={"bg-lacblue w-[15rem] h-[4rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 flex-grow-0 mr-2 " + (credentialsContext.newCenterCredentialsCardActive ? "normal" : "hidden")} onClick={CancelAdditionalCenter}>
                                <span className="drop-shadow">Cancel Center</span>
                            </button>
                        </div>
                        
                    </div>
                </div>

                <CredentialNotesModal UpdateNotes={UpdateNotes} TherapistId={ credentialsContext.selectedTherapist.therapistId } Active={ credentialsContext.therapistNoteModalActive } setActive={ credentialsContext.setTherapistNoteModalActive } />

            </div>
        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }

}

export function BCBACredentials() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['Credentials.Read', 'Credentials.Write']}>
                    <BCBACredentialsContent />
                </ProtectedPage>

            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>


        </>
    );
}