// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { EDPNContext } from "./EDPNContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";

import { addDays, format, isAfter, isBefore, isMonday, isSameDay, isYesterday, parse, parseISO, set, subDays } from "date-fns";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import { ClassNames } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import { time } from "console";
import { timingSafeEqual } from "crypto";
import { ModalNotification } from "../../components/ModalNotification";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePicture } from "../../components/ProfilePicture";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalConfirm } from "../../components/ModalConfirm";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { AbsentReason, APIReturnObject, Client, ECLAnswerObject, EDPNAnswerObject, EDPNQuestion, FutureTimeObject, Status } from "../../utils/Definitions";
import { EDPNQuestionNode } from "../../components/edpn/EDPNQuestionNode";

//only renders if authenticated
const EDPNEditAbsentContent = () => {

    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);

    const edpnContext = useContext(EDPNContext);

    const [addNoteButtonDisabled, setAddNoteButtonDisabled] = useState<boolean>(true);

    const [loading, setLoading] = useState<boolean>(true);
    const [addNoteLoading, setAddNoteLoading] = useState<boolean>(false);

    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [validationModalActive, setValidationModalActive] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<string>("");

    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);

    const [selectedReason, setSelectedReason] = useState<AbsentReason | null>();


    var tempFlatQuestions: EDPNQuestion[] = [];
    const [flatQuestions, setFlatQuestions] = useState<EDPNQuestion[]>([]);

    const [filteredQuestions, setFilteredQuestions] = useState<EDPNQuestion[]>();

    useEffect(() => {
        if (!edpnContext.selectedClient) {
            navigate("/edpn");
        }
    })

    useEffect(() => {
        UpdateDiableAddLogButton();

    }, [selectedReason])

    useEffect(() => {
        if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.absentReasonId && edpnContext.absentReasons) {
            var reason: AbsentReason | undefined = edpnContext.absentReasons.find(x => x.id == edpnContext.selectedEditAnswer?.absentReasonId);

            if (reason) {
                setSelectedReason(reason);
            }
        }
    }, [edpnContext.selectedEditAnswer])


    function NavigateBack() {
        navigate("/edpn");
    }

   

    function EditNote() {

        setAddNoteLoading(true); // Show loading spinner

        var finalAnswerObject = edpnContext.selectedEditAnswer;

        if (finalAnswerObject && selectedReason) {
            finalAnswerObject.absentReasonId = selectedReason.id;
        }

        globalContext.GetToken().then((token: any) => {

            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(finalAnswerObject)
            };

            console.log("final answer object edit:", finalAnswerObject);

            // Log the size of finalAnswerObject in kb for debugging purposes
            const size = new TextEncoder().encode(JSON.stringify(finalAnswerObject)).length;
            const kiloBytes = size / 1024;
            console.log("size:" + kiloBytes + " kb ", finalAnswerObject);

            var url = apiConfig.apiEndpoint + "/EDPN/EditLog";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result: APIReturnObject) => {
                        if (result.status === Status.SUCCESS) {
                            setAddNoteLoading(false);
                            setSuccessModalActive(true);
                        }
                        else if (result.status === Status.FAIL) {
                            globalContext.setNotificationModalMessage("Cannot edit log. " + result.message);
                            globalContext.setNotificationModalActive(true);
                            setAddNoteLoading(false);
                        }
                        else if (result.status === Status.ERROR) {
                            globalContext.setErrorModalProps({ errorMessage: "Failed to edit log. " + result.message, errorLocation: "/EDPN/EditLog" });
                            globalContext.setErrorModalActive(true);
                            setAddNoteLoading(false);
                        }
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/EditLog" });
                        globalContext.setErrorModalActive(true);
                        setAddNoteLoading(false);
                    }
                )
        })
    }


    function UpdateDiableAddLogButton() {

        //logic for add log button
        setAddNoteButtonDisabled(selectedReason == null || selectedReason == undefined);
    }


    function SuccessModalClose() {
        NavigateBack();
    }

    function ValidationModalClose() {
    }

    const AddNoteButton = () => {

        if (addNoteLoading) {
            return (
                <button disabled={true} className="m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacblue-800">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else {
            return (<button onClick={EditNote} disabled={addNoteButtonDisabled} className={"bg-lacblue m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 " + (addNoteButtonDisabled ? 'bg-lacblue-800 hover:bg-lacblue-800' : '')}><span className="drop-shadow">Edit</span></button>)
        }
    }


    if (edpnContext.selectedClient) {

        return (
            <div className="ml-auto mr-auto p-2">
                <div className="grid grid-cols-2 h-fit mt-0 mb-auto">

                    <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-40 cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to Logs</span>
                        <div className="flex-1"></div>
                    </div>

                    <ProfilePicture isEditable={false} setProfilePictureModalActive={setProfilePictureModalActive} Client={edpnContext.selectedClient} setSelectedClientForModal={setSelectedClientForModal} />

                    <table>
                        <tbody>
                            <tr>
                                <td><span className="font-bold">Learner Name:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.fullName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Birthday:</span></td>
                                <td><span className="text-bold ml-2">{new Date(edpnContext.selectedClient.dob).toLocaleDateString()}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Insurance Provider:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.insuranceName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">RBT Required:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.rbtrequired == null ? '' : edpnContext.selectedClient.rbtrequired ? 'Yes' : 'No'}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Telehealth Allowed:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.telehealthAllowed == null ? '' : edpnContext.selectedClient.telehealthAllowed ? 'Yes' : 'No'}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="grid gap-8 grid-cols-12 xl:w-[40rem] w-full m-auto mt-4 mb-8">

                    <div className="w-full xl:col-span-12 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Absent Reason:
                        </label>
                        <Select isSearchable={false} options={edpnContext.absentReasons ? edpnContext.absentReasons?.filter(x => x.absent == true) : undefined} onChange={setSelectedReason} value={selectedReason} />
                    </div>
                   

                    <div className="w-full col-span-12 flex justify-between">
                        <button onClick={NavigateBack} className="bg-rose w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 flex-grow-0 mr-2">
                            <span className="drop-shadow">Cancel</span>
                        </button>
                        <div className="flex-grow"></div>
                        <AddNoteButton />
                    </div>

                </div>

                <ModalNotification Title={"Success!"} Body={"Note for " + edpnContext.selectedClient.fullName + " successfully edited."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={SuccessModalClose} />
                <ModalNotification Title={"Error!"} Body={"Contact IT if this error persists. Error Message: " + errorMessage} Active={errorModalActive} Icon={"Warning"} setActive={setErrorModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning!"} Body={validationMessage} Active={validationModalActive} Icon={"Warning"} setActive={setValidationModalActive} OnModalClose={ValidationModalClose} />


            </div>

            

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }
    
};




export function EDPNEditAbsent() {

    const authRequest = {
        ...loginRequest
    };


    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                    <EDPNEditAbsentContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
      )
};

