import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { GlobalContext } from '../utils/GlobalContext';
import { Event } from 'react-big-calendar';
import { addDays, format, isAfter, isBefore, isSameDay, isYesterday, parse, parseISO, set, subDays } from "date-fns";

interface Props {
    event: any | null,
    Active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>,
    deleteEvent: any,
    updateEvent: any;
}

export const ModalDailyScheduleEvent: React.FC<Props> = (props) => {

    const [startTime, setStartTime] = useState<Date | null>(null);
    const [startTimeText, setStartTimeText] = useState<string>("");

    const [endTime, setEndTime] = useState<Date | null>(null);
    const [endTimeText, setEndTimeText] = useState<string>("");

    const [editTimeVerification, setEditTimeVerification] = useState<boolean>(false);


    //update modal on active change
    useEffect(() => {
        if (props.event && props.event.start && props.event.end) {
            setStartTimeText(new Date(props.event.start).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
            setEndTimeText(new Date(props.event.end).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
            setEditTimeVerification(true);
        }

    }, [props.Active]);

    useEffect(() => {
        if (editTimeVerification) {
            StartTimeValidateTime(false, null);
            EndTimeValidateTime(false, null);
        }
    }, [editTimeVerification])


    function StartTimeValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempStartTimeText = startTimeText.replace(/\s+/g, '').trim()

        var d1 = parse(tempStartTimeText, "h:mm a", new Date());
        var d2 = parse(tempStartTimeText, "h:mma", new Date());
        var d3 = parse(tempStartTimeText, "hmma", new Date());
        var d4 = parse(tempStartTimeText, "h a", new Date());
        var d5 = parse(tempStartTimeText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempStartTimeText != dformatted) {
                    setStartTimeText(dformatted);
                }

                //check for negative time
                if ((endTime && isBefore(d, endTime)) || (SiblingDate && isBefore(d, SiblingDate))) {
                    //update time in
                    setStartTime(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        EndTimeValidateTime(false, d);
                    }
                }
                else if (!endTime) {
                    setStartTime(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        EndTimeValidateTime(false, d);
                    }
                }
                else {
                    setStartTime(null);
                }

            }
        }
        else {
            setStartTime(null);
        }
    }

    function EndTimeValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempEndTimeText = endTimeText.replace(/\s+/g, '').trim()

        var d1 = parse(tempEndTimeText, "h:mm a", new Date());
        var d2 = parse(tempEndTimeText, "h:mma", new Date());
        var d3 = parse(tempEndTimeText, "hmma", new Date());
        var d4 = parse(tempEndTimeText, "h a", new Date());
        var d5 = parse(tempEndTimeText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempEndTimeText != dformatted) {
                    setEndTimeText(dformatted);
                }

                //check for negative time
                if ((startTime && isAfter(d, startTime)) || (SiblingDate && isAfter(d, SiblingDate))) {
                    //update time out
                    setEndTime(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        StartTimeValidateTime(false, d);
                    }
                }
                else if (!startTime) {
                    setEndTime(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        StartTimeValidateTime(false, d);
                    }
                }
                else {

                    setEndTime(null);
                }
            }
        }
        else {
            setEndTime(null);
        }
    }

    function CancelModal() {
        props.setActive(false);
    }

    function ConfirmModal() {
        //update the list of events with new times
        if (props.event) {
            props.updateEvent(props.event.id, startTime, endTime);
        }
        props.setActive(false);
    }

    function DeleteEvent() {
        props.deleteEvent(props.event);
        props.setActive(false);
    }

    return (
        <div className={"z-[100] relative " + (props.Active ? "normal" : "hidden")} aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3 className="text-lg font-medium leading-6" id="modal-title">Start Time:</h3>
                                    <div className="mt-2">
                                        <input className={"outline-none h-[38px] text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (startTimeText.length > 0 && !startTime ? 'border-2 border-rose' : 'border border-gray-300')} onBlur={() => StartTimeValidateTime(true, null)} value={startTimeText} onChange={event => setStartTimeText(event.target.value)} />
                                    </div>

                                    <h3 className="text-lg font-medium leading-6" id="modal-title">End Time:</h3>
                                    <div className="mt-2">
                                        <input className={"outline-none h-[38px] text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (endTimeText.length > 0 && !endTime ? 'border-2 border-rose' : 'border border-gray-300')} onBlur={() => EndTimeValidateTime(true, null)} value={endTimeText} onChange={event => setEndTimeText(event.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row sm:px-6 justify-between">
                            <span className="flex items-center">
                                <FontAwesomeIcon className="text-lacblue w-7 h-7 hover:cursor-pointer" icon={solid('trash-can')} onClick={DeleteEvent} />
                            </span>
                            <div className="flex space-x-2">
                                <button onClick={CancelModal} className="bg-rose w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Cancel</span></button>
                                <button onClick={ConfirmModal} disabled={(startTimeText.length > 0 && !startTime) || (endTimeText.length > 0 && !endTime) } className="bg-lacgreen w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Confirm</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

