import { ClientApplication } from '@azure/msal-browser/dist/app/ClientApplication';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiConfig } from "../../authConfig";
import { ModalNotification } from "../ModalNotification";

import { GlobalContext } from "../../utils/GlobalContext";
import React, { useEffect, useRef, useState, useContext } from 'react';
import { ReactCropperElement, Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { Client, PayorInfoType, VwAuthorizationDetail, VwEzclaimInsuranceAuthorizationsMatched, AuthorizationStatus, ChoiceOption, VwAuthorizationHistory, VwAuthorization } from '../../utils/Definitions';
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from '@mui/material';
import Select from "react-select";
import { ValueContainer } from "../../components/ValueContainer";
import { addDays, format, isAfter, isBefore, isSameDay, isYesterday, parse, parseISO, set, subDays } from "date-fns";

import ValueType from "react-select";
import OptionType from "react-select";
import { SingleValue } from "react-select";
import { ProfilePicture } from '../ProfilePicture';

interface Props {
    Client: Client | null,
    AuthId: string | null,
    PayorInfoTypes: PayorInfoType[],
    AuthorizationStatuses: AuthorizationStatus[],
    Active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>;
    OnModalClose: any;
}

const buckets = [
    { value: '0', label: 'Non-Bucket' },
    { value: '1', label: 'Bucket' },
]

export const AuthorizationModal: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [saveButtonActive, setSaveButtonActive] = useState<boolean>(false);

    const [authorization, setAuthorization] = useState<VwAuthorization | null>(null);
    const [authorizationDetails, setAuthorizationDetails] = useState<VwAuthorizationDetail[]>([]);
    const [authorizationHistory, setAuthorizationHistory] = useState<VwAuthorizationHistory[]>([]);

    const [authComment, setAuthComment] = useState<string>("");
    const [reportDueDate, setReportDueDate] = useState<Date | null>(new Date());
    const [isBucket, setIsBucket] = useState<ChoiceOption | null>();
    const [selectedPayorType, setSelectedPayorType] = useState<ChoiceOption | null>(null);
    const [selectedAuthStatus, setSelectedAuthStatus] = useState<ChoiceOption | null>(null);


    useEffect(() => {
        if (props.Active) {
            GetAuthorization();
            GetAuthorizationHistory();
            GetAuthorizationDetails();
        }
    }, [props.Active]);

    useEffect(() => {
        if (authorization) {
            SetSelectedPayorTypeValueOnLoad();
            SetSelectedAuthStatusValueOnLoad();
            SetSelectedBucketValueOnLoad();
            SetSelectedReportDueDateOnLoad();
        }
    }, [authorization]);

    function SetSelectedReportDueDateOnLoad() {
        if (authorization) {
            setReportDueDate(authorization.reportDueDate);
        }
    }

    function SetSelectedBucketValueOnLoad() {
        if (authorization) {

            const targetValue = authorization.bucket ? "1" : "0";

            var foundBucket = buckets?.find(z => z.value === targetValue);
            var temp: ChoiceOption;

            if (foundBucket) {
                temp = ({ value: String(foundBucket.value), label: foundBucket.label })
                setIsBucket(temp);
            }

        }
    }

    function SetSelectedPayorTypeValueOnLoad() {
        if (props.PayorInfoTypes && authorization) {

            var foundPayorType = props.PayorInfoTypes?.find(z => String(z.payorInfoTypeId).toLowerCase() === String(authorization.payorType).toLowerCase());
            var temp: ChoiceOption;

            if (foundPayorType) {
                temp = ({ value: String(foundPayorType.payorInfoTypeId), label: foundPayorType.payorInfoType1 })
                setSelectedPayorType(temp);
            }

        }
    }

    function SetSelectedAuthStatusValueOnLoad() {
        if (props.AuthorizationStatuses && authorization) {

            var foundAuthStatus = props.AuthorizationStatuses?.find(z => String(z.authStatusId) === String(authorization.statusId));
            var temp: ChoiceOption;

            if (foundAuthStatus) {
                temp = ({ value: String(foundAuthStatus.authStatusId), label: foundAuthStatus.status })
                setSelectedAuthStatus(temp);
            }

        }
    }

    //AddAuthorizationComment
    function AddAuthComment() {
        if (props.AuthId && authComment !== "") {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                };

                var url = apiConfig.apiEndpoint + "/Credentials/AddAuthorizationComment?authId=" + props.AuthId + "&text=" + authComment;
                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            GetAuthorizationHistory();
                        },
                        (error) => {
                            //setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/AddAuthorizationComment" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    const HandleAddComment = () => {
        AddAuthComment();
        setAuthComment("");
    }


    function GetAuthorization() {
        if (props.AuthId) {
            setIsLoading(true);

            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "GET",
                    headers: headers,
                };
                
                var url = apiConfig.apiEndpoint + "/Credentials/GetAuthorizationByAuthId?authId=" + props.AuthId;
                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setAuthorization(result);
                            setIsLoading(false);
                        },
                        (error) => {
                            setIsLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetAuthorizationByAuthId" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function GetAuthorizationDetails() {
        if (props.AuthId) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "GET",
                    headers: headers,
                };

                var url = apiConfig.apiEndpoint + "/Credentials/GetAuthorizationDetailsByAuthId?authId=" + props.AuthId;
                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setAuthorizationDetails(result);
                            setIsLoading(false);
                        },
                        (error) => {
                            //setLoading(false);
                            setIsLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetAuthorizationDetailsByAuthId" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function GetAuthorizationHistory() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Credentials/GetAuthorizationHistory?authId=" + props.AuthId;
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setAuthorizationHistory(result);
                        setIsLoading(false);
                    },
                    (error) => {
                        //setLoading(false);
                        setIsLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetAuthorizationHistory" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateAuthorization() {

        if (props.AuthId && saveButtonActive && reportDueDate != null) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                };

                var url = apiConfig.apiEndpoint + "/Credentials/UpdateAuthorizations?authId=" + props.AuthId + "&reportDueDate=" + new Date(reportDueDate).toLocaleDateString() + "&isBucket=" + (isBucket?.value === "1") + "&payorType=" + selectedPayorType?.value + "&authorizationStatus=" + selectedAuthStatus?.value;
                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            //GetAuthorizationHistory();
                            var ttest = 'test';
                        },
                        (error) => {
                            //setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/UpdateAuthorizations" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }

    }

    function CloseModal() {
        props.setActive(false);
        setSaveButtonActive(false);
        props.OnModalClose();
    }

    function OnPayorTypeChange(input: SingleValue<ChoiceOption>) {

        if (props.PayorInfoTypes) {

            var foundPayorType = props.PayorInfoTypes?.find(z => String(z.payorInfoTypeId) === String(input?.value));
            var temp: ChoiceOption;

            if (foundPayorType) {
                temp = ({ value: String(foundPayorType.payorInfoTypeId), label: foundPayorType.payorInfoType1 })
                setSelectedPayorType(temp);
            }
            setSaveButtonActive(true);
        }

    }

    function OnAuthorizationStatusChange(input: SingleValue<ChoiceOption>) {
        if (props.AuthorizationStatuses) {
            var foundAuthorizationStatus = props.AuthorizationStatuses?.find(z => String(z.authStatusId) === String(input?.value));
            var temp: ChoiceOption;

            if (foundAuthorizationStatus) {
                temp = ({ value: String(foundAuthorizationStatus.authStatusId), label: foundAuthorizationStatus.status })
                setSelectedAuthStatus(temp);
            }
            setSaveButtonActive(true);
        }
    }

    return (
        <div className={"inset-0 z-60 relative " + (props.Active ? "normal" : "hidden")} aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-60 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all xl:m-8 w-fit h-fit">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <div className="mt-2">

                                        <div className={"w-full xl:w-[60rem] h-[12.5rem] mb-2 bg-gray-50 border drop-shadow rounded flex"}>

                                            <div className="flex-none p-4 h-fit m-auto hidden sm:inline">
                                                <ProfilePicture isEditable={false} Client={props.Client} setProfilePictureModalActive={null} setSelectedClientForModal={null} />
                                            </div>


                                            <div className="grid grid-cols-2 gap-x-8 h-fit m">
                                                {/* First Column */}
                                                <div className="grid grid-cols-2 gap-y-2 mt-5">
                                                    <label className="font-bold text-right pr-2">Learner Name:</label>
                                                    <span className="hitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{authorization ? authorization?.firstName + " " + authorization.lastName : "N/A"}</span>

                                                    <label className="font-bold text-right pr-2">Birthday:</label>
                                                    <span>{authorization ? new Date(authorization.dob).toLocaleDateString() : "N/A"}</span>

                                                    <label className="font-bold text-right pr-2">Client ID:</label>
                                                    <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{authorization?.clientId}</span>

                                                    <label className="font-bold text-right pr-2">Center:</label>
                                                    <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{authorization?.centerName}</span>

                                                    <label className="font-bold text-right pr-2">Assigned Clinician:</label>
                                                    <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{authorization?.providerName}</span>
                                                </div>

                                                {/* Second Column */}
                                                <div className="grid grid-cols-2 gap-y-2 mt-5">
                                                    <label className="font-bold text-right pr-2">Authorization ID:</label>
                                                    <span>{authorization?.authNumber}</span>

                                                    <label className="font-bold text-right pr-2">Auth Start Date:</label>
                                                    <span>{authorization?.startDate ? new Date(authorization?.startDate).toLocaleDateString() : "N/A"}</span>

                                                    <label className="font-bold text-right pr-2">Auth End Date:</label>
                                                    <span>{authorization?.endDate ? new Date(authorization?.endDate).toLocaleDateString() : "N/A"}</span>

                                                    <label className="font-bold text-right pr-2">Payor:</label>
                                                    <span>{authorization?.payorName}</span>

                                                    <label className="font-bold text-right pr-2">Authorized Provider:</label>
                                                    <span>{authorization?.providerName}</span>
                                                </div>
                                            </div>


                                        </div>

                                        <div className={(isLoading ? "hidden" : "")}>
                                            <div className="ml-auto p-2 border-2 border-lacblue rounded-lg">
                                                <div className="w-full flex items-center justify-between pb-3">
                                                    <h2 className="text-2xl text-lacblue w-fit">Current Authorization</h2>

                                                    <button className=" justify-right bg-cyan ml-2 w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-cyan-600"><span className="drop-shadow">Update</span></button>
                                                </div>

                                                <table className="table-auto w-full">
                                                    <thead className="w-full border border-lacblue-500  text-center">
                                                        <tr className="bg-lacblue text-white">
                                                            <th>Code</th>
                                                            <th>Units Requested</th>
                                                            <th>Units Approved</th>
                                                            <th>Mod 1</th>
                                                            <th>Mod 2</th>
                                                            <th>Classification</th>
                                                            <th>Notes</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Build out dependent on the view authorizationDetails*/}
                                                        {authorizationDetails.map(authorizationDetail =>
                                                            <tr className="border border-gray-500 text-center">
                                                                <td className="border border-gray-500">{authorizationDetail.cptcode}</td>
                                                                <td className="border border-gray-500">{authorizationDetail.unitsRequested}</td>
                                                                <td className="border border-gray-500">{authorizationDetail.unitsAllowed}</td>
                                                                <td className="border border-gray-500">{authorizationDetail.mod1}</td>
                                                                <td className="border border-gray-500">{authorizationDetail.mod2}</td>
                                                                <td className="border border-gray-500">Some Classification</td>
                                                                <td className="border border-gray-500">{authorizationDetail.notes}</td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="grid gap-2 grid-cols-12 xl:w-[60rem] w-full m-auto mt-4 mb-2">

                                                <div className="w-full m-auto sm:col-span-3 col-span-3">
                                                    <label className="block font-bold" htmlFor="search">
                                                        Report Due Date
                                                    </label>
                                                    <DatePicker
                                                        value={reportDueDate}
                                                        disabled={false}
                                                        disableFuture={false}
                                                        onChange={(newValue) => {
                                                            setReportDueDate(newValue);
                                                            setSaveButtonActive(true);
                                                        }}
                                                        renderInput={(params) => <TextField size="small" {...params} />}
                                                    />
                                                </div>
                                                <div className="w-full m-auto sm:col-span-3 col-span-3">
                                                    <label className="block font-bold" htmlFor="search">
                                                        Bucket Information
                                                    </label>
                                                    <Select
                                                        className=""
                                                        components={{ ValueContainer }}
                                                        isMulti={false}
                                                        closeMenuOnSelect={true}
                                                        options={[
                                                            { value: "1", label: "Bucket" },
                                                            { value: "0", label: "Non-Bucket" }
                                                        ]}
                                                        onChange={(newValue) => {
                                                            if (newValue) {
                                                                setIsBucket(newValue);
                                                                setSaveButtonActive(true);
                                                            }
                                                            else {
                                                                setIsBucket(null);
                                                                setSaveButtonActive(true);
                                                            }
                                                        }}
                                                        value={isBucket}
                                                    />
                                                </div>
                                                <div className="w-full m-auto sm:col-span-3 col-span-3">
                                                    <label className="block font-bold" htmlFor="search">
                                                        Payor Type
                                                    </label>
                                                    <Select options={props.PayorInfoTypes && props.PayorInfoTypes.length > 0 ? props.PayorInfoTypes.map((payor) => ({ value: String(payor.payorInfoTypeId), label: payor.payorInfoType1 })) : undefined} onChange={OnPayorTypeChange} value={selectedPayorType} />
                                                </div>
                                                <div className="w-full m-auto sm:col-span-3 col-span-3">
                                                    <label className="block font-bold" htmlFor="search">
                                                        Authorization Status
                                                    </label>
                                                    <Select options={props.AuthorizationStatuses && props.AuthorizationStatuses.length > 0 ? props.AuthorizationStatuses.map((authorizationStatus) => ({ value: String(authorizationStatus.authStatusId), label: authorizationStatus.status })) : undefined} onChange={OnAuthorizationStatusChange} value={selectedAuthStatus} />
                                                </div>


                                            </div>

                                            <div className="mb-2">
                                                <label className="block font-bold mt-2">
                                                    Add Comment:
                                                </label>
                                                <div className="flex items-center mt-2">
                                                    <input
                                                        maxLength={250}
                                                        autoComplete="off"
                                                        type="text"
                                                        value={authComment}
                                                        onChange={(event) => setAuthComment(event.target.value)}
                                                        className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue w-full p-2.5"
                                                    />
                                                    <button onClick={() => HandleAddComment()} className="ml-2 bg-lacyellow w-[10rem] h-[2.5rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacyellow-600">
                                                        <span className="drop-shadow">Add Comment</span>
                                                    </button>
                                                </div>
                                            </div>

                                            <span className="font-bold">Comments:</span>
                                            <div className="w-full border-2">
                                                <div className="max-h-[170px] overflow-y-auto">
                                                    <table className="w-full">
                                                        <tbody>
                                                            {authorizationHistory && authorizationHistory.length > 0 ? (
                                                                authorizationHistory.map(comment => (
                                                                    <tr key={comment.authHistoryId}>
                                                                        <td><span className="font-bold">[{comment.updatedOn ? format(new Date(comment.updatedOn), 'M/d/yy') : ""}] {comment.updatedBy}:</span> {comment.text}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr><td className="text-rose">No Comments.</td></tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        {/* else here */}
                                        <div className={"w-full h-auto flex " + (isLoading ? "" : "hidden")}>
                                            <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 flex justify-center sm:flex sm:justify-center sm:flex-row-reverse sm:px-6">
                            <button onClick={CloseModal} className="bg-rose ml-2 w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Cancel</span></button>
                            <button onClick={UpdateAuthorization} className={"w-[7rem] ml-2 h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out bg-lacgreen hover:bg-lacgreen-600 " + (saveButtonActive ? 'hover:bg-lacgreen-600' : 'opacity-50 cursor-not-allowed')} disabled={(!saveButtonActive)}><span className="drop-shadow">Save</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}