import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { Client } from "../../utils/Definitions";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../ProfilePicture";
import { GlobalContext } from "../../utils/GlobalContext";
import { ModalNotification } from "../ModalNotification";


interface Props {
    Client: Client,
    SetProfilePictureModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    SetSelectedClientForModal: React.Dispatch<React.SetStateAction<Client | null>>,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>

}

export const EDPNClientCardLearnerInfo: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);


    const edpnContext = useContext(EDPNContext);
    const navigate = useNavigate();

    function NavigateToAddLog() {
        navigate("/edpn/addlog");
    }

    function NavigateToViewLogs() {
        navigate("/edpn/viewlogs");
    }

    function NavigateToLearnerInfo() {
        navigate("/edpn/learnerinfo");
    }

    function WarnUserMissingCredentials() {
        props.SetCredentialsModalActive(true);
    }

    const HandleAddLog = (value?: string) => {
        if (value) {
            edpnContext.setSelectedClient(JSON.parse(value));
            NavigateToAddLog();
        }
    }

    const HandleViewLogs = (value?: string) => {
        if (value) {
            edpnContext.setSelectedClient(JSON.parse(value));
            NavigateToViewLogs();
        }
    }

    function HandleLearnerInfo() 
    {
        edpnContext.setSelectedClient(props.Client);
        NavigateToLearnerInfo();
    }

   



    return (

        <div className="w-full xl:w-[38rem] h-[12rem] bg-gray-50 border drop-shadow rounded flex">


            <div className="flex-none p-4 h-fit m-auto sm:inline">
                <ProfilePicture isEditable={true} Client={props.Client} setProfilePictureModalActive={props.SetProfilePictureModalActive} setSelectedClientForModal={props.SetSelectedClientForModal} />
            </div>
            

            <div className="grid grid-flow-row grow h-fit">
                <span className="font-bold text-2xl pt-1">{props.Client.fullName}</span>

                <span className="font-bold">BIRTHDAY:</span>
                <span>{new Date(props.Client.dob).toLocaleDateString()}</span>

                <span className="font-bold mt-1">SCHEDULE:</span>
                <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{props.Client.therapySchedule}</span>

                <span className="font-bold mt-1">LOGS TODAY:</span>
                <span>{props.Client.notesCount} ({props.Client.notesTime} hours)</span>

            </div>

            


        </div>
    )
    
    
};

