import React, { useContext } from "react"
import { GlobalContext } from "../utils/GlobalContext";
import Toast from "./Toast";
import { ModalConfirm } from "./ModalConfirm";
import { ModalError } from "./ModalError";
import { ModalNotification } from "./ModalNotification";
import NavBar from "./NavBar";

interface Props {
    children: React.ReactNode;
}

export const PageLayout: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);


    return (
        <div className="min-h-screen flex flex-col">
            <NavBar />
            <div className="grow flex">
                {props.children}
            </div>

            <ModalError
                ErrorModalProps={globalContext.errorModalProps}
                Active={globalContext.errorModalActive}
                setActive={globalContext.setErrorModalActive}
            />

            <ModalNotification
                Active={globalContext.notificationModalActive}
                setActive={globalContext.setNotificationModalActive}
                Title={"Warning!"}
                Body={globalContext.notificationModalMessage ? globalContext.notificationModalMessage : "Unknown Error."}
                Icon={"Warning"}
                OnModalClose={function noop() { } }                
            />

            <Toast/>

        </div>
    );
};