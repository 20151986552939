import React, { useState, useContext, useEffect, useCallback } from 'react';
import Select, { MultiValue, OnChangeValue } from "react-select";
import { OutcomesContext } from '../../pages/outcomes/OutcomesContext';

import { ChoiceOption, OutcomeEvaluation, OutcomeEvaluationAnswer, OutcomesEvaluation, OutcomesQuestion } from '../../utils/Definitions';
import { ValueContainer } from '../ValueContainer';
import { OutcomesQuestionNullSwitch } from './OutcomesQuestionNullSwitch';


interface Props {
    OutcomeEvaluation: OutcomeEvaluation | null,
    OutcomesQuestion: OutcomesQuestion | null;
    setOutcomeEvaluationObject: React.Dispatch<React.SetStateAction<OutcomeEvaluation | null>>;
    onChange: any;
    ChildOnChange: any;
    GetText: any;
    NullQuestion: any;
    GetNullValue: any;

}

export const OutcomesQuestionTypeText: React.FC<Props> = (props) => {

    function IsDisabled(): boolean {
        var nullable = props.OutcomeEvaluation?.nullables.find(x => x.questionID == props.OutcomesQuestion?.question.questionId);
        if (nullable) {
            return nullable.value == "True";
        }
        else {
            return false;
        }
    }


    return (
        <div key={props.OutcomesQuestion?.question.questionId} className="w-full xl:w-[38rem] h-fit bg-gray-50 border drop-shadow rounded mt-4 p-2">
            <span className="text-xl font-bold">{props.OutcomesQuestion?.question.questionText}</span>

            <div className="w-full h-fit m-auto flex">

                {OutcomesQuestionNullSwitch({
                    onChange: props.onChange,
                    OutcomeEvaluation: props.OutcomeEvaluation,
                    OutcomesQuestion: props.OutcomesQuestion,
                    setOutcomeEvaluationObject: props.setOutcomeEvaluationObject,
                    NullQuestion: props.NullQuestion,
                    GetNullValue: props.GetNullValue,
                })}
            

                <div className="grow">
                    <div key={props.OutcomesQuestion?.question.questionId} className="grow">
                        <input disabled={IsDisabled()} key={props.OutcomesQuestion?.question.questionId} onChange={(event) => props.ChildOnChange(event.target.value, props.OutcomesQuestion)} value={props.GetText(props.OutcomesQuestion)} autoComplete="off" type="text" className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>
                </div>
            </div>
        </div>
    );

}

