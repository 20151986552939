import React from 'react';
import { components, ValueContainerProps } from 'react-select';


//overriding react-select value container with custom multi value messages
export const ValueContainer = ({children,...props}: ValueContainerProps<{value: string, label: string}>) => {
    let [values, input] = children as any;

    if (Array.isArray(values)) {
        const val = (i: number) => values[i].props.children;
        const { length } = values;

        //if only 1 item is selected, display that item. Else display number of items selected
        if (length == 1) {
            values = `${val(0)}`;
        }
        else {
            values = `${length} items selected`;
        }
    }

    return (
        <components.ValueContainer {...props}>
            {values}
            {input}
        </components.ValueContainer>
    );
};