import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { TherapistData } from '../utils/Definitions';
import SignatureCanvas from 'react-signature-canvas'
import { apiConfig } from '../authConfig';
import { GlobalContext } from '../utils/GlobalContext';


interface Props {
    Active: boolean;
    setActive: React.Dispatch<React.SetStateAction<boolean>>;
    OnModalClose: any;
    OnSignatureSave: any;
    TherapistData: TherapistData | null;
}

interface SignatureUpdateObject {
    badge: number;
    signature: string;
}

export const ModalSignature: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const [loading, setLoading] = useState<boolean>(false);


    let padRef = React.useRef<SignatureCanvas>(null);

    function clearSignature() {
        padRef.current?.clear();
    };

    function saveSignature() {
        var image = padRef.current?.getCanvas().toDataURL();

        if (props.TherapistData && props.TherapistData.badge && image) {

            const base64Prefix = "image/png;base64,se64,";
            image = image.substring(base64Prefix.length);

            //build Credentials Update Object
            var obj: SignatureUpdateObject = {
                badge: props.TherapistData.badge,
                signature: image
            };

            setLoading(true);

            globalContext.GetToken().then((token: any) => {
                //save credentials to database
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(obj)
                };

                var url = apiConfig.apiEndpoint + "/Admin/SaveSignature";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                clearSignature();
                                props.OnSignatureSave();
                                setLoading(false);
                            }
                            else {
                                setLoading(false);
                                globalContext.setErrorModalProps({ errorMessage: "Failed to save signature. Unknown error.", errorLocation: "/Admin/SaveSignature" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: "Failed to save signature. " + error, errorLocation: "/Admin/SaveSignature" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
        

        

    }

    //update modal on active change
    useEffect(() => {

    }, [props.Active]);

    function CloseModal() {

        props.setActive(false);
        props.OnModalClose();
    }

    const SaveSignatureButton = () => {

        if (loading) {
            return (
                <button disabled={true} className=" hidden signatureBreak:block ml-4 w-fit h-[2rem] px-2 rounded font-bold text-lg text-white bg-lacgreen-600">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else {
            return (<button onClick={saveSignature} className="bg-lacgreen ml-4 hidden signatureBreak:block w-fit h-[2rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Save Signature</span></button>)
        }

    }


    return (
        <div className={"z-[100] relative " + (props.Active ? "normal" : "hidden")} aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          
            <div className="fixed inset-0 z-60 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full signatureBreak:w-fit">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                                    <div className="mt-2">

                                        <div className="border-2 border-black hidden signatureBreak:block">
                                            <SignatureCanvas ref={padRef} minDistance={0} dotSize={0.1} velocityFilterWeight={1} minWidth={1} maxWidth={1} throttle={16} canvasProps={{width:600, height:150, className: 'sigCanvas'} }/>
                                        </div>

                                        <div className="text-xl font-bold text-rose signatureBreak:hidden">Screen is too small!</div>
                                        <div className="text-rose signatureBreak:hidden">Try rotating your screen if you are on a mobile device, or switch to a device with a bigger screen.</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-1 sm:flex sm:flex-row-reverse sm:px-6 ">
                            <SaveSignatureButton />
                            <button onClick={clearSignature} className="bg-purple hidden signatureBreak:block px-2 ml-4 w-fit h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"><span className="drop-shadow">Clear</span></button>
                            <button onClick={CloseModal} className="bg-rose px-2 w-fit h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Cancel</span></button>
                        </div>
                    </div>
                </div>
            </div>
      </div>
  );
}

