import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiConfig } from "../../authConfig";
import { ECLContext } from "../../pages/ecl/ECLContext";
import { ChoiceOption, Client, ECLAnswer, ECLAnswerObject, ECLQuestion, Therapist, TherapistSmallObject } from "../../utils/Definitions";
import { GlobalContext } from "../../utils/GlobalContext";


interface Props {
    Narritive: string;
    ECLAnswerObject: ECLAnswerObject;
    Therapists: TherapistSmallObject[];
    ECLQuestions: ECLQuestion[];
    setAnswerObject: React.Dispatch<React.SetStateAction<ECLAnswerObject | null>>;
    answerObject: ECLAnswerObject | null;
}

export const ECLNarritiveSentance: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const eclContext = useContext(ECLContext);
    const navigate = useNavigate();

    //generate narritive when answers change
    useEffect(() => {
        if (props.answerObject) {
            var newNarritive = GenerateNarritive(props.Narritive);
            if (props.answerObject.narrative != newNarritive) {
                props.setAnswerObject({ ...props.answerObject, narrative: newNarritive });
            }
        }
    }, [props.answerObject, props.answerObject?.answers]);


    function GetQuestionByID(id: number, array: ECLQuestion[]): ECLQuestion | undefined {
        for (const node of array) {
            if (node.questionId === id) return node;
            if (node.children) {
                const child = GetQuestionByID(id, node.children);
                if (child) return child;
            }
        }
    }


    function GetValueFromAnswer(answers: ECLAnswer[]): string {
        switch (answers && answers[0] && answers[0].questionId && GetQuestionByID(answers[0].questionId, props.ECLQuestions)?.type) {
            case "text":
                var result: string = "[BLANK]";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempTextValue = answers[0].textValue ? answers[0].textValue : "[BLANK]";
                    result = tempTextValue;
                }
                return result;
            case "multitext":
                var result: string = "";
                for (let i = 0; i < answers.length; i++) {
                    var tempMultiTextValue = answers[i].textValue ? answers[i].textValue : "[BLANK]";
                    result = String(result + "<div>" + tempMultiTextValue + "</div>");
                }
                return result;
            case "number":
                var result: string = "[BLANK]";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempNumberValue = answers[0].numberValue ? String(answers[0].numberValue) : "[BLANK]";
                    result = tempNumberValue;
                }
                return result;
            case "bool_default_false":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    result = answers[0].boolValue ? "Yes" : "No";
                }
                return result;
            case "rbt":
                var result: string = "[BLANK]";
                if (answers && answers[0] && answers[0].textValue) {
                    var therapistname = GetTherapistNameByID(Number(answers[0].textValue));
                    if (therapistname.length > 0) {
                        result = therapistname;
                    }
                }
                return result;
            case "multirbt":
                var result: string = "";
                if (answers && answers[0] && answers[0].textValue) {
                    var therapistnames = GetListOfTherapistNamesByCommaSeperatedID(answers[0].textValue);
                    if (therapistnames.length > 0) {
                        result = "Also present was: " + therapistnames;
                    }
                }
                return result;
            case "choice":
                var result: string = "";
                if (answers && answers[0] && answers[0].choiceValue) {
                    var choice = eclContext.allChoiceOptions?.find(x => x.value == String(answers[0].choiceValue));
                    if (choice) {
                        return choice.label;
                    }
                }
                return result;
            case "text_split":
                var result: string = "[BLANK]";
                if (answers && answers[0] && answers[0].textValue) {
                    var tempTextValue = answers[0].textValue ? answers[0].textValue : "[BLANK]";
                    result = tempTextValue;
                }
                return result;
            default:
                return "______";
        }
    }

    function GetTherapistNameByID(id: number): string {
        var therapist = props.Therapists.find(x => x.therapistId == id);
        if (therapist) {
            return therapist.employeeName;
        }
        else if (id == -1)
        {
            return "";
        }
        else {
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.name) {
                return globalContext.CoreAccount.currentUser.name;
            }
            else {
                return "Unknown User";
            }
        }
    }

    function GetListOfTherapistNamesByCommaSeperatedID(ids: string): string {

        var names: string[] = [];

        ids.split(',').forEach(x => {
            var temp = GetTherapistNameByID(Number(x));
            names.push(temp);
        })

        if (names.length > 0) {
            return names.join(", ");
        }
        else {
            return "";
        }
         
    }


    function ReplaceNarritive(input: string): string {

        if (input == "{BCBA}") {
            if (props.ECLAnswerObject.therapistId) {
                return GetTherapistNameByID(props.ECLAnswerObject.therapistId);
            }
            else {
                return "ERROR!";
            }
        }
        else if (input == "{CLIENT}") {
            if (eclContext.selectedClient && eclContext.selectedClient.fullName) {
                return eclContext.selectedClient.fullName;
            }
            else {
                return "ERROR!";
            }
        }
        else if (input == "{LB}") {
            return "</div><div>";
        }

        //answer replace
        var nummatch = input.match(/\d+/);

        if (nummatch && nummatch[0] && props.ECLAnswerObject && props.ECLAnswerObject.answers) {

            var answersFound = props.ECLAnswerObject.answers.filter(x => x.questionId != null && nummatch && nummatch[0] && String(x.questionId) == nummatch[0]);
            return GetValueFromAnswer(answersFound);
        }

        return input;

    }

    function GenerateNarritive(Narritive: string): string {
        var reg = /\{(.*?)\}/g;

        // Just get all numbers
        const result = Narritive.replace(reg, function (wholeMatch, num) {
            // act here or after the loop...

            return ReplaceNarritive(wholeMatch);
        });

        return result;
    }


    if (props.Narritive && props.Narritive.length > 0 && props.ECLAnswerObject && props.ECLAnswerObject.answers && props.answerObject) {
        return (

            <div className="w-full h-fit mt-4 bg-gray-50 border drop-shadow rounded p-2">
                <span className="font-bold">Generated Summary:</span>
                <div dangerouslySetInnerHTML={{ __html: props.answerObject.narrative != null ? props.answerObject.narrative : "" }} />

            </div>
        )
    }
    else {
        return <></>
    }
    


};

