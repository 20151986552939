import { useContext, useEffect, useState } from "react";
import { ECLContext } from "../../pages/ecl/ECLContext";
import { ECLAnswer, ECLAnswerObject, ECLQuestion } from "../../utils/Definitions";

interface Props {
    ECLQuestion: ECLQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<ECLAnswerObject | null>>;
    answerObject: ECLAnswerObject | null;
    FlatQuestions: ECLQuestion[],
}

export const ECLQuestionTypeMultiText: React.FC<Props> = (props) => {
    const eclContext = useContext(ECLContext);

    const [answers, setAnswers] = useState<string[]>(['']);

    function arraysEqual(a: string[], b: string[]) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    //update answer object with local answers
    useEffect(() => {

        if (props.ECLQuestion.questionId && props.answerObject && props.answerObject.answers) {


            if (eclContext.selectedEditAnswer && eclContext.selectedEditAnswer.answers && arraysEqual(answers, [''])) {
                //autopopulate from edit

                var answerarray: string[] = [];

                //get all multitext answers from edit
                var tanswers = eclContext.selectedEditAnswer.answers.filter(x => x.questionId == props.ECLQuestion.questionId);

                //get all questions that are multitext
                var multitextquestions = props.FlatQuestions.filter(x => x.type == "multitext");

                //assign multi index
                tanswers.filter(z => multitextquestions.find(y => y.questionId == z.questionId)).forEach((answer, index) => {
                    //set multiindex
                    answer.multiIndex = index;
                })

                //copy new answers into answer array
                tanswers.forEach((answer) => {
                    if (answer.textValue) {
                        answerarray.push(answer.textValue);
                    }
                })

                //set local answers
                setAnswers(answerarray);
            }
            else {
                //dont populate from edit, just copy over local answers

                //cast answers object to converted array
                var convertedAnswers: string[] = [];
                props.answerObject.answers.filter(x => x.questionId == props.ECLQuestion.questionId).forEach((answer) => {
                    if (answer.textValue) {
                        convertedAnswers.push(answer.textValue);
                    }
                    else {
                        convertedAnswers.push("");
                    }
                });

                //check if there is a difference between converted array and local
                if (!arraysEqual(convertedAnswers, answers)) {
                    //overwrite answers object

                    //get all answers except multi text
                    var tempanswers = props.answerObject.answers.filter(x => x.questionId != props.ECLQuestion.questionId);

                    //add in all answers
                    for (var i = 0; i < answers.length; i++) {

                        var tempAnswer: ECLAnswer = {
                            questionId: props.ECLQuestion.questionId,
                            answerId: null,
                            multiIndex: i,
                            logId: null,
                            textValue: answers[i],
                            numberValue: null,
                            boolValue: null,
                            choiceValue: null,
                            log: null,
                            question: null
                        };

                        tempanswers.push(tempAnswer);
                    }

                    //overwrite answers object
                    props.setAnswerObject({ ...props.answerObject, answers: tempanswers });
                    
                }
            }
        }
    }, [props.answerObject, props.ECLQuestion, answers]);

    function AddLine() {
        var tempanswers: string[] = [];

        for (var i = 0; i < answers.length; i++) {
            tempanswers.push(answers[i]);
        }

        //new line
        tempanswers.push("");

        setAnswers(tempanswers);
    }

    function RemoveLine() {
        if (answers.length > 1) {
            var tempanswers: string[] = [];

            for (var i = 0; i < answers.length; i++) {
                tempanswers.push(answers[i]);
            }

            //remove line
            tempanswers.pop();

            setAnswers(tempanswers);
        }
    }


    function UpdateAnswers(index: number, value: string) {

        var tempanswers: string[] = [];

        for (var i = 0; i < answers.length; i++) {
            if (i != index) {
                tempanswers.push(answers[i]);
            }
            else {
                tempanswers.push(value);
            }
        }
        setAnswers(tempanswers);
    }

   
    return (
        <div className="w-full m-auto mt-4">

            <label className="block font-bold">
                {props.ECLQuestion.questionText}
            </label>

            {Array.from(Array(answers.length), (e, i) => {
                /*return <input disabled={eclContext.isViewOnly} key={i} onChange={e => UpdateAnswers(i, e.target.value)} defaultValue={answers[i]} autoComplete="off" type="text" className="mb-2 outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />*/
                return <div key={i} className="outline-none col-span-12 min-h-[38px] overflow-y-hidden border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full max-w-full p-2.5 my-2.5" contentEditable={!eclContext.isViewOnly} onBlur={(event) => { UpdateAnswers(i, event.currentTarget.innerText); }} dangerouslySetInnerHTML={{ __html: answers[i] }}></div>
            })}
            
            <button disabled={eclContext.isViewOnly} onClick={AddLine} className={"bg-lacblue m-auto w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 noprint " + (eclContext.isViewOnly ? 'bg-lacblue-900 hover:bg-lacblue-900' : '')}><span className="drop-shadow">Add Line</span></button>
            <button disabled={answers.length == 1} onClick={RemoveLine} className={"bg-rose m-auto ml-2 w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 noprint " + (answers.length == 1 ? 'bg-rose-900 hover:bg-rose-900' : '')}><span className="drop-shadow">Remove Line</span></button>

        </div>
    )
    
    
    
};

