import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SingleValue, ActionMeta, InputActionMeta } from "react-select/dist/declarations/src";
import Select from "react-select";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { EDPNAnswer, EDPNAnswerObject, EDPNQuestion, Therapist, ChoiceOption, TherapistSmallObject } from "../../utils/Definitions";
import { EDPNQuestionTypeMultiText } from "./EDPNQuestionTypeMultiText";
import { EDPNQuestionTypeText } from "./EDPNQuestionTypeText";
import { EDPNQuestionTypeNumber } from "./EDPNQuestionTypeNumber";
import { EDPNQuestionTypeRBT } from "./EDPNQuestionTypeRBT";
import { EDPNNarritiveSentance } from "./EDPNNarritiveSentence";
import { EDPNQuestionTypeMultiRBT } from "./EDPNQuestionTypeMultiRBT";
import { EDPNQuestionTypeChoice } from "./EDPNQuestionTypeChoice";
import { GlobalContext } from "../../utils/GlobalContext";
import { EDPNQuestionTypeBoolDefaultFalse } from "./EDPNQuestionTypeBoolDefaultFalse";
import { EDPNQuestionTypeLongText } from "./EDPNQuestionTypeLongText";
import { EDPNQuestionTypeMultichoiceCreatable } from "./EDPNQuestionTypeMultichoiceCreatable";
import { EDPNQuestionTypeBCBAPresent } from "./EDPNQuestionTypeBCBAPresent";
import { EDPNQuestionTypeBCBAName } from "./EDPNQuestionTypeBCBAName";
import { EDPNQuestionTypeABAPresent } from "./EDPNQuestionTypeABAPresent";
import { EDPNQuestionTypeABAName } from "./EDPNQuestionTypeABAName";
import { EDPNQuestionTypePeoplePresent } from "./EDPNQuestionTypePeoplePresent";
import { EDPNQuestionTypePeopleName } from "./EDPNQuestionTypePeopleName";
import { EDPNQuestionTypeBoolDefaultFalseABA } from "./EDPNQuestionTypeBoolDefaultFalseABA";
import { EDPNQuestionTypeBoolDefaultFalseBCBA } from "./EDPNQuestionTypeBoolDefaultFalseBCBA";
import { EDPNQuestionTypeBoolDefaultFalsePeople } from "./EDPNQuestionTypeBoolDefaultFalsePeople";
import { EDPNQuestionTypeBoolDefaultFalseAbnormalBehaviors } from "./EDPNQuestionTypeBoolDefaultFalseAbnormalBehaviors";
import { EDPNQuestionTypeBoolDefaultFalseInjury } from "./EDPNQuestionTypeBoolDefaultFalseInjury";
import { EDPNQuestionTypeBoolDefaultFalseParticipate } from "./EDPNQuestionTypeBoolDefaultFalseParticipate";
import { EDPNQuestionTypeTextAbnormalBehaviors } from "./EDPNQuestionTypeTextAbnormalBehaviors";
import { EDPNQuestionTypeTextInjury } from "./EDPNQuestionTypeTextInjury";
import { EDPNQuestionTypeTextParticipate } from "./EDPNQuestionTypeTextParticipate";
import { EDPNQuestionTypeMultichoice } from "./EDPNQuestionTypeMultichoice";
import { EDPNQuestionTypeTextSplit } from "./EDPNQuestionTypeTextSplit";

interface Props {
    EDPNQuestions: EDPNQuestion[],
    FlatQuestions: EDPNQuestion[],
    EDPNParentQuestion: EDPNQuestion | null,
    setAnswerObject: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>;
    answerObject: EDPNAnswerObject | null;
    Therapists: TherapistSmallObject[] | null,
    ResetAnswerObject: any,
    AutoSelectQuestionStack: EDPNQuestion[] | null,
}



export const EDPNQuestionNode: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const edpnContext = useContext(EDPNContext);
    const navigate = useNavigate();


    const [options, setOptions] = useState<EDPNQuestion[] | null>();

    const [selectedOption, setSelectedOption] = useState<EDPNQuestion | null>(null);

    useEffect(() => {

        var tempOptions = GetOptions();

        if (tempOptions) {
            setOptions(tempOptions);
        }
    }, [props.EDPNParentQuestion, props.EDPNQuestions]);

    function InitializeAnswers() {
        if (options && props.EDPNQuestions && (props.EDPNParentQuestion == null || (props.EDPNParentQuestion && props.EDPNParentQuestion.type == "none")) && !(props.EDPNParentQuestion && props.EDPNParentQuestion.children && props.EDPNParentQuestion.children.filter(x => x.type != "none").length > 0)) {

        }
        else {
            //initailize answers
            if (props.answerObject && props.answerObject.answers && props.answerObject.answers.length == 0 && props.EDPNParentQuestion && props.EDPNParentQuestion.children && (props.EDPNParentQuestion.children.filter(x => x.type != "none").length > 0)) {

                var tempAnswers: EDPNAnswer[] = [];
                props.EDPNParentQuestion.children.filter(x => x.type != "none").forEach((child, i) => {
                    tempAnswers.push(CreateAnswer(child));
                })
                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
    }

    useEffect(() => {
        if (props.answerObject && props.answerObject.answers && props.answerObject.answers.length == 0) {
            InitializeAnswers();
        }

    }, [props.answerObject, props.answerObject?.answers]);

    //when options change, null selected option
    useEffect(() => {
        if (options) {
            setSelectedOption(null);
        }

    }, [options]);

    //autoselect question
    useEffect(() => {
        if (props.AutoSelectQuestionStack && props.EDPNQuestions && options) {

            var tempoptionselect = options.find(z => props.AutoSelectQuestionStack?.find(x => x.questionId == z.questionId));
            if (tempoptionselect) {
                setSelectedOption(tempoptionselect);
            }
        }

    }, [props.AutoSelectQuestionStack, props.EDPNQuestions, options]);

    //when options or selected option changes, clear answers array
    useEffect(() => {
        props.ResetAnswerObject();

    }, [selectedOption, options]);


    function GetOptions(): EDPNQuestion[] | null {

        //if parent question has null id, return top level of tree
        if (!props.EDPNParentQuestion || (props.EDPNParentQuestion && props.EDPNParentQuestion.questionId == null)) {
            return props.EDPNQuestions;
        }
        else {
            //find questions from flat questions array
            var temp = props.FlatQuestions.filter(x => x.parentQuestionId == props.EDPNParentQuestion?.questionId);
            if (temp) {
                return temp;
            }

        }

        return null;
    }


    function CreateAnswer(EDPNQuestion: EDPNQuestion): EDPNAnswer {

        if (EDPNQuestion.type == "text") {
            //text
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "text_split") {
            //text
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "longtext") {
            //long text
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "number") {
            //number
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "multitext") {
            //multitext
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: 0,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "rbt") {
            //rbt
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: '-1',
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "multirbt") {
            //multi rbt
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: '-1',
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "choice") {
            //choice
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: -1,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "multichoicecreatable") {
            //multichoicecreatable
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: -1,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "multichoice") {
            //multichoice
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: -1,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "bcbapresent") {
            //bcba present
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: -1,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "bcbaname") {
            //bcba name
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: '-1',
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "abapresent") {
            //aba present
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: -1,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "abaname") {
            //aba name
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: '-1',
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "peoplepresent") {
            //people present
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: -1,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "peoplename") {
            //people name
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: '-1',
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "bool_default_false_aba") {
            //booldefaultfalse ABA

            //autopopulate edit
            var tempboolvalue: boolean | null = null;
            if (edpnContext.selectedEditAnswer) {
                var tempanswer = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == EDPNQuestion.questionId);
                if (tempanswer) {
                    tempboolvalue = tempanswer.boolValue;
                }
            }

            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: tempboolvalue,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "bool_default_false_bcba") {
            //booldefaultfalse BCBA

            //autopopulate edit
            var tempboolvalue: boolean | null = null;
            if (edpnContext.selectedEditAnswer) {
                var tempanswer = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == EDPNQuestion.questionId);
                if (tempanswer) {
                    tempboolvalue = tempanswer.boolValue;
                }
            }

            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: tempboolvalue,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "bool_default_false_people") {
            //booldefaultfalse PEOPLE

            //autopopulate edit
            var tempboolvalue: boolean | null = null;
            if (edpnContext.selectedEditAnswer) {
                var tempanswer = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == EDPNQuestion.questionId);
                if (tempanswer) {
                    tempboolvalue = tempanswer.boolValue;
                }
            }

            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: tempboolvalue,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "bool_default_false_abnormal_behaviors") {
            //booldefaultfalse abnormal behaviors

            //autopopulate edit
            var tempboolvalue: boolean | null = null;
            if (edpnContext.selectedEditAnswer) {
                var tempanswer = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == EDPNQuestion.questionId);
                if (tempanswer) {
                    tempboolvalue = tempanswer.boolValue;
                }
            }

            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: tempboolvalue,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "bool_default_false_injury") {
            //booldefaultfalse INJURY

            //autopopulate edit
            var tempboolvalue: boolean | null = null;
            if (edpnContext.selectedEditAnswer) {
                var tempanswer = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == EDPNQuestion.questionId);
                if (tempanswer) {
                    tempboolvalue = tempanswer.boolValue;
                }
            }

            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: tempboolvalue,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "bool_default_false_participate") {
            //booldefaultfalse PARTICIPATE

            //autopopulate edit
            var tempboolvalue: boolean | null = null;
            if (edpnContext.selectedEditAnswer) {
                var tempanswer = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == EDPNQuestion.questionId);
                if (tempanswer) {
                    tempboolvalue = tempanswer.boolValue;
                }
            }

            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: tempboolvalue,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "text_abnormal_behaviors") {
            //text abnormal
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "text_injury") {
            //text injury
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else if (EDPNQuestion.type == "text_participate") {
            //text participate
            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: null,
                choiceValue: null,
                log: null,
                question: null
            };
        }
        else {
            //booldefaultfalse

            //autopopulate edit
            var tempboolvalue: boolean | null = null;
            if (edpnContext.selectedEditAnswer) {
                var tempanswer = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == EDPNQuestion.questionId);
                if (tempanswer) {
                    tempboolvalue = tempanswer.boolValue;
                }
            }

            return {
                questionId: EDPNQuestion.questionId,
                answerId: null,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: tempboolvalue,
                choiceValue: null,
                log: null,
                question: null
            };
        }

    }

    

    if (options &&
        props.EDPNQuestions &&
        (props.EDPNParentQuestion == null || (props.EDPNParentQuestion && props.EDPNParentQuestion.type == "none")) &&
        !(props.EDPNParentQuestion && props.EDPNParentQuestion.children && props.EDPNParentQuestion.children.filter(x => x.type != "none").length > 0) 
    ) {

        if (props.EDPNQuestions.filter(x => x.type != "none").length > 0) {


            //no parent choose, just display dyanmic quesitons
            return (
                <>
                    {props.EDPNQuestions && props.EDPNQuestions.map((question, i) => {
                        if (question.type == "text") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeText key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "text_split") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeTextSplit key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "multitext") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeMultiText key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} FlatQuestions={props.FlatQuestions}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "longtext") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeLongText key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "number") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeNumber key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "rbt") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeRBT key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={props.Therapists}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "multirbt") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeMultiRBT key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={props.Therapists}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "choice") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeChoice key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "multichoicecreatable") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeMultichoiceCreatable key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "multichoice") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeMultichoice key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "bool_default_false") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeBoolDefaultFalse key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "bcbapresent") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeBCBAPresent key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "bcbaname") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeBCBAName key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={globalContext.bcbas}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "abapresent") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeABAPresent key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "abaname") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeABAName key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={globalContext.therapists}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "peoplepresent") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypePeoplePresent key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                            
                        }
                        else if (question.type == "peoplename") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypePeopleName key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={globalContext.therapists}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "bool_default_false_aba") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeBoolDefaultFalseABA key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "bool_default_false_bcba") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeBoolDefaultFalseBCBA key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "bool_default_false_people") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeBoolDefaultFalsePeople key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "bool_default_false_abnormal_behaviors") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeBoolDefaultFalseAbnormalBehaviors key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "bool_default_false_injury") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeBoolDefaultFalseInjury key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "bool_default_false_participate") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeBoolDefaultFalseParticipate key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "text_abnormal_behaviors") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeTextAbnormalBehaviors key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "text_injury") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeTextInjury key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }
                        else if (question.type == "text_participate") {
                            return (
                                <div key={question.questionId}>
                                    <EDPNQuestionTypeTextParticipate key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                                    {question.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance key={`narritive_${question.questionId}`} Narritive={question.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
                                </div>
                            )
                        }

                    })}

                </>
            );
        }
        else {

            //contains parent choose, display choose
            return (
                <div className="w-full m-auto mt-4 grid col-span-12 grid-cols-12 gap-4">
                    <div className="w-full m-auto mt-4 grid col-span-12">
                        <label className="block font-bold" htmlFor="pods">
                            Choose:
                        </label>

                        <Select isSearchable={false} isDisabled={edpnContext.isViewOnly} options={options ? options : undefined} onChange={setSelectedOption} value={selectedOption} />
                    </div>


                    {selectedOption && selectedOption.children.length > 0 && <EDPNQuestionNode EDPNQuestions={props.EDPNQuestions} EDPNParentQuestion={selectedOption} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={props.Therapists} ResetAnswerObject={props.ResetAnswerObject} AutoSelectQuestionStack={props.AutoSelectQuestionStack} FlatQuestions={props.FlatQuestions}  />}

                </div>

            )
        }

        
    }
    else {

        return (
            <>
                {props.EDPNParentQuestion && props.EDPNParentQuestion.children && props.EDPNParentQuestion.children.map((question, i) => {

                    if (question.type == "text") {
                        return <EDPNQuestionTypeText key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "text_split") {
                        return <EDPNQuestionTypeTextSplit key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "multitext") {
                        return <EDPNQuestionTypeMultiText key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} FlatQuestions={props.FlatQuestions}  />
                    }
                    else if (question.type == "longtext") {
                        return <EDPNQuestionTypeLongText key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "number") {
                        return <EDPNQuestionTypeNumber key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "rbt") {
                        return <EDPNQuestionTypeRBT key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={props.Therapists}  />
                    }
                    else if (question.type == "multirbt") {
                        return <EDPNQuestionTypeMultiRBT key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={props.Therapists}  />
                    }
                    else if (question.type == "choice") {
                        return <EDPNQuestionTypeChoice key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "multichoicecreatable") {
                        return <EDPNQuestionTypeMultichoiceCreatable key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "multichoice") {
                        return <EDPNQuestionTypeMultichoice key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "bool_default_false") {
                        return <EDPNQuestionTypeBoolDefaultFalse key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}   />
                    }
                    else if (question.type == "bcbapresent") {
                        return <EDPNQuestionTypeBCBAPresent key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "bcbaname") {
                        return <EDPNQuestionTypeBCBAName key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={globalContext.bcbas}  />
                    }
                    else if (question.type == "abapresent") {
                        return <EDPNQuestionTypeABAPresent key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "abaname") {
                        return <EDPNQuestionTypeABAName key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={globalContext.therapists}  />
                    }
                    else if (question.type == "peoplepresent") {
                        return <EDPNQuestionTypePeoplePresent key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "peoplename") {
                        return <EDPNQuestionTypePeopleName key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject} Therapists={globalContext.therapists}  />
                    }
                    else if (question.type == "bool_default_false_aba") {
                        return <EDPNQuestionTypeBoolDefaultFalseABA key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "bool_default_false_bcba") {
                        return <EDPNQuestionTypeBoolDefaultFalseBCBA key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "bool_default_false_people") {
                        return <EDPNQuestionTypeBoolDefaultFalsePeople key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "bool_default_abnormal_behaviors") {
                        return <EDPNQuestionTypeBoolDefaultFalseAbnormalBehaviors key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "bool_default_false_injury") {
                        return <EDPNQuestionTypeBoolDefaultFalseInjury key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "bool_default_false_people") {
                        return <EDPNQuestionTypeBoolDefaultFalseParticipate key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "text_abnormal_behaviors") {
                        return <EDPNQuestionTypeTextAbnormalBehaviors key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "text_injury") {
                        return <EDPNQuestionTypeTextInjury key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                    else if (question.type == "text_participate") {
                        return <EDPNQuestionTypeTextParticipate key={question.questionId} EDPNQuestion={question} setAnswerObject={props.setAnswerObject} answerObject={props.answerObject}  />
                    }
                })}
                


                {props.EDPNParentQuestion && props.EDPNParentQuestion.narrativeText && props.answerObject && props.Therapists && <EDPNNarritiveSentance Narritive={props.EDPNParentQuestion.narrativeText} EDPNAnswerObject={props.answerObject} Therapists={props.Therapists} EDPNQuestions={props.EDPNQuestions} answerObject={props.answerObject} setAnswerObject={props.setAnswerObject} />}
            </>
        );
    }



};

