import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { GrammarItem } from '../utils/Definitions';
import { GrammarSet, VwCenterAllocation } from '../utils/Definitions';

interface Props {
    centerAllocations: VwCenterAllocation[],
    /* grammarItem: GrammarItem,
     makeCorrection: any,
     masterDivId: string,*/
}

export const PopperSignatureDisplay: React.FC<Props> = (props) => {

    return (
        <div>
            {props.centerAllocations.map(allocation =>
                <div
                    data-popover
                    id={"popoverContent_" + allocation.centerId + "_" + allocation.therapistId}
                    key={allocation.centerId + "_" + allocation.therapistId}
                    role="tooltip"
                    className="invisible popover absolute z-10 inline-block w-96 rounded-lg border border-gray-200 bg-white text-sm text-gray-500 opacity-0 shadow-sm transition-opacity duration-300 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400">
                    <div className="rounded-t-lg border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                        <h3 className="font-semibold text-gray-900 dark:text-white">
                            {allocation.firstName + " " + allocation.lastName}
                        </h3>
                    </div>
                    <div className="px-3 py-2">
                        <div className="">
                            <img className={"m-auto h-min-[50px] h-full w-full " + (allocation.signatureImage?.toString() == "" ? 'hidden' : '')} src={`data:image/png;base64,${allocation.signatureImage}`} alt="Image" />
                        </div>
                    </div>
                    <div data-popper-arrow></div>
                </div>
            )}
        </div>
    );
}

