// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../authConfig";

// imports
import { Loading } from "../components/Loading";
import { ErrorComponent } from "../components/ErrorComponent";
import { useNavigate } from "react-router-dom";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../utils/GlobalContext";
import ProtectedComponent from "../utils/ProtectedComponent";
import ProtectedPage from "../utils/ProtectedPage";
import { ECLContext } from "./ecl/ECLContext";
import { TherapistData } from "../utils/Definitions";
import { ModalConfirm } from "../components/ModalConfirm";
import { ModalNotification } from "../components/ModalNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SignatureCanvas from "react-signature-canvas";
import { ModalSignature } from "../components/ModalSignature";


//only renders if authenticated
const ProfileContent = () => {

    const globalContext = useContext(GlobalContext);
    const eclContext = useContext(ECLContext);

    const [loading, setLoading] = useState<boolean>(false);

    const [credentialsValue, setCredentialsValue] = useState<string>("");

    const [confirmSaveModalActive, setConfirmSaveModalActive] = useState<boolean>(false);
    const [confirmSaveModalMessage, setConfirmSaveModalMessage] = useState<string>("");

    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);

    const [signatureSrc, setSignatureSrc] = useState<string>("");

    const [signatureModalActive, setSignatureModalActive] = useState<boolean>(false);


    interface CredentialsUpdateObject {
        badge: number;
        credentials: string;
    }

    const navigate = useNavigate();

    function HandleCredentialsChange(input: string) {
        setCredentialsValue(input);
    }

    useEffect(() => {
        if (globalContext.CoreAccount.therapistData) {

            //set credentials text
            if (globalContext.CoreAccount.therapistData.credentials) {
                setCredentialsValue(globalContext.CoreAccount.therapistData.credentials);
            }
            else {
                setCredentialsValue("");
            }

            //set signature image
            if (globalContext.CoreAccount.therapistData.signatureImage) {
                setSignatureSrc(`data:image/png;base64,${globalContext.CoreAccount.therapistData.signatureImage}`);
            }
            else {
                setSignatureSrc("");
            }
        }
    }, [globalContext.CoreAccount]);

    function UpdateAll() {
        globalContext.UpdateTherapistData();
    }

    function OpenSaveCredentialsModal() {
        setConfirmSaveModalMessage("<div>Are you sure you want to save your credentials as the following?</div><div>" + credentialsValue + "</div>")
        setConfirmSaveModalActive(true);
    }

    function SaveCredentials() {


        if (globalContext.CoreAccount.therapistData && globalContext.CoreAccount.therapistData.badge && credentialsValue) {

            //build Credentials Update Object
            var obj: CredentialsUpdateObject = {
                badge: globalContext.CoreAccount.therapistData.badge,
                credentials: credentialsValue
            };

            console.log("saving credentials:", obj);

            setLoading(true);

            globalContext.GetToken().then((token: any) => {
                //save credentials to database
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(obj)
                };

                var url = apiConfig.apiEndpoint + "/Admin/SaveCredentials";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                UpdateAll();
                                setLoading(false);
                                setSuccessModalActive(true);
                            }
                            else {
                                setLoading(false);
                                globalContext.setErrorModalProps({ errorMessage: "Failed to save credentials. Unknown error.", errorLocation: "/Admin/SaveCredentials" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: "Failed to save credentials. " + error, errorLocation: "/Admin/SaveCredentials" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
        else {
            globalContext.setErrorModalProps({ errorMessage: "Failed to save credentials. Unknown error.", errorLocation: "/Admin/SaveCredentials" });
            globalContext.setErrorModalActive(true);
        }
    }

    function OnSuccessClose() {

    }

    function OnSignatureSave() {
        setSignatureModalActive(false);
        UpdateAll();
    }

    function OnSignatureClose() {

    }

    return (

        <div className="flex-1 p-5">

            <div className={"m-auto text-center text-rose font-bold text-xl " + (!loading && globalContext.CoreAccount.currentUser?.therapistID == null ? '' : 'hidden') }>You are not a clinician in our system, so your credentials and signature are not available. Contact your billing coordinator if you think this is wrong.</div>


            <div className={"grid grid-cols-1 w-full xl:w-fit m-auto " + (!loading && globalContext.CoreAccount.currentUser?.therapistID != null ? '' : 'hidden')}>

                <div className="grid w-full m-auto max-w-[700px]">
                    <div className="w-full m-auto grid grid-cols-1">
                        <label className="font-bold" htmlFor="search">
                            Educational Credentials: (examples: RBT, MA, BCBA | MA, BCBA, LBA | PhD, BCBA-D, LBA)
                        </label>
                        <input maxLength={250} autoComplete="off" onChange={(event) => HandleCredentialsChange(event.target.value)} value={credentialsValue} type="text" className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" placeholder="MA, BCBA etc..." />
                    </div>

                    <button disabled={credentialsValue.length == 0} onClick={OpenSaveCredentialsModal} className={"bg-lacgreen ml-auto mt-2 px-3 w-fit h-[38px] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600 " + (credentialsValue.length == 0 ? 'bg-lacgreen-800 hover:bg-lacgreen-800' : '')}><span className="drop-shadow">Save Credentials</span></button>
                </div>

                <div className="grid w-fit m-auto mt-8">
                    <span className="font-bold">Signature: (Include educational credentials)</span>
                    <img className={"border-2 border-black w-fit m-auto " + (signatureSrc == "" ? 'hidden' : '')} src={signatureSrc} alt="Image" />
                    <div className={"xl:w-[600px] h-[150px] w-fit p-2 border-2 border-black rounded m-auto " + (signatureSrc == "" ? '' : 'hidden')}>
                        <span className="m-auto w-fit h-fit block text-rose text-xl">No signature found. Add one by clicking the "Edit Signature" button below.</span>
                    </div>

                    <p className={"max-w-[700px]"}>
                        I understand that my signature with credentials, will be used in the automatic signing of my BCBA session notes as the Rendering Provider. Additionally, if I am a credentialed and authorized BCBA, I understand my signature with credentials will be used on the learner session notes (ONLY Learners I hold the Authorization for) as the Credentialed/Authorized BCBA, following the Rendering Provider signature.
                    </p>

                    <button onClick={() => setSignatureModalActive(true)} className={"bg-lacblue ml-auto mt-2 px-3 w-fit h-[38px] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"}><span className="drop-shadow">Edit Signature</span></button>
                </div>

                
                

            </div>   


            <div className={"w-full h-auto flex " + (loading ? "" : "hidden")}>
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
            

            <ModalConfirm Message={confirmSaveModalMessage} Active={confirmSaveModalActive} setActive={setConfirmSaveModalActive} OnConfirm={SaveCredentials} />
            <ModalNotification Title={"Success!"} Body={"Credentials Saved Successfully"} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={OnSuccessClose} />

            <ModalSignature Active={signatureModalActive} setActive={setSignatureModalActive} OnModalClose={OnSignatureClose} OnSignatureSave={OnSignatureSave} TherapistData={globalContext.CoreAccount.therapistData} />

        </div>
    );
};


export function Profile() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Read', 'EDPN.Write', 'ECL.View', 'ECL.Write', 'ECL.Approve']}>
                    <ProfileContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
};