import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';


interface Props {
    Title: string,
    Body: string,
    Active: boolean,
    Icon: "Success" | "Warning" | "Info" | "None",
    setActive: React.Dispatch<React.SetStateAction<boolean>>;
    OnModalClose: any;
}

export const ModalNotification: React.FC<Props> = (props) => {

    //update modal on active change
    useEffect(() => {

    }, [props.Active]);

    function CloseModal() {

        props.setActive(false);
        props.OnModalClose();
    }


    const Success: React.FC = () => {
        return (
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-lacgreen-100 sm:mx-0 sm:h-10 sm:w-10">
                <FontAwesomeIcon className="h-6 w-6 text-lacgreen-600" icon={regular('check-circle')} />
            </div>
        )
    }

    const Warning: React.FC = () => {
        return (
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-rose-100 sm:mx-0 sm:h-10 sm:w-10">
                <FontAwesomeIcon className="h-6 w-6 text-rose-600" icon={regular('xmark-circle')} />
            </div>
        )
    }

    const Info: React.FC = () => {
        return (
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                <FontAwesomeIcon className="h-6 w-6 text-cyan-600" icon={solid('info')} />
            </div>
        )
    }

    const Icon: React.FC = () => {
        switch (props.Icon) {
            case "Success":
                return <Success/>;
            case "Warning":
                return <Warning />;
            case "Info":
                return <Info />;
            default:
                return <></>;
        }
    }


    return (
        <div className={"z-[100] relative " + (props.Active ? "normal" : "hidden")} aria-labelledby="modal-title" role="dialog" aria-modal="true">

          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          
          <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">
                              <Icon />
                              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                  <h3 className="text-lg font-medium leading-6" id="modal-title">{props.Title}</h3>
                                    <div className="mt-2">
                                        <div dangerouslySetInnerHTML={{ __html: props.Body != null ? props.Body : "" }} />
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button onClick={CloseModal} className="bg-lacblue w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">OK</span></button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}

