// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";

// imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../../utils/GlobalContext";
import ProtectedComponent from "../../utils/ProtectedComponent";
import ProtectedPage from "../../utils/ProtectedPage";


//only renders if authenticated
const ECLHomeContent = () => {

    const navigate = useNavigate();

    useEffect(() => {
        GoToLogs();
    }, []);

    function GoToLogs() {
        navigate("/ecl/logs");
    }

    function GoToApprovals() {
        navigate("/ecl/approvals");
    }

    return (

        <div className="flex-1 p-5">
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 place-items-center w-fit h-full m-auto">
                <ProtectedComponent RequiredRoles={['ECL.View', 'ECL.Write', 'ECL.Approve']}>
                    <button onClick={GoToLogs} className="bg-lacblue-400 text-2xl xl:text-xl w-[16rem] h-[8rem] rounded-lg font-bold text-white transition duration-250 ease-in-out hover:bg-lacblue-600">Logs</button>
                </ProtectedComponent>

                <ProtectedComponent RequiredRoles={['ECL.Write', 'ECL.Approve']}>
                    <button onClick={GoToApprovals} className="bg-lacblue-400 text-2xl xl:text-xl w-[16rem] h-[8rem] rounded-lg font-bold text-white transition duration-250 ease-in-out hover:bg-lacblue-600">Corrections</button>
                </ProtectedComponent>
            </div>
            

        </div>
    );
};


export function ECLHome() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['ECL.View', 'ECL.Write', 'ECL.Approve']}>
                    <ECLHomeContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
      )
};