import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { EDPNAnswer, EDPNAnswerObject, EDPNQuestion } from "../../utils/Definitions";

interface Props {
    EDPNQuestion: EDPNQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>;
    answerObject: EDPNAnswerObject | null;
    FlatQuestions: EDPNQuestion[],

}

export const EDPNQuestionTypeMultiText: React.FC<Props> = (props) => {
    const edpnContext = useContext(EDPNContext);

    const [answers, setAnswers] = useState<string[]>(['']);

    function arraysEqual(a: string[], b: string[]) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    //update answer object with local answers
    useEffect(() => {

        if (props.EDPNQuestion.questionId && props.answerObject && props.answerObject.answers) {


            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.answers && arraysEqual(answers, [''])) {
                //autopopulate from edit

                var answerarray: string[] = [];

                //get all multitext answers from edit
                var tanswers = edpnContext.selectedEditAnswer.answers.filter(x => x.questionId == props.EDPNQuestion.questionId);

                //get all questions that are multitext
                var multitextquestions = props.FlatQuestions.filter(x => x.type == "multitext");

                //assign multi index
                tanswers.filter(z => multitextquestions.find(y => y.questionId == z.questionId)).forEach((answer, index) => {
                    //set multiindex
                    answer.multiIndex = index;
                })

                //copy new answers into answer array
                tanswers.forEach((answer) => {
                    if (answer.textValue) {
                        answerarray.push(answer.textValue);
                    }
                    else if(tanswers.length == 1) {
                        answerarray.push("");
                    }
                })

                if (answerarray.length == 0) {
                    answerarray = [''];
                }

                //only update if there is a change (avoid infinite loop)
                if (!arraysEqual(answerarray, answers)) {
                    setAnswers(answerarray);
                }
                
            }
            else {
                //dont populate from edit, just copy over local answers

                //cast answers object to converted array
                var convertedAnswers: string[] = [];
                props.answerObject.answers.filter(x => x.questionId == props.EDPNQuestion.questionId).forEach((answer) => {
                    if (answer.textValue) {
                        convertedAnswers.push(answer.textValue);
                    }
                    else {
                        convertedAnswers.push("");
                    }
                });

                //check if there is a difference between converted array and local
                if (!arraysEqual(convertedAnswers, answers)) {
                    //overwrite answers object

                    //get all answers except multi text
                    var tempanswers = props.answerObject.answers.filter(x => x.questionId != props.EDPNQuestion.questionId);

                    //add in all answers
                    for (var i = 0; i < answers.length; i++) {

                        var tempAnswer: EDPNAnswer = {
                            questionId: props.EDPNQuestion.questionId,
                            answerId: null,
                            multiIndex: i,
                            logId: null,
                            textValue: answers[i],
                            numberValue: null,
                            boolValue: null,
                            choiceValue: null,
                            log: null,
                            question: null
                        };

                        tempanswers.push(tempAnswer);
                    }

                    //overwrite answers object
                    props.setAnswerObject({ ...props.answerObject, answers: tempanswers });
                    
                }
            }
        }
    }, [props.answerObject, props.EDPNQuestion, answers]);

    function AddLine() {
        var tempanswers: string[] = [];

        for (var i = 0; i < answers.length; i++) {
            tempanswers.push(answers[i]);
        }

        //new line
        tempanswers.push("");

        setAnswers(tempanswers);
    }

    function RemoveLine() {
        if (answers.length > 1) {
            var tempanswers: string[] = [];

            for (var i = 0; i < answers.length; i++) {
                tempanswers.push(answers[i]);
            }

            //remove line
            tempanswers.pop();

            setAnswers(tempanswers);
        }
    }


    function UpdateAnswers(index: number, value: string) {

        var tempanswers: string[] = [];

        for (var i = 0; i < answers.length; i++) {
            if (i != index) {
                tempanswers.push(answers[i]);
            }
            else {
                tempanswers.push(value);
            }
        }

        setAnswers(tempanswers);
    }

    function OpenExample() {
        if (props.EDPNQuestion.exampleText) {
            edpnContext.setExampleMessage(props.EDPNQuestion.exampleText);
            edpnContext.setExampleModalActive(true);
        }
    }

    if (props.EDPNQuestion.exampleText) {
        return (
            <div className="w-full m-auto mt-4 col-span-12 grid grid-cols-12">
                <label className="font-bold col-span-11">
                    {props.EDPNQuestion.questionText}:
                </label>
                <div onClick={OpenExample} className="cursor-pointer ml-auto mb-1 col-span-1">
                    <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle text-lacblue hover:text-lacblue-500" icon={solid('circle-info')} />
                </div>

                <div className="col-span-12">
                    {Array.from(Array(answers.length), (e, i) => {
                        return <input onCut={(event) => event.preventDefault()} onCopy={(event) => event.preventDefault()} onPaste={(event) => event.preventDefault()} disabled={edpnContext.isViewOnly} key={i} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={e => UpdateAnswers(i, e.target.value)} defaultValue={answers[i]} autoComplete="off" type="text" className="mb-2 outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    })}

                    <button disabled={edpnContext.isViewOnly} onClick={AddLine} className={"bg-lacblue m-auto w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 noprint " + (edpnContext.isViewOnly ? 'bg-lacblue-900 hover:bg-lacblue-900' : '')}><span className="drop-shadow">Add Line</span></button>
                    <button disabled={answers.length == 1} onClick={RemoveLine} className={"bg-rose m-auto ml-2 w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 noprint " + (answers.length == 1 ? 'bg-rose-900 hover:bg-rose-900' : '')}><span className="drop-shadow">Remove Line</span></button>

                </div>

            </div>
        )
    }
    else {
        return (
            <div className="w-full m-auto mt-4 col-span-12">

                <label className="block font-bold">
                    {props.EDPNQuestion.questionText}
                </label>

                {Array.from(Array(answers.length), (e, i) => {
                    return <input onCut={(event) => event.preventDefault()} onCopy={(event) => event.preventDefault()} onPaste={(event) => event.preventDefault()} disabled={edpnContext.isViewOnly} key={i} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={e => UpdateAnswers(i, e.target.value)} defaultValue={answers[i]} autoComplete="off" type="text" className="mb-2 outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                })}

                <button disabled={edpnContext.isViewOnly} onClick={AddLine} className={"bg-lacblue m-auto w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 noprint " + (edpnContext.isViewOnly ? 'bg-lacblue-900 hover:bg-lacblue-900' : '')}><span className="drop-shadow">Add Line</span></button>
                <button disabled={answers.length == 1} onClick={RemoveLine} className={"bg-rose m-auto ml-2 w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 noprint " + (answers.length == 1 ? 'bg-rose-900 hover:bg-rose-900' : '')}><span className="drop-shadow">Remove Line</span></button>

            </div>
        )
    }
    
    
    
};

