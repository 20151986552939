import React, { useContext, useEffect, useState } from 'react';
import { EDPNAnswerObject, Therapist } from '../../utils/Definitions';
import { GlobalContext } from '../../utils/GlobalContext';
import ProtectedComponent from '../../utils/ProtectedComponent';
import { EDPNApprovalCard } from './EDPNApprovalCard';

interface Props {
    Logs: EDPNAnswerObject[],
    Therapists: Therapist[] | null,
    ApproveLog: any,
    UnapproveLog: any,
    DeleteLog: any,
    HoldLog: any,
    ApproveSet: any,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>,
}

export const EDPNSetCard: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    function WarnUserMissingCredentials() {
        props.SetCredentialsModalActive(true);
    }

    const ApproveSetButton = () => {
        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData.signatureImage) {
            return (
                <button onClick={() => props.ApproveSet(props.Logs[0].logSetId)} className="bg-lacgreen float-right m-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve Set</span></button>
            )
        }
        else {
            return (
                <button onClick={WarnUserMissingCredentials} className="bg-lacgreen float-right m-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve Set</span></button>
            )
        }
    }

    return (

        <div className={"grid grid-cols-1 gap-2 place-items-center w-fit m-auto h-fit bg-gray-50 border-2 border-black drop-shadow rounded p-2 " + (props.Logs.length > 0 ? "" : "hidden")}>

            <div className="w-full flow-root">
                <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                    <ApproveSetButton/>
                </ProtectedComponent>
            </div>
            {props.Logs.map(log => <EDPNApprovalCard key={log.logId} Therapists={props.Therapists} Log={log} ApproveLog={() => props.ApproveLog(log)} UnapproveLog={() => props.UnapproveLog(log)} DeleteLog={() => props.DeleteLog(log)} HoldLog={() => props.HoldLog(log)} SetCredentialsModalActive={props.SetCredentialsModalActive} />)}
        </div>
    )
};