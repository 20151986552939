import { ClientApplication } from '@azure/msal-browser/dist/app/ClientApplication';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiConfig } from "../authConfig";
import { ModalNotification } from "./ModalNotification";

import { GlobalContext } from "../utils/GlobalContext";
import React, { useEffect, useRef, useState, useContext } from 'react';
import { ReactCropperElement, Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { Client } from '../utils/Definitions';


interface Props {
    Active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>;
    OnModalClose: any;
    Client: Client | null;
}

export const ProfilePictureModal: React.FC<Props> = (props) => {

    const [newProfilePictureReady, setNewProfilePictureReady] = useState<boolean>(false);

    const globalContext = useContext(GlobalContext);

    var cropperRef = useRef<ReactCropperElement>(null);

    var fileRef = React.useRef<HTMLInputElement>(null);

    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            setNewProfilePictureReady(true);
        }
    };

    const handleClick = () => {
        if (fileRef) {
            fileRef.current?.click();
        }
    }

    //update modal on active change
    useEffect(() => {
        if (props.Active === true) {
            GetProfileImage();
        }
    }, [props.Active]);

    //update modal on profile picture change
    useEffect(() => {

    }, [newProfilePictureReady]);

    function CloseModal() {
        props.setActive(false);
        props.OnModalClose();
    }

    //handle file selection change
    const handleOnChange = (event : any) => {
        const fileUploaded = event.target.files;

        fileChange(fileUploaded);
    }

    //handle cropper picture change
    function fileChange(filelist: FileList | null) {
        if (filelist && filelist.length > 0) {
            var URL = window.URL;
            cropperRef.current?.cropper.replace(URL.createObjectURL(filelist[0]), false);
        }
    }

    //save new cropped profile picture
    function SaveImage() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);

            cropperRef.current?.cropper.getCroppedCanvas({ maxHeight: 4096, maxWidth: 4096 }).toBlob((blob) => {
                const pictureData = new FormData();

                if (blob && props.Client?.clientId) {
                    var file = new File([blob], "croppedImage");

                    pictureData.append('clientID', props.Client?.clientId.toString());
                    pictureData.append('image', file);

                    var options = {
                        method: "POST",
                        headers: headers,
                        body: pictureData
                    };

                    var url = apiConfig.apiEndpoint + "/Clients/SavePicture";

                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                setSuccessModalActive(true);
                            },
                            (error) => {
                                globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/SavePicture" });
                                globalContext.setErrorModalActive(true);
                            }
                        )
                }
            })

        })
    }

    //get current original profile picture
    function GetProfileImage() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);

            if (props.Client?.clientId) {
                var options = {
                    method: "GET",
                    headers: headers,
                };

                var url = apiConfig.apiEndpoint + "/Clients/GetProfilePicture?thumb=" + false + "&clientid=" + props.Client.clientId;

                fetch(url, options)
                    .then(res => res.blob())
                    .then(
                        (result) => {
                            var file = new File([result], "ProfilePicture");

                            cropperRef.current?.cropper.replace(URL.createObjectURL(file), false);
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetProfilePicture" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })
    }


    return (
        <div className={"z-10 relative " + (props.Active ? "normal" : "hidden")} aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all xl:m-8 w-fit h-fit">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3 className="text-lg font-medium leading-6" id="modal-title">Edit {props.Client?.fullName}'s Profile Picture</h3>
                                    <div className="mt-10">

                                        <div className="flex justify-center">
                                            <div className="mx-50 w-[100%] h-[300] xl:w-[300] xl:h-[300]">
                                                <Cropper
                                                    style={{ maxHeight: "400px", maxWidth: "400px", display: "flex", justifyContent: "center" }}
                                                    // Cropper.js options
                                                    initialAspectRatio={1 / 1}
                                                    aspectRatio={1 / 1}
                                                    guides={true}
                                                    crop={onCrop}
                                                    scalable={true}
                                                    ref={cropperRef}
                                                    minContainerHeight={250}
                                                    minContainerWidth={250}
                                                    viewMode={1}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div>
                                        <div className="flex m-5 justify-center">
                                            <button onClick={handleClick} className={"bg-lacblue m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-md text-white transition duration-250 ease-in-out hover:bg-lacblue-600"}><span className="drop-shadow ">Upload New Photo</span></button>
                                            <input onChange={handleOnChange} ref={fileRef} type="file" style={{ display: 'none' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 flex justify-center sm:flex sm:justify-center sm:flex-row-reverse sm:px-6">
                            <button onClick={CloseModal} className="bg-rose ml-2 w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Cancel</span></button>
                            <button onClick={SaveImage} className={"w-[7rem] ml-2 h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out " + (newProfilePictureReady ? 'bg-lacgreen hover:bg-lacgreen-600' : 'bg-lacgreen-800 hover:bg-lacgreen-800')}><span className="drop-shadow">Save</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalNotification Title={"Success!"} Body={"Profile picture successfully added."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={CloseModal} />
        </div>
    );
}

