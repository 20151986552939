import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CredentialsContext } from "../../pages/credentials/CredentialsContext";
import { Therapist, Center, TherapistCredentialStatus, BCBATherapist } from "../../utils/Definitions";
import { GlobalContext } from "../../utils/GlobalContext";
import { ModalNotification } from "../ModalNotification";
import { apiConfig, loginRequest } from "../../authConfig";



interface Props {
    Therapist: BCBATherapist,
    SetProfilePictureModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    SetSelectedTherapistForModal: React.Dispatch<React.SetStateAction<Therapist | null>>,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>
    centers: Center[] | undefined;
}

export const CredentialsTherapistCard: React.FC<Props> = (props) => {

    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);
    const credentialsContext = useContext(CredentialsContext);

    const [loading, setLoading] = useState<boolean>(false);

    const [therapistCredentialedCenters, setTherapistCredentialedCenters] = useState<TherapistCredentialStatus[]>();
    
    function NavigateBCBACredentials() {
        credentialsContext.setSelectedTherapist(props.Therapist);
        navigate("/credentials/bcbacredentials")
    }

    //update centers
    useEffect(() => {
        GetCredentialedCenters();
    }, []);


    function GetCredentialedCenters() {
        if (credentialsContext && props.Therapist) {

            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Credentials/GetTherapistFullyCredentialedCenters?therapistId=" + props.Therapist.therapistId, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setTherapistCredentialedCenters(result);
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetTherapistFullyCredentialedCenters" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function FullyCredentialedCenters() {
        var displayCredentials = "";
        if (props.centers != undefined && therapistCredentialedCenters != undefined) {

            therapistCredentialedCenters.forEach(function (credentialedCenter) {

                var center = props.centers?.find(obj => obj.centerId === credentialedCenter.centerId && credentialedCenter.credentialed === true)?.centerName;

                if (center) {
                    displayCredentials += center + ", ";
                }
            })
        }
        return displayCredentials.slice(0, -2);
    }


    return (
        <div key={props.Therapist.therapistId} onClick={NavigateBCBACredentials} className="w-full xl:w-[38rem] h-[12rem] bg-gray-50 p-5 border drop-shadow rounded flex hover:cursor-pointer hover:bg-gray-100">

            <div className="grid grid-flow-row grow h-fit">
                <span className="font-bold text-2xl pt-1">{props.Therapist.employeeName}</span>

                <div className="grid grid-cols-2 mt-4">
                    <span>Job Title: </span>
                    <span>{props.Therapist.jobTitle}</span>
                </div>

                <div className="grid grid-cols-2 mt-4">
                    <span>Fully Credentialed: </span>
                    <span>{FullyCredentialedCenters()}</span>
                </div>

                <div className="grid grid-cols-2 mt-4">
                    <span>Re-Cert Date: </span>
                    <span>{props.Therapist.bcbarecertDate ? new Date(props.Therapist.bcbarecertDate).toLocaleDateString() : "N/A"}</span>
                </div>

            </div>
        </div>
    )
};