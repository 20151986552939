import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ECLContext } from "../../pages/ecl/ECLContext";
import { Client, ECLAnswerObject, Therapist, TherapistSmallObject } from "../../utils/Definitions";
import pic1562 from '../../images/1562.png'; 
import pic222 from '../../images/222.png'; 
import pic255 from '../../images/255.png'; 
import pic889 from '../../images/889.png'; 
import pic94 from '../../images/94.png'; 
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ModalConfirm } from "../ModalConfirm";
import { format, isAfter, sub } from "date-fns";
import { GlobalContext } from "../../utils/GlobalContext";


interface Props {
    Log: ECLAnswerObject,
    Therapists: TherapistSmallObject[] | null,
    EditButtonVisible: boolean,
    ApproveButtonVisible: boolean,
    DeleteButtonVisible: boolean,
    ApproveLog: any,
    DeleteLog: any,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const ECLViewLogCard: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const eclContext = useContext(ECLContext);
    const navigate = useNavigate();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    function NavigateToEditLog() {

        eclContext.setSelectedEditAnswer(props.Log);
        eclContext.setIsViewOnly(false);
        navigate("/ecl/logs/viewlogs/editlog");
    }

    function GetTherapistName(id: number) {
        if (props.Therapists) {

            var therapist = props.Therapists.find(x => x.therapistId == id);

            if (therapist) {
                return therapist.employeeName;
            }
            else {
                return String(id);
            }
        }
        else {
            return String(id);
        }
    }

    function ToggleExpand() {
        setIsExpanded(!isExpanded);
    }

    function WarnUserMissingCredentials() {
        props.SetCredentialsModalActive(true);
    }

    const ExpandButton = () => {

        if (isExpanded) {
            return (
                <FontAwesomeIcon onClick={ToggleExpand} className="fill-current w-8 h-8 cursor-pointer my-auto" icon={solid('angle-up')} />
            );
        }
        else {
            return <FontAwesomeIcon onClick={ToggleExpand} className="fill-current w-8 h-8 cursor-pointer my-auto" icon={solid('angle-down')} />
        }

    }

    const Narritive = () => {
        if (isExpanded) {
            return (
                <div className="mt-4">
                    <span className="font-bold">Session Summary:</span>
                    <div dangerouslySetInnerHTML={{ __html: props.Log.narrative != null ? props.Log.narrative : "N/A" }} />
                </div>
            );
        }
        else {
            return <></>
        }
    }

    const Comments = () => {
        if (isExpanded && props.Log.comments && props.Log.comments.length > 0) {
            return (
                <div className="mt-4">
                    <span className="font-bold">Comments:</span>
                    <table className="border-separate border-spacing-3">
                        <tbody>
                            {props.Log.comments ? props.Log.comments.map(comment => <tr key={comment.dpncommentId}><td>[{comment.createdOn ? format(new Date(comment.createdOn), 'M/d/yy H:mm a') : ""}]</td><td>{comment.createdBy}: </td><td>{comment.comment}</td></tr>) : <></>}
                        </tbody>
                    </table>
                </div>
            );
        }
        else {
            return <></>
        }
    }

    const BillingDate = () => {
        if (isExpanded) {
            return (
                <div className="grid grid-flow-row grow h-auto w-[10rem]">
                    <span className="font-bold">Service Date:</span>
                    {props.Log.billingDate && <span>{new Date(props.Log.billingDate).toLocaleDateString()}</span>}

                    <span className="font-bold mt-2">Time In:</span>
                    {props.Log.startTime && <span>{new Date(props.Log.startTime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</span>}
                </div>
            );
        }
        else {
            return (
                <div className="grid grid-flow-row grow h-auto w-[10rem]">
                    <span className="font-bold">Service Date:</span>
                    {props.Log.billingDate && <span>{new Date(props.Log.billingDate).toLocaleDateString()}</span>}
                </div>
            )
        }
    }

    const CPTCode = () => {
        if (isExpanded) {
            return (
                <div className="grid grid-flow-row grow h-auto w-[10rem]">
                    <span className="font-bold">CPT Code:</span>
                    {props.Log.cptcode && <span>{props.Log.cptcode}</span>}

                    <span className="font-bold mt-2">Time Out:</span>
                    {props.Log.endTime && <span>{new Date(props.Log.endTime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</span>}
                </div>
                );
        }
        else {
            return (
                <div className="grid grid-flow-row grow h-auto w-[10rem]">
                    <span className="font-bold">CPT Code:</span>
                    {props.Log.cptcode && <span>{props.Log.cptcode}</span>}
                </div>
                )
        }
    }

    const Status = () => {
        switch (props.Log.dpnstatusId) {
            case 1:
                //approved
                return (
                    <span className="text-lacgreen">Approved</span> 
                )
            case 2:
                //pending
                return (
                    <span className="text-yellow">Pending</span>
                )
            case 3:
                //imported
                return (
                    <span className="text-lacblue">Imported</span>
                )
            case 4:
                //created
                return (
                    <span className="text-rose">Created</span>
                )
            default:
                return <></>;
        }
    }

    const DeleteLogButton = () => {

        //show delete button ONLY if current user is author
        if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.therapistID && props.Log.therapistId == globalContext.CoreAccount.currentUser.therapistID) {
            switch (props.Log.dpnstatusId) {
                case 1:
                    //approved
                    return (
                        <></>
                    )
                case 2:
                    //pending
                    if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                        return (
                            <button onClick={() => props.DeleteLog()} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                    else {
                        return (
                            <button onClick={WarnUserMissingCredentials} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                    
                case 3:
                    //imported
                    return (
                        <></>
                    )
                case 4:
                    //created
                    if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                        return (
                            <button onClick={() => props.DeleteLog()} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                    else {
                        return (
                            <button onClick={WarnUserMissingCredentials} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                    
                default:
                    return <></>;
            }
        }

        return <></>;
    }

    const EditLogButton = () => {
        if (props.Log.billingDate) {

            //show edit button ONLY if current user is author
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.therapistID && props.Log.therapistId == globalContext.CoreAccount.currentUser.therapistID) {

                switch (props.Log.dpnstatusId) {
                    case 1:
                        //approved
                        return (
                            <></>
                        )
                    case 2:
                        //pending
                        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                            return (
                                <button onClick={NavigateToEditLog} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>
                            )
                        }
                        else {
                            return (
                                <button onClick={WarnUserMissingCredentials} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>
                            )
                        }
                            
                    case 3:
                        //imported
                        return (
                            <></>
                        )
                    case 4:
                        //created
                        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                            return (
                                <button onClick={NavigateToEditLog} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>
                            )
                        }
                        else {
                            return (
                                <button onClick={WarnUserMissingCredentials} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>
                            )
                        }
                            
                    default:
                        return <></>;
                }
            }
        }

        return <></>;
    }

    const ApproveLogButton = () => {
        if (props.Log.billingDate) {
            switch (props.Log.dpnstatusId) {
                case 1:
                    //approved
                    return (
                        <></>
                    )
                case 2:
                    //pending
                    if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                        return (
                            <button onClick={() => props.ApproveLog(props.Log)} className="bg-lacgreen m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve</span></button>
                        )
                    }
                    else {
                        return (
                            <button onClick={WarnUserMissingCredentials} className="bg-lacgreen m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve</span></button>
                        )
                    }
                        
                case 3:
                    //imported
                    return (
                        <></>
                    )
                case 4:

                    //remove approve button
                    return <></>;

                    /*
                    //created
                    if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                        return (
                            <button onClick={() => props.ApproveLog(props.Log)} className="bg-lacgreen m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve</span></button>
                        )
                    }
                    else {
                        return (
                            <button onClick={WarnUserMissingCredentials} className="bg-lacgreen m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve</span></button>
                        )
                    }
                    */
                        
                default:
                    return <></>;
            }
        }

        return <></>;
    }

    return (

        <div className={"h-fit bg-gray-50 border drop-shadow rounded p-2 mb-8 " + (props.ApproveButtonVisible ? 'w-[45rem] xl:w-[66rem]' : 'w-[45rem] xl:w-[56rem]')}>

            <div className="flex">

                <div className="grid grid-flow-row grow h-fit w-[3rem] mt-2">
                    <ExpandButton/>
                </div>

                <BillingDate/>

                <CPTCode/>

                <div className="grid grid-flow-row grow h-fit w-[12rem]">
                    <span className="font-bold">Clinician:</span>
                    {props.Log.therapistId && props.Therapists && <span>{GetTherapistName(props.Log.therapistId)}</span>}
                </div>

                <div className="grid grid-flow-row grow h-fit w-[10rem]">
                    <span className="font-bold">Status:</span>
                    <Status/>
                </div>

                <div className={"grid grid-flow-row grow h-fit mt-2 w-[7rem] " + (props.DeleteButtonVisible ? '' : 'hidden')}>
                    <ProtectedComponent RequiredRoles={['ECL.Write', 'ECL.Approve']}>
                        <DeleteLogButton />
                    </ProtectedComponent>
                </div>

                <div className={"grid grid-flow-row grow h-fit mt-2 w-[7rem] " + (props.EditButtonVisible ? '' : 'hidden')}>
                    <ProtectedComponent RequiredRoles={['ECL.Write', 'ECL.Approve']}>
                        <EditLogButton/>
                    </ProtectedComponent>
                </div>

                <div className={"grid grid-flow-row grow h-fit mt-2 w-[7rem] " + (props.ApproveButtonVisible ? '' : 'hidden')}>
                    <ProtectedComponent RequiredRoles={['ECL.Write', 'ECL.Approve']}>
                        <ApproveLogButton/>
                    </ProtectedComponent>
                </div>

            </div>

            <Narritive />

            <Comments/>

        </div>
    )
    
    
};

