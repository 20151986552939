import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OutcomesContext } from "../../pages/outcomes/OutcomesContext";
import { ChoiceOption, Client, OutcomeEvaluation, OutcomeEvaluationAnswer, OutcomesEvaluation, OutcomesGroup, OutcomesQuestion } from "../../utils/Definitions";
import pic1562 from '../../images/1562.png'; 
import pic222 from '../../images/222.png'; 
import pic255 from '../../images/255.png'; 
import pic889 from '../../images/889.png'; 
import pic94 from '../../images/94.png'; 
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../ProfilePicture";
import { GlobalContext } from "../../utils/GlobalContext";
import { ModalNotification } from "../ModalNotification";
import { OutcomesQuestionTypeChoice } from "./OutcomesQuestionTypeChoice";
import { OutcomesQuestionTypeMultiChoice } from "./OutcomesQuestionTypeMultiChoice";
import { OutcomesQuestionTypeText } from "./OutcomesQuestionTypeText";
import { OutcomesQuestionTypeNumber } from "./OutcomesQuestionTypeNumber";
import { Profile } from "../../pages/Profile";
import { OutcomesQuestionTypeVineland } from "./OutcomesQuestionTypeVineland";


interface Props {
    OutcomeGroup: OutcomesGroup;
    OutcomeSelectedGroup: number | null;
    SetOutcomeSelectedGroup: React.Dispatch<React.SetStateAction<number | null>>;

    GetMultiChoiceSelectedOptions: any;
    OutcomeEvaluation: OutcomeEvaluation | null;
    setOutcomeEvaluationObject: React.Dispatch<React.SetStateAction<OutcomeEvaluation | null>>;
    onChange: any;
    ChildChoiceOnChange: any;
    ChildMultiOnChange: any;
    ChildOnChange: any;
    GetSelectedOption: any;
    NullQuestion: any;
    GetText: any;
    SetValidations: any;
    GetValidation: any;
    GetNullValue: any;
}

export const OutcomesQuestionGroup: React.FC<Props> = (props) => {

    function GetGroupStatus(): string {

        var tempComplete: boolean = false;
        var tempVinelandQuestions = props.OutcomeGroup.outcomesQuestions.filter(x => x.question.questionType == "vineland");

        props.OutcomeGroup.outcomesQuestions.forEach(question => {

            var answerFound = props.OutcomeEvaluation?.answers.find(answer => answer.questionID == question.question.questionId);


            if (!answerFound || answerFound.value == null || answerFound.value == "") {
                var nullableFound = props.OutcomeEvaluation?.nullables.find(x => x.questionID == question.question.questionId);

                if (nullableFound?.value != "True") {

                    tempComplete = true;
                }
            }
            else if (tempVinelandQuestions && tempVinelandQuestions.find(x => x.question.questionId == answerFound?.questionID)) {
                //if answer is a vineland answer, validate answer
                const pattern: RegExp = /^(>|<)?(\d+)(?::(\d+))?\+?$/;
                const isMatch: boolean = pattern.test(answerFound.value);

                if (!isMatch) {
                    tempComplete = true;
                }
            }

        })

        if (tempComplete) {
            return "Incomplete";
        }
        else {
            return "Complete";
        }
    }

    function OnClickSelectGroup() {
        console.log("selecting group:", props.OutcomeGroup.groupId);
        props.SetOutcomeSelectedGroup(props.OutcomeGroup.groupId);
    }

    if (props.OutcomeSelectedGroup == props.OutcomeGroup.groupId) {
        return (
            <div className="mt-4" key={props.OutcomeGroup.groupId}>
                {props.OutcomeGroup.outcomesQuestions && props.OutcomeGroup.outcomesQuestions.map((question, i) => {

                    switch (question.question.questionType) {

                        case "choice": {
                            return (
                                OutcomesQuestionTypeChoice({
                                    onChange: props.onChange,
                                    OutcomeEvaluation: props.OutcomeEvaluation,
                                    OutcomesQuestion: question,
                                    setOutcomeEvaluationObject: props.setOutcomeEvaluationObject,
                                    ChildChoiceOnChange: props.ChildChoiceOnChange,
                                    GetSelectedOption: props.GetSelectedOption,
                                    NullQuestion: props.NullQuestion,
                                    GetNullValue: props.GetNullValue,
                                })
                            )
                        }
                        case "multichoice": {
                            return (
                                OutcomesQuestionTypeMultiChoice({
                                    onChange: props.onChange,
                                    OutcomeEvaluation: props.OutcomeEvaluation,
                                    OutcomesQuestion: question,
                                    setOutcomeEvaluationObject: props.setOutcomeEvaluationObject,
                                    GetMultiChoiceSelectedOptions: props.GetMultiChoiceSelectedOptions,
                                    ChildOnChange: props.ChildMultiOnChange,
                                    NullQuestion: props.NullQuestion,
                                    GetNullValue: props.GetNullValue,
                                })
                            )
                        }
                        case "text": {
                            return (
                                OutcomesQuestionTypeText({
                                    onChange: props.onChange,
                                    OutcomeEvaluation: props.OutcomeEvaluation,
                                    OutcomesQuestion: question,
                                    setOutcomeEvaluationObject: props.setOutcomeEvaluationObject,
                                    ChildOnChange: props.ChildOnChange,
                                    GetText: props.GetText,
                                    NullQuestion: props.NullQuestion,
                                    GetNullValue: props.GetNullValue,
                                })
                            )

                        }
                        case "vineland": {
                            return (
                                OutcomesQuestionTypeVineland({
                                    onChange: props.onChange,
                                    OutcomeEvaluation: props.OutcomeEvaluation,
                                    OutcomesQuestion: question,
                                    setOutcomeEvaluationObject: props.setOutcomeEvaluationObject,
                                    ChildOnChange: props.ChildOnChange,
                                    GetText: props.GetText,
                                    NullQuestion: props.NullQuestion,
                                    SetValidations: props.SetValidations,
                                    GetValidation: props.GetValidation,
                                    GetNullValue: props.GetNullValue,
                                })
                            )

                        }
                        case "number": {
                            return (
                                OutcomesQuestionTypeNumber({
                                    onChange: props.onChange,
                                    OutcomeEvaluation: props.OutcomeEvaluation,
                                    OutcomesQuestion: question,
                                    setOutcomeEvaluationObject: props.setOutcomeEvaluationObject,
                                    ChildOnChange: props.ChildOnChange,
                                    GetText: props.GetText,
                                    NullQuestion: props.NullQuestion,
                                    GetNullValue: props.GetNullValue,
                                })
                            )
                        }
                        default: {
                            return (
                                <div key={question.question.questionId}></div>
                            )
                        }
                    }


                })}

            </div>
        )
    }
    else if (props.OutcomeGroup.groupId != null) {
        return (
            <div key={props.OutcomeGroup.groupId} className="w-full xl:w-[38rem] h-fit bg-gray-50 border drop-shadow rounded mt-4 p-2">
                <span className="text-xl font-bold">{props.OutcomeGroup.groupText}</span>

                <div className="w-full h-fit m-auto flex">

                    <span>Status:</span><span className={"grow ml-1 font-bold " + (GetGroupStatus() == "Complete" ? "text-lacgreen" : "text-rose")}>{GetGroupStatus()}</span>

                    <button onClick={() => OnClickSelectGroup()} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>

                </div>
            </div>
        )
    }
    else {
        return (
            <div key={props.OutcomeGroup.groupId} className="w-full xl:w-[38rem] h-fit mt-4 p-2">
                <div onClick={() => OnClickSelectGroup()} className="flex items-center justify-center w-40 cursor-pointer col-span-2">
                    <div className="">
                        <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                    </div>
                    <span className="text-xl w-auto ml-3">Back</span>
                    <div className="flex-1"></div>
                </div>
            </div>

            )
    }
    

   
};

