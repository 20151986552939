// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ChoiceOption, Client, CPTCode, ECLAnswer, ECLAnswerObject, ECLQuestion, OutcomeEvaluation, OutcomeEvaluationAnswer, OutcomesGroup, OutcomesQuestion, Therapist, TherapistSmallObject } from "../../utils/Definitions";
import { Navigate, useNavigate } from "react-router-dom";
import { ECLQuestionNode } from "../../components/ecl/ECLQuestionNode";
import Select, { MultiValue, SingleValue } from "react-select";


import { TextField } from '@mui/material';
import { ClassNames } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import { time } from "console";
import { timingSafeEqual } from "crypto";
import { ModalNotification } from "../../components/ModalNotification";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePicture } from "../../components/ProfilePicture";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalConfirm } from "../../components/ModalConfirm";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { OutcomesContext } from "./OutcomesContext";
import { OutcomesEvaluation } from "../../utils/Definitions";
import { OutcomesQuestionTypeChoice } from "../../components/outcomes/OutcomesQuestionTypeChoice";
import { OutcomesQuestionTypeText } from "../../components/outcomes/OutcomesQuestionTypeText";
import { OutcomesQuestionTypeMultiChoice } from "../../components/outcomes/OutcomesQuestionTypeMultiChoice";
import { OutcomesQuestionTypeNumber } from "../../components/outcomes/OutcomesQuestionTypeNumber";
import { OutcomesEvaluationCard } from "../../components/outcomes/OutcomesEvaluationCard";
import { OutcomesQuestionGroup } from "../../components/outcomes/OutcomesQuestionGroup";

//only renders if authenticated
const OutcomesAddAssessmentContent = () => {

    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);

    const outcomesContext = useContext(OutcomesContext);

    const [loading, setLoading] = useState<boolean>(true);

    const [completeDisabled, setCompleteDisabled] = useState<boolean>(true);


    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);

    const [editLoading, setEditLoading] = useState<boolean>(false);
    const [completeLoading, setCompleteLoading] = useState<boolean>(false);

    const [completeModalActive, setCompleteModalActive] = useState<boolean>(false);


    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [outcomeQuestions, setOutcomeQuestions] = useState<OutcomesQuestion[] | null>();
    const [outcomeGroupsRaw, setOutcomeGroupsRaw] = useState<OutcomesGroup[] | null>(null);
    const [outcomeGroups, setOutcomeGroups] = useState<OutcomesGroup[] | null>(null);
    const [outcomeSelectedGroup, setOutcomeSelectedGroup] = useState<number | null>(null);

    const [outcomeEvaluationObject, setOutcomeEvaluationObject] = useState<OutcomeEvaluation | null>(null);


    function LoadEvaluation() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Outcomes/GetOutcomeEvaluation?evalID=" + outcomesContext.selectedEvaluationForEdit?.outcomesEvaluationsId, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("setting:");

                        const outcomesEvaluationTemp: OutcomeEvaluation | null = result;

                        if (outcomesEvaluationTemp) {

                            var tempNullables: OutcomeEvaluationAnswer[] = [];

                            //create nullables
                            outcomesEvaluationTemp.answers.filter(x => x.value == null).forEach(z => {
                                const tempnullable: OutcomeEvaluationAnswer = {
                                    questionID: z.questionID,
                                    value: z.value == null ? "True" : "False",
                                };

                                tempNullables.push(tempnullable);
                            })

                            setOutcomeEvaluationObject({
                                answers: outcomesEvaluationTemp.answers,
                                nullables: tempNullables,
                                validations: [],
                                evaluation: outcomesEvaluationTemp.evaluation,
                            })

                            UpdateQuestions();
                        }

                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Outcomes/GetOutcomeEvaluation" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function SaveOutcome() {

        setSaveLoading(true);

        var outcomesEvaluationObjectFinal = outcomeEvaluationObject;

        if (outcomesEvaluationObjectFinal) {

            //update date updated
            outcomesEvaluationObjectFinal.evaluation.dateUpdated = new Date();

            //trim all answers
            outcomesEvaluationObjectFinal.answers.forEach(x => {
                if (x.value && typeof x.value === 'string') {
                    x.value = x.value.trim();
                }
            })

            console.log("outcomesEvaluationObjectFinal:", outcomesEvaluationObjectFinal);
           

            globalContext.GetToken().then((token: any) => {

                //add log to database
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(outcomesEvaluationObjectFinal)
                };

                var url = apiConfig.apiEndpoint + "/Outcomes/InsertEvaluation";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                setSaveLoading(false);
                                setSuccessModalActive(true);
                            }
                            else {
                                setSaveLoading(false);
                                globalContext.setErrorModalProps({ errorMessage: "Failed to save evaluation. Unknown error.", errorLocation: "/Outcomes/InsertEvaluation" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            setSaveLoading(false);
                            setErrorMessage(error);
                            setErrorModalActive(true);
                        }
                    )

            })
        }
        
    }

    function EditOutcome(isComplete: boolean) {

        setEditLoading(true);
        setCompleteLoading(true);

        var outcomesEvaluationObjectFinal = outcomeEvaluationObject;

        if (outcomesEvaluationObjectFinal) {

            //update date updated
            outcomesEvaluationObjectFinal.evaluation.dateUpdated = new Date();

            //trim all answers
            outcomesEvaluationObjectFinal.answers.forEach(x => {
                if (x.value && typeof x.value === 'string') {
                    x.value = x.value.trim();
                }
            })


            //completion
            if (isComplete) {
                outcomesEvaluationObjectFinal.evaluation.dateCompleted = new Date();
            }

            console.log("outcomesEvaluationObjectFinal:", outcomesEvaluationObjectFinal);


            globalContext.GetToken().then((token: any) => {

                //add log to database
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(outcomesEvaluationObjectFinal)
                };

                var url = apiConfig.apiEndpoint + "/Outcomes/UpdateEvaluation";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                setEditLoading(false);
                                setCompleteLoading(false);
                                setSuccessModalActive(true);
                            }
                            else {
                                setEditLoading(false);
                                setCompleteLoading(false);
                                if (isComplete) {
                                    globalContext.setErrorModalProps({ errorMessage: "Failed to complete evaluation. Unknown error.", errorLocation: "/Outcomes/UpdateEvaluation" });
                                }
                                else {
                                    globalContext.setErrorModalProps({ errorMessage: "Failed to edit evaluation. Unknown error.", errorLocation: "/Outcomes/UpdateEvaluation" });
                                }
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            setEditLoading(false);
                            setCompleteLoading(false);
                            setErrorMessage(error);
                            setErrorModalActive(true);
                        }
                    )

            })
        }

    }

    function ProfilePictureOnCLose() {
        setSelectedClientForModal(null);
    }

    function SetValidations(question: OutcomesQuestion, isValid: boolean) {
        if (outcomeEvaluationObject && outcomeEvaluationObject.validations) {
            var tempvalidations = outcomeEvaluationObject.validations;

            var validationfound = tempvalidations.find(x => x.questionID == question.question.questionId);

            if (validationfound) {
                validationfound.value = isValid ? 'True' : 'False';

                setOutcomeEvaluationObject({ ...outcomeEvaluationObject, validations: tempvalidations });
            }
            else {
                const tempvalidation: OutcomeEvaluationAnswer = {
                    questionID: question.question.questionId,
                    value: isValid ? "True" : "False",
                };

                tempvalidations.push(tempvalidation);

                setOutcomeEvaluationObject({ ...outcomeEvaluationObject, validations: tempvalidations });
            }
        }
    }


    const onChange = useCallback((question: OutcomesQuestion, answers: OutcomeEvaluationAnswer[]) => {

        if (outcomeEvaluationObject && outcomeEvaluationObject.answers) {

            var NewAnswers: OutcomeEvaluationAnswer[] = outcomeEvaluationObject?.answers;

            //delete existing answers with same question id
            NewAnswers = NewAnswers.filter(x => x.questionID != question.question.questionId);

            //add answers back
            answers.forEach(x => {

                const tempanswer: OutcomeEvaluationAnswer = {
                    questionID: x.questionID,
                    value: x.value,
                };
                NewAnswers.push(tempanswer);
            });

            setOutcomeEvaluationObject({ ...outcomeEvaluationObject, answers: NewAnswers });
        }
        
        
    }, [outcomeEvaluationObject]);



    useEffect(() => {
        console.log("outcomeEvaluationObject:", outcomeEvaluationObject);

        if (outcomeEvaluationObject?.evaluation.dateCompleted != null) {
            setCompleteDisabled(true);
            return;
        }

        if (outcomeQuestions) {

            var tempCompleteDisabled: boolean = false;
            var tempVinelandQuestions = outcomeQuestions.filter(x => x.question.questionType == "vineland");


            outcomeQuestions.forEach(question => {

                var answerFound = outcomeEvaluationObject?.answers.find(answer => answer.questionID == question.question.questionId);

                if (!answerFound || answerFound.value == null || answerFound.value == "") {
                    var nullableFound = outcomeEvaluationObject?.nullables.find(x => x.questionID == question.question.questionId);

                    if (nullableFound?.value != "True") {

                        tempCompleteDisabled = true;
                    }
                }
                else if (tempVinelandQuestions && tempVinelandQuestions.find(x => x.question.questionId == answerFound?.questionID)) {
                    //if answer is a vineland answer, validate answer
                    const pattern: RegExp = /^(>|<)?(\d+)(?::(\d+))?\+?$/;
                    const isMatch: boolean = pattern.test(answerFound.value);

                    if (!isMatch) {
                        tempCompleteDisabled = true;
                    }
                }
                
            })

            setCompleteDisabled(tempCompleteDisabled);
        }

    }, [outcomeEvaluationObject?.answers, outcomeEvaluationObject?.nullables, outcomeQuestions])

    useEffect(() => {
        console.log("complete disabled:", completeDisabled);

    }, [completeDisabled])

    function NavigateBack() {
        navigate(-1);
    }

    useEffect(() => {
        if (outcomesContext.selectedEvaluationForEdit) {
            console.log("edit");
            LoadEvaluation();
        }
        else {
            console.log("add");
            UpdateQuestions();
        }
    }, []);

    useEffect(() => {
        if (!outcomesContext.selectedClient) {
            navigate("/outcomes");
        }
    })

    useEffect(() => {
        if (outcomeQuestions && outcomeGroupsRaw) {
            var uniqueGroups = new Set(outcomeQuestions.map(obj => obj.question.groupId));

            var newGroups: OutcomesGroup[] = []

            uniqueGroups.forEach(x => {

                var GroupFound = outcomeGroupsRaw.find(z => z.groupId == x);

                const newGroup: OutcomesGroup = {
                    groupId: x,
                    groupText: GroupFound ? GroupFound.groupText : "Error",
                    outcomesQuestions: outcomeQuestions.filter(z => z.question.groupId == x),
                };

                newGroups.push(newGroup);
            })

            setOutcomeSelectedGroup(newGroups[0].groupId);

            setOutcomeGroups(newGroups);
        }

    }, [outcomeQuestions, outcomeGroupsRaw])

    useEffect(() => {
        if (!outcomeEvaluationObject) {
            ResetEvaluationObject();
        }
    }, [])

    function ResetEvaluationObject() {

        if (outcomesContext.selectedEvaluationType && outcomesContext.selectedClient && outcomesContext.selectedClient.clientId) {
            const outcomesEvaluationTemp: OutcomesEvaluation = {
                outcomesEvaluationsId: null,
                evaluationsTypeId: outcomesContext.selectedEvaluationType,
                dateCreated: new Date(),
                dateUpdated: new Date(),
                dateCompleted: null,
                clientId: outcomesContext.selectedClient.clientId,
                sourceTypeId: 1,
            };

            setOutcomeEvaluationObject({
                answers: [],
                nullables: [],
                validations: [],
                evaluation: outcomesEvaluationTemp,
            })
        }
    }

    function UpdateQuestions() {

        if (outcomesContext.selectedEvaluationType) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Outcomes/GetQuestions?evaluationTypeID=" + outcomesContext.selectedEvaluationType, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setOutcomeQuestions(result);
                            UpdateOutcomeGroups();
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Outcomes/GetQuestions" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function UpdateOutcomeGroups() {
        if (outcomesContext.selectedEvaluationType) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Outcomes/GetOutcomesQuestionGroups", options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setOutcomeGroupsRaw(result);
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Outcomes/GetOutcomesQuestionGroups" });
                            globalContext.setErrorModalActive(true);
                        }
                )
            })
        }
    }


    function GetMultiChoiceSelectedOptions(question: OutcomesQuestion): ChoiceOption[] | null {

        var temp: OutcomeEvaluationAnswer[] | undefined = outcomeEvaluationObject?.answers.filter(
            (x) => x.questionID == question.question.questionId
        );

        if (temp && temp.length > 0) {
            var selected: ChoiceOption[] = question.choices.filter(x => temp?.find(z => z.value == x.value));
            return selected ? selected : null; // Return temp if it exists, otherwise return null or handle the case according to your requirements
        }

        return null;
    }

    function ChildMultiOnChange(input: MultiValue<ChoiceOption>, question: OutcomesQuestion) {

        if (outcomeEvaluationObject && outcomeEvaluationObject.answers && input) {

            var answers: OutcomeEvaluationAnswer[] = [];

            input.forEach(x => {

                const answer: OutcomeEvaluationAnswer = {
                    questionID: question.question.questionId,
                    value: x.value,
                };

                answers.push(answer);
            })

            onChange(question, answers);
        }
    }

    function ChildChoiceOnChange(input: SingleValue<ChoiceOption>, question: OutcomesQuestion) {

        if (outcomeEvaluationObject && outcomeEvaluationObject.answers && input) {

            var answers: OutcomeEvaluationAnswer[] = [];

            const answer: OutcomeEvaluationAnswer = {
                questionID: question.question.questionId,
                value: input.value,
            };

            answers.push(answer);
                

            onChange(question, answers);
        }
    }

    function GetText(OutcomeQuestion: OutcomesQuestion): string {
        var temp: OutcomeEvaluationAnswer | undefined = outcomeEvaluationObject?.answers.find(
            (x) => x.questionID == OutcomeQuestion.question.questionId
        );
        if (temp && temp.value) {
            return temp.value;
        }
        else {
            return "";
        }

    }

    function GetValidation(OutcomeQuestion: OutcomesQuestion): boolean {
        var temp: OutcomeEvaluationAnswer | undefined = outcomeEvaluationObject?.validations.find(
            (x) => x.questionID == OutcomeQuestion.question.questionId
        );

        if (temp) {
            return temp.value == "True";
        }
        else {
            return true;
        }
    }


    function ChildOnChange(input: string, OutcomesQuestion: OutcomesQuestion) {

        if (outcomeEvaluationObject && outcomeEvaluationObject.answers && OutcomesQuestion) {

            var answers: OutcomeEvaluationAnswer[] = [];

            const tempanswer: OutcomeEvaluationAnswer = {
                questionID: OutcomesQuestion.question.questionId,
                value: input ? input : "",
            };

            answers.push(tempanswer);

            //if input is null, clear it out
            if (!input) {
                answers = answers.filter(x => x.questionID != OutcomesQuestion.question.questionId);
            }

            onChange(OutcomesQuestion, answers);
            
        }
    }

    function GetSelectedOption(OutcomesQuestion: OutcomesQuestion): ChoiceOption | null {
        var temp = outcomeEvaluationObject?.answers.find(
            (x) => x.questionID == OutcomesQuestion.question.questionId
        );

        if (temp) {
            var selected = OutcomesQuestion.choices.find(x => x.value == temp?.value);
            return selected ? selected : null; // Return temp if it exists, otherwise return null or handle the case according to your requirements

        }

        return null;
    }

    function NullQuestion(OutcomesQuestion: OutcomesQuestion, nulled: boolean) {

        if (outcomeEvaluationObject) {

            if (nulled) {
                //null 

                //add null to nullables
                var nullables = outcomeEvaluationObject.nullables.filter(x => x.questionID != OutcomesQuestion.question.questionId);
                const tempnullable: OutcomeEvaluationAnswer = {
                    questionID: OutcomesQuestion.question.questionId,
                    value: "True",
                };

                nullables.push(tempnullable);

                //add null to answers
                var NewAnswers = outcomeEvaluationObject.answers.filter(x => x.questionID != OutcomesQuestion.question.questionId);
                const tempanswer: OutcomeEvaluationAnswer = {
                    questionID: OutcomesQuestion.question.questionId,
                    value: null,
                };

                NewAnswers.push(tempanswer);

                if (NewAnswers) {
                    setOutcomeEvaluationObject({ ...outcomeEvaluationObject, answers: NewAnswers, nullables: nullables });
                }
            }
            else {
                //not null
                var nullables = outcomeEvaluationObject.nullables.filter(x => x.questionID != OutcomesQuestion.question.questionId);
                var NewAnswers = outcomeEvaluationObject.answers.filter(x => x.questionID != OutcomesQuestion.question.questionId);
                setOutcomeEvaluationObject({ ...outcomeEvaluationObject, answers: NewAnswers, nullables: nullables });
            }
        }
    }

    function GetNullValue(OutcomesQuestion: OutcomesQuestion): boolean {
        var nullable = outcomeEvaluationObject?.nullables.find(x => x.questionID == OutcomesQuestion?.question.questionId);

        if (nullable && nullable.value == "True") {
            return true;
        }
        else {
            return false;
        }
        
    }

    function CallEditEvaluation() {
        EditOutcome(false);
    }

    function CallCompleteEvaluation() {
        EditOutcome(true);
    }

    const AddOutcomeButton = () => {
        if (!outcomesContext.selectedEvaluationForEdit) {
            //add
            if (saveLoading) {
                return (
                    <button disabled={true} className="m-auto mt-4 w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacblue-600">
                        <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                        <span className="drop-shadow align-middle pl-1">Processing</span>
                    </button>
                );
            }
            else {
                return (<button onClick={SaveOutcome} className={"bg-lacblue m-auto mt-4 w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"}><span className="drop-shadow">Save</span></button>)
            }
        }
        else {
            //edit
            if (editLoading) {
                return (
                    <button disabled={true} className="m-auto mt-4 w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacblue-600">
                        <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                        <span className="drop-shadow align-middle pl-1">Processing</span>
                    </button>
                );
            }
            else {
                return (<button onClick={CallEditEvaluation} className={"bg-lacblue m-auto mt-4 w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"}><span className="drop-shadow">Save</span></button>)
            }
        }

        
    }

    const CompleteOutcomeButton = () => {

        if (outcomeEvaluationObject?.evaluation.dateCompleted != null) {
            return <div></div>;
        }

        if (completeLoading) {
            return (
                <button disabled={true} className="m-auto mt-4 w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacgreen-600">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else if (completeDisabled) {
            return (<button disabled={true} className={"bg-lacgreen m-auto mt-4 w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out bg-lacgreen-600"}><span className="drop-shadow">Complete</span></button>)
        }
        else {
            return (<button onClick={() => setCompleteModalActive(true)} className={"bg-lacgreen m-auto mt-4 w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"}><span className="drop-shadow">Complete</span></button>)

        }
    }

    function SuccessModalClose() {
        console.log("navigate back");
        NavigateBack();
    }

    if (outcomesContext.selectedClient && outcomeQuestions && outcomeQuestions.length > 0) {

        return (
            <div className="ml-auto mr-auto p-2">
                <div className="grid grid-cols-2 h-fit mt-0 mb-auto">

                    <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-40 cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back</span>
                        <div className="flex-1"></div>
                    </div>

                    <ProfilePicture isEditable={false} setProfilePictureModalActive={setProfilePictureModalActive} Client={outcomesContext.selectedClient} setSelectedClientForModal={setSelectedClientForModal} />

                    <table>
                        <tbody>
                            <tr>
                                <td><span className="font-bold">Learner Name:</span></td>
                                <td><span className="text-bold ml-2">{outcomesContext.selectedClient.fullName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Birthday:</span></td>
                                <td><span className="text-bold ml-2">{new Date(outcomesContext.selectedClient.dob).toLocaleDateString()}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Insurance Provider:</span></td>
                                <td><span className="text-bold ml-2">{outcomesContext.selectedClient.insuranceName}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

               

                <div>
                    {outcomeGroups && outcomeGroups.map((OutcomeGroup, i) => {
                        return (
                            OutcomesQuestionGroup({
                                OutcomeGroup: OutcomeGroup,
                                OutcomeEvaluation: outcomeEvaluationObject,
                                setOutcomeEvaluationObject: setOutcomeEvaluationObject,
                                ChildChoiceOnChange: ChildChoiceOnChange,
                                GetSelectedOption: GetSelectedOption,
                                NullQuestion: NullQuestion,
                                OutcomeSelectedGroup: outcomeSelectedGroup,
                                SetOutcomeSelectedGroup: setOutcomeSelectedGroup,
                                GetMultiChoiceSelectedOptions: GetMultiChoiceSelectedOptions,
                                onChange: onchange,
                                ChildMultiOnChange: ChildMultiOnChange,
                                ChildOnChange: ChildOnChange,
                                GetText: GetText,
                                SetValidations: SetValidations,
                                GetValidation: GetValidation,
                                GetNullValue: GetNullValue,
                            })

                        )
                                
                    })}

                    
                   
                </div>

                <div className="w-full col-span-12 grid xl:grid-cols-3 grid-cols-1 justify-between mt-4">
                    <button onClick={NavigateBack} className="bg-rose m-auto mt-4 w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 flex-grow-0">
                        <span className="drop-shadow">Cancel</span>
                    </button>

                    <CompleteOutcomeButton/>

                    <AddOutcomeButton />
                </div>

                <ModalNotification Title={"Success!"} Body={"Evaluation for " + outcomesContext.selectedClient.fullName + " successfully saved."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={SuccessModalClose} />
                <ModalNotification Title={"Error!"} Body={"Contact IT if this error persists. Error Message: " + errorMessage} Active={errorModalActive} Icon={"Warning"} setActive={setErrorModalActive} OnModalClose={function noop() {} } />
                <ProfilePictureModal Client={selectedClientForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose} />
                <ModalConfirm Active={completeModalActive} setActive={setCompleteModalActive} Message={"Are you sure you want to complete this evaluation? This cannot be undone."} OnConfirm={CallCompleteEvaluation} />
            </div>

            

        );
    }
    else {

        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }
    
};



export function OutcomesAddAssessment() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['Outcomes.Read', 'Outcomes.Write']}>
                    <OutcomesAddAssessmentContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
      )
};