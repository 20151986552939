// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { JSXElementConstructor, ReactElement, SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EDPNContext } from "./EDPNContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Center, EDPNQuestion, EDPNAnswerObject, EDPNSet, Therapist, TherapistOption, ApprovalMetrics, APIReturnObject, Status, OptimizedECLEDPN } from "../../utils/Definitions";
import { EDPNViewLogCard } from "../../components/edpn/EDPNViewLogCard";
import { ModalNotification } from "../../components/ModalNotification";
import ProtectedPage from "../../utils/ProtectedPage";
import ProtectedComponent from "../../utils/ProtectedComponent";
import Select, { MultiValue } from "react-select";
import { EDPNApprovalCard } from "../../components/edpn/EDPNApprovalCard";
import { EDPNApprovalList } from "../../components/edpn/EDPNApprovalList";
import { EDPNApprovalListCard } from "../../components/edpn/EDPNApprovalListCard"
import { ValueContainer } from "../../components/ValueContainer";
import { ModalConfirm } from "../../components/ModalConfirm";
import { EDPNLogsList } from "../../components/edpn/EDPNLogsList";
import { isAfter, set, sub, format } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, TextFieldProps } from "@mui/material";
import { zonedTimeToUtc } from "date-fns-tz";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


//only renders if authenticated
const EDPNApprovals2Content = () => {


    const navigate = useNavigate();

    const edpnContext = useContext(EDPNContext);
    const globalContext = useContext(GlobalContext);

    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [logsLoading, setLogsLoading] = useState<boolean>(true);


    const [unapprovedLogs, setUnapprovedLogs] = useState<EDPNAnswerObject[]>([]);

    const [therapists, setTherapists] = useState<Therapist[] | null>(null);

    const [confirmModalActiveLogs, setConfirmModalActiveLogs] = useState<boolean>(false);
    const [confirmModalMessageLogs, setConfirmModalMessageLogs] = useState<string>("");


    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [approveModalActive, setApproveModalActive] = useState<boolean>(false);
    const [approveMessage, setApproveMessage] = useState<string>("");

    const [logToApprove, setLogToApprove] = useState<EDPNAnswerObject | null>(null);
    const [logsToApprove, setLogsToApprove] = useState<EDPNAnswerObject[] | null>(null);

    const [confirmDeleteModalActive, setConfirmDeleteModalActive] = useState<boolean>(false);
    const [confirmDeleteModalMessage, setConfirmDeleteModalMessage] = useState<string>("");
    const [logToDelete, setLogToDelete] = useState<EDPNAnswerObject | null>(null);


    const [showImportedHistory, setShowImportedHistory] = useState<boolean>(false);

    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);

    const [medicaidCheckModalActive, setMedicaidCheckModalActive] = useState<boolean>(false);

    const [importedHistoryDate, setImportedHistoryDate] = useState<Date | null>(new Date());

    const [approvalsCount, setApprovalsCount] = useState<number>(0);
    const [approvalsSelectedCount, setApprovalsSelectedCount] = useState<number>(0)

    const [selectedApprovalsList, setSelectedApprovalsList] = useState<EDPNAnswerObject[]>([]);

    const [logPreviewActive, setLogPreviewActive] = useState<boolean>(false);
    const [hoveredLog, setHoveredLog] = useState<EDPNAnswerObject | null>(null);

    const [questions, setQuestions] = useState<EDPNQuestion[]>();

    const [approvalMetrics, setApprovalMetrics] = useState<ApprovalMetrics | null>(null);

    const [confirmUnapproveModalActive, setConfirmUnapproveModalActive] = useState<boolean>(false);
    const [confirmUnapproveModalMessage, setConfirmUnapproveModalMessage] = useState<string>("");

    function SelectRow(log: EDPNAnswerObject, isChecked: boolean) {
        if (isChecked) {
            setSelectedApprovalsList(prevList => {
                // Use the spread operator to create a new array with the additional item
                const newList = [...prevList, log];
                return newList;
            });
        } else {
            // Handle the case where the item is unchecked
            setSelectedApprovalsList(prevList => prevList.filter(item => item.logId !== log.logId));
        }
    }

    function SelectRows(logs: EDPNAnswerObject[]) {
        // Check if any logs don't already exist in setSelectedApprovalsList
        const logsToAdd = logs.filter((log) => !selectedApprovalsList.includes(log));

        if (logsToAdd.length > 0) {
            //add all logs to setSelectedApprovalsList
            setSelectedApprovalsList((prevList) => [...prevList, ...logsToAdd]);
        }
        else {
            //remove all logs to setSelectedApprovalsList
            const updatedList = selectedApprovalsList.filter((log) => !logs.includes(log));
            setSelectedApprovalsList(updatedList);
        }
    }

    // Update the selected approvals count
    useEffect(() => {

        setApprovalsSelectedCount(selectedApprovalsList.length);
    }, [selectedApprovalsList, unapprovedLogs]);

    function NavigateBack() {
        navigate(-1);
    }

    function OnClickDeleteLog(EDPNLog: EDPNAnswerObject) {
        setLogToDelete(EDPNLog);
        setConfirmDeleteModalMessage("<div>Are you sure you want to delete the log for:</div><div>" + EDPNLog.clientName + " on " + (EDPNLog.billingDate ? new Date(EDPNLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmDeleteModalActive(true);
    }

    function UpdateQuestions() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Admin/GetQuestions?app=DPN";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setQuestions(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetQuestions" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function DeleteLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToDelete) {
                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/SoftDeleteLog?id=" + logToDelete.logId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            if (result.status === Status.SUCCESS) {
                                setLogsLoading(true);
                                UpdateLogsByCenter();
                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setNotificationModalMessage("Cannot delete log. " + result.message);
                                globalContext.setNotificationModalActive(true);
                                setLogsLoading(true);
                                UpdateLogsByCenter();
                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to delete log. " + result.message, errorLocation: "/EDPN/ApproveLog" });
                                globalContext.setErrorModalActive(true);
                                setLogsLoading(true);
                                UpdateLogsByCenter();
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/SoftDeleteLog" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })

    }

    function OnClickApproveAllLogs() {
        var tempLogsToApprove: EDPNAnswerObject[] = unapprovedLogs.filter(x => (x.dpnstatusId == 4 || x.dpnstatusId == 7));

        var tempMessage: string = "<div>Are you sure you want to approve the following logs?</div>";
        if (tempLogsToApprove.length > 0) {
            tempLogsToApprove.forEach((log) => {
                tempMessage += "<div>" + log.clientName + " on " + (log.billingDate ? new Date(log.billingDate).toLocaleDateString() : "") + "</div>";
            })
        }
        else {
            tempMessage = "<div>No approveable logs detected.</div>";
        }

        setLogsToApprove(tempLogsToApprove);
        setConfirmModalMessageLogs(tempMessage);
        setConfirmModalActiveLogs(true);
        
    }

    function OnClickApproveSelectedLogs() {

        var tempMessage: string = "<div>Are you sure you want to approve the following logs?</div>";
        if (selectedApprovalsList.length > 0) {
            selectedApprovalsList.forEach((log) => {
                tempMessage += "<div>" + log.clientName + " on " + (log.billingDate ? new Date(log.billingDate).toLocaleDateString() : "") + "</div>";
            })
        }
        else {
            tempMessage = "<div>No approveable logs detected.</div>";
        }

        setLogsToApprove(selectedApprovalsList);
        setConfirmModalMessageLogs(tempMessage);
        setConfirmModalActiveLogs(true);
    }

    async function ApprovalValid(token: string, edpnanswerobjects: EDPNAnswerObject[] | null) {

        if (edpnanswerobjects && edpnanswerobjects.length > 0) {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(edpnanswerobjects)
            };

            const response = await fetch(apiConfig.apiEndpoint + "/EDPN/ApprovalLogCheck", options);
            var errorText: string = await response.json();

            if (errorText.length > 0) {
                //error!
                setApproveMessage(errorText);
                setApproveModalActive(true);

                return errorText;
            }
            else {
                //no error, continue
                return errorText;
            }
        }
        else {
            return "";
        }
    }


    function ApproveLogs() {
        globalContext.GetToken().then((token: any) => {
            if (logsToApprove) {

                //check if approval is valid
                ApprovalValid(token, logsToApprove).then((errorMessage) => {

                    if (errorMessage.length == 0) {
                        //add log to database
                        var headers = new Headers();
                        var bearer = "Bearer " + token;
                        headers.append("Authorization", bearer);
                        headers.append("Content-type", "application/json;charset=UTF-8");

                        var logsid = logsToApprove.map(x => x.logId).join(',');

                        var options = {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify(logsid)
                        };

                        var url = apiConfig.apiEndpoint + "/EDPN/ApproveLogs";

                        fetch(url, options)
                            .then(res => res.json())
                            .then(
                                (result: APIReturnObject) => {
                                    if (result.status === Status.SUCCESS) {
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                        setApprovalsSelectedCount(0);
                                    }
                                    else if (result.status === Status.FAIL) {
                                        globalContext.setNotificationModalMessage("Cannot approve. " + result.message);
                                        globalContext.setNotificationModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                        setApprovalsSelectedCount(0);
                                    }
                                    else if (result.status === Status.ERROR) {
                                        globalContext.setErrorModalProps({ errorMessage: "Failed to approve. " + result.message, errorLocation: "/EDPN/ApproveLogs" });
                                        globalContext.setErrorModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                        setApprovalsSelectedCount(0);
                                    }
                                },
                                (error) => {
                                    globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/ApproveLogs" });
                                    globalContext.setErrorModalActive(true);
                                }
                            )
                    }
                })
            }

        })
    }

    function SetApprovalCount() {
        var numberOfApprovals = unapprovedLogs.filter(x => (x.dpnstatusId == 4) &&
            !(x.cptcode == '97151' && x.isMimedicaid == 1) &&
            x.showApproveButton).length;

        setApprovalsCount(numberOfApprovals);
    }

    //initial load
    useEffect(() => {
        var newList: EDPNAnswerObject[] = [];
        var templist = selectedApprovalsList;
        templist.forEach(x => {
            var temp = unapprovedLogs.find(z => z.logId == x.logId);
            if (temp && (temp.dpnstatusId == 4 || temp.dpnstatusId == 7)) {
                newList.push(temp);
            }
        })

        setSelectedApprovalsList(newList);

    }, [unapprovedLogs]);

    //initial load
    useEffect(() => {
            UpdateTherapists();
            UpdateQuestions();
    }, []);

    //auto select center
    useEffect(() => {
        //select first center if there is no selected center
        if (globalContext.lockdownCenters && globalContext.lockdownCenters.length > 0 && !edpnContext.selectedCenter) {

            //attempt to default to user's center if they have one
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.centerID && globalContext.CoreAccount.currentUser.centerID != null) {
                var center = globalContext.lockdownCenters.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.centerId == globalContext.CoreAccount.currentUser.centerID)
                if (center) {
                    edpnContext.setSelectedCenter(center);
                }
                else {
                    //if user doesnt have a center, select first index
                    edpnContext.setSelectedCenter(globalContext.lockdownCenters[0]);
                }
            }
            else {
                //if user doesnt have a center, select first index
                edpnContext.setSelectedCenter(globalContext.lockdownCenters[0]);
            }

        }

    }, [globalContext.lockdownCenters]);

    //upon center choice change, update logs
    useEffect(() => {
        if (edpnContext.selectedCenter && importedHistoryDate) {
            setLogsLoading(true);
            UpdateLogsByCenter();
            UpdateMetrics();
        }
    }, [edpnContext.selectedCenter, showImportedHistory, importedHistoryDate]);

    //upon log change, set new therapist options
    useEffect(() => {

        if (unapprovedLogs) {
            SetApprovalCount();
        }
    }, [unapprovedLogs, approvalsCount]);

    // AUTO REFRESH EVERY MINUTE
    useEffect(() => {
        const intervalId = setInterval(() => {
            UpdateLogsByCenter();
            UpdateMetrics();
            
        }, (60 * 1000)); // 60 seconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    });


    useEffect(() => {
        setLogsLoading(false);
    }, [unapprovedLogs]);

    function UpdateLogsByCenter() {

        globalContext.GetToken().then((token: any) => {
            if (edpnContext.selectedCenter && edpnContext.selectedCenter.centerId && token) {

                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/GetLogsByCenter?centerid=" + edpnContext.selectedCenter.centerId + "&imported=" + showImportedHistory + "&monthday=" + importedHistoryDate?.toDateString();

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: OptimizedECLEDPN) => {
                            const temp: EDPNAnswerObject[] = [];

                            //map comments to logs
                            if (result && result.logs) {
                                result.logs.forEach((log) => {
                                    if (result.comments && log.billingDate) {

                                        log.comments = result.comments.filter((x) => {
                                            const sameClient = x.clientId === log.clientId;

                                            const xBillingDate = x.billingDate ? new Date(x.billingDate) : null;
                                            const logBillingDate = log.billingDate ? new Date(log.billingDate) : null;

                                            const sameBillingDate =
                                                xBillingDate &&
                                                logBillingDate &&
                                                xBillingDate.getFullYear() === logBillingDate.getFullYear() &&
                                                xBillingDate.getMonth() === logBillingDate.getMonth() &&
                                                xBillingDate.getDate() === logBillingDate.getDate();

                                            return sameClient && sameBillingDate;
                                        });
                                    }

                                    temp.push(log);
                                });
                            }

                            setUnapprovedLogs(temp);
                            setPageLoading(false);
                        },
                        (error) => {
                            setPageLoading(false);
                            setLogsLoading(false);
                            //globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetLogsByCenter" });
                            //globalContext.setErrorModalActive(true);
                        }
                )
            }

        })
    }

    function UpdateMetrics() {
        globalContext.GetToken().then((token: any) => {
            if (edpnContext.selectedCenter && edpnContext.selectedCenter.centerId && token) {

                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/GetApprovalsPageMetrics?centerid=" + edpnContext.selectedCenter.centerId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setApprovalMetrics(result);
                        },
                        (error) => {
                            setPageLoading(false);
                            setLogsLoading(false);
                            //globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetLogsByCenter" });
                            //globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })
    }

    function UpdateTherapists() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Centers/GetAllTherapists";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setTherapists(result);
                    },
                    (error) => {
                        setPageLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetAllTherapists" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function NavigateToLogs() {
        navigate("/edpn");
    }

    function WarnUserMissingCredentials() {
        setCredentialsModalActive(true);
    }

    function OnClickUnapproveLog(EDPNLog: EDPNAnswerObject) {
        setLogToApprove(EDPNLog);
        setConfirmUnapproveModalMessage("<div>Are you sure you want to Unapprove the log for:</div><div>" + EDPNLog.clientName + " on " + (EDPNLog.billingDate ? new Date(EDPNLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmUnapproveModalActive(true);
    }

    function UnapproveLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToApprove) {


                if (errorMessage.length == 0) {
                    var headers = new Headers();

                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    var options = {
                        method: "GET",
                        headers: headers
                    };

                    var url = apiConfig.apiEndpoint + "/EDPN/UnapproveLog?logid=" + logToApprove.logId;

                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result: APIReturnObject) => {
                                if (result.status === Status.SUCCESS) {
                                    setLogsLoading(true);
                                    UpdateLogsByCenter();
                                }
                                else if (result.status === Status.FAIL) {
                                    globalContext.setNotificationModalMessage("Cannot unapprove log. " + result.message);
                                    globalContext.setNotificationModalActive(true);
                                    setLogsLoading(true);
                                    UpdateLogsByCenter();
                                }
                                else if (result.status === Status.ERROR) {
                                    globalContext.setErrorModalProps({ errorMessage: "Failed to unapprove log. " + result.message, errorLocation: "/EDPN/ApproveLog" });
                                    globalContext.setErrorModalActive(true);
                                    setLogsLoading(true);
                                    UpdateLogsByCenter();
                                }
                            },
                            (error) => {
                                globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/UnapproveLog" });
                                globalContext.setErrorModalActive(true);
                            }

                        )
                }

            }

        })

    }

    const ApproveAllButton = () => {
        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData.signatureImage) {
            return (

                <button onClick={OnClickApproveAllLogs} className="bg-lacgreen ml-auto my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve All</span></button>
            )
        }
        else {
            return (
                <button onClick={WarnUserMissingCredentials} className="bg-lacgreen ml-auto my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve All</span></button>
            )
        }
    }

    const ApproveSelectedButton = () => {
        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData.signatureImage) {
            return (
                <button onClick={OnClickApproveSelectedLogs} className="bg-purple mx-5 ml-auto my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"><span className="drop-shadow">Approve Selected ({approvalsSelectedCount})</span></button>
            )
        }
        else {
            return (
                <button onClick={WarnUserMissingCredentials} className="bg-purple mx-5 ml-auto my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"><span className="drop-shadow">Approve Selected ({approvalsSelectedCount})</span></button>
            )
        }
    }

    const LogPreviewContent = () => {
        if (hoveredLog) {
            return (

                <div className={"z-[100] relative " + (logPreviewActive ? "normal" : "hidden")} aria-labelledby="modal-title" role="dialog" aria-modal="true">

                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <div className="relative transform overflow-hidden rounded-lg border-2 border-lacblue text-left shadow-xl transition-all sm:max-w-[xl] sm:max-h-[4xl]">
                                <div className="bg-gray-100 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <h3 className="text-xl text-lacblue font-medium leading-6" id="modal-title">Log Preview</h3>
                                            <div className="mt-2 grid">
                                                <div className="grid grid-cols-2">
                                                    <div className="mx-5">
                                                        <label className="block font-bold">Learner: </label>
                                                        <span>
                                                            {hoveredLog.clientName}
                                                        </span>
                                                    </div>

                                                    <div className="mx-5">
                                                        <label className="block font-bold">Date of Service: </label>
                                                        <span>
                                                            {hoveredLog.billingDate && hoveredLog.billingDate ? new Date(hoveredLog.billingDate).toLocaleDateString() : "N/A"}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-2">
                                                    <div className="mx-5">
                                                        <label className="block font-bold">Service Type: </label>
                                                        <span>
                                                            {hoveredLog.cptcode ? edpnContext.CPTCodes?.filter(x => x.value == hoveredLog.cptcode?.toString()).find(y => y.label)?.label : "N/A"}
                                                        </span>
                                                    </div>

                                                    <div className="mx-5">
                                                        <label className="block font-bold">Place of Service: </label>
                                                        <span>
                                                            {hoveredLog.placeOfServiceId ? edpnContext.servicePlaces?.filter(x => x.value == hoveredLog.placeOfServiceId?.toString()).find(y => y.label)?.label : "N/A"}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-3">
                                                    <div className="mx-5">
                                                        <label className="block font-bold">Therapist: </label>
                                                        <span>
                                                            {hoveredLog.therapistId ? globalContext.therapists.filter(x => x.therapistId == hoveredLog.therapistId).find(y => y.employeeName)?.employeeName : "N/A"}
                                                        </span>
                                                    </div>

                                                    <div className="mx-5">
                                                        <label className="block font-bold">Start: </label>
                                                        <span>
                                                            {hoveredLog.startTime ? new Date(hoveredLog.startTime).toLocaleTimeString() : "N/A"}
                                                        </span>
                                                    </div>

                                                    <div className="mx-5">
                                                        <label className="block font-bold">End: </label>
                                                        <span>
                                                            {hoveredLog.endTime ? new Date(hoveredLog.endTime).toLocaleTimeString() : "N/A"}
                                                        </span>
                                                    </div>
                                                </div>

                                                {hoveredLog.tardyReason && (
                                                    <div className="grid grid-cols-1 mt-1">
                                                        <label className="block font-bold">Hours Missed Reason:</label>
                                                        <span>
                                                            {hoveredLog.tardyReason}
                                                        </span>
                                                    </div>
                                                )}

                                                {hoveredLog.antecendant && (
                                                    <div className="grid grid-cols-1 mt-1">
                                                        <label className="block font-bold">Antecedant Strategies:</label>
                                                        <span>
                                                            {hoveredLog.antecendant}
                                                        </span>
                                                    </div>
                                                )}

                                                <div className="grid grid-cols-1 mt-1">
                                                    <label className="block font-bold">BCBA(s) Present:</label>
                                                    <span>
                                                        {hoveredLog.bcbaname == null || hoveredLog.bcbaname.length == 0 ? "No" : hoveredLog.bcbaname
                                                            .split(',') // Split the comma-separated list into an array of IDs
                                                            .map(id => {
                                                                const therapist = globalContext.therapists.find(t => String(t.therapistId) == id.trim()); // Find the therapist with matching ID
                                                                return therapist ? therapist.employeeName : null; // Return therapist name if found, otherwise null
                                                            })
                                                            .filter(name => name) // Filter out null values (in case there are unmatched IDs)
                                                            .join(', ')} {/* Join therapist names with a comma */}
                                                    </span>
                                                </div>
                                                

                                                <div className="grid grid-cols-1 mt-1">
                                                    <label className="block font-bold">Injury: </label>
                                                    <span>
                                                        {hoveredLog.injury ? 'Yes' : 'No'}
                                                    </span>
                                                </div>

                                                {hoveredLog.answers ? hoveredLog.answers.filter(x => x.questionId === 163 || x.questionId === 164).map(question => (
                                                    <div key={question.questionId} className="grid grid-cols-1 mt-1">
                                                        <label className="block font-bold">{questions && questions.filter(x => x.questionId === question.questionId).find(y => y.questionText) !== undefined ? questions.filter(x => x.questionId === question.questionId).find(y => y.questionText)?.questionText : "N/A"}</label>
                                                        <span>
                                                            {question.textValue}
                                                        </span>
                                                    </div>

                                                )) : <></>}

                                                <div className="grid grid-cols-1 mt-3">
                                                    <label className="block font-bold">Summary: </label>
                                                    <div dangerouslySetInnerHTML={{ __html: hoveredLog.narrative != null ? hoveredLog.narrative : "N/A" }} />
                                                </div>

                                                <div className="grid grid-cols-1 mt-3">
                                                    <label className="block font-bold">Comments: </label>
                                                    <table className="border-separate border-spacing-3">
                                                        <tbody>
                                                            {hoveredLog.comments && hoveredLog.comments?.filter(x => x.refId == hoveredLog.logId || x.refId == null).length > 0 ? hoveredLog.comments.filter(x => x.refId == hoveredLog.logId || x.refId == null).map(comment => <tr key={comment.dpncommentId}><td>[{comment.createdOn ? format(new Date(comment.createdOn), 'M/d/yy') : ""}] {comment.createdBy}: </td><td>{comment.comment}</td></tr>) : <></>}
                                                            {hoveredLog.comments && hoveredLog.comments?.filter(x => x.refId == hoveredLog.logId || x.refId == null).length == 0 && <tr><td className="text-rose">No Comments.</td></tr>}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        }
        else {
            return (
                <></>
            )
        }
    }

    if (unapprovedLogs && !pageLoading) {
        return (

            <div className="grid grid-rows-auto ml-auto mr-auto">

                <div className="grid grid-cols-1 h-fit mt-0 mb-8">

                    <div className="mt-4 mb-4 w-full flex">
                        <div onClick={NavigateToLogs} className="my-2 flex items-center cursor-pointer">
                            <div>
                                <FontAwesomeIcon className="w-8 h-8 mr-3" icon={faArrowLeft} />
                            </div>
                            <span className="text-xl">Back to eDPN Home</span>
                        </div>

                        <div className="ml-auto p-2 border-2 border-lacblue rounded-lg">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span></span>
                                        </td>
                                        <td className="pr-4">
                                            <span>Learners</span>
                                        </td>
                                        <td className="pr-4">
                                            <span>Absent</span>
                                        </td>
                                        <td className="pr-4">
                                            <span>Missing</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">
                                            <span>Today:</span>
                                        </td>
                                        <td className="text-right pr-4">
                                            <span>{approvalMetrics?.todayActiveLearners}</span>
                                        </td>
                                        <td className="text-right pr-4">
                                            <span>{approvalMetrics?.todayAbsent}</span>
                                        </td>
                                        <td className="text-right pr-4">
                                            <span>{approvalMetrics?.todayRemaining}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">
                                            <span>Last Business Day:</span>
                                        </td>
                                        <td className="text-right pr-4">
                                            <span>{approvalMetrics?.yesterdayActiveLearners}</span>
                                        </td>
                                        <td className="text-right pr-4">
                                            <span>{approvalMetrics?.yesterdayAbsent}</span>
                                        </td>
                                        <td className="text-right pr-4">
                                            <span>{approvalMetrics?.yesterdayRemaining}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="grid grid-flow-row">

                        <div className="grid grid-cols-1 gap-4 xl:gap-[4.5rem] content-between xl:grid-flow-col">

                            <div className="w-full w-[16rem] mr-auto pl-2">
                                <label className="block font-bold" htmlFor="centers">
                                    Center:
                                </label>

                                <Select options={globalContext.lockdownCenters ? globalContext.lockdownCenters : undefined} onChange={edpnContext.setSelectedCenter} value={edpnContext.selectedCenter} />
                            </div>

                            <div className="text-right m-auto pr-2">
                                <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                                    <ApproveSelectedButton />
                                </ProtectedComponent>

                                <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                                    <ApproveAllButton />
                                </ProtectedComponent>
                            </div>
                            
                        </div>


                        <div className={"w-full mx-auto " + (logsLoading ? 'hidden' : '')}>
                            <EDPNApprovalList Logs={unapprovedLogs} Therapists={therapists} DeleteLog={OnClickDeleteLog} SelectRow={SelectRow} ShowImportedLogs={showImportedHistory} ApproveAll={OnClickApproveAllLogs} PreviewLog={setLogPreviewActive} SetCredentialsModalActive={setCredentialsModalActive} HoveredLog={setHoveredLog} setApprovalsPageLoading={setPageLoading} SelectRows={SelectRows} selectedApprovalsList={selectedApprovalsList} UnapproveLog={OnClickUnapproveLog} />
                        </div>

                        <div className={'w-full h-auto flex text-center mt-4 ' + (logsLoading ? '' : 'hidden')}>
                            <FontAwesomeIcon className="fill-current text-lacblue text-center w-16 h-16 animate-spin m-auto" icon={solid('spinner')} />
                        </div>

                    </div>

                </div>

                <LogPreviewContent />

                <ModalConfirm Message={confirmUnapproveModalMessage} Active={confirmUnapproveModalActive} setActive={setConfirmUnapproveModalActive} OnConfirm={UnapproveLog} />
                <ModalConfirm Message={confirmModalMessageLogs} Active={confirmModalActiveLogs} setActive={setConfirmModalActiveLogs} OnConfirm={ApproveLogs} />
                <ModalConfirm Message={confirmDeleteModalMessage} Active={confirmDeleteModalActive} setActive={setConfirmDeleteModalActive} OnConfirm={DeleteLog} />
                <ModalNotification Title={"Warning!"} Body={"<div>You must fill out your credentials and signature to modify a log.</div><div>1. Navigate to the top right of your screen</div><div>2. Click on the menu button.</div><div>3. Click on 'Edit Profile'.</div>"} Active={credentialsModalActive} Icon={"Warning"} setActive={setCredentialsModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning!"} Body={"<div>This learner has 97151 logs that are not in this set. Please add those logs to the set to continue.</div>"} Active={medicaidCheckModalActive} Icon={"Warning"} setActive={setMedicaidCheckModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning!"} Body={approveMessage} Active={approveModalActive} Icon={"Warning"} setActive={setApproveModalActive} OnModalClose={function noop() { }} />


            </div>

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }



};


export function EDPNApprovals2() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Read', 'EDPN.Write']}>
                    <EDPNApprovals2Content />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
};
