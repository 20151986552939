import { text } from "@fortawesome/fontawesome-svg-core";
import { useContext, useEffect, useState } from "react";
import { ECLContext } from "../../pages/ecl/ECLContext";
import { ECLAnswer, ECLAnswerObject, ECLQuestion } from "../../utils/Definitions";

interface Props {
    ECLQuestion: ECLQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<ECLAnswerObject | null>>;
    answerObject: ECLAnswerObject | null;
}

export const ECLQuestionTypeTextSplit: React.FC<Props> = (props) => {

    const [textValue, setTextValue] = useState<string>("");

    const eclContext = useContext(ECLContext);


    useEffect(() => {
        if (eclContext.selectedEditAnswer && props.answerObject && props.answerObject.answers && props.ECLQuestion) {
            var tempanswer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);

            if (tempanswer && tempanswer.textValue) {
                //autopopulate text value
                setTextValue(tempanswer.textValue);
            }
        }

    }, [props.answerObject, props.answerObject?.answers, props.ECLQuestion]);

    useEffect(() => {
        //autopopulate answer object
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);
            if (!answer) {

                var AutoPopulatedText = eclContext.selectedEditAnswer?.answers?.find(x => x.questionId == props.ECLQuestion.questionId)?.textValue;

                //if answer doesn't already exist, create new
                var tempAnswer: ECLAnswer = {
                    answerId: null,
                    questionId: props.ECLQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: AutoPopulatedText ? AutoPopulatedText : null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }

    }, [eclContext.selectedEditAnswer, props.answerObject]);

    function SaveToObject(input: string) {
        if (props.answerObject && props.answerObject.answers) {

            //if answer already exists, update
            var answer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);
            if (answer) {

                var tempAnswers = props.answerObject.answers;
                if (tempAnswers) {
                    tempAnswers.filter(x => x.questionId == props.ECLQuestion.questionId).forEach(x => x.textValue = input);
                }

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
            else {
                //if answer doesn't already exist, create new
                var tempAnswer: ECLAnswer = {
                    answerId: null,
                    questionId: props.ECLQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: input,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
    }

    return (
        <div className="w-1/2 m-auto mt-4 p-1">
            <label className="block font-bold">
                {props.ECLQuestion.questionText}:
            </label>
            <input disabled={eclContext.isViewOnly} onChange={(event) => SaveToObject(event.target.value)} defaultValue={textValue} autoComplete="off" type="text" className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full m-auto p-2.5" />
        </div>
    )

};

