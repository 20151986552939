import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState, useEffect } from 'react';
import { Client } from '../utils/Definitions';

import { GlobalContext } from "../utils/GlobalContext";
import { apiConfig } from "../authConfig";
import { profile } from 'console';

interface Props {
    Client: Client | null,
    setProfilePictureModalActive: React.Dispatch<React.SetStateAction<boolean>> | null;
    setSelectedClientForModal: React.Dispatch<React.SetStateAction<Client | null>> | null;
    isEditable: boolean
}

export const ProfilePicture: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const [profilePicture, setProfilePicture] = useState<string>("");

    useEffect(() => {
        if (props.Client) {
            GetProfilePicture(props.Client.clientId);
        }
    }, [props.Client]);

    async function GetProfilePicture(clientID: number) {

        const token = await globalContext.GetToken();

        var url = apiConfig.apiEndpoint + "/Clients/GetProfilePicture?thumb=" + true + "&clientid=" + clientID;
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        try {
            const res = await fetch(url, options);
            const result = await res.blob();

            if (result.size > 0) {
                setProfilePicture(URL.createObjectURL(result));
            }

            return result;
        } catch (error) {
            /*
            globalContext.setErrorModalProps({ errorMessage: String(error), errorLocation: "/Clients/GetProfilePicture" });
            globalContext.setErrorModalActive(true);
            */
            return false;
        }
    }

    function OnClick() {
        if (props.isEditable && props.setSelectedClientForModal && props.setProfilePictureModalActive) {
            props.setSelectedClientForModal(props.Client);
            props.setProfilePictureModalActive(true);
        }
    }

    //remove picture editing
    if (!props.isEditable) {
        return (
            <div className="w-[8rem] h-[8rem] group ring ring-lacblue ring-offset-4 m-auto rounded-full bg-gray flex items-center justify-center relative inline-block noprint">
                <img src={profilePicture} alt="Profile Picture" className={"w-[8rem] h-[8rem] object-cover ring ring-lacblue ring-offset-4 m-auto rounded-full " + (profilePicture !== "" ? "normal" : "hidden")}></img>
                <span className={"text-gray-200 font-bold text-5xl text-center " + (profilePicture === "" ? "normal" : "hidden")}>{props.Client ? props.Client.firstName[0].toUpperCase() + props.Client.lastName[0].toUpperCase() : ""}</span>
            </div>
        )
    }
    else {
        return (
            <div onClick={OnClick} className="w-[8rem] h-[8rem] group cursor-pointer ring ring-lacblue ring-offset-4 m-auto rounded-full bg-gray flex items-center justify-center relative inline-block">
                <img src={profilePicture} alt="Profile Picture" className={"w-[8rem] h-[8rem] cursor-pointer object-cover ring ring-lacblue ring-offset-4 m-auto rounded-full " + (profilePicture !== "" ? "normal" : "hidden")}></img>
                <span className={"text-gray-200 font-bold text-5xl text-center " + (profilePicture === "" ? "normal" : "hidden")}>{props.Client ? props.Client.firstName[0].toUpperCase() + props.Client.lastName[0].toUpperCase() : ""}</span>
                <FontAwesomeIcon className={"group-hover:visible invisible absolute w-[2rem] h-[2rem] bottom-0 right-0 inline-block border-2 p-2 bg-white border-black rounded-full " + (props.isEditable ? "normal" : "hidden")} icon={solid('pen-to-square')} />
            </div>
        )
    }

}
