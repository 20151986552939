import isAfter from 'date-fns/esm/isAfter/index.js';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { EDPNAnswerObject, EDPNSet, Therapist } from '../../utils/Definitions';
import { GlobalContext } from '../../utils/GlobalContext';
import ProtectedComponent from '../../utils/ProtectedComponent';
import { EDPNApprovalCard } from './EDPNApprovalCard';
import { EDPNApprovalListCard } from './EDPNApprovalListCard';
import { EDPNSetCard } from './EDPNSetCard';



interface Props {
    Logs: EDPNAnswerObject[],
    Therapists: Therapist[] | null,
    ShowImportedLogs: boolean,
    ApproveAll: any,
    DeleteLog: any,
    SelectRow: any,
    SelectRows: any,
    PreviewLog: React.Dispatch<React.SetStateAction<boolean>>,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    HoveredLog: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>,
    setApprovalsPageLoading: React.Dispatch<React.SetStateAction<boolean>>,
    selectedApprovalsList: EDPNAnswerObject[],
    UnapproveLog: any,
}

export const EDPNApprovalList: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const [pendingLogs, setPendingLogs] = useState<EDPNAnswerObject[]>([]);
    const [createdLogs, setCreatedLogs] = useState<EDPNAnswerObject[]>([]);
    const [incompleteLogs, setIncompleteLogs] = useState<EDPNAnswerObject[]>([]);
    const [approvals, setapprovals] = useState<EDPNAnswerObject[]>([]);

    const [learnerCount, setLearnerCount] = useState<number>(0);
    const [edpnCreatedCount, setedpnCreatedCount] = useState<number>(0);
    const [edpnApprovedCount, setedpnApprovedCount] = useState<number>(0);
    const [correctionsCount, setCorrectionsCount] = useState<number>(0);
    const [incompleteCount, setIncompleteCount] = useState<number>(0);

    useEffect(() => {
        setPendingLogs(props.Logs.filter(x => x.dpnstatusId == 2 || x.dpnstatusId == 7));
        setCreatedLogs(props.Logs.filter(x => x.dpnstatusId == 4));
        setIncompleteLogs(props.Logs.filter(x => x.dpnstatusId == 6));
        setapprovals(props.Logs.filter(x => x.dpnstatusId == 4 || x.dpnstatusId == 1))

        setLearnerCount(props.Logs.length);
        setedpnCreatedCount(props.Logs.filter(x => x.dpnstatusId == 4).length);
        setedpnApprovedCount(props.Logs.filter(x => x.dpnstatusId == 1).length);
        setCorrectionsCount(props.Logs.filter(x => x.dpnstatusId == 2 || x.dpnstatusId == 7).length);
        setIncompleteCount(props.Logs.filter(x => x.dpnstatusId == 6).length);


    }, [props.Logs]);

    function selectAllCorrections() {
        props.SelectRows(pendingLogs.filter(x => x.dpnstatusId == 7));
    }

    function selectAllApprovals() {
        props.SelectRows(approvals.filter(x => x.dpnstatusId == 4));
    }

    
    return (

        <div className="mt-4 p-2 w-full">

            <div>
                {props.Logs.length == 0 ? <span className="font-bold text-xl text-rose w-full">No logs found.</span> : <></>}
            </div>


            <div className={"grid grid-cols-1 gap-3 place-items-center w-full m-auto mt-8 h-fit bg-gray-50 border-2 border-cyan drop-shadow rounded p-4 mb-8 " + (incompleteLogs.length > 0 ? "" : "hidden")}>
                <div className="w-full flex items-center justify-between">

                    <h2 className="text-2xl text-cyan w-fit">Incomplete</h2>

                    <div className="flex-grow text-right">
                        <span className="px-5 w-fit">Incomplete Count: <strong>{incompleteCount}</strong></span>
                    </div>

                    <div className="w-fit invisible">
                        <button className="bg-purple w-[12rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"><span className="drop-shadow">Select all incomplete</span></button>
                    </div>
                </div>

                <div className="h-fit bg-gray-50 border drop-shadow rounded p-2 w-full">

                    <div className="grid-cols md:flex max-h-[25rem] overflow-y-scroll">

                        <table className="table-auto w-full">

                            <thead className="w-full border border-cyan-500">
                                <tr className="bg-cyan text-white">
                                    <th></th>
                                    <th>Select</th>
                                    <th>Learner</th>
                                    <th>Duration</th>
                                    <th>Absent</th>
                                    <th>Start & End Time</th>
                                    <th>Tx Schedule</th>
                                    <th>Hours Missed Reason</th>
                                    <th>Service Date</th>
                                    <th className={"border border-cyan-500 " + (incompleteLogs.filter(x => x.dpnstatusId === 4 || x.dpnstatusId === 6).length > 0 ? "" : "hidden")}></th>
                                    <th className={"border border-cyan-500 " + (incompleteLogs.filter(x => x.comments?.length !== 0).length > 0 ? "" : "hidden")}></th>
                                </tr>
                            </thead>

                            <tbody>
                                {incompleteLogs.map(log => <EDPNApprovalListCard key={log.logId} DeleteLog={props.DeleteLog} Therapists={props.Therapists} Log={log} SelectRow={props.SelectRow} PreviewLog={props.PreviewLog} SetCredentialsModalActive={props.SetCredentialsModalActive} HoveredLog={props.HoveredLog} setApprovalsPageLoading={props.setApprovalsPageLoading} selectedApprovalsList={props.selectedApprovalsList} UnapproveLog={props.UnapproveLog} />)}
                            </tbody>

                        </table>

                    </div>

                </div>
            </div>


            <div className={"grid grid-cols-1 gap-3 place-items-center w-full m-auto mt-8 h-fit bg-gray-50 border-2 border-yellow drop-shadow rounded p-4 mb-8 " + (pendingLogs.length > 0 ? "" : "hidden")}>
                <div className="w-full flex items-center justify-between">

                    <h2 className="text-2xl text-yellow w-fit">Corrections</h2>

                    <div className="flex-grow text-right">
                        <span className="px-5 w-fit">Corrections Count: <strong>{correctionsCount}</strong></span>
                    </div>

                    <div className="w-fit">
                        <button onClick={selectAllCorrections} className="bg-purple w-[12rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"><span className="drop-shadow">Select all corrections</span></button>
                    </div>
                </div>

                <div className="h-fit bg-gray-50 border drop-shadow rounded p-2 w-full">

                    <div className="grid-cols md:flex max-h-[25rem] overflow-y-scroll">

                        <table className="table-auto w-full">

                            <thead className="w-full border border-yellow-500">
                                <tr className="bg-yellow text-white">
                                    <th></th>
                                    <th>Select</th>
                                    <th>Learner</th>
                                    <th>Duration</th>
                                    <th>Absent</th>
                                    <th>Start & End Time</th>
                                    <th>Tx Schedule</th>
                                    <th>Hours Missed Reason</th>
                                    <th>Service Date</th>
                                    <th className={"border border-yellow-500 " + (pendingLogs.filter(x => x.dpnstatusId === 2 || x.dpnstatusId === 7).length > 0 ? "" : "hidden")}></th>
                                    <th className={"border border-yellow-500 " + (pendingLogs.filter(x => x.comments?.length !== 0).length > 0 ? "" : "hidden")}></th>
                                </tr>
                            </thead>

                            <tbody>
                                {pendingLogs.map(log => <EDPNApprovalListCard key={log.logId} Therapists={props.Therapists} Log={log} DeleteLog={props.DeleteLog} SelectRow={props.SelectRow} PreviewLog={props.PreviewLog} SetCredentialsModalActive={props.SetCredentialsModalActive} HoveredLog={props.HoveredLog} setApprovalsPageLoading={props.setApprovalsPageLoading} selectedApprovalsList={props.selectedApprovalsList} UnapproveLog={props.UnapproveLog} />)}
                            </tbody>

                        </table>

                    </div>

                </div>
            </div>

            <div className={"grid grid-cols-1 gap-3 place-items-center w-full m-auto h-fit bg-gray-50 border-2 border-lacgreen drop-shadow rounded p-4 mb-8 " + (approvals.length > 0 ? "" : "hidden")}>
                <div className="w-full flex items-center justify-between">

                    <h2 className="text-2xl text-lacgreen w-fit">Approvals</h2>

                    <div className="flex-grow text-right">
                        <span className="px-5">eDPN's Created: <strong>{edpnCreatedCount}</strong></span>
                        <span className="px-5">eDPN's Approved: <strong>{edpnApprovedCount}</strong></span>
                    </div>

                    <div className="w-fit">
                        <button onClick={selectAllApprovals} className="bg-purple w-[12rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"><span className="drop-shadow">Select all approvals</span></button>
                    </div>
                </div>

                <div className="h-fit bg-gray-50 border drop-shadow rounded p-2 w-full">

                    <div className="grid-cols md:flex max-h-[25rem] overflow-y-scroll">

                        <table className="table-auto w-full">

                            <thead className="w-full border border-lacgreen-500">
                                <tr className="bg-lacgreen text-white">
                                    <th></th>
                                    <th>Select</th>
                                    <th>Learner</th>
                                    <th>Duration</th>
                                    <th>Absent</th>
                                    <th>Start & End Time</th>
                                    <th>Tx Schedule</th>
                                    <th>Hours Missed Reason</th>
                                    <th>Service Date</th>
                                    <th className={"border border-lacgreen-500 " + (approvals.filter(x => x.dpnstatusId === 4 || x.dpnstatusId === 6).length > 0 ? "" : "hidden")}></th>
                                    <th className={"border border-lacgreen-500 " + (approvals.filter(x => x.comments?.length !== 0).length > 0 ? "" : "hidden")}></th>
                                </tr>
                            </thead>

                            <tbody>
                                {approvals.map(log => <EDPNApprovalListCard key={log.logId} Therapists={props.Therapists} Log={log} DeleteLog={props.DeleteLog} SelectRow={props.SelectRow} PreviewLog={props.PreviewLog} SetCredentialsModalActive={props.SetCredentialsModalActive} HoveredLog={props.HoveredLog} setApprovalsPageLoading={props.setApprovalsPageLoading} selectedApprovalsList={props.selectedApprovalsList} UnapproveLog={props.UnapproveLog} />)}
                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>
    )
};