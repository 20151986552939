// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { JSXElementConstructor, ReactElement, SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EDPNContext } from "./EDPNContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { APIReturnObject, Center, Client, EDPNAnswerObject, EDPNSet, OptimizedECLEDPN, Status, Therapist, TherapistOption } from "../../utils/Definitions";
import { EDPNViewLogCard } from "../../components/edpn/EDPNViewLogCard";
import { ModalNotification } from "../../components/ModalNotification";
import ProtectedPage from "../../utils/ProtectedPage";
import ProtectedComponent from "../../utils/ProtectedComponent";
import Select, { MultiValue } from "react-select";
import { EDPNApprovalCard } from "../../components/edpn/EDPNApprovalCard";
import { ValueContainer } from "../../components/ValueContainer";
import { ModalConfirm } from "../../components/ModalConfirm";
import { EDPNLogsList } from "../../components/edpn/EDPNLogsList";
import { isAfter, set, sub } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, TextFieldProps } from "@mui/material";
import { zonedTimeToUtc } from "date-fns-tz";


//only renders if authenticated
const EDPNApprovalsContent = () => {


    const navigate = useNavigate();

    const edpnContext = useContext(EDPNContext);
    const globalContext = useContext(GlobalContext);

    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [logsLoading, setLogsLoading] = useState<boolean>(true);


    const [unapprovedLogs, setUnapprovedLogs] = useState<EDPNAnswerObject[]>([]);
    const [filteredUnapprovedLogs, setFilteredUnapprovedLogs] = useState<EDPNAnswerObject[]>([]);

    const [therapists, setTherapists] = useState<Therapist[] | null>(null);

    const [RBTOptions, setRBTOptions] = useState<TherapistOption[]>();

    const [confirmUnapproveModalActive, setConfirmUnapproveModalActive] = useState<boolean>(false);
    const [confirmUnapproveModalMessage, setConfirmUnapproveModalMessage] = useState<string>("");

    const [confirmModalActive, setConfirmModalActive] = useState<boolean>(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState<string>("");

    const [confirmModalActiveLogs, setConfirmModalActiveLogs] = useState<boolean>(false);
    const [confirmModalMessageLogs, setConfirmModalMessageLogs] = useState<string>("");

    const [confirmModalActiveSetLogs, setConfirmModalActiveSetLogs] = useState<boolean>(false);
    const [confirmModalMessageSetLogs, setConfirmModalMessageSetLogs] = useState<string>("");

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [approveModalActive, setApproveModalActive] = useState<boolean>(false);
    const [approveMessage, setApproveMessage] = useState<string>("");

    const [logToApprove, setLogToApprove] = useState<EDPNAnswerObject | null>(null);
    const [logsToApprove, setLogsToApprove] = useState<EDPNAnswerObject[] | null>(null);

    const [edpnSetLogsToApprove, setEclSetLogsToApprove] = useState<EDPNAnswerObject[] | null>(null);

    const [confirmDeleteModalActive, setConfirmDeleteModalActive] = useState<boolean>(false);
    const [confirmDeleteModalMessage, setConfirmDeleteModalMessage] = useState<string>("");
    const [logToDelete, setLogToDelete] = useState<EDPNAnswerObject | null>(null);

    const [logToHold, setLogToHold] = useState<EDPNAnswerObject | null>(null);
    const [confirmHoldModalActive, setConfirmHoldModalActive] = useState<boolean>(false);
    const [confirmHoldModalMessage, setConfirmHoldModalMessage] = useState<string>("");

    const [showImportedHistory, setShowImportedHistory] = useState<boolean>(false);

    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);

    const [medicaidCheckModalActive, setMedicaidCheckModalActive] = useState<boolean>(false);

    const [importedHistoryDate, setImportedHistoryDate] = useState<Date | null>(new Date());

    function NavigateBack() {
        navigate(-1);
    }

    function OnClickDeleteLog(EDPNLog: EDPNAnswerObject) {
        setLogToDelete(EDPNLog);
        setConfirmDeleteModalMessage("<div>Are you sure you want to delete the log for:</div><div>" + EDPNLog.clientName + " on " + (EDPNLog.billingDate ? new Date(EDPNLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmDeleteModalActive(true);
    }

    // AUTO REFRESH EVERY MINUTE
    useEffect(() => {
        const intervalId = setInterval(() => {
            UpdateLogsByCenter();

        }, (60 * 1000)); // 60 seconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    });

    function DeleteLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToDelete) {
                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/SoftDeleteLog?id=" + logToDelete.logId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            if (result.status === Status.SUCCESS) {
                                setLogsLoading(true);
                                UpdateLogsByCenter();
                                console.log("success, updating logs");
                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to delete log. " + result.message, errorLocation: "/EDPN/SoftDeleteLog" });
                                globalContext.setErrorModalActive(true);
                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: result.message, errorLocation: "/EDPN/SoftDeleteLog" });
                                globalContext.setErrorModalActive(true);
                            }
                        }
                    )
            }

        })

    }

    function OnClickApproveLog(EDPNLog: EDPNAnswerObject) {
        setLogToApprove(EDPNLog);
        setConfirmModalMessage("<div>Are you sure you want to approve the log for:</div><div>" + EDPNLog.clientName + " on " + (EDPNLog.billingDate ? new Date(EDPNLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmModalActive(true);
    }
    function OnClickUnapproveLog(EDPNLog: EDPNAnswerObject) {
        setLogToApprove(EDPNLog);
        setConfirmUnapproveModalMessage("<div>Are you sure you want to Unapprove the log for:</div><div>" + EDPNLog.clientName + " on " + (EDPNLog.billingDate ? new Date(EDPNLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmUnapproveModalActive(true);
    }

    function OnClickApproveAllLogs() {

        var tempLogsToApprove: EDPNAnswerObject[] = filteredUnapprovedLogs.filter(x => (x.dpnstatusId == 4) &&
            !(x.cptcode == '97151' && x.isMimedicaid == 1) &&
            x.showApproveButton);

        var tempMessage: string = "<div>Are you sure you want to approve the following logs?</div>";
        if (tempLogsToApprove.length > 0) {
            tempLogsToApprove.forEach((log) => {
                tempMessage += "<div>" + log.clientName + " on " + (log.billingDate ? new Date(log.billingDate).toLocaleDateString() : "") + "</div>";
            })
        }
        else {
            tempMessage = "<div>No approveable logs detected.</div>";
        }

        setLogsToApprove(tempLogsToApprove);
        setConfirmModalMessageLogs(tempMessage);
        setConfirmModalActiveLogs(true);
    }

    async function ApprovalValid(token: string, edpnanswerobjects: EDPNAnswerObject[] | null) {

        if (edpnanswerobjects && edpnanswerobjects.length > 0) {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(edpnanswerobjects)
            };

            const response = await fetch(apiConfig.apiEndpoint + "/EDPN/ApprovalLogCheck", options);
            var errorText: string = await response.json();

            if (errorText.length > 0) {
                //error!
                setApproveMessage(errorText);
                setApproveModalActive(true);

                return errorText;
            }
            else {
                //no error, continue
                return errorText;
            }
        }
        else {
            return "";
        }
    }

    function ApproveLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToApprove) {

                //check if approval is valid
                var templogs: EDPNAnswerObject[] = [];
                templogs.push(logToApprove);
                ApprovalValid(token, templogs).then((errorMessage) => {

                    if (errorMessage.length == 0) {
                        var headers = new Headers();

                        var bearer = "Bearer " + token;
                        headers.append("Authorization", bearer);
                        var options = {
                            method: "GET",
                            headers: headers
                        };

                        var url = apiConfig.apiEndpoint + "/EDPN/ApproveLog?logid=" + logToApprove.logId;

                        fetch(url, options)
                            .then(res => res.json())
                            .then(
                                (result: APIReturnObject) => {
                                    if (result.status === Status.SUCCESS) {
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                    }
                                    else if (result.status === Status.FAIL) {
                                        globalContext.setNotificationModalMessage("Cannot approve. " + result.message);
                                        globalContext.setNotificationModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                    }
                                    else if (result.status === Status.ERROR) {
                                        globalContext.setErrorModalProps({ errorMessage: "Failed to approve. " + result.message, errorLocation: "/EDPN/ApproveLog" });
                                        globalContext.setErrorModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                    }
                                },
                                (error) => {
                                    globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/ApproveLog" });
                                    globalContext.setErrorModalActive(true);
                                }
                            )
                    }

                })
            }
            
        })
        
    }

    function UnapproveLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToApprove) {


                    if (errorMessage.length == 0) {
                        var headers = new Headers();

                        var bearer = "Bearer " + token;
                        headers.append("Authorization", bearer);
                        var options = {
                            method: "GET",
                            headers: headers
                        };

                        var url = apiConfig.apiEndpoint + "/EDPN/UnapproveLog?logid=" + logToApprove.logId;

                        fetch(url, options)
                            .then(res => res.json())
                            .then(
                                (result: APIReturnObject) => {
                                    if (result.status === Status.SUCCESS) {
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                    }
                                    else if (result.status === Status.FAIL) {
                                        globalContext.setNotificationModalMessage("Cannot unapprove log. " + result.message);
                                        globalContext.setNotificationModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                    }
                                    else if (result.status === Status.ERROR) {
                                        globalContext.setErrorModalProps({ errorMessage: "Failed to unapprove log. " + result.message, errorLocation: "/EDPN/ApproveLog" });
                                        globalContext.setErrorModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                    }
                                },
                                (error) => {
                                    globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/UnapproveLog" });
                                    globalContext.setErrorModalActive(true);
                                }

                            )
                    }

            }

        })

    }

    function ApproveLogs() {
        globalContext.GetToken().then((token: any) => {
            if (logsToApprove) {

                //check if approval is valid
                ApprovalValid(token, logsToApprove).then((errorMessage) => {

                    if (errorMessage.length == 0) {
                        //add log to database
                        var headers = new Headers();
                        var bearer = "Bearer " + token;
                        headers.append("Authorization", bearer);
                        headers.append("Content-type", "application/json;charset=UTF-8");

                        var logsid = logsToApprove.map(x => x.logId).join(',');

                        var options = {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify(logsid)
                        };

                        var url = apiConfig.apiEndpoint + "/EDPN/ApproveLogs";

                        fetch(url, options)
                            .then(res => res.json())
                            .then(
                                (result: APIReturnObject) => {
                                    if (result.status === Status.SUCCESS) {
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                    }
                                    else if (result.status === Status.FAIL) {
                                        globalContext.setNotificationModalMessage("Cannot approve. " + result.message);
                                        globalContext.setNotificationModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                    }
                                    else if (result.status === Status.ERROR) {
                                        globalContext.setErrorModalProps({ errorMessage: "Failed to approve. " + result.message, errorLocation: "/EDPN/ApproveLogs" });
                                        globalContext.setErrorModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogsByCenter();
                                    }
                                },
                                (error) => {
                                    globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/ApproveLogs" });
                                    globalContext.setErrorModalActive(true);
                                }
                            )
                    }
                })
            }

        })
    }

    function OnClickApproveSet(setid: number) {

        var tempSetLogsToApprove: EDPNAnswerObject[] = filteredUnapprovedLogs.filter(x => x.isMimedicaid == 1 && x.logSetId == setid);


        if (tempSetLogsToApprove.length > 0) {
            //michigan medicaid check
            globalContext.GetToken().then((token: any) => {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");


                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/MIMedicaidApproveSetValidCheck?clientID=" + tempSetLogsToApprove[0].clientId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {

                                var tempMessage: string = "<div>Are you sure you want to approve the following set?</div>";
                                tempSetLogsToApprove.forEach((log) => {
                                    tempMessage += "<div>" + log.clientName + " on " + (log.billingDate ? new Date(log.billingDate).toLocaleDateString() : "") + "</div>";
                                })


                                setEclSetLogsToApprove(tempSetLogsToApprove);
                                setConfirmModalMessageSetLogs(tempMessage);
                                setConfirmModalActiveSetLogs(true);

                            }
                            else {
                                setMedicaidCheckModalActive(true);
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/MIMedicaidApproveSetValidCheck" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function ApproveSet() {
        globalContext.GetToken().then((token: any) => {

            console.log("edpnSetLogsToApprove:", edpnSetLogsToApprove);

            if (edpnSetLogsToApprove) {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var logsid = edpnSetLogsToApprove.map(x => x.logId).join(',');

                console.log("set:", logsid);

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(logsid)
                };

                var url = apiConfig.apiEndpoint + "/EDPN/ApproveLogs";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                setLogsLoading(true);
                                UpdateLogsByCenter();
                            }
                            else {
                                setErrorMessage("Failed to approve set. Unknown reason.");
                                setErrorModalActive(true);
                                setLogsLoading(true);
                                UpdateLogsByCenter();
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/ApproveLogs" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })
    }

    function OnClickHoldLog(EDPNLog: EDPNAnswerObject) {
        setLogToHold(EDPNLog);
        setConfirmHoldModalMessage("<div>Are you sure you want to hold the log for:</div><div>" + EDPNLog.clientName + " on " + (EDPNLog.billingDate ? new Date(EDPNLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmHoldModalActive(true);
    }

    function HoldLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToHold) {
                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/HoldLog?id=" + logToHold.logId + "&author=" + globalContext.CoreAccount.name;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                setLogsLoading(true);
                                UpdateLogsByCenter();
                                console.log("success, updating logs");
                            }
                            else {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to hold log. Unknown reason.", errorLocation: "/EDPN/HoldLog" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/HoldLog" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })
    }

    function GetTherapistNameByID(id: number): string {
        if (therapists) {
            var therapist = therapists.find(x => x.therapistId == id);
            if (therapist) {
                return therapist.employeeName;
            }
            else if (id == -1) {
                return String(id);
            }
            else {
                return String(id);
            }
        }
        else return String(id);
    }

    function OnChangeTherapist(input: MultiValue<TherapistOption>) {
        var rbts: TherapistOption[] = [];

        if (RBTOptions) {
            input.forEach(x => {
                var foundrbt = RBTOptions.find(z => z.value == x.value);
                if (foundrbt) {
                    rbts.push(foundrbt);
                }
            })
        }

        edpnContext.setSelectedRBTs(rbts);
    }

    //initial load
    useEffect(() => {
        UpdateTherapists();
    }, []);

    //auto select center
    useEffect(() => {
        //select first center if there is no selected center
        if (globalContext.lockdownCenters && globalContext.lockdownCenters.length > 0 && !edpnContext.selectedCenter) {

            //attempt to default to user's center if they have one
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.centerID && globalContext.CoreAccount.currentUser.centerID != null) {
                var center = globalContext.lockdownCenters.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.centerId == globalContext.CoreAccount.currentUser.centerID)
                if (center) {
                    edpnContext.setSelectedCenter(center);
                }
                else {
                    //if user doesnt have a center, select first index
                    edpnContext.setSelectedCenter(globalContext.lockdownCenters[0]);
                }
            }
            else {
                //if user doesnt have a center, select first index
                edpnContext.setSelectedCenter(globalContext.lockdownCenters[0]);
            }

        }

    }, [globalContext.lockdownCenters]);

    //upon center choice change, update logs
    useEffect(() => {

        if (edpnContext.selectedCenter && importedHistoryDate) {
            setLogsLoading(true);
            UpdateLogsByCenter();
        }
    }, [edpnContext.selectedCenter, showImportedHistory, importedHistoryDate]);

    //upon log change, set new therapist options
    useEffect(() => {

        if (unapprovedLogs) {

            var rbts: TherapistOption[] = [];
            unapprovedLogs.forEach((log) => {
                if (!rbts.find(x => x.value == String(log.therapistId))) {
                    var rbt: TherapistOption = {
                        value: String(log.therapistId),
                        label: log.therapistId ? GetTherapistNameByID(log.therapistId) : String(log.therapistId),
                    }
                    rbts.push(rbt);
                }
            })

            rbts = rbts.sort((a, b) => a.label.localeCompare(b.label));

            setRBTOptions(rbts);
        }
    }, [unapprovedLogs, therapists]);

    //autoselect user if they are an rbt, else select first
    useEffect(() => {

        //get list of therapists to reselect
        var reselectRBTs: TherapistOption[] = [];
        if (RBTOptions && RBTOptions.length > 0 && edpnContext.selectedRBTs.length > 0) {
            edpnContext.selectedRBTs.forEach((rbt) => {
                var foundRBT = RBTOptions.find(x => x.value == rbt.value);
                if (foundRBT) {
                    reselectRBTs.push(foundRBT);
                }
            })
        }

        //autoselect
        if (reselectRBTs.length > 0) {
            //reselect existing rbts
            edpnContext.setSelectedRBTs(reselectRBTs);
        }
        else {
            //select fresh rbt if reselect list is empty
            if (RBTOptions && RBTOptions.length > 0) {
                if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.therapistID) {
                    //autoselect users id
                    var therapistFound = RBTOptions.find(x => x.value == String(globalContext.CoreAccount?.currentUser?.therapistID));
                    if (therapistFound) {
                        var tempTherapists: TherapistOption[] = [];
                        tempTherapists.push(therapistFound);
                        edpnContext.setSelectedRBTs(tempTherapists);
                    }
                    else {
                        //if no therapist id found, select first index as fallback
                        var tempTherapists: TherapistOption[] = [];
                        tempTherapists.push(RBTOptions[0]);
                        edpnContext.setSelectedRBTs(tempTherapists);
                    }
                }
                else {
                    //autoselect first therapist as fallback
                    var tempTherapists: TherapistOption[] = [];
                    tempTherapists.push(RBTOptions[0]);
                    edpnContext.setSelectedRBTs(tempTherapists);
                }
            }
            else {
                edpnContext.setSelectedRBTs([]);
            }
        }
        
        
    }, [RBTOptions]);

    //filter logs by selected rbts
    useEffect(() => {

        console.log("edpnContext.selectedRBTs", edpnContext.selectedRBTs);

        if (edpnContext.selectedRBTs && unapprovedLogs) {
            setFilteredUnapprovedLogs(unapprovedLogs.filter(x => edpnContext.selectedRBTs.find(z => z.value == String(x.therapistId))));
            console.log("filterd logs", filteredUnapprovedLogs);
        }
        else {
            setFilteredUnapprovedLogs([]);
        }
    }, [edpnContext.selectedRBTs]);

    useEffect(() => {
        setLogsLoading(false);
    }, [filteredUnapprovedLogs]);

    function UpdateLogsByCenter() {

        globalContext.GetToken().then((token: any) => {
            if (edpnContext.selectedCenter && edpnContext.selectedCenter.centerId) {

                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/GetLogsByCenter?centerid=" + edpnContext.selectedCenter.centerId + "&imported=" + showImportedHistory + "&monthday=" + importedHistoryDate?.toDateString();

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: OptimizedECLEDPN) => {
                            const temp: EDPNAnswerObject[] = [];

                            //map comments to logs
                            if (result && result.logs) {
                                result.logs.forEach((log) => {
                                    if (result.comments && log.billingDate) {

                                        log.comments = result.comments.filter((x) => {
                                            const sameClient = x.clientId === log.clientId;

                                            const xBillingDate = x.billingDate ? new Date(x.billingDate) : null;
                                            const logBillingDate = log.billingDate ? new Date(log.billingDate) : null;

                                            const sameBillingDate =
                                                xBillingDate &&
                                                logBillingDate &&
                                                xBillingDate.getFullYear() === logBillingDate.getFullYear() &&
                                                xBillingDate.getMonth() === logBillingDate.getMonth() &&
                                                xBillingDate.getDate() === logBillingDate.getDate();

                                            return sameClient && sameBillingDate;
                                        });
                                    }

                                    temp.push(log);
                                });
                            }

                            setUnapprovedLogs(temp);
                            setPageLoading(false);
                        },
                        (error) => {
                            setPageLoading(false);
                            setLogsLoading(false);
                            //globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetLogsByCenter" });
                            //globalContext.setErrorModalActive(true);
                        }
                    )
            }
            
        })
    }

    function UpdateTherapists() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Centers/GetAllTherapists";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setTherapists(result);
                    },
                    (error) => {
                        setPageLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetAllTherapists" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    const handleDateChange = (date: Date | null) => {
        // Extract the month from the selected date
        setImportedHistoryDate(date);
    };

    function NavigateToLogs() {
        navigate("/edpn");
    }

    if (unapprovedLogs && !pageLoading) {
        return (

            <div className="grid grid-rows-auto ml-auto mr-auto">

                <div className="grid grid-cols-1 h-fit mt-0 mb-8">

                    <div className="mt-4 mb-4 mr-auto">
                        <div onClick={NavigateToLogs} className="my-2 flex items-center w-fit cursor-pointer col-span-2">
                            <div className="flex-1">
                                <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                            </div>
                            <span className="text-xl w-auto ml-3">Back to eDPN Home</span>
                            <div className="flex-1"></div>
                        </div>
                    </div>


                    <div className="grid grid-flow-row">

                        <div className="grid grid-cols-1 gap-4 xl:gap-[4.5rem] content-between xl:grid-flow-col xl:grid-cols-4 xl:w-fit xl:m-auto">

                            <div className="w-full xl:w-[16rem] m-auto">
                                <label className="block font-bold" htmlFor="centers">
                                    Center:
                                </label>

                                <Select options={globalContext.lockdownCenters ? globalContext.lockdownCenters : undefined} onChange={edpnContext.setSelectedCenter} value={edpnContext.selectedCenter} />
                            </div>

                            <div className="w-full xl:w-[16rem] m-auto">
                                <label className="block font-bold" htmlFor="centers">
                                    Provider:
                                </label>

                                <Select isDisabled={logsLoading} components={{ ValueContainer }} options={RBTOptions} hideSelectedOptions={false} closeMenuOnSelect={false} isMulti={true} onChange={OnChangeTherapist} value={edpnContext.selectedRBTs} />
                            </div>

                            <div className="w-full xl:w-[16rem] m-auto xl:mt-auto xl:mb-1">
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" onChange={event => setShowImportedHistory(event.target.checked)} checked={showImportedHistory} className="sr-only peer" />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                    <span className="ml-3 text-sm font-medium">Show Imported History</span>
                                </label>
                            </div>

                            <div className={"w-full xl:w-[16rem] m-auto " + (showImportedHistory ? '' : 'hidden')}>
                                <label className="block font-bold" htmlFor="centers">
                                    Imported History Month:
                                </label>

                                <DatePicker className="w-fit mt-4" disableFuture={true} openTo={'month'} views={['month', 'year']} value={importedHistoryDate} onChange={handleDateChange} renderInput={(params) => <TextField size="small" {...params} />} />
                            </div>

                        </div>


                        <div className={"w-full mx-auto " + (logsLoading ? 'hidden' : '' )}>
                            <EDPNLogsList Logs={filteredUnapprovedLogs} Therapists={therapists} ApproveLog={OnClickApproveLog} UnapproveLog={OnClickUnapproveLog} DeleteLog={OnClickDeleteLog} HoldLog={OnClickHoldLog} ShowImportedLogs={showImportedHistory} ApproveSet={OnClickApproveSet} ApproveAll={OnClickApproveAllLogs} SetCredentialsModalActive={setCredentialsModalActive} />
                        </div>

                        <div className={'w-full h-auto flex text-center mt-4 ' + (logsLoading ? '' : 'hidden')}>
                            <FontAwesomeIcon className="fill-current text-lacblue text-center w-16 h-16 animate-spin m-auto" icon={solid('spinner')} />
                        </div>

                    </div>
               
                </div>

                <ModalConfirm Message={confirmUnapproveModalMessage} Active={confirmUnapproveModalActive} setActive={setConfirmUnapproveModalActive} OnConfirm={UnapproveLog} />
                <ModalConfirm Message={confirmModalMessage} Active={confirmModalActive} setActive={setConfirmModalActive} OnConfirm={ApproveLog} />
                <ModalConfirm Message={confirmModalMessageLogs} Active={confirmModalActiveLogs} setActive={setConfirmModalActiveLogs} OnConfirm={ApproveLogs} />
                <ModalConfirm Message={confirmDeleteModalMessage} Active={confirmDeleteModalActive} setActive={setConfirmDeleteModalActive} OnConfirm={DeleteLog} />
                <ModalConfirm Message={confirmHoldModalMessage} Active={confirmHoldModalActive} setActive={setConfirmHoldModalActive} OnConfirm={HoldLog} />
                <ModalConfirm Message={confirmModalMessageSetLogs} Active={confirmModalActiveSetLogs} setActive={setConfirmModalActiveSetLogs} OnConfirm={ApproveSet} />
                <ModalNotification Title={"Warning!"} Body={"<div>You must fill out your credentials and signature to modify a log.</div><div>1. Navigate to the top right of your screen</div><div>2. Click on the menu button.</div><div>3. Click on 'Edit Profile'.</div>"} Active={credentialsModalActive} Icon={"Warning"} setActive={setCredentialsModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning!"} Body={"<div>This learner has 97151 logs that are not in this set. Please add those logs to the set to continue.</div>"} Active={medicaidCheckModalActive} Icon={"Warning"} setActive={setMedicaidCheckModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning!"} Body={approveMessage} Active={approveModalActive} Icon={"Warning"} setActive={setApproveModalActive} OnModalClose={function noop() { }} />


            </div>

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }
    
    
    
};


export function EDPNApprovals() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Read', 'EDPN.Write']}>
                    <EDPNApprovalsContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
      )
};
