// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ECLContext } from "./ECLContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { APIReturnObject, Center, Client, ECLAnswerObject, ECLSet, Status, Therapist, TherapistOption } from "../../utils/Definitions";
import { ECLViewLogCard } from "../../components/ecl/ECLViewLogCard";
import { ModalNotification } from "../../components/ModalNotification";
import ProtectedPage from "../../utils/ProtectedPage";
import ProtectedComponent from "../../utils/ProtectedComponent";
import Select, { MultiValue } from "react-select";
import { ECLApprovalCard } from "../../components/ecl/ECLApprovalCard";
import { ValueContainer } from "../../components/ValueContainer";
import { ModalConfirm } from "../../components/ModalConfirm";
import { ECLLogsList } from "../../components/ecl/ECLLogsList";
import { isAfter, set, sub } from "date-fns";
import { ProfilePicture } from "../../components/ProfilePicture";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { zonedTimeToUtc } from "date-fns-tz";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, TextFieldProps } from "@mui/material";


//only renders if authenticated
const ECLViewLogsContent = () => {


    const navigate = useNavigate();

    const eclContext = useContext(ECLContext);
    const globalContext = useContext(GlobalContext);

    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [logsLoading, setLogsLoading] = useState<boolean>(true);

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    function ProfilePictureOnCLose() {
        setSelectedClientForModal(null);
    }


    const [logs, setLogs] = useState<ECLAnswerObject[]>([]);
    const [importedHistoryDate, setImportedHistoryDate] = useState<Date | null>(new Date());

    const [therapists, setTherapists] = useState<Therapist[] | null>(null);

    const [centers, setCenters] = useState<Center[]>();

    const [deleteModalActive, setDeleteModalActive] = useState<boolean>(false);
    const [deleteMessage, setDeleteMessage] = useState<string>("");

    const [confirmModalActive, setConfirmModalActive] = useState<boolean>(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState<string>("");

    const [confirmModalActiveLogs, setConfirmModalActiveLogs] = useState<boolean>(false);
    const [confirmModalMessageLogs, setConfirmModalMessageLogs] = useState<string>("");

    const [confirmModalActiveSetLogs, setConfirmModalActiveSetLogs] = useState<boolean>(false);
    const [confirmModalMessageSetLogs, setConfirmModalMessageSetLogs] = useState<string>("");

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [approveModalActive, setApproveModalActive] = useState<boolean>(false);
    const [approveMessage, setApproveMessage] = useState<string>("");

    const [logToApprove, setLogToApprove] = useState<ECLAnswerObject | null>(null);
    const [logsToApprove, setLogsToApprove] = useState<ECLAnswerObject[] | null>(null);

    const [eclSetLogsToApprove, setEclSetLogsToApprove] = useState<ECLAnswerObject[] | null>(null);

    const [confirmDeleteModalActive, setConfirmDeleteModalActive] = useState<boolean>(false);
    const [confirmDeleteModalMessage, setConfirmDeleteModalMessage] = useState<string>("");
    const [logToDelete, setLogToDelete] = useState<ECLAnswerObject | null>(null);

    const [logToHold, setLogToHold] = useState<ECLAnswerObject | null>(null);
    const [confirmHoldModalActive, setConfirmHoldModalActive] = useState<boolean>(false);
    const [confirmHoldModalMessage, setConfirmHoldModalMessage] = useState<string>("");

    const [showImportedHistory, setShowImportedHistory] = useState<boolean>(false);

    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);

    const [medicaidCheckModalActive, setMedicaidCheckModalActive] = useState<boolean>(false);



    function NavigateBack() {
        navigate(-1);
    }

    useEffect(() => {
        if (!eclContext.selectedClient) {
            navigate("/ecl/logs");
        }
    })

    function OnClickDeleteLog(ECLLog: ECLAnswerObject) {
        setLogToDelete(ECLLog);
        setConfirmDeleteModalMessage("<div>Are you sure you want to delete the log for:</div><div>" + ECLLog.clientName + " on " + (ECLLog.billingDate ? new Date(ECLLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmDeleteModalActive(true);
    }

    function DeleteLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToDelete) {

                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/ECL/SoftDeleteLog?id=" + logToDelete.logId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            console.log("RESULT:", result);
                            if (result.status == Status.SUCCESS)
                            {
                                UpdateLogs();
                            }
                            else if (result.status == Status.FAIL) {
                                globalContext.setNotificationModalMessage("Unable to delete log. " + result.message);
                                globalContext.setNotificationModalActive(true);
                            }
                            else if (result.status == Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to delete log. " + result.message, errorLocation: "/ECL/SoftDeleteLog" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/SoftDeleteLog" });
                            globalContext.setErrorModalActive(true);
                        }
                )
            }

        })

    }

    function getNextBusinessDate(currentDate: Date): Date {
        // Clone the current date to avoid mutating the original object
        const nextDate = new Date(currentDate);

        // Increment the date by one day until a business day (Mon-Fri) is found
        do {
            nextDate.setDate(nextDate.getDate() + 1);
        } while (nextDate.getDay() === 0 || nextDate.getDay() === 6); // 0 is Sunday, 6 is Saturday

        console.log("next day:", nextDate);

        return nextDate;
    }

    function OnClickApproveLog(ECLLog: ECLAnswerObject) {
        setLogToApprove(ECLLog);
        setConfirmModalMessage("<div>Are you sure you want to approve the log for:</div><div>" + ECLLog.clientName + " on " + (ECLLog.billingDate ? new Date(ECLLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmModalActive(true);
    }

    function OnClickApproveAllLogs() {
        var tempMessage: string = "<div>Are you sure you want to approve the following logs?</div>";

        var tempLogsToApprove: ECLAnswerObject[] = logs.filter(x => (x.dpnstatusId == 4) &&
            !(x.cptcode == '97151' && x.isMimedicaid == 1) &&
            x.showApproveButton);

        if (tempLogsToApprove.length > 0) {
            tempLogsToApprove.forEach((log) => {
                tempMessage += "<div>" + log.clientName + " on " + (log.billingDate ? new Date(log.billingDate).toLocaleDateString() : "") + "</div>";
            })

            setLogsToApprove(tempLogsToApprove);

        }
        else {
            tempMessage = "<div>No approveable logs detected.</div>";
        }
        
        setConfirmModalMessageLogs(tempMessage);
        setConfirmModalActiveLogs(true);
    }

    async function ApprovalValid(token: string, eclanswerobjects: ECLAnswerObject[] | null) {

        if (eclanswerobjects && eclanswerobjects.length > 0) {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(eclanswerobjects)
            };

            const response = await fetch(apiConfig.apiEndpoint + "/ECL/ApprovalLogCheck", options);
            var errorText: string = await response.json();

            if (errorText.length > 0) {
                //error!
                setApproveMessage(errorText);
                setApproveModalActive(true);

                return errorText;
            }
            else {
                //no error, continue
                return errorText;
            }
        }
        else {
            return "";
        }
    }

    function ApproveLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToApprove) {

                //check if approval is valid
                var templogs: ECLAnswerObject[] = [];
                templogs.push(logToApprove);
                ApprovalValid(token, templogs).then((errorMessage) => {

                    if (errorMessage.length == 0) {
                        var headers = new Headers();

                        var bearer = "Bearer " + token;
                        headers.append("Authorization", bearer);
                        var options = {
                            method: "GET",
                            headers: headers
                        };

                        var url = apiConfig.apiEndpoint + "/ECL/ApproveLog?logid=" + logToApprove.logId;

                        fetch(url, options)
                            .then(res => res.json())
                            .then(
                                (result: APIReturnObject) => {
                                    if (result.status === Status.SUCCESS) {
                                        UpdateLogs();
                                    }
                                    else if (result.status === Status.FAIL) {
                                        globalContext.setNotificationModalMessage("Cannot approve log. " + result.message);
                                        globalContext.setNotificationModalActive(true);
                                        UpdateLogs();
                                    }
                                    else if (result.status === Status.ERROR) {
                                        globalContext.setErrorModalProps({ errorMessage: result.message, errorLocation: "/ECL/ApproveLog" });
                                        globalContext.setErrorModalActive(true);
                                        UpdateLogs();
                                    }
                                },
                                (error) => {
                                    globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/ApproveLog" });
                                    globalContext.setErrorModalActive(true);
                                }
                            )
                    }

                })
            }

        })

    }

    function ApproveLogs() {
        globalContext.GetToken().then((token: any) => {
            if (logsToApprove) {

                //check if approval is valid
                ApprovalValid(token, logsToApprove).then((errorMessage) => {

                    if (errorMessage.length == 0) {
                        //add log to database
                        var headers = new Headers();
                        var bearer = "Bearer " + token;
                        headers.append("Authorization", bearer);
                        headers.append("Content-type", "application/json;charset=UTF-8");

                        var logsid = logsToApprove.map(x => x.logId).join(',');

                        var options = {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify(logsid)
                        };

                        var url = apiConfig.apiEndpoint + "/ECL/ApproveLogs";

                        fetch(url, options)
                            .then(res => res.json())
                            .then(
                                (result: APIReturnObject) => {
                                    console.log(result);

                                    if (result.status === Status.SUCCESS) {
                                        UpdateLogs();
                                    }
                                    else if (result.status === Status.FAIL) {
                                        globalContext.setNotificationModalMessage("Cannot approve. " + result.message);
                                        globalContext.setNotificationModalActive(true);
                                        UpdateLogs();
                                    }
                                    else if (result.status === Status.ERROR) {
                                        globalContext.setErrorModalProps({ errorMessage: "Failed to approve. " + result.message, errorLocation: "/ECL/ApproveLogs" });
                                        globalContext.setErrorModalActive(true);
                                        UpdateLogs();
                                    }
                                },
                                (error) => {
                                    globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/ApproveLogs" });
                                    globalContext.setErrorModalActive(true);
                                }
                            )
                    }
                })
            }

        })
    }

    function OnClickApproveSet(setid: number) {

        var tempSetLogsToApprove: ECLAnswerObject[] = logs.filter(x => x.logSetId == setid);


        if (tempSetLogsToApprove.length > 0) {
            //michigan medicaid check
            globalContext.GetToken().then((token: any) => {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");


                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/ECL/MIMedicaidApproveSetValidCheck?clientID=" + tempSetLogsToApprove[0].clientId;

                console.log("URL:", url);

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log(result);
                            if (result == true) {

                                var tempMessage: string = "<div>Are you sure you want to approve the following set?</div>";
                                tempSetLogsToApprove.forEach((log) => {
                                    tempMessage += "<div>" + log.clientName + " on " + (log.billingDate ? new Date(log.billingDate).toLocaleDateString() : "") + "</div>";
                                })


                                setEclSetLogsToApprove(tempSetLogsToApprove);
                                setConfirmModalMessageSetLogs(tempMessage);
                                setConfirmModalActiveSetLogs(true);

                            }
                            else {
                                setMedicaidCheckModalActive(true);
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/MIMedicaidApproveSetValidCheck" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function ApproveSet() {
        globalContext.GetToken().then((token: any) => {

            console.log("eclSetLogsToApprove:", eclSetLogsToApprove);

            if (eclSetLogsToApprove) {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var logsid = eclSetLogsToApprove.map(x => x.logId).join(',');

                console.log("set:", logsid);

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(logsid)
                };

                var url = apiConfig.apiEndpoint + "/ECL/ApproveLogs";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            console.log(result);

                            if (result.status === Status.SUCCESS) {
                                UpdateLogs();
                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setNotificationModalMessage("Cannot approve. " + result.message);
                                globalContext.setNotificationModalActive(true);
                                UpdateLogs();
                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to approve. " + result.message, errorLocation: "/ECL/ApproveLogs" });
                                globalContext.setErrorModalActive(true);
                                UpdateLogs();
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/ApproveLogs" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })
    }

    function OnClickHoldLog(ECLLog: ECLAnswerObject) {
        setLogToHold(ECLLog);
        setConfirmHoldModalMessage("<div>Are you sure you want to hold the log for:</div><div>" + ECLLog.clientName + " on " + (ECLLog.billingDate ? new Date(ECLLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmHoldModalActive(true);
    }

    function HoldLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToHold) {
                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/ECL/HoldLog?id=" + logToHold.logId + "&author=" + globalContext.CoreAccount.name;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            if (result.status === Status.SUCCESS) {
                                UpdateLogs();
                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setNotificationModalMessage("Cannot hold log. " + result.message);
                                globalContext.setNotificationModalActive(true);
                                UpdateLogs();
                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to hold log. " + result.message, errorLocation: "/ECL/HoldLog" });
                                globalContext.setErrorModalActive(true);
                                UpdateLogs();
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/HoldLog" });
                            globalContext.setErrorModalActive(true);
                        }
                    )

            }

        })

    }

    function GetTherapistNameByID(id: number): string {
        if (therapists) {
            var therapist = therapists.find(x => x.therapistId == id);
            if (therapist) {
                return therapist.employeeName;
            }
            else if (id == -1) {
                return String(id);
            }
            else {
                return String(id);
            }
        }
        else return String(id);
    }

    

    //initial load
    useEffect(() => {
        UpdateLogs();
        UpdateTherapists();
    }, []);

    //auto select center
    useEffect(() => {
        //select first center if there is no selected center
        if (centers && centers.length > 0 && !eclContext.selectedCenter) {

            //attempt to default to user's center if they have one
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.centerID && globalContext.CoreAccount.currentUser.centerID != null) {
                var center = centers.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.centerId == globalContext.CoreAccount.currentUser.centerID)
                if (center) {
                    eclContext.setSelectedCenter(center);
                }
                else {
                    //if user doesnt have a center, select first index
                    eclContext.setSelectedCenter(centers[0]);
                }
            }
            else {
                //if user doesnt have a center, select first index
                eclContext.setSelectedCenter(centers[0]);
            }

        }

    }, [centers]);

    //upon center choice change, update logs
    useEffect(() => {

        if (eclContext.selectedCenter && importedHistoryDate) {
            UpdateLogs();
        }
    }, [eclContext.selectedCenter, showImportedHistory, importedHistoryDate]);


    useEffect(() => {
        setLogsLoading(false);
    }, [logs]);
    

    function UpdateLogs() {
        console.log("update logs");
        globalContext.GetToken().then((token: any) => {
            if (eclContext.selectedCenter && eclContext.selectedCenter.centerId) {
                setLogsLoading(true);

                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/ECL/GetLogs?id=" + eclContext.selectedClient?.clientId + "&imported=" + showImportedHistory + "&monthday=" + importedHistoryDate?.toDateString();

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("ECL LOGS:", result);
                            setLogs(result);
                            setPageLoading(false);
                        },
                        (error) => {
                            setPageLoading(false);
                            setLogsLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/GetLogs" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })
    }

    function UpdateTherapists() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Centers/GetAllTherapists";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setTherapists(result);
                    },
                    (error) => {
                        setPageLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetAllTherapists" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }


    const handleDateChange = (date: Date | null) => {
        // Extract the month from the selected date
        setImportedHistoryDate(date);
    };
   

    if (logs && eclContext.selectedClient && !pageLoading) {
        return (

            <div className="grid grid-rows-auto ml-auto mr-auto">

                <div className="grid grid-cols-1 h-fit mt-0 mb-8">

                    <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-fit cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to ECL Home</span>
                        <div className="flex-1"></div>
                    </div>


                    <div className="grid grid-flow-row">



                        <div className="grid grid-cols-1 gap-4 xl:gap-[4.5rem] content-between xl:grid-flow-col xl:w-fit xl:m-auto">

                            <div className="grid grid-cols-2 h-fit mt-0 mb-8 w-fit mx-auto">

                                <ProfilePicture isEditable={false} Client={eclContext.selectedClient} setProfilePictureModalActive={setProfilePictureModalActive} setSelectedClientForModal={setSelectedClientForModal} />

                                <table>
                                    <tbody>
                                        <tr>
                                            <td><span className="font-bold">Learner Name:</span></td>
                                            <td><span className="text-bold ml-2">{eclContext.selectedClient.fullName}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">Birthday:</span></td>
                                            <td><span className="text-bold ml-2">{new Date(eclContext.selectedClient.dob).toLocaleDateString()}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">Insurance Provider:</span></td>
                                            <td><span className="text-bold ml-2">{eclContext.selectedClient.insuranceName}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">RBT Required:</span></td>
                                            <td><span className="text-bold ml-2">{eclContext.selectedClient.rbtrequired == null ? '' : eclContext.selectedClient.rbtrequired ? 'Yes' : 'No'}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">Telehealth Allowed:</span></td>
                                            <td><span className="text-bold ml-2">{eclContext.selectedClient.telehealthAllowed == null ? '' : eclContext.selectedClient.telehealthAllowed ? 'Yes' : 'No'}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <div className="w-full xl:w-[16rem] m-auto xl:mt-auto xl:mb-1 ml-2">
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" onChange={event => setShowImportedHistory(event.target.checked)} checked={showImportedHistory} className="sr-only peer" />
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                <span className="ml-3 text-sm font-medium">Show Imported History</span>
                            </label>
                        </div>

                        <div className={"w-full xl:w-[16rem] ml-2 m-auto " + (showImportedHistory ? '' : 'hidden')}>
                            <label className="block font-bold" htmlFor="centers">
                                Imported History Month:
                            </label>

                            <DatePicker className="w-fit mt-4" disableFuture={true} openTo={'month'} views={['month', 'year']} value={importedHistoryDate} onChange={handleDateChange} renderInput={(params) => <TextField size="small" {...params} />} />
                        </div>


                        <div className={"w-full mx-auto " + (logsLoading ? 'hidden' : '')}>
                            <ECLLogsList Logs={logs} Therapists={therapists} ApproveLog={OnClickApproveLog} DeleteLog={OnClickDeleteLog} HoldLog={OnClickHoldLog} ShowImportedLogs={showImportedHistory} ApproveSet={OnClickApproveSet} ApproveAll={OnClickApproveAllLogs} SetCredentialsModalActive={setCredentialsModalActive} />
                        </div>

                        <div className={'w-full h-auto flex text-center mt-4 ' + (logsLoading ? '' : 'hidden')}>
                            <FontAwesomeIcon className="fill-current text-lacblue text-center w-16 h-16 animate-spin m-auto" icon={solid('spinner')} />
                        </div>

                    </div>
               
                </div>


                <ModalConfirm Message={confirmModalMessage} Active={confirmModalActive} setActive={setConfirmModalActive} OnConfirm={ApproveLog} />
                <ModalConfirm Message={confirmModalMessageLogs} Active={confirmModalActiveLogs} setActive={setConfirmModalActiveLogs} OnConfirm={ApproveLogs} />
                <ModalConfirm Message={confirmDeleteModalMessage} Active={confirmDeleteModalActive} setActive={setConfirmDeleteModalActive} OnConfirm={DeleteLog} />
                <ModalConfirm Message={confirmHoldModalMessage} Active={confirmHoldModalActive} setActive={setConfirmHoldModalActive} OnConfirm={HoldLog} />
                <ModalConfirm Message={confirmModalMessageSetLogs} Active={confirmModalActiveSetLogs} setActive={setConfirmModalActiveSetLogs} OnConfirm={ApproveSet} />
                <ProfilePictureModal Client={selectedClientForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose} />
                <ModalNotification Title={"Warning!"} Body={"<div>You must fill out your credentials and signature to modify a log.</div><div>1. Navigate to the top right of your screen</div><div>2. Click on the menu button.</div><div>3. Click on 'Edit Profile'.</div>"} Active={credentialsModalActive} Icon={"Warning"} setActive={setCredentialsModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning!"} Body={"<div>This learner has 97151 logs that are not in this set. Please add those logs to the set to continue.</div>"} Active={medicaidCheckModalActive} Icon={"Warning"} setActive={setMedicaidCheckModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning!"} Body={approveMessage} Active={approveModalActive} Icon={"Warning"} setActive={setApproveModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning: Log cannot be deleted."} Body={deleteMessage} Active={deleteModalActive} setActive={setDeleteModalActive} Icon={"Warning"} OnModalClose={function noop() { }} />


            </div>

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }



};


export function ECLViewLogs() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['ECL.Write', 'ECL.Approve']}>
                    <ECLViewLogsContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
};

function getNextBusinessDate(billingDate: any): number | Date {
    throw new Error("Function not implemented.");
}
