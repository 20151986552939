// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { CredentialsContext } from "../credentials/CredentialsContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { EDPNContext } from "./EDPNContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AbsentReason, Client, CPTCode, ClientComment, EDPNAnswer, EDPNAnswerObject, EDPNQuestion, Therapist, TherapistSmallObject, APIReturnObject, Status, Schedule } from "../../utils/Definitions";
import { Navigate, useNavigate } from "react-router-dom";
import { EDPNQuestionNode } from "../../components/edpn/EDPNQuestionNode";
import Select from "react-select";


import pic1562 from '../../images/1562.png';
import pic222 from '../../images/222.png';
import pic255 from '../../images/255.png';
import pic889 from '../../images/889.png';
import pic94 from '../../images/94.png';
import { addDays, format, isAfter, isBefore, isSameDay, isYesterday, parse, parseISO, set, subDays } from "date-fns";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import { ClassNames } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import { time } from "console";
import { timingSafeEqual } from "crypto";
import { ModalNotification } from "../../components/ModalNotification";
import { EDPNViewLogCard } from "../../components/edpn/EDPNViewLogCard";
import ProtectedPage from "../../utils/ProtectedPage";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../../components/ProfilePicture";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalConfirm } from "../../components/ModalConfirm";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

//only renders if authenticated
const EDPNViewLogContent = () => {

    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);

    const edpnContext = useContext(EDPNContext);

    const credentialsContext = useContext(CredentialsContext);

    const [loading, setLoading] = useState<boolean>(true);

    const [addLogButtonDisabled, setAddLogButtonDisabled] = useState<boolean>(true);

    const [answerObject, setAnswerObject] = useState<EDPNAnswerObject | null>(null);

    const [questions, setQuestions] = useState<EDPNQuestion[]>();

    const [filteredQuestions, setFilteredQuestions] = useState<EDPNQuestion[]>();

    const [selectedQuestion, setSelectedQuestion] = useState<EDPNQuestion | null>(null);

    const [billingDate, setBillingDate] = useState<Date | null>(new Date());
    const [timeIn, setTimeIn] = useState<Date | null>(null);
    const [timeInText, setTimeInText] = useState<string>("");

    const [timeOut, setTimeOut] = useState<Date | null>(null);
    const [timeOutText, setTimeOutText] = useState<string>("");

    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);
    const [addLogLoading, setAddLogLoading] = useState<boolean>(false);

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [futureModalActive, setFutureModalActive] = useState<boolean>(false);
    const [futureMessage, setFutureMessage] = useState<string>("");

    const [questionStack, setQuestionStack] = useState<EDPNQuestion[]>([]);

    const [editTimeVerification, setEditTimeVerification] = useState<boolean>(false);

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    function ProfilePictureOnCLose() {
        setSelectedClientForModal(null);
    }

    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);

    const [overlapModalActive, setOverlapModalActive] = useState<boolean>(false);
    const [overlapMessage, setOverlapMessage] = useState<string>("");

    const [timeModalActive, setTimeModalActive] = useState<boolean>(false);
    const [timeMessage, setTimeMessage] = useState<string>("");

    var tempFlatQuestions: EDPNQuestion[] = [];
    const [flatQuestions, setFlatQuestions] = useState<EDPNQuestion[]>([]);

    const [selectedReason, setSelectedReason] = useState<AbsentReason | null>();

    const [commentsLoading, setCommentsLoading] = useState<boolean>(true);

    const [clientComment, setClientComment] = useState<string>("");
    const [clientComments, setClientComments] = useState<ClientComment[]>([]);

    const [changeToPendingChecked, setChangeToPendingChecked] = useState<boolean>(false);

    const [changeToPendingModalActive, setChangeToPendingModalActive] = useState<boolean>(false);

    const [billingDaySchedule, setBillingDaySchedule] = useState<Schedule | null>();

    useEffect(() => {

        if (edpnContext.selectedCPTCode && answerObject) {
            setAnswerObject({ ...answerObject, cptcode: edpnContext.selectedCPTCode.value });
        }
    }, [edpnContext.selectedCPTCode]);


    //this might be the issue
    useEffect(() => {
        UpdateQuestions();
    }, []);

    useEffect(() => {
        if (!edpnContext.selectedClient) {
            navigate("/edpn/viewlogs");
        }
    })

    useEffect(() => {

        if (editTimeVerification) {
            TimeInValidateTime(false, null);
            TimeOutValidateTime(false, null);
        }
    }, [editTimeVerification])


    useEffect(() => {

        if (!answerObject) {
            ResetAnswerObject();
        }
    }, [])

    useEffect(() => {
        if (billingDate) {
            UpdateComments();
            GetSchdedule();
        }
    }, [billingDate])

    useEffect(() => {

        if (edpnContext.selectedCPTCode && questions) {
            if (questions) {
                setFilteredQuestions(questions.filter(question => String(question.cptcode) == String(edpnContext.selectedCPTCode?.value)));
            }
        }
    }, [edpnContext.selectedCPTCode, questions]);


    useEffect(() => {
        if (questions) {
            //reset tempflatquestions, then regenerate them.
            tempFlatQuestions = [];

            GenerateFlatQuestions(questions);

            setFlatQuestions(tempFlatQuestions);
        }

    }, [questions]);

    function GetSchdedule() {
        if (billingDate) {
            var dayID = new Date(billingDate).getDay() + 1;

            var schedule = credentialsContext.schedules.find(x => x.therapyScheduleId == edpnContext.selectedClient?.therapyScheduleId && x.dayOfWeekId == dayID);

            if (schedule) {
                setBillingDaySchedule(schedule);
            }
        }
    }

    function GenerateFlatQuestions(questions: EDPNQuestion[]) {
        tempFlatQuestions = tempFlatQuestions.concat(questions);

        questions.forEach((q) => {
            if (Array.isArray(q.children)) {
                GenerateFlatQuestions(q.children);
            }
        })

    }

    function UpdateQuestions() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Admin/GetQuestions?app=DPN";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("questions:", result);
                        setQuestions(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetQuestions" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateComments() {
        if (edpnContext.selectedClient && edpnContext.selectedClient.clientId && billingDate) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Clients/GetCommentsByDate?clientID=" + edpnContext.selectedClient?.clientId + "&date=" + new Date(new Date(billingDate).toLocaleString() + " +0000").toISOString();

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("comments:", result.length);
                            setClientComments(result);
                            setCommentsLoading(false);
                        },
                        (error) => {
                            setCommentsLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetCommentsByDate" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }

    }


    function SuccessModalClose() {
        NavigateBack();
    }



    function GetQuestionByID(id: number, array: EDPNQuestion[]): EDPNQuestion | undefined {
        for (const node of array) {
            if (node.questionId === id) return node;
            if (node.children) {
                const child = GetQuestionByID(id, node.children);
                if (child) return child;
            }
        }
    }

    useEffect(() => {
        if (edpnContext.selectedEditAnswer) {
            console.log("cptcode:", edpnContext.selectedEditAnswer?.cptcode);
            console.log("answers:", edpnContext.selectedEditAnswer?.answers);
        }
    }, [edpnContext.selectedEditAnswer, edpnContext.selectedEditAnswer?.answers])

    useEffect(() => {

        if (edpnContext.selectedEditAnswer && questions && edpnContext.CPTCodes && edpnContext.absentReasons) {
            //autoselect cptcode
            var cptcoderesult = edpnContext.CPTCodes.find(x => x.value == edpnContext.selectedEditAnswer?.cptcode)
            if (cptcoderesult) {
                edpnContext.setSelectedCPTCode(cptcoderesult);
            }

            //autoselect billing date
            if (edpnContext.selectedEditAnswer.billingDate) {
                setBillingDate(new Date(edpnContext.selectedEditAnswer.billingDate));
            }

            //autoselect time in
            if (edpnContext.selectedEditAnswer.startTime) {
                setTimeInText(new Date(edpnContext.selectedEditAnswer.startTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
                setEditTimeVerification(true);
            }

            //autoselect time out
            if (edpnContext.selectedEditAnswer.endTime) {
                setTimeOutText(new Date(edpnContext.selectedEditAnswer.endTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
                setEditTimeVerification(true);
            }

            //autoselect tardy reason
            var tardyresult = edpnContext.absentReasons.find(x => x.id == edpnContext.selectedEditAnswer?.tardyReasonId)
            if (tardyresult) {
                setSelectedReason(tardyresult);
            }

            //set question stack from answer
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.answers && edpnContext.selectedEditAnswer.answers[0].questionId && questions) {

                var Continue = true;
                var QuestionID = edpnContext.selectedEditAnswer.answers[0].questionId;
                while (Continue) {

                    var questionresult = GetQuestionByID(QuestionID, questions);
                    if (questionresult) {

                        //push only tree into stack, ignore actual question forms
                        if (questionresult.type == "none") {
                            var tempQuestionStack = questionStack;
                            tempQuestionStack.push(questionresult);

                            setQuestionStack(tempQuestionStack);
                        }

                        //if question has a parent, set parent as next question id
                        if (questionresult.parentQuestionId) {
                            QuestionID = questionresult.parentQuestionId;
                        }
                        else {
                            Continue = false;
                        }
                    }
                    else {
                        Continue = false;
                    }
                }
            }


        }
    }, [edpnContext.selectedEditAnswer, questions, edpnContext.CPTCodes]);

    function ResetAnswerObject() {

        var therapistID: number | null = null;
        if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.therapistID) {
            therapistID = globalContext.CoreAccount.currentUser.therapistID;
        }

        setAnswerObject({
            answers: [],
            logId: null,
            startTime: null,
            endTime: null,
            createdDate: null,
            approvedDate: null,
            clientId: edpnContext.selectedClient ? edpnContext.selectedClient.clientId : null,
            cptcode: null,
            narrative: null,
            billingDate: null,
            therapistId: therapistID,
            dpnstatusId: null,
            active: true,
            harvested: false,
            client: null,
            dpnstatus: null,
            therapist: null,
            ecldpnanswers: null,
            placeOfServiceId: null,
            centerId: null,
            updatedOn: null,
            updatedBy: null,
            approvedById: null,
            podId: null,
            clientName: null,
            comments: null,
            modeOfServiceId: null,
            isIamedicaid: null,
            isIlmedicaid: null,
            isInmedicaid: null,
            isMimedicaid: null,
            logSetId: null,
            timezone: null,
            showApproveButton: null,
            absent: false,
            absentReasonId: null,
            tardyReasonId: null,
            tardyReason: null,
            therapyScheduleId: null,
            therapySchedule: null,
            companyInsuranceId: null,
            antecendant: null,
            bcbaname: null,
            injury: null,
            scheduleDuration: null,
            dpnactualDuration: null,
            groupId: null,
        })

    }

    useEffect(() => {
        if (answerObject) {
            UpdateDiableAddLogButton();
        }
    }, [answerObject])


    useEffect(() => {
        UpdateDiableAddLogButton();
    }, [timeIn, timeOut, billingDate])

    useEffect(() => {

        if (!edpnContext.selectedServicePlace && edpnContext.servicePlaces && edpnContext.selectedEditAnswer) {
            //autoselect edit

            var placeofservicefound = edpnContext.servicePlaces.find(x => x.value == String(edpnContext.selectedEditAnswer?.placeOfServiceId));

            if (placeofservicefound) {
                edpnContext.setSelectedServicePlace(placeofservicefound);
            }
        }
        else if (!edpnContext.selectedServicePlace && edpnContext.servicePlaces) {
            //autoselect fresh
            edpnContext.setSelectedServicePlace(edpnContext.servicePlaces[0]);
        }
    }, [edpnContext.servicePlaces])

    function NavigateBack() {
        navigate(-1);
    }

    function GetTherapistNameByID(id: number | null): string {
        if (globalContext.therapists) {
            var therapist = globalContext.therapists.find(x => x.therapistId == id);
            if (therapist) {
                return therapist.employeeName;
            }
            else if (id == -1) {
                return "";
            }
            else {
                if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.name) {
                    return globalContext.CoreAccount.currentUser.name;
                }
                else {
                    return "Unknown Clinician";
                }
            }
        }
        else {
            return "Unknown Clinician";
        }
    }

    async function NoteValidation(token: string, edpnanswerobject: EDPNAnswerObject | null) {

        if (edpnanswerobject) {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(edpnanswerobject)
            };

            const response = await fetch(apiConfig.apiEndpoint + "/EDPN/NoteValidation", options);
            var result: APIReturnObject = await response.json();
            if (result.status === Status.SUCCESS) {
                setAddLogLoading(false);
                setSuccessModalActive(true);
                return false;
            }
            else if (result.status === Status.FAIL) {
                setOverlapMessage(result.message ? result.message : "Unknown error.");
                setOverlapModalActive(true);
                setAddLogLoading(false);
                return true;
            }
            else if (result.status === Status.ERROR) {
                globalContext.setErrorModalProps({ errorMessage: result.message, errorLocation: "/EDPN/NoteValidation" });
                globalContext.setErrorModalActive(true);
                setAddLogLoading(false);
                return true;
            }
        }
        else {
            return true;
        }


    }

    function EditNote() {

        setAddLogLoading(true); // Show loading spinner

        var finalAnswerObject = answerObject;

        if (answerObject && finalAnswerObject) {
            // Set cptcode if selectedCPTCode is available
            if (edpnContext.selectedCPTCode && edpnContext.selectedCPTCode.value) {
                finalAnswerObject.cptcode = edpnContext.selectedCPTCode.value;
            }

            // Set logId if selectedEditAnswer is available
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.logId) {
                finalAnswerObject.logId = edpnContext.selectedEditAnswer.logId;
            }

            // Set dpnstatusId if selectedEditAnswer is available
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.dpnstatusId) {
                finalAnswerObject.dpnstatusId = edpnContext.selectedEditAnswer.dpnstatusId;
            }

            // Set createdDate if selectedEditAnswer is available
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.createdDate) {
                finalAnswerObject.createdDate = new Date(new Date(edpnContext.selectedEditAnswer.createdDate).toLocaleString() + " +0000");
            }

            // Set billingDate, startTime, and endTime if billingDate, timeIn, and timeOut are available
            if (billingDate && timeIn && timeOut) {
                finalAnswerObject.billingDate = new Date(billingDate.toLocaleString() + " +0000")
                finalAnswerObject.startTime = new Date(timeIn.toLocaleString() + " +0000")
                finalAnswerObject.endTime = new Date(timeOut.toLocaleString() + " +0000")
            }

            // Set placeOfServiceId if selectedServicePlace is available
            if (edpnContext.selectedServicePlace) {
                finalAnswerObject.placeOfServiceId = Number(edpnContext.selectedServicePlace.value);
            }

            // Set centerId if selectedEditAnswer is available
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.centerId) {
                finalAnswerObject.centerId = edpnContext.selectedEditAnswer.centerId;
            }

            // Set podId if selectedEditAnswer is available
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.podId) {
                finalAnswerObject.podId = edpnContext.selectedEditAnswer.podId;
            }

            // Set approvedDate
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.approvedDate) {
                finalAnswerObject.approvedDate = new Date(new Date(edpnContext.selectedEditAnswer.approvedDate).toLocaleString() + " +0000");
            }

            // Set approvedByID
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.approvedById) {
                finalAnswerObject.approvedById = edpnContext.selectedEditAnswer.approvedById;
            }
        }

        globalContext.GetToken().then((token: any) => {

            //test for log overlap
            NoteValidation(token, finalAnswerObject).then((overlap) => {

                if (overlap) {
                    setAddLogLoading(false);
                }
                else {
                    var headers = new Headers();
                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    headers.append("Content-type", "application/json;charset=UTF-8");

                    var options = {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify(finalAnswerObject)
                    };

                    console.log("final answer object edit:", finalAnswerObject);

                    // Log the size of finalAnswerObject in kb for debugging purposes
                    const size = new TextEncoder().encode(JSON.stringify(finalAnswerObject)).length;
                    const kiloBytes = size / 1024;
                    console.log("size:" + kiloBytes + " kb ", finalAnswerObject);

                    var url = apiConfig.apiEndpoint + "/EDPN/EditLog";

                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result: APIReturnObject) => {
                                if (result.status === Status.SUCCESS) {
                                    setAddLogLoading(false);
                                    setSuccessModalActive(true);
                                }
                                else if (result.status === Status.FAIL) {
                                    globalContext.setNotificationModalMessage("Cannot edit log. " + result.message);
                                    globalContext.setNotificationModalActive(true);
                                    setAddLogLoading(false);
                                }
                                else if (result.status === Status.ERROR) {
                                    globalContext.setErrorModalProps({ errorMessage: "Failed to edit log. " + result.message, errorLocation: "/EDPN/EditLog" });
                                    globalContext.setErrorModalActive(true);
                                    setAddLogLoading(false);
                                }
                            },
                            (error) => {
                                globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/EditLog" });
                                globalContext.setErrorModalActive(true);
                                setAddLogLoading(false);
                            }
                        )
                }

            })

        })
    }

    function UpdateLogDPNStatus() {
        globalContext.GetToken().then((token: any) => {

            if (edpnContext && edpnContext.selectedClient && edpnContext.selectedClient.clientId && globalContext.CoreAccount.currentUser?.therapistID && changeToPendingChecked) {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                };

                var url = apiConfig.apiEndpoint + "/EDPN/UpdateLogDPNStatus?id=" + edpnContext.selectedEditAnswer?.logId + "&status=" + 2;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            if (result.status === Status.SUCCESS) {
                                UpdateComments();
                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setNotificationModalMessage("Cannot edit log. " + result.message);
                                globalContext.setNotificationModalActive(true);
                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to edit log. " + result.message, errorLocation: "/EDPN/UpdateLogDPNStatus" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/UpdateLogDPNStatus" });
                            globalContext.setErrorModalActive(true);
                        }

                    )

            }
        })
    }

    function AddCommentToLog() {
        globalContext.GetToken().then((token: any) => {

            if (edpnContext && edpnContext.selectedClient && edpnContext.selectedClient.clientId && globalContext.CoreAccount.currentUser && billingDate != null && clientComment.length > 0 && edpnContext.selectedEditAnswer?.logId) {

                var commentObject: ClientComment = {
                    clientId: edpnContext.selectedClient.clientId,
                    billingDate: new Date(new Date(billingDate).toLocaleString() + " +0000"), /*this needs to get changed to current billing date?*/
                    comment: clientComment,
                    createdOn: new Date(new Date().toLocaleString() + " +0000"),
                    createdBy: globalContext.CoreAccount.currentUser.name,
                    refId: edpnContext.selectedEditAnswer?.logId,
                };

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(commentObject)
                };

                //debug size in kb
                const size = new TextEncoder().encode(JSON.stringify(commentObject)).length
                const kiloBytes = size / 1024;
                console.log("size:" + kiloBytes + " kb ", commentObject);

                var url = apiConfig.apiEndpoint + "/Clients/AddComment";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                console.log("success adding comment");
                                if (changeToPendingChecked) {
                                    UpdateLogDPNStatus();
                                }

                                setClientComment("");
                                UpdateComments();
                            }
                            else {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to add comment. Unknown error.", errorLocation: "/Clients/AddComment" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            setErrorMessage(error);
                            setErrorModalActive(true);
                        }
                    )

            }
            else if (clientComment.length == 0) {
                globalContext.setErrorModalProps({ errorMessage: "Failed to add comment. Please be sure to input a comment before adding or sending back for corrections", errorLocation: "/Clients/AddComment" });
                globalContext.setErrorModalActive(true);
            }
            else {
                globalContext.setErrorModalProps({ errorMessage: "Failed to add comment. Unknown error.", errorLocation: "/Clients/AddComment" });
                globalContext.setErrorModalActive(true);
            }
        })
    }

    function HandleOnAddCommentClick() {
        if (changeToPendingChecked) {
            setChangeToPendingModalActive(true);
        }
        else {
            AddCommentToLog();
        }

    }


    function UpdateDiableAddLogButton() {

        //loop through all answers, if one answer is blank, set allanswerscomplete to false
        let AllAnswersComplete = true;
        if (answerObject && answerObject.answers) {

            //only check required questions
            var RequiredQuestions = flatQuestions.filter(x => x.type != "multirbt");

            //rbt present
            var RBTPresentQuestions = flatQuestions.filter(x => x.type == "rbt");

            answerObject.answers.filter(a =>
                RequiredQuestions.find(y => y.questionId == a.questionId)
                && (!a.choiceValue || a.choiceValue == -1)
                && (!a.numberValue)
                && (!a.textValue || a.textValue.length == 0 || RBTPresentQuestions.find(z => z.questionId == a.questionId && a.textValue == "-1"))
                && (a.boolValue == null)
            ).forEach(z => AllAnswersComplete = false);
        }

        //logic for add log button
        setAddLogButtonDisabled(
            !answerObject ||
            !answerObject.answers ||
            answerObject.answers.length == 0 ||
            !AllAnswersComplete ||
            timeIn == null ||
            timeOut == null
        );
    }

    const AddLogButton = () => {

        if (addLogLoading) {
            return (
                <button disabled={true} className="m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacblue-600">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else {
            return (<button onClick={() => EditNote()} disabled={addLogButtonDisabled} className={"bg-lacblue m-auto w-full w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 " + (addLogButtonDisabled ? 'bg-lacblue-800 hover:bg-lacblue-800' : '')}><span className="drop-shadow">Edit Log</span></button>)
        }
    }

    function TimeInValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempTimeInText = timeInText.replace(/\s+/g, '').trim()

        var d1 = parse(tempTimeInText, "h:mm a", new Date());
        var d2 = parse(tempTimeInText, "h:mma", new Date());
        var d3 = parse(tempTimeInText, "hmma", new Date());
        var d4 = parse(tempTimeInText, "h a", new Date());
        var d5 = parse(tempTimeInText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempTimeInText != dformatted) {
                    setTimeInText(dformatted);
                }

                //check for negative time
                if ((timeOut && isBefore(d, timeOut)) || (SiblingDate && isBefore(d, SiblingDate))) {
                    //update time in
                    setTimeIn(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeOutValidateTime(false, d);
                    }
                }
                else if (!timeOut) {
                    setTimeIn(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeOutValidateTime(false, d);
                    }
                }
                else {
                    setTimeIn(null);
                }

            }
        }
        else {
            setTimeIn(null);
        }
    }

    function TimeOutValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempTimeOutText = timeOutText.replace(/\s+/g, '').trim()

        var d1 = parse(tempTimeOutText, "h:mm a", new Date());
        var d2 = parse(tempTimeOutText, "h:mma", new Date());
        var d3 = parse(tempTimeOutText, "hmma", new Date());
        var d4 = parse(tempTimeOutText, "h a", new Date());
        var d5 = parse(tempTimeOutText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempTimeOutText != dformatted) {
                    setTimeOutText(dformatted);
                }

                //check for negative time
                if ((timeIn && isAfter(d, timeIn)) || (SiblingDate && isAfter(d, SiblingDate))) {
                    //update time out
                    setTimeOut(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeInValidateTime(false, d);
                    }
                }
                else if (!timeIn) {
                    setTimeOut(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeInValidateTime(false, d);
                    }
                }
                else {

                    setTimeOut(null);
                }
            }
        }
        else {
            setTimeOut(null);
        }
    }



    const LogEditView = () => {
        if (edpnContext.selectedEditAnswer) {
            return <EDPNViewLogCard Log={edpnContext.selectedEditAnswer} Therapists={globalContext.therapists} SetCredentialsModalActive={setCredentialsModalActive} />
        }
        else {
            return <></>
        }

    }

    if (edpnContext.selectedClient && !commentsLoading) {
        return (
            <div className="ml-auto mr-auto p-2">
                <div className="grid grid-cols-2 h-fit mt-0 mb-auto">

                    <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-40 cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to Logs</span>
                        <div className="flex-1"></div>
                    </div>




                    <ProfilePicture isEditable={false} Client={edpnContext.selectedClient} setProfilePictureModalActive={setProfilePictureModalActive} setSelectedClientForModal={setSelectedClientForModal} />

                    <table>
                        <tbody>
                            <tr>
                                <td><span className="font-bold">Learner Name:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.fullName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Birthday:</span></td>
                                <td><span className="text-bold ml-2">{new Date(edpnContext.selectedClient.dob).toLocaleDateString()}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Insurance Provider:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.insuranceName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">RBT Required:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.rbtrequired == null ? '' : edpnContext.selectedClient.rbtrequired ? 'Yes' : 'No'}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Telehealth Allowed:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.telehealthAllowed == null ? '' : edpnContext.selectedClient.telehealthAllowed ? 'Yes' : 'No'}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Billing Day Schedule:</span></td>
                                <td><span className="text-bold ml-2">{billingDaySchedule ? new Date(billingDaySchedule.startTime).toLocaleTimeString() + "-" + new Date(billingDaySchedule.endTime).toLocaleTimeString() : "N/A"}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="col-span-2 mt-8 xl:inline hidden">
                    <span className="text-lg font-bold">Original Log:</span>
                    <LogEditView />
                </div>

                {/* Comments go here*/}
                <div className="grid w-full mt-5 xl:col-span-12 col-span-12">

                    <div className="w-full border-2">
                        <span className="font-bold">Comments:</span>
                        <table className="border-separate border-spacing-3">
                            <tbody>
                                {clientComments ? clientComments.filter(x => x.refId == edpnContext.selectedEditAnswer?.logId || x.refId == null).map(comment => <tr key={comment.dpncommentId}><td>[{comment.createdOn ? format(new Date(comment.createdOn), 'M/d/yy') : ""}]</td><td>{comment.createdBy}: </td><td>{comment.comment}</td></tr>) : <></>}
                                {clientComments.filter(x => x.refId == edpnContext.selectedEditAnswer?.logId || x.refId == null).length == 0 && <tr><td className="text-rose">No Comments.</td></tr>}

                            </tbody>
                        </table>
                    </div>
                    <label className="block font-bold mt-2">
                        Add Comment:
                    </label>
                    <input maxLength={250} autoComplete="off" type="text" value={clientComment} onChange={(event) => setClientComment(event.target.value)} className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />

                    <div>
                        <div className="float-left">
                            <button onClick={HandleOnAddCommentClick} className={"bg-lacblue m-auto mt-2 w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 noprint"}><span className="drop-shadow">Add Comment</span></button>
                        </div>

                        <ProtectedComponent RequiredRoles={['EDPN.Corrections']}>
                            <div className={"pl-10 float-left mt-2 " + (edpnContext.selectedEditAnswer?.dpnstatusId == 4 ? "normal" : "hidden")} >
                                <span className="float-left">
                                    <strong>Send back for Correction: </strong><input type="checkbox" onChange={event => setChangeToPendingChecked(event.target.checked)} />
                                </span>
                            </div>
                        </ProtectedComponent>
                    </div>

                </div>

                <div className="grid gap-8 grid-cols-12 xl:w-[40rem] w-full m-auto mt-4 mb-8">

                    <div className="w-full xl:col-span-12 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Service Type:
                        </label>
                        <Select isDisabled={true} options={edpnContext.CPTCodes ? edpnContext.CPTCodes : undefined} onChange={edpnContext.setSelectedCPTCode} value={edpnContext.selectedCPTCode} />
                    </div>

                    <div className="w-full xl:col-span-6 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Place of Service:
                        </label>
                        <Select isDisabled={true} options={edpnContext.servicePlaces ? edpnContext.servicePlaces : undefined} onChange={edpnContext.setSelectedServicePlace} value={edpnContext.selectedServicePlace} />
                    </div>

                    <div className="w-full m-auto xl:col-span-4 col-span-12">
                        <label className="block font-bold">
                            Service Date:
                        </label>
                        <DatePicker
                            value={billingDate}
                            disabled={true}
                            disableFuture={true}
                            minDate={new Date()}
                            onChange={(newValue) => {
                                setBillingDate(newValue);
                            }}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </div>

                    <div className="w-full m-auto xl:col-span-4 col-span-12">
                        <label className="block font-bold" htmlFor="search">
                            Time In:
                        </label>
                        <input disabled={true} onBlur={() => TimeInValidateTime(true, null)} onChange={event => setTimeInText(event.target.value)} value={timeInText} autoComplete="off" type="text" className={"outline-none h-[38px]  text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (timeInText.length > 0 && !timeIn ? 'border-2 border-rose' : 'border border-gray-300')} placeholder="hh:mm aa" />
                    </div>

                    <div className="w-full m-auto xl:col-span-4 col-span-12">
                        <label className="block font-bold" htmlFor="search">
                            Time Out:
                        </label>
                        <input disabled={true} onBlur={() => TimeOutValidateTime(true, null)} onChange={event => setTimeOutText(event.target.value)} value={timeOutText} autoComplete="off" type="text" className={"outline-none h-[38px]  text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (timeOutText.length > 0 && !timeOut ? 'border-2 border-rose' : 'border border-gray-300')} placeholder="hh:mm aa" />
                    </div>

                    <div className="w-full xl:col-span-12 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Were there missed hours this session?:
                        </label>
                        <Select isDisabled={true} isSearchable={false} options={edpnContext.absentReasons ? edpnContext.absentReasons?.filter(x => x.absent == false) : undefined} onChange={setSelectedReason} value={selectedReason} />
                    </div>

                    <div className="w-full h-fit col-span-12">
                        {filteredQuestions && <EDPNQuestionNode EDPNQuestions={filteredQuestions} EDPNParentQuestion={null} setAnswerObject={setAnswerObject} answerObject={answerObject} Therapists={globalContext.therapists} ResetAnswerObject={ResetAnswerObject} AutoSelectQuestionStack={questionStack} FlatQuestions={flatQuestions} />}
                    </div>


                    <div className="w-full col-span-12 flex justify-between">
                        <button onClick={NavigateBack} className="bg-rose w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 flex-grow-0 mr-2">
                            <span className="drop-shadow">Cancel</span>
                        </button>
                        <div className="flex-grow"></div>
                    </div>

                </div>

                <ModalNotification Title={"Success!"} Body={"Note for " + edpnContext.selectedClient.fullName + " successfully edited."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={SuccessModalClose} />
                <ModalNotification Title={"Error!"} Body={"Contact IT if this error persists. Error Message: " + errorMessage} Active={errorModalActive} Icon={"Warning"} setActive={setErrorModalActive} OnModalClose={function noop() { }} />
                <ProfilePictureModal Client={selectedClientForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose} />
                <ModalNotification Title={"Warning!"} Body={"Failed to edit note. " + overlapMessage} Active={overlapModalActive} Icon={"Warning"} setActive={setOverlapModalActive} OnModalClose={function noop() { }} />
                <ModalConfirm Message={"Your log is " + timeMessage + " hours long. Are you sure you want to edit this note?"} Active={timeModalActive} setActive={setTimeModalActive} OnConfirm={() => EditNote()} />
                <ModalNotification Title={"Warning: Note cannot be edited."} Body={futureMessage} Active={futureModalActive} setActive={setFutureModalActive} Icon={"Warning"} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Summary"} Body={edpnContext.exampleMessage} Active={edpnContext.exampleModalActive} Icon={"Info"} setActive={edpnContext.setExampleModalActive} OnModalClose={function noop() { }} />

                <ModalConfirm Message={"Are you sure you want to send this note back for corrections?"} Active={changeToPendingModalActive} setActive={setChangeToPendingModalActive} OnConfirm={() => AddCommentToLog()} />

            </div>

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }

};




export function EDPNViewLog() {

    const authRequest = {
        ...loginRequest
    };


    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Read', 'EDPN.Write', 'EDPN.Approve']}>
                    <EDPNViewLogContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
};