import React from 'react';
import { OutcomeEvaluation, OutcomesQuestion } from '../../utils/Definitions';

interface Props {
    OutcomeEvaluation: OutcomeEvaluation | null,
    OutcomesQuestion: OutcomesQuestion | null;
    setOutcomeEvaluationObject: React.Dispatch<React.SetStateAction<OutcomeEvaluation | null>>;
    onChange: any;
    NullQuestion: any;
    GetNullValue: any;
}
export const OutcomesQuestionNullSwitch: React.FC<Props> = (props) => {

    function OnChangeNullQuestion(checked: boolean) {
        props.NullQuestion(props.OutcomesQuestion, checked);
    }

    if (props.OutcomesQuestion?.question.nullable) {
        return (
            <div className="h-fit w-fit ml-auto my-auto mr-4">
                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" className="sr-only peer" checked={props.GetNullValue(props.OutcomesQuestion)} onChange={event => OnChangeNullQuestion(event.target.checked)} />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span className="ml-3 text-sm font-medium">N/A</span>
                </label>
            </div>
        );
    }
    else {
        return <></>
    }
    
}