import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { CredentialsContext } from "../../pages/credentials/CredentialsContext";
import { Client, Schedule } from "../../utils/Definitions";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../ProfilePicture";
import { GlobalContext } from "../../utils/GlobalContext";
import { ModalNotification } from "../ModalNotification";


interface Props {
    Client: Client,
    SetProfilePictureModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    SetSelectedClientForModal: React.Dispatch<React.SetStateAction<Client | null>>,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    setCredentialsRBTModalActive: any,
}

export const EDPNClientCard: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);
    const credentialsContext = useContext(CredentialsContext);
    const [billingDaySchedule, setBillingDaySchedule] = useState<Schedule | null>();


    const edpnContext = useContext(EDPNContext);
    const navigate = useNavigate();


    useEffect(() => {
        if (edpnContext.selectedCenter) {
            GetSchdedule();
        }
    }, []);


    function NavigateToAddLog() {
        navigate("/edpn/addlog");
    }

    function NavigateToViewLogs() {
        navigate("/edpn/viewlogs");
    }

    function NavigateToLearnerInfo() {
        navigate("/edpn/learnerinfo");
    }

    function NavigateToAddAbsent() {
        navigate("/edpn/addabsent");
    }


    function WarnUserMissingCredentials() {
        props.SetCredentialsModalActive(true);
    }

    const HandleAddLog = (value?: string) => {
        if (value) {
            edpnContext.setSelectedClient(JSON.parse(value));
            NavigateToAddLog();
        }
    }

    const HandleAddAbsent = (value?: string) => {
        if (value) {
            edpnContext.setSelectedClient(JSON.parse(value));
            NavigateToAddAbsent();
        }
    }

    const HandleViewLogs = (value?: string) => {
        if (value) {
            edpnContext.setSelectedClient(JSON.parse(value));
            NavigateToViewLogs();
        }
    }

    function HandleLearnerInfo() 
    {
        edpnContext.setSelectedClient(props.Client);
        NavigateToLearnerInfo();
    }

    function HandleCornfirmRBTRequiredModal(value?: string) {
        if (value) {
            edpnContext.setSelectedClient(JSON.parse(value));
            props.setCredentialsRBTModalActive(true);
        }
    }

    function GetSchdedule() {
        var today = new Date;
        var dayID = today.getDay() + 1;

        var schedule = credentialsContext.schedules.find(x => x.therapyScheduleId == props.Client.therapyScheduleId && x.dayOfWeekId == dayID);

        if (schedule) {
            setBillingDaySchedule(schedule);
        }
    }

    const AddLogButton = () => {
        if (props.Client.absent == 0 && (!props.Client.rbtrequired || (props.Client.rbtrequired && globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.credentials.toLowerCase() != 'bt'))) {
            if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData.signatureImage) {
                return (
                    <button onClick={(e) => HandleAddLog(JSON.stringify(props.Client))} className="bg-lacblue m-auto w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Add Note</span></button>
                )
            }
            else {
                return (
                    <button onClick={WarnUserMissingCredentials} className="bg-lacblue m-auto w-full h-[3rem] xl:h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Add Note</span></button>
                )
            }
        }
        else {
            if (props.Client.rbtrequired && globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.credentials.toLowerCase() == 'bt'){
                return (
                    <button onClick={(e) => HandleCornfirmRBTRequiredModal(JSON.stringify(props.Client))} className="bg-lacblue m-auto w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Add Note</span></button>
                )
            }
            else {
                return (
                    <button className="invisible bg-lacblue m-auto w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Add Note</span></button>
                )
            }
            
        }
    }

    const ViewNotesButton = () => {
        return (
            <button onClick={(e) => HandleViewLogs(JSON.stringify(props.Client))} className="bg-lacyellow m-auto w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacyellow-600"><span className="drop-shadow">View Notes</span></button>
        )
    }

    const AbsentButton = () => {
        if (props.Client.notesCount == 0) {

            if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData.signatureImage) {
                return (
                    <button onClick={(e) => HandleAddAbsent(JSON.stringify(props.Client))} className="bg-rose m-auto w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Absent</span></button>
                )
            }
            else {
                return (
                    <button onClick={WarnUserMissingCredentials} className="bg-rose m-auto w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Absent</span></button>
                )
            }
        }
        else {
            return (<></>)
        }
    }

    const LogsToday = () => {
        if (props.Client.absent) {
            return (
                <span className={"text-red"}>Absent</span>
            )
        }
        else {
            return (
                <span>{props.Client.notesCount} ({props.Client.notesTime} hours)</span>
            )
        }
    }


    return (

        <div className={"w-full xl:w-[38rem] h-[12.5rem] bg-gray-50 border drop-shadow rounded flex " + (props.Client.absent ? "border-red border-2" : "")}>


            <div className="flex-none p-4 h-fit m-auto hidden sm:inline">
                <ProfilePicture isEditable={false} Client={props.Client} setProfilePictureModalActive={props.SetProfilePictureModalActive} setSelectedClientForModal={props.SetSelectedClientForModal} />
            </div>
            

            <div className="grid grid-flow-row grow h-fit m">
                <span onClick={HandleLearnerInfo} className="font-bold text-2xl pt-1 cursor-pointer hover:text-lacblue">{props.Client.fullName}</span>

                <span className="font-bold">BIRTHDAY:</span>
                <span>{new Date(props.Client.dob).toLocaleDateString()}</span>

                <span className="font-bold mt-1">SCHEDULE:</span>
                <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{billingDaySchedule ? new Date(billingDaySchedule.startTime).toLocaleTimeString() + "-" + new Date(billingDaySchedule.endTime).toLocaleTimeString() : "N/A"}</span>

                <span className="font-bold mt-1">LOGS TODAY:</span>
                <LogsToday/>
            </div>

            <div className="grid grid-rows-3 flex-none p-2">
                <ProtectedComponent RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                    <AddLogButton/>
                </ProtectedComponent>
                <ViewNotesButton />
                <ProtectedComponent RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                    <AbsentButton />
                </ProtectedComponent>

            </div>


        </div>
    )
    
    
};

