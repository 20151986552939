import React, { useCallback, useEffect, useState } from 'react';
import Select, { OnChangeValue, SingleValue } from "react-select";
import { ChoiceOption, OutcomeEvaluation, OutcomeEvaluationAnswer, OutcomesQuestion } from '../../utils/Definitions';
import { OutcomesQuestionNullSwitch } from './OutcomesQuestionNullSwitch';

interface Props {
    OutcomeEvaluation: OutcomeEvaluation | null,
    OutcomesQuestion: OutcomesQuestion;
    setOutcomeEvaluationObject: React.Dispatch<React.SetStateAction<OutcomeEvaluation | null>>;
    onChange: any;
    ChildChoiceOnChange: any;
    GetSelectedOption: any;
    NullQuestion: any;
    GetNullValue: any;

}

export const OutcomesQuestionTypeChoice: React.FC<Props> = (props) => {

    function IsDisabled(): boolean {
        var nullable = props.OutcomeEvaluation?.nullables.find(x => x.questionID == props.OutcomesQuestion?.question.questionId);
        if (nullable) {
            return nullable.value == "True";
        }
        else {
            return false;
        }
    }

    return (
        <div key={props.OutcomesQuestion?.question.questionId} className="w-full xl:w-[38rem] h-fit bg-gray-50 border drop-shadow rounded mt-4 p-2">
            <span className="text-xl font-bold">{props.OutcomesQuestion?.question.questionText}</span>

            <div className="w-full h-fit m-auto flex">

                {OutcomesQuestionNullSwitch({
                    onChange: props.onChange,
                    OutcomeEvaluation: props.OutcomeEvaluation,
                    OutcomesQuestion: props.OutcomesQuestion,
                    setOutcomeEvaluationObject: props.setOutcomeEvaluationObject,
                    NullQuestion: props.NullQuestion,
                    GetNullValue: props.GetNullValue,
                })}

                <div className="grow">
                    <Select isDisabled={IsDisabled()} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} options={props.OutcomesQuestion.choices ? props.OutcomesQuestion.choices : undefined} onChange={(selectedOption: OnChangeValue<ChoiceOption, false>) => props.ChildChoiceOnChange(selectedOption, props.OutcomesQuestion)} value={props.GetSelectedOption(props.OutcomesQuestion)} />
                </div>

            </div>
        </div>
    );
}

