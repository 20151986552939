import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OutcomesContext } from "../../pages/outcomes/OutcomesContext";
import { Client } from "../../utils/Definitions";
import pic1562 from '../../images/1562.png'; 
import pic222 from '../../images/222.png'; 
import pic255 from '../../images/255.png'; 
import pic889 from '../../images/889.png'; 
import pic94 from '../../images/94.png'; 
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../ProfilePicture";
import { GlobalContext } from "../../utils/GlobalContext";
import { ModalNotification } from "../ModalNotification";


interface Props {
    Client: Client,
    SetProfilePictureModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    SetSelectedClientForModal: React.Dispatch<React.SetStateAction<Client | null>>,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>

}

export const OutcomesClientCard: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);


    const outcomesContext = useContext(OutcomesContext);
    const navigate = useNavigate();

    function NavigateToAddLog() {
        navigate("/ecl/logs/addlog");
    }

    function NavigateToViewAssessments() {
        navigate("/outcomes/evaluations");
    }

    function WarnUserMissingCredentials() {
        props.SetCredentialsModalActive(true);
    }

    const HandleAddLog = (value?: string) => {
        if (value) {
            outcomesContext.setSelectedClient(JSON.parse(value));
            NavigateToAddLog();
        }
    }

    const HandleViewAssessments = (value?: string) => {
        if (value) {
            outcomesContext.setSelectedClient(JSON.parse(value));
            NavigateToViewAssessments();
        }
    }

    return (

        <div className="w-full xl:w-[38rem] h-[12rem] bg-gray-50 border drop-shadow rounded flex">


            <div className="flex-none p-4 h-fit m-auto hidden sm:inline">
                <ProfilePicture isEditable={false} Client={props.Client} setProfilePictureModalActive={props.SetProfilePictureModalActive} setSelectedClientForModal={props.SetSelectedClientForModal} />
            </div>
            

            <div className="grid grid-flow-row grow h-fit">
                <span className="font-bold text-2xl pt-1">{props.Client.fullName}</span>

                <span className="font-bold mt-4">BIRTHDAY:</span>
                <span>{new Date(props.Client.dob).toLocaleDateString()}</span>

                <span className="font-bold mt-4">START DATE:</span>
                <span>{new Date(props.Client.startDate).toLocaleDateString()}</span>

            </div>

            <div className="flex-none p-2 my-auto">
                <button onClick={(e) => HandleViewAssessments(JSON.stringify(props.Client))} className="bg-lacblue m-auto w-[8rem] h-[4rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Evaluations</span></button>
            </div>


        </div>
    )
    
    
};

