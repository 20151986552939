import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactChildren, ReactElement, ReactNode, useContext } from "react";
import { GlobalContext } from "./GlobalContext";

interface RequireType {
    children: ReactChildren | ReactNode | ReactElement;
    RequiredRoles: string[];
}

/**
 * If the user has ANY of the given RequiredRoles, renders child components. Otherwise, children will not be rendered, even in the DOM.
 */
const ProtectedComponent = ({ children, RequiredRoles }: RequireType): ReactElement => {
    const globalContext = useContext(GlobalContext);

    let hasPermission = globalContext.CheckRoles(RequiredRoles);

    if (hasPermission) {
        return <>{children}</>;
    }
    else {

        if (globalContext.CoreAccount.currentUser) {
            //doesn't have required permissions
            return <></>;
        }
        else {
            //loading permissions
            return <></>;
        }

    }
};

export default ProtectedComponent;